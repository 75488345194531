import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./MiniCalendar.css";
import { Text, Icon } from "@chakra-ui/react";
// Chakra imports
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
// Custom components
import Card from "../card/TableChakra/CardChakra";

export default function MiniCalendar(props: any) {
  const { dateFromCalendar, setDateFromCalendar, selectRange, ...rest } = props;

  console.log("Date from calender", dateFromCalendar);
  return (
    <Card
      align="center"
      direction="column"
      w="100%"
      maxW="max-content"
      p="20px 15px"
      h="max-content"
      {...rest}
    >
      <Calendar
        onChange={setDateFromCalendar}
        //changed defaultActiveStartDate to defaultValue
        // defaultActiveStartDate={dateFromCalendar}
        defaultValue={dateFromCalendar}
        selectRange={selectRange}
        view={"month"}
        tileContent={<Text color="brand.500"></Text>}
        prevLabel={<Icon as={MdChevronLeft} w="24px" h="24px" mt="4px" />}
        nextLabel={<Icon as={MdChevronRight} w="24px" h="24px" mt="4px" />}
        maxDate={new Date()}
      />
    </Card>
  );
}

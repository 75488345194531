import * as React from "react";
import {  Typography } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

export default function SubtitleTypography(props: any) {
  const typoGraphyDefaultCss = {
    color: "#000",
    fontSize: "16px",
    fontWeight: "600",
  };
  return (
    <Typography
      style={{
        fontSize: props.fontSize || typoGraphyDefaultCss.fontSize,
        color: props.color || typoGraphyDefaultCss.color,
        fontWeight: props.color || typoGraphyDefaultCss.fontWeight,
      }}
    >
      {props?.isLoading === true && (<CircularProgress />)}
      { !props?.isLoading  && (<> {props.title}</>)}
     
    </Typography>
  );
}

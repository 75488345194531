import React from "react";
import { Box, Grid, Paper } from "@mui/material";
import "./Coupon.scss";
import CouponCodeForm from "./CouponCodeForm"

interface CouponFormProps {
  onSubmit: ({ }: any) => void;
  couponcode: any,
  handleBack: () => any;
  mode: string;
  allTempleList: any[];
  allPujaList: any[];
  handleTempleSelect: ([]: any) => any
  handlePujaSelect: ([]: any) => any
  allPackagesList: any[];
  handlePackageSelect: ([]: any) => any
  seletedTempleOptions: any[];
  seletedPujaOptions: any[];
  seletedPackageOptions: any[];

}

const CouponCodeView: React.FC<CouponFormProps> = ({
  onSubmit,
  couponcode,
  handleBack,
  mode,
  allTempleList,
  handleTempleSelect,
  allPujaList,
  handlePujaSelect,
  allPackagesList,
  handlePackageSelect,
  seletedTempleOptions,
  seletedPujaOptions,
  seletedPackageOptions
}) => {
  const handleSubmit = async (data: any) => { onSubmit(data) };

console.log(seletedTempleOptions,seletedPujaOptions,seletedPackageOptions)
// console.log("Temple list in form",allTempleList)
  return (
    <>

      <Box className="pageContainer couponPage">
        <Grid container spacing={1}>
          <Grid item xs={12} md={2} lg={3} xl={3} className="pagehdLeftBlock"></Grid>
          <Grid item xs={12} md={8} lg={6} xl={6} className="pageCenterBlock">
            <Paper elevation={3} sx={{ width: "100%", padding: "30px" }} className="couponContainer">
              <CouponCodeForm
                couponcode={couponcode}
                onSubmit={handleSubmit}
                handleBack={handleBack}
                mode={mode}
                allTempleList={allTempleList}
                handlePujaSelect={handlePujaSelect}
                handleTempleSelect={handleTempleSelect}
                allPujaList={allPujaList}
                allPackagesList={allPackagesList}
                handlePackageSelect={handlePackageSelect}
                seletedTempleOptions={seletedTempleOptions} 
                seletedPujaOptions={seletedPujaOptions} 
                seletedPackageOptions={seletedPackageOptions}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={2} lg={3} xl={3} className="pagehdRightBlock"></Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CouponCodeView;

import capricornRashi from "../../assets/horoscopes/capricorn.png";
import aquariusRashi from "../../assets/horoscopes/aquarius.png";
import piscesRashi from "../../assets/horoscopes/pisces.png";
import ariesRashi from "../../assets/horoscopes/aries.png";
import taurusRashi from "../../assets/horoscopes/taurus.png";
import geminiRashi from "../../assets/horoscopes/gemini.png";
import cancerRashi from "../../assets/horoscopes/cancer.png";
import leoRashi from "../../assets/horoscopes/leo.png";
import virgoRashi from "../../assets/horoscopes/virgo.png";
import libraRashi from "../../assets/horoscopes/libra.png";
import scorpioRashi from "../../assets/horoscopes/scorpio.png";
import sagittariusRashi from "../../assets/horoscopes/sagittarius.png";



export function getSunSign(day:number, month:number) {
    const sunSigns = [
        { name: "Capricorn", startDate: new Date(0, 0, 1), endDate: new Date(0, 0, 19),rashiImage:capricornRashi },
        { name: "Aquarius", startDate: new Date(0, 0, 20), endDate: new Date(0, 1, 18),rashiImage:aquariusRashi },
        { name: "Pisces", startDate: new Date(0, 1, 19), endDate: new Date(0, 2, 20),rashiImage:piscesRashi },
        { name: "Aries", startDate: new Date(0, 2, 21), endDate: new Date(0, 3, 19),rashiImage:ariesRashi },
        { name: "Taurus", startDate: new Date(0, 3, 20), endDate: new Date(0, 4, 20),rashiImage:taurusRashi },
        { name: "Gemini", startDate: new Date(0, 4, 21), endDate: new Date(0, 5, 20),rashiImage:geminiRashi },
        { name: "Cancer", startDate: new Date(0, 5, 21), endDate: new Date(0, 6, 22),rashiImage:cancerRashi },
        { name: "Leo", startDate: new Date(0, 6, 23), endDate: new Date(0, 7, 22),rashiImage:leoRashi },
        { name: "Virgo", startDate: new Date(0, 7, 23), endDate: new Date(0, 8, 22),rashiImage:virgoRashi },
        { name: "Libra", startDate: new Date(0, 8, 23), endDate: new Date(0, 9, 22),rashiImage:libraRashi },
        { name: "Scorpio", startDate: new Date(0, 9, 23), endDate: new Date(0, 10, 21),rashiImage:scorpioRashi },
        { name: "Sagittarius", startDate: new Date(0, 10, 22), endDate: new Date(0, 11, 21),rashiImage:sagittariusRashi },
        { name: "Capricorn", startDate: new Date(0, 11, 22), endDate: new Date(0, 11, 31),rashiImage:capricornRashi }
    ];

    const birthDate = new Date(0, month , day);

    for (let i = 0; i < sunSigns.length; i++) {
        if (birthDate >= sunSigns[i].startDate && birthDate <= sunSigns[i].endDate) {
            return  { rashiName : sunSigns[i].name , rashiImage: sunSigns[i].rashiImage  } ;
        }
    }

    return null;
}

export function getSignInfo(name:string) {
    const sunSigns = [
        { name: "Capricorn", startDate: new Date(0, 0, 1), endDate: new Date(0, 0, 19),rashiImage:capricornRashi },
        { name: "Aquarius", startDate: new Date(0, 0, 20), endDate: new Date(0, 1, 18),rashiImage:aquariusRashi },
        { name: "Pisces", startDate: new Date(0, 1, 19), endDate: new Date(0, 2, 20),rashiImage:piscesRashi },
        { name: "Aries", startDate: new Date(0, 2, 21), endDate: new Date(0, 3, 19),rashiImage:ariesRashi },
        { name: "Taurus", startDate: new Date(0, 3, 20), endDate: new Date(0, 4, 20),rashiImage:taurusRashi },
        { name: "Gemini", startDate: new Date(0, 4, 21), endDate: new Date(0, 5, 20),rashiImage:geminiRashi },
        { name: "Cancer", startDate: new Date(0, 5, 21), endDate: new Date(0, 6, 22),rashiImage:cancerRashi },
        { name: "Leo", startDate: new Date(0, 6, 23), endDate: new Date(0, 7, 22),rashiImage:leoRashi },
        { name: "Virgo", startDate: new Date(0, 7, 23), endDate: new Date(0, 8, 22),rashiImage:virgoRashi },
        { name: "Libra", startDate: new Date(0, 8, 23), endDate: new Date(0, 9, 22),rashiImage:libraRashi },
        { name: "Scorpio", startDate: new Date(0, 9, 23), endDate: new Date(0, 10, 21),rashiImage:scorpioRashi },
        { name: "Sagittarius", startDate: new Date(0, 10, 22), endDate: new Date(0, 11, 21),rashiImage:sagittariusRashi },
        { name: "Capricorn", startDate: new Date(0, 11, 22), endDate: new Date(0, 11, 31),rashiImage:capricornRashi }
    ];

    

    for (let i = 0; i < sunSigns.length; i++) {
        if (name == sunSigns[i].name ) {
            return  { rashiName : sunSigns[i].name , rashiImage: sunSigns[i].rashiImage  } ;
        }
    }

    return null;
}

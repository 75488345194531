import TempleDetailsView from "../../Views/Temple/TempleDetailsView";
import {
  mainCategories,
  upcomingPujas,
  popularTemples,
  posts,
  featuresToDispaly,
} from "../../utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import templedetailbanner from "../../assets/postprofile3.jpg";
import { useEffect } from "react";
import {
  UserService,
  TempleService,
  PanchangService,
  PostService,
  AuthService,
} from "td_server";
import { Link, useLocation } from "react-router-dom";
import LazyLoadingScreen from "../../Screen/LazyLoading/LazyLoadingScreen";
import { getTempleDetailsById,getTempleDetailsByNameKey } from "../../store/actions/templeAction";
import { getOneImageForTemple } from "../../utils/HelperFunctions";
import { Box } from "@mui/material";
import ParentView from "../../Views/PrarentView/ParentView";
function TempleDetailsScreen(props:any) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: any = useDispatch();
  const { selectedTempleDetails } = useSelector(
    (state: any) => state.temple
  );

  const templePersonalInfo = selectedTempleDetails && selectedTempleDetails.personalInfo ? selectedTempleDetails.personalInfo : {}
  console.log("Temple personal info", templePersonalInfo)


  const templeProfilePic = () => {
    let imageArrayForTemple = templePersonalInfo && templePersonalInfo.media && templePersonalInfo.media.pictures && templePersonalInfo.media.pictures.length ? templePersonalInfo.media.pictures : []
    let templeProfilePic = getOneImageForTemple(imageArrayForTemple)
    return templeProfilePic;
  };
  const goToBookPujaScreen = () => {
    navigate("/templePujas?templeNameKey=" + selectedTempleDetails?.nameKey);
  };

  useEffect(() => {
    // console.log("in fetch temle",templeIdComing)
    let keyType = props?.keyType
    if(keyType&&keyType==="id"){
      const templeIdComing = location.pathname.split("/")[2];
      dispatch(getTempleDetailsById(templeIdComing))
      .then((res:any)=>console.log("Response"))
      .catch((err:any) => console.log("Error in fetching temple",err))
    }
    else{
      const params = new URLSearchParams(location.search);
      const templeId = params.get('templeNameKey')
      const templeIdComing =templeId
      dispatch(getTempleDetailsByNameKey(templeIdComing))
    .then((res:any)=>console.log("Response"))
    .catch((err:any) => console.log("Error in fetching temple",err))
      
    }
    
  }, []);

  return (
    <ParentView>
      <TempleDetailsView
        templeDetails={selectedTempleDetails}
        templePersonalInfo={templePersonalInfo}
        goToBookPujaScreen={goToBookPujaScreen}
        templeProfilePic={templeProfilePic}
      />
    </ParentView>
  );
}

export default TempleDetailsScreen;

import { useEffect, useState } from "react";
import TempleView from "../../Views/Temple/TempleView";
import { UserService, TempleService, PanchangService, PostService } from "td_server";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import ParentView from "../../Views/PrarentView/ParentView";
import LiveDarshanView from "../../Views/LiveDarshan/LiveDarshanView";
import { getDarshanList } from "../../store/actions/templeAction";
function LiveDarshanScreen() {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  var { darshanList } = useSelector((state: any) => state.temple);
  useEffect(()=>{
    dispatch(getDarshanList())
  },[])

  const goToTempleDarshan = (darshanId:any)=>{
    navigate("/livedarshantemple/"+darshanId)
  }


    return (
      <ParentView>
        <LiveDarshanView darshanList={darshanList} goToTempleDarshan={goToTempleDarshan}/>
      </ParentView>
    );
  }
  
  export default LiveDarshanScreen;
  
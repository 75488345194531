import ChooseProfileView from "../../Views/Auth/ChooseProfile/ChooseProfileView";
import devotee from "../../assets/chooseProfile/devotee.png"
import org from "../../assets/chooseProfile/organization.png"
import pandit from "../../assets/chooseProfile/pandit.svg"
import pujasamagri from "../../assets/chooseProfile/pujaSamagri.png"
import astrologer from "../../assets/chooseProfile/astrologer.png"
import { Outlet,useNavigate  } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSignUpProfile } from "../../store/slices/authSlice";
import { logoutAction } from "../../store/actions/authAction";
import { useEffect } from "react";
// import { setProfileSelectedDuringSignup ,logOutUser} from "../../redux/reducer/AuthReducer/AuthSlice";
function ChooseProfileScreen() {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const {userInfo} = useSelector((state: any) => state.user);
  const profiles = [
    { id: 1, title: "Devotee",img:devotee,userType:"NORMALUSER" },
    // { id: 2, title: "Organization" ,img:org,userType:"ORGANISATION" },
    // { id: 3, title: "Pandit",img:pandit,userType:"PANDIT"},
    // { id: 4, title: "Pooja Samagri",img:pujasamagri},
    // { id: 5, title: "Astrologer",img:astrologer},
  ];

  useEffect(()=>{
    if(!userInfo){
      navigate("/login")
    }
    //For now directly navigate to Signup screen
    else{
      dispatch(setSignUpProfile(profiles[0]));
    navigate("/signup")
    }
  },[])

  const selectOneProfile = (profileSelected:any)=>{
    dispatch(setSignUpProfile(profileSelected));
    navigate("/signup")
  }

  const cancelAndGoToLogin = ()=>{
dispatch(logoutAction())
  }
  return (
    <div>
      <ChooseProfileView profiles={profiles} selectOneProfile={selectOneProfile} cancelAndGoToLogin={cancelAndGoToLogin} />
    </div>
  );
}

export default ChooseProfileScreen;

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import ParentView from "../../Views/PrarentView/ParentView";
import PostsContentViews from "../../Views/Blogs/PostsContentViews";
import { useDispatch, useSelector } from "react-redux";
import {
  addLikeToBlog,
  countLikesOnBlog,
  getBlogById,
  hasUserLikedBlog,
  removeLikeFromBlog,
} from "../../store/actions/BlogAction";
import { setMessageToReactNative } from "../../utils/WebNativeFunctions";
function PostsContentScreen(props: any) {
  const navigate = useNavigate();
  const [parentViewCheck, setParentViewCheck] = useState(true);
  const location = useLocation();
  const dispatch: any = useDispatch();
  const { isLoading, error, singleSelectedPost } = useSelector(
    (state: any) => state.blogs
  );
  const { userInfo } = useSelector((state: any) => state.user);
  const { id } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    dispatch(getBlogById(id));
  }, [id]);

  useEffect(() => {
    const pathData = location.pathname;
    if (pathData.includes("nativeViews")) {
      props.setIsMobileView(true);
      setParentViewCheck(false);
    }
  }, [location]);

  useEffect(() => {
    if (singleSelectedPost) {
      setPost(singleSelectedPost);
    }
  }, [singleSelectedPost]);

  const [likeCount, setlikeCount] = useState(0);
  const [userLikedBlog, setUserLikedBlog] = useState(false);

  const getLikeCount = () => {
    dispatch(countLikesOnBlog(id))
      .then((response: any) => {
        const data = response;
        setlikeCount(data);
      })
      .catch((error: any) => {
        console.error("Error in counting likes:", error);
      });
  };

  const checkUserLiked = () => {
    if (userInfo?.id) {
      dispatch(hasUserLikedBlog(id, userInfo?.id))
        .then((response: any) => {
          const data = response;
          setUserLikedBlog(data);
        })
        .catch((error: any) => {
          console.error("Error in counting likes:", error);
        });
    }
  };

  useEffect(() => {
    checkUserLiked();
  }, [userInfo?.id]);

  useEffect(() => {
    getLikeCount();
  }, [id]);

  const handleLikeClick = () => {
    if (userInfo) {
      if (userLikedBlog) {
        dispatch(removeLikeFromBlog(id, userInfo.id))
          .then(() => {
            setUserLikedBlog(false);
            getLikeCount();
          })
          .catch((error: any) => {
            console.error("Error removing like:", error);
          });
      } else {
        dispatch(addLikeToBlog(id, userInfo.id))
          .then(() => {
            setUserLikedBlog(true);
            getLikeCount();
          })
          .catch((error: any) => {
            console.error("Error adding like:", error);
          });
      }
    } else {
      if (!parentViewCheck) {
        setMessageToReactNative(JSON.stringify({ notLoggedIn: true }));
      } else {
        navigate("/login");
      }
    }
  };
  const handleBackToBlog = () => {
    const url = parentViewCheck ? `/posts` : `/nativeViews/posts`;
    navigate(url);
  };

  return (
    <>
      {parentViewCheck ? (
        <ParentView>
          <PostsContentViews
            parentViewCheck={parentViewCheck}
            post={post}
            onLikeClick={handleLikeClick}
            likeCount={likeCount}
            checkUserLiked={userLikedBlog}
            backToBlog={handleBackToBlog}
          />
        </ParentView>
      ) : (
        <PostsContentViews
          parentViewCheck={parentViewCheck}
          post={post}
          onLikeClick={handleLikeClick}
          likeCount={likeCount}
          checkUserLiked={userLikedBlog}
          backToBlog={handleBackToBlog}
        />
      )}
    </>
  );
}

export default PostsContentScreen;

import React, { useState, useEffect } from "react";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { useLoadScript } from "@react-google-maps/api";
import { googleMapKey } from "../../configs/firebaseConfig";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { convertMinutesToOffset } from "../../utils/HelperFunctions";
const libraries: "places"[] = ["places"];

const CityAutocomplete: React.FC<any> = ({
  onChangeHandle,
  variant = "outlined",
  label = "Enter Your Birth Location",
  placeholder = "Start typing city...",
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapKey,
    libraries,
  });

  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState<any[]>([]);

  useEffect(() => {
    if (inputValue === "") {
      setOptions([]);
      return;
    }

    const service = new window.google.maps.places.AutocompleteService();
    const request = {
      input: inputValue,
      types: ["(cities)"],
      // componentRestrictions: { country: 'in' },
    };

    service.getPlacePredictions(request, (predictions: any, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        setOptions(
          predictions.map((prediction: any) => ({
            label: prediction.description,
            value: prediction.place_id,
          }))
        );
      }
    });
  }, [inputValue]);

  const handlePlaceSelect = (placeId: string) => {
    const service = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
    const request = {
      placeId,
      fields: [
        "name",
        "utc_offset_minutes",
        "geometry.location",
        "address_components",
      ],
    };

    service.getDetails(request, (place: any, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const lat = place?.geometry?.location?.lat();
        const lng = place?.geometry?.location?.lng();
        const utc_offset_minutes = place?.utc_offset_minutes
          ? convertMinutesToOffset(place?.utc_offset_minutes)
          : "0";
        const addressComponents = place?.address_components || [];
        const countryComponent = addressComponents.find((component: any) => {
          return component.types.includes("country");
        });
        const country: any = countryComponent ? countryComponent.long_name : "";
        const countryCode: any = countryComponent
          ? countryComponent.short_name
          : "";
        onChangeHandle({
          label: place?.name,
          value: placeId,
          utc_offset_minutes,
          lat,
          lng,
          country,
          countryCode,
        });
      }
    });
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option.label}
      filterOptions={(x) => x}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event, value) => {
        // onChangeHandle(value);
        if (value) {
          handlePlaceSelect(value.value);
        }
        // else {
        //   onChangeHandle(null);
        // }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label || undefined}
          variant={variant}
          placeholder={placeholder}
        />
      )}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <LocationOnIcon style={{ marginRight: 8 }} />
          <Typography variant="body2">{option.label}</Typography>
        </Box>
      )}
    />
  );
};

export default CityAutocomplete;

import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import ParentView from "../../Views/PrarentView/ParentView";
import AIGurujiView from "../../Views/AIGurujiView/AIGurujiView";
import { connectSocket, disconnectSocket } from "../../utils/socketConnection";
import { v4 as uuidv4 } from "uuid";
import { Socket } from "socket.io-client";
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import {
  generateUniqueNumberString,
  getCurrentTime,
  getDateToday,
  getDynamicPdfFileName,
} from "../../utils/HelperFunctions";
import { idID } from "@mui/material/locale";
import { error } from "console";
import { useDispatch, useSelector } from "react-redux";
import {
  createChat,
  deleteChat,
  downloadGurujiChatPDF,
  getChatByUserId,
  updateChat,
} from "../../store/actions/gurujiChatAction";
import moment from "moment";

// function for creating tables (new tables)
function parseTable(tableString: any) {
  const lines = tableString.trim().split("\n");

  // Extract the header and rows
  const header = lines[1]
    .split("|")
    .map((item: any) => item.trim())
    .filter(Boolean);
  const rows = lines.slice(3, -1).map((line: any) =>
    line
      .split("|")
      .map((item: any) => item.trim())
      .filter(Boolean)
  );

  return { header, rows };
}

interface AIGurujiHODProps {
  openModal: boolean;
  closeModal: () => void;
  isMobileView: boolean;
}

const AIGurujiHOD: React.FC<AIGurujiHODProps> = ({
  openModal,
  closeModal,
  isMobileView,
}) => {
  const dispatch: any = useDispatch();
  const [messages, setMessages] = useState<any[]>([]);
  const [roomId, setRoomId] = useState<string>("");
  const [socket, setSocket] = useState<Socket | null>(null);
  const [selectedLang, setSelectedLang] = useState<string>("");
  const [selectedServices, setSelectedServices] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [isChatInputBoxDisebal, setIsChatInputBoxDisebal] = useState(true);
  const selectedServicesRef = useRef(selectedServices);
  const [currentPayload, setcurrentPayload] = useState<any>(null);
  const [isPlayingAudioId, setIsPlayingAudioId] = useState<any>(null);
  const currentPayloadRef = useRef(currentPayload);
  const messagesRef = useRef(messages);
  const [availableButton, SetAvailableButton] = useState<any>([]);
  const [isOnline, setIsOnline] = React.useState(navigator.onLine);
  const { userInfo } = useSelector((state: any) => state.user);
  const { isDownloading } = useSelector((state: any) => state.gurujiChat);
  const [userChat, setUserChat] = useState<any | null>(null);
  const [userId, setUserId] = useState<any>(null);
  const [userChatHistory, setUserChatHistory] = useState<any>(null);
  const [exportChatId, setExportChatId] = useState<any>(null);

  useEffect(() => {
    if (!isDownloading) {
      setExportChatId(null);
    }
  }, [isDownloading]);
  useEffect(() => {
    selectedServicesRef.current = selectedServices;
  }, [selectedServices]);

  useEffect(() => {
    currentPayloadRef.current = currentPayload;
  }, [currentPayload]);

  useEffect(() => {
    messagesRef.current = messages;
    if (userId && selectedServices) {
      updateChatHistory();
    }
  }, [messages]);

  useEffect(() => {
    const newSocket = connectSocket();
    setSocket(newSocket);
    const generatedRoomId = uuidv4();
    setRoomId(generatedRoomId);
    newSocket.on("connect", () => {
      console.log("Connected to the server");
    });

    newSocket.on("available_actions", (data) => {
      data["type"] = "receive";
      data["id"] = generateUniqueNumberString();
      if (messagesRef?.current?.length < 2) {
        setMessages([data]);
        setIsLoading(false);
        setIsChatInputBoxDisebal(true);
      }
      if (messagesRef?.current?.length > 2) {
        setIsLoading(false);
        setIsChatInputBoxDisebal(false);
        SetAvailableButton(data?.available_actions);
      }

      console.log("-----", data);

      // else if(messagesRef?.current[messagesRef?.current?.length].contentType !== "menu"){
      //   setMessages((prevMessages) => [...prevMessages, data]);
      // }
      // else{

      // }
    });

    newSocket.on("response", (data) => {
      deliverResponseMessages(data);
    });

    return () => {
      disconnectSocket();
    };
  }, []);

  useEffect(() => {
    if (userChat) {
      console.error(`userChat:::`, userChat);
      if (userChat.length > 0) {
        setcurrentPayload(userChat[0]?.currentPayload);
        setUserChatHistory(userChat);
        setMessages(userChat[0]?.chatHistory);
        setIsLoading(false);
        setIsChatInputBoxDisebal(false);

        if (userChat[0].chatHistory?.length > 0) {
          const lastItem =
            userChat[0].chatHistory[userChat[0].chatHistory?.length - 1];
          if (lastItem) {
            const lang = lastItem?.extra_parameter?.language
              ? lastItem?.extra_parameter?.language
              : lastItem?.language;

            const service = lastItem?.service
              ? lastItem?.service
              : lastItem?.extra_parameter?.service;
            SetAvailableButton(lastItem?.buttons);
            setSelectedLang(lang);
            setSelectedServices(service);
          }
        }
      }
    }
  }, [userChat]);

  useEffect(() => {
    if (openModal === true) {
      if (userId) {
        dispatch(getChatByUserId(userId))
          .then((_response: any) => {
            if (_response) {
              setUserChat(_response);
            } else {
              setUserChat(null);
              handleSendMessage();
            }
          })
          .catch((error: any) => {
            setUserChat(null);
            handleSendMessage();
          });
      }
    } else {
      //-------Modal Closed
      setMessages([]);
      handleSendMessage();
    }
  }, [openModal, userId]);

  useEffect(() => {
    console.log(`userInfo`, userInfo);
    if (userInfo) {
      setUserId(userInfo.id);
    }
  }, [userInfo]);

  const cleanJsonString = (jsonString: any) => {
    return jsonString
      .replace(/`/g, "")
      .replace(/\\n/g, " ")
      .replace(/\\t/g, " ")
      .replace(/\s+/g, " ")
      .trim();
  };

  const deliverResponseMessages = (rdata: any) => {
    SetAvailableButton([]);
    const data = { ...rdata };
    const currentSelectedServices = selectedServicesRef.current;
    if (currentSelectedServices?.trim() === "ai_numerology") {
      if (data["service"] && data["service"] === "ai_numerology") {
        data["contentType"] = "json";
        data["formateResponse"] = JSON.parse(cleanJsonString(data["response"]));
        console.log(data["formateResponse"]?.buttons);
        //SetAvailableButton(data?.buttons)
        data["currentPayload"] = currentPayloadRef.current;
        let tempCardSection = null;
        if (
          data["formateResponse"] &&
          data["formateResponse"]["cards_section"]
        ) {
          const tdata = [...data["formateResponse"]["cards_section"]];
          tempCardSection = tdata.map((item: any) => {
            const imgKey =
              item.title?.trim().replaceAll(" ", "_").toLowerCase() + "_svg";
            console.log("title", item.title);
            console.log("imgKey", imgKey);
            item["imageUrl"] = data["cards_section_icon"][imgKey];
            return item;
          });
        }
      } else {
        data["contentType"] = "text";
      }
      if (data?.error) {
        data["response"] = data["error"];
      }
    } else if (currentSelectedServices?.trim() === "ai_horoscope") {
      if (data["service"] && data["service"] === "ai_horoscope") {
        data["contentType"] = "json";
        //data["formateResponse"] = data;
        data["currentPayload"] = currentPayloadRef.current;
        data["final_table_report"] = data?.final_table_report || [];
        //SetAvailableButton(data?.buttons)
      } else {
        data["contentType"] = "text";
      }
      if (data?.error) {
        data["response"] = data?.error;
        data["contentType"] = "error";
      }
      console.log(data);
    } else {
      data["contentType"] = "text";
    }

    if (data?.buttons) {
      SetAvailableButton(data?.buttons);
    }

    //data["currentPayload"] = currentPayloadRef.current;
    data["id"] = generateUniqueNumberString();
    data["service"] = currentSelectedServices?.trim();
    data["type"] = "receive";
    data["currentTime"] = getCurrentTime();
    console.log("deliverResponseMessages", data);
    setMessages((prevMessages) => [...prevMessages, data]);
    setIsLoading(false);
    setIsChatInputBoxDisebal(false);
  };

  const handleSendMessage = () => {
    handleLanguageSwitch("EN");
  };

  const getChatAfterUpdate = () => {
    dispatch(getChatByUserId(userId))
      .then((_response: any) => {
        if (_response) {
          setUserChat(_response);
        } else {
          setUserChat(null);
          handleSendMessage();
        }
      })
      .catch((error: any) => {
        console.log("get Chat History", error);
        setUserChat(null);
        handleSendMessage();
      });
  };

  const updateChatHistory = () => {
    if (messages.length > 2) {
      if (userChat) {
        dispatch(updateChat(userId, messages, currentPayload))
          .then((_response: any) => {})
          .catch((error: any) => console.log(error));
      } else {
        dispatch(createChat(userId, messages, userInfo.phoneNumber))
          .then((_response: any) => {
            getChatAfterUpdate();
          })
          .catch((error: any) => console.log(error));
      }
    }
  };

  const handleLanguageSwitch = (lang: any) => {
    setSelectedLang(lang);

    if (messages.length > 2) {
      setIsLoading(false);
      setIsChatInputBoxDisebal(false);
      //return;
    }

    if (socket) {
      setIsLoading(true);
      setIsChatInputBoxDisebal(true);
      socket.emit("initial_message", {
        action_id: "initial_message",
        language: lang,
        session_id: roomId,
      });
    }
  };

  const handleMenuSelect = (item: any) => {
    if (!item?.extra_parameters?.service) return;
    console.log(
      `item?.extra_parameters?.service`,
      item?.extra_parameters?.service
    );
    setSelectedServices(item?.extra_parameters?.service || "");
    setIsLoading(false);

    if (item?.extra_parameters?.service === "ai_guruji") {
      setIsChatInputBoxDisebal(false);
      setcurrentPayload(null);
    } else {
      setIsChatInputBoxDisebal(true);
    }

    const welcomeMessage =
      selectedLang === "HI"
        ? `प्रणाम भक्त! मैं वाल्मीकि, आपका अपना एआई गुरुजी। मैं यहाँ आपकी सभी आध्यात्मिक और धार्मिक प्रश्नों में आपकी सहायता के लिए हूँ। नीचे अपना प्रश्न पूछें और जीवन के हर पथ में मार्गदर्शन प्राप्त करें।`
        : `Pranam Bhakt! I'm Valmiki, your own AI Guruji. I'm here to help you through all your spiritual and religious queries. Ask your question below and get guidance for every path in your life.`;

    const showInChatSentRightSelectedMenuData = {
      contentType: "text",
      response: item?.name,
      service: item?.extra_parameters?.service,
      type: "send",
      language: selectedLang,
      currentTime: getCurrentTime(),
      id: generateUniqueNumberString(),
    };

    setMessages((prevMessages) => [
      ...prevMessages,
      showInChatSentRightSelectedMenuData,
    ]);

    const showWelcomeMessageData: any = {
      contentType: "text",
      response: welcomeMessage,
      service: item?.extra_parameters?.service,
      type: "receive",
      language: selectedLang,
      id: generateUniqueNumberString(),
    };

    setMessages((prevMessages) => [...prevMessages, showWelcomeMessageData]);

    if (item?.extra_parameters?.service.toLowerCase() !== "ai_guruji") {
      const sendMenuSelectActionData: any = {
        contentType: "menu",
        response: item?.name,
        service: item?.extra_parameters?.service,
        type: "send",
        language: selectedLang,
        id: generateUniqueNumberString(),
      };
      setMessages((prevMessages) => [
        ...prevMessages,
        sendMenuSelectActionData,
      ]);
    }
  };

  const sendMessageToBoat = (payload: any) => {
    console.log(`payload-------`, payload);
    setIsLoading(true);
    setIsChatInputBoxDisebal(true);
    if (socket) {
      socket.emit(selectedServices, payload);
    }
  };

  const handleSendInpute = (val: any) => {
    if (val?.trim().length > 0 && selectedLang?.trim().length > 0) {
      setIsLoading(true);
      setIsChatInputBoxDisebal(true);
      if (currentPayload) {
        const newPayload = JSON.parse(JSON.stringify(currentPayload));
        newPayload["extra_parameters"]["service"] = selectedServices + "_chat";
        newPayload["extra_parameters"]["question"] = val;
        newPayload["extra_parameters"]["language"] = selectedLang;
        sendMessageToBoat(newPayload);
      } else {
        sendMessageToBoat({
          action_id: "selected_action",
          name: selectedServices,
          extra_parameters: {
            session_id: roomId,
            language: selectedLang,
            question: val,
            service: selectedServices,
          },
        });
      }

      const data = {
        contentType: "text",
        response: val,
        language: selectedLang,
        currentTime: getCurrentTime(),
        type: "send",
      };
      setMessages((prevMessages) => [...prevMessages, data]);
    }
  };

  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const [player, setPlayer] = useState<any>(null);
  const [speechSynthesizerM, setSpeechSynthesizerM] = useState<any>(null);

  // useEffect(() => {
  //   console.log("isPlaying", isPlaying);
  // }, [isPlaying]);

  const setPlayerIconPlay = () => {
    console.log("call to change icon");
    setIsPlaying(false);
    setIsPlayingAudioId(null);
  };
  const translateAndSynthesize = async (transText: any, id: any) => {
    console.log(
      "translateAndSynthesize---",
      transText,
      selectedLang,
      isPlaying,
      isPlayingAudioId,
      id,
      typeof isPlayingAudioId,
      typeof id
    );
    if (isPlaying && id === isPlayingAudioId) {
      try {
        player?.pause();
        // player.close((e: any) => {
        //   console.log(
        //     `------player----Close---isPlaying && id === isPlayingAudioId--`,
        //     e
        //   );
        // });
        // speechSynthesizerM.close();
        // //  console.log("inside 1st if");

        // setPlayer(null);
        //setPlayerIconPlay();
        setIsPlaying(false);
        return;
      } catch (error) {
        setPlayerIconPlay();
        console.error(error);
      }
    } else if (!isPlaying && id === isPlayingAudioId) {
      setIsPlaying(true);
      player?.resume();
      return;
    } else if (isPlaying && transText.length > 5) {
      try {
        player?.pause();
        player?.close((e: any) => {
          console.log(`------player----Close---isPlaying--`, e);
        });
        // console.log("inside 2nd if");

        speechSynthesizerM.close();
        setIsPlaying((prev) => {
          setPlayer(null);
          return true;
        });
      } catch (error) {
        //setPlayerIconPlay();

        console.error(error);
      }
    } else if (isPlaying) {
      try {
        player?.pause();
        player?.close((e: any) => {
          console.log(`------player----Close---isPlaying--`, e);
        });
        //console.log("inside 3rd if");

        speechSynthesizerM?.close();
        setIsPlaying((prev) => {
          setPlayer(() => {
            return null;
          });
          return false;
        });
      } catch (error) {
        setPlayerIconPlay();
        console.error(error);
      }
    } else {
      setIsPlaying(true);
    }

    setIsPlayingAudioId(id);

    try {
      let xlang = "";
      let voiceName = "";
      if (selectedLang === "HI") {
        xlang = "hi-IN";
        voiceName = "hi-IN-AaravNeural";
      } else {
        voiceName = "en-IN-PrabhatNeural";
        xlang = "en-IN";
      }

      const ssmlData = `
           <speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="${xlang}">
             <voice name="${voiceName}">
                <mstts:express-as style="customerservice">
                <prosody rate="1%" pitch="5%">${transText}</prosody>
                </mstts:express-as>
             </voice>
           </speak>`;

      const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(
        "0aa3523aa55d4a0a95c94aac3ab9f987",
        "centralindia"
      );
      const iplayer = new SpeechSDK.SpeakerAudioDestination();
      setPlayer(iplayer);

      const audioConfig = SpeechSDK.AudioConfig.fromSpeakerOutput(iplayer);
      const speechSynthesizer = new SpeechSDK.SpeechSynthesizer(
        speechConfig,
        audioConfig
      );
      setSpeechSynthesizerM(speechSynthesizer);

      //setIsPlaying(true);
      // dispatch(setIsPlaying(true));
      iplayer.onAudioStart = (e: any) => {
        console.log(`------PlayBack Start--`, e);
        //setIsPlaying(true);
      };

      iplayer.onAudioEnd = function (e: any) {
        console.log(`------PlayBack end--`, e);
        setPlayerIconPlay();
        //dispatch(setIsPlaying(false));
      };

      speechSynthesizer.synthesisStarted = function (s, e) {
        // setIsPlaying(true);
        // dispatch(setIsPlaying(true));
      };

      // if(!isPlaying){

      // }

      speechSynthesizer.speakSsmlAsync(
        ssmlData,
        (result) => {
          if (
            result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted
          ) {
          } else {
            console.error("Speech synthesis canceled, " + result.errorDetails);
            setPlayerIconPlay();
          }
          speechSynthesizer.close();
        },
        (error) => {
          console.error(error);
          speechSynthesizer.close();
        }
      );
    } catch (error) {
      console.error("Translate And Synthesize :: ", error);
    }
  };

  const addFormDataToLastItem = (formData: any) => {
    // Update the last item by adding formData to it
    setMessages((prevData) => {
      const updatedData = [...prevData]; // Make a copy of the current state
      updatedData[updatedData.length - 1] = {
        // Get the last item
        ...updatedData[updatedData.length - 1], // Copy the properties of the last item
        formData: formData, // Add the new formData property
      };
      return updatedData; // Return the updated array
    });
  };

  const handleCalculateNumerology = (data: any, formData: any) => {
    if (formData) {
      addFormDataToLastItem(JSON.stringify(formData));
    }

    const payload: any = {
      action_id: "selected_action",
      name: "AI Numerology",
      extra_parameters: {
        session_id: roomId,
        language: selectedLang,
        service: selectedServices,
        ...data,
      },
    };
    console.log(`payload`, payload);
    setIsLoading(true);
    setIsChatInputBoxDisebal(true);
    sendMessageToBoat(payload);
    setcurrentPayload(payload);
  };

  const handleCalculateHoroscope = (data: any, formData: any) => {
    if (formData) {
      addFormDataToLastItem(JSON.stringify(formData));
    }
    const payload: any = {
      action_id: "selected_action",
      name: "AI Horoscope",
      extra_parameters: {
        session_id: roomId,
        language: selectedLang,
        service: selectedServices,
        ...data,
      },
    };

    setIsLoading(true);
    setIsChatInputBoxDisebal(true);
    sendMessageToBoat(payload);
    setcurrentPayload(payload);
  };

  const handleBackToMainMenu = () => {
    if (isPlaying) {
      translateAndSynthesize("", isPlayingAudioId);
    }
    SetAvailableButton([]);
    setMessages([]);
    //debugger;
    if (userChat[0]?.id) {
      dispatch(deleteChat(userChat[0].id))
        .then(() => {
          setUserChat(null);
        })
        .catch((error: any) => {
          console.log("get Chat History", error);
          setUserChat(null);
        });
    }

    if (socket) {
      setIsLoading(true);
      setIsChatInputBoxDisebal(true);
      socket.emit("initial_message", {
        action_id: "initial_message",
        language: selectedLang,
        session_id: roomId,
      });
    }
    // setTimeout(() => {
    //   handleLanguageSwitch(selectedLang && selectedLang ==="HI" ? "HI":"EN" )
    // }, 500);
  };

  const handleEditForm = (id: any) => {
    const tempMessage = [...messages];
    const index = tempMessage.findIndex((tempMessage) => tempMessage.id === id);
    console.log("Form", tempMessage[index - 1]);
    if (tempMessage[index - 1]) {
      setMessages((prevMessages) => [...prevMessages, tempMessage[index - 1]]);
    }

    // if (index !== -1) {
    //   tempMessage.splice(index);
    // }
    //SetAvailableButton([]);
    //setMessages(tempMessage);
  };

  // const handleEditForm = (id: any, newId: any) => {
  //   const tempMessage = [...messages];
  //   const index = tempMessage.findIndex(message => message.id === id);
  //   if (index !== -1) {

  //     //const copiedMessage = { ...tempMessage[index], id: newId };
  //     //tempMessage.push(copiedMessage);
  //   }
  //   //setMessages(tempMessage);
  const onCloseAiChat = () => {
    if (isPlaying) {
      translateAndSynthesize("", isPlayingAudioId);
    }
    closeModal();
  };

  const handleDownload = (chat: any, matchedExport: any) => {
    if (chat?.id) {
      setExportChatId(chat?.id);
    }
    if (
      chat?.service === "ai_horoscope" ||
      chat?.service === "ai_numerology" ||
      chat?.service === "ai_guruji"
    ) {
      const serviceData =
        chat?.service !== "ai_guruji"
          ? messages
              ?.slice(
                matchedExport[chat?.service]?.start,
                matchedExport[chat?.service]?.end + 1
              )
              .reverse()
              .find(
                (item: any) =>
                  item?.type === "receive" &&
                  item?.contentType === "json" &&
                  item?.service === chat?.service
              )
          : messages
              ?.slice(
                matchedExport[chat?.service]?.start,
                matchedExport[chat?.service]?.end + 1
              )
              .reverse()
              .find(
                (item: any) =>
                  item?.type === "receive" &&
                  item?.contentType === "text" &&
                  item?.service === chat?.service
              );
      let payload: any = {
        service: serviceData?.service,
        contentType: serviceData?.contentType,
        type: serviceData?.type,
        defaultMessage: serviceData?.response,
        chat_history: chat.chat_history ? chat?.chat_history : [],
        currentTime: serviceData?.currentTime,
        language: serviceData?.extra_parameter?.language,
        CurrentDate: getDateToday(),
      };
      const shouldAddUserDetails =
        chat?.service === "ai_horoscope" || chat?.service === "ai_numerology";
      if (shouldAddUserDetails) {
        const userFormData = messages
          ?.slice(
            matchedExport[chat?.service].start,
            matchedExport[chat?.service].end + 1
          )
          .reverse()
          .find(
            (item: any) => item?.contentType === "menu" && item?.type === "send"
          )?.formData;

        const usrPData = JSON.parse(userFormData);
        // console.log("userform-Data--", userFormData)
        payload["userBirthDetails"] = {
          language: serviceData?.extra_parameter?.language,
          fullName: usrPData?.fullName,
          gender: usrPData?.gender,
          birthLocation: usrPData?.birthLocation,
          nation: usrPData?.nation,
          timeOfBirth: usrPData?.timeOfBirth,
          dateOfBirth: usrPData?.dateOfBirth,
        };
      }

      if (chat?.service === "ai_horoscope") {
        // Old Table
        const newSrting = serviceData?.table_report
          ?.replaceAll("-", "")
          ?.replaceAll("+", "");
        const lines: string[] = newSrting ? newSrting.trim().split("\n") : [];

        const header: string[] =
          lines.length > 2
            ? lines[2]
                ?.split("|")
                ?.map((item) => item.trim())
                .filter((item) => item)
            : [];
        const rows: string[][] =
          lines.length > 2
            ? lines.slice(3).map((line) =>
                line
                  .split("|")
                  .map((item) => item?.trim())
                  .filter((item) => item)
              )
            : [[]];
        let rowfinal = rows.filter((item: any) => item.length !== 0);

        // New Table
        const tables = serviceData.final_table_report
          ? serviceData?.final_table_report.map((item: any) => {
              const tableData = parseTable(item.tableValue);
              return { name: item.name, [item.name]: tableData };
            })
          : [];

        payload["birth_chart_url"] = serviceData?.birth_chart_url;
        payload["lagna_chart_url"] = serviceData?.lagna_chart_url;
        payload["planet_table_report"] = {
          header: [...header],
          row: [...rowfinal],
        };
        payload["finalTables"] = tables;
        payload["moon_sign"] = serviceData?.moon_sign;
        payload["sun_sign"] = serviceData?.sun_sign;
      }

      if (chat?.service === "ai_numerology") {
        payload["cards_section_icon"] = serviceData?.cards_section_icon;
        payload["cards_section"] = serviceData?.formateResponse?.cards_section;
        payload["number_calculations"] =
          serviceData?.formateResponse?.number_calculations;
      }

      console.log("----54----payload(numerology) for export---: ", payload);
      dispatch(downloadGurujiChatPDF(payload, isMobileView));
    } else {
      console.log("selected other service");
    }
  };
  return (
    <AIGurujiView
      open={openModal}
      closeModal={onCloseAiChat}
      onLanguageSwitch={handleLanguageSwitch}
      chatMessage={messages}
      onMenuSelect={handleMenuSelect}
      sendInpute={handleSendInpute}
      isLoading={isLoading}
      isChatInputBoxDisebal={isChatInputBoxDisebal}
      transcribeAudio={translateAndSynthesize}
      calculateNumerology={handleCalculateNumerology}
      selectedLang={selectedLang}
      calculateHoroscope={handleCalculateHoroscope}
      isPlayingAudioId={isPlayingAudioId}
      handleBackToMainMenu={handleBackToMainMenu}
      handleEditForm={handleEditForm}
      availableButton={availableButton}
      selectedServices={selectedServices}
      isPlaying={isPlaying}
      handleDownload={handleDownload}
      isDownloading={isDownloading}
      exportChatId={exportChatId}
      isMobileView={isMobileView}
    ></AIGurujiView>
  );
};

export default AIGurujiHOD;

 
 import {

    Input,
  
  } from "@chakra-ui/react";
 const DefaultColumnFilter= ({
    column: { filterValue, setFilter },
  }) => {
    return (
      <Input
        variant="filled"
        size="sm"
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        placeholder={"Filter"}
        htmlSize={4}
        width="auto"
      />
    );
  };

  export default DefaultColumnFilter;
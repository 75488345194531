import React from 'react';
import { Container, Typography, Link, Box } from '@mui/material';
import "./TermsAndConditionsView.scss"

const TermsAndConditions = () => {
    return (

        // <Box className="pageContainer privacyPolicy">
        //     <Container className="container">
        //         <div className="sectionHead text-center style-4 mb-40">
        //             <small className="title_small d-none"></small>
        //             <Typography className="mainTitle mb-20">
        //                 Temple Dekho <span >Privacy</span> <span> Policy </span>
        //             </Typography>

        //         </div>
        //         <div className="privacyInfoDetails mb-40">
        //             <Typography variant="body1" className="mb-20">
        //                 At Omkara Infotech Pvt. Ltd., creators of the "Temple Dekho App", safeguarding your privacy is paramount to us. This Privacy Policy outlines how we collect, utilize, and disclose your data when you engage with our platform, including our website <a href="https://templedekho.com">(https://templedekho.com)</a> and our mobile application, Temple Dekho ("App"). Together, these are referred to as the "Platform".
        //                 <br></br>
        //                 <br></br>
        //                 Throughout this Privacy Policy, "we", "our", "us", or the "Company" refer to Omkara Infotech Pvt. Ltd. "You", "your", or "user" denote any individual or entity utilizing our Platform. We pledge not to utilize or distribute your information to any party unless expressly outlined in this Privacy Policy.
        //                 <br></br>
        //                 <br></br>
        //                 This Privacy Policy constitutes an electronic record under the Information Technology Act, 2000 (as amended / re-enacted) and its rules, specifically Rule 3(1) of the Information Technology (Intermediaries Guidelines and Digital Media Ethics Code) Rules, 2021. By accessing and using this Platform, you agree to adhere to the terms and conditions outlined in this Privacy Policy, as well as our Terms of Use and Temple Dekho Content Guidelines. Furthermore, you consent to our collection, use, and disclosure of your Personal Information (as defined below) as described herein. If you do not agree with these terms and conditions, please refrain from using this Platform.
        //                 <br></br>
        //                 <br></br>
        //                 Ensuring the protection and security of your personal information is our utmost priority. We have implemented all necessary and reasonable measures to safeguard the confidentiality of user information and its transmission over the internet.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 By utilizing our Services and accessing the Platform, or by providing us with your information through any means, you are agreeing to the terms outlined in this Privacy Policy. Your use of our Services constitutes express consent to our practices regarding the collection, use, and disclosure of your Personal Information (as defined below) as described in this Privacy Policy. By continuing to use our Services and the Platform, you acknowledge and agree to abide by both this Privacy Policy and our Terms of Use. If you do not agree with these terms, please refrain from subscribing to our Services, using the Platform, or providing us with any of your information.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 This Privacy Policy applies only to information collected by the Company through the Platform, as outlined in the "Collection of Information" section above. It does not cover any unsolicited information you provide through this Platform or by any other means. Such unsolicited information, including information posted in public areas of the Platform, is considered non-confidential, and the Company is free to use and disclose it without restriction.
        //                 <br></br>
        //                 <br></br>
        //                 The Company also protects your Personal Information offline as specified in this Privacy Policy. Access to your Personal Information is limited to employees, agents, partners, and third parties who the Company reasonably believes need the information to provide Services to you. However, the Company is not responsible for the confidentiality, security, or distribution of your Personal Information by our partners and third parties outside the scope of our agreement with them.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 <Typography variant="h5" className="mb-10 mt-10 fw-bold">We collect and process personal information provided by you through the Platform, our partners, or any engagement with us. This includes, but is not limited to:</Typography>
        //                 <ul>
        //                     <li>1. Information you voluntarily provide, such as your name, phone number, gender, photograph, date of birth, time of birth, and place of birth.</li>
        //                     <li>2. Data automatically captured by the Platform, including your mobile phone operating system, domain name, and IP address to help us identify your location. This information reveals nothing personal beyond the IP address from which you accessed the Platform.</li>
        //                     <li>3. Contacts list from your mobile device, accessed only with your consent, and you have the option to deny this access.</li>
        //                 </ul>
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 <Typography variant="h5" className="mb-10 mt-10 fw-bold">Information Security</Typography>
        //                 Information used for logging in includes your name, phone number or email, password or OTP, profile photo, and IP address. During registration, we collect your phone number and request access to SMS messages to send a One-Time-Password ("OTP") for identity confirmation.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 We do not store any payment or card-related information, such as card number, account number, validity date, expiry date, or CVV number when you make payments through the Platform.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 In addition to Login Information, we also collect gender, date of birth, address, and any biography provided in your user profile.
        //                 <br></br>
        //                 <br></br>
        //                 During registration on our Platform, we collect your phone number and request access to SMS messages on your device by sending a One-Time-Password ("OTP"). You confirm your identity by entering the OTP.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 <Typography variant="h5" className="mb-10 mt-10 fw-bold">This encompasses all information you choose to share with other users on the Platform, such as:</Typography>
        //                 <ul>
        //                     <li>1. Any information that you voluntarily share about yourself, such as quotes, images, political views, religious beliefs, and more.</li>
        //                     <li>2. Your posts and comments on the Platform, which include public profile details, photos, and videos captured with your device's camera, as well as any reposts from other users. This also includes associated Location Data and Log Data.</li>
        //                     <li>3. Information about you shared by other users, including communications directed to you, along with associated Location Data and Log Data.</li>
        //                     <li>4. Information obtained from third-party sources, including business partners, technical subcontractors, analytics providers, etc. This data may be shared internally and combined with information collected on our Platform.</li>
        //                 </ul>
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 <Typography variant="h5" className="mb-10 mt-10 fw-bold">"Log Data" refers to information automatically collected during your use of the Platform, which may include but is not limited to:</Typography>
        //                 <ul>
        //                     <li>1. Technical details such as mobile carrier information, configuration settings from your web browser or other accessing programs, your IP address, and device version and identification numbers.</li>
        //                     <li>2. Details of your activities on the Platform, including search queries, visited social media profiles, utilized mini applications, and specifics of other accessed or requested information and content.</li>
        //                     <li>3. General information regarding your interactions on the Platform, including the identity of users you communicate with, and timestamps, dates, and durations of these interactions.</li>
        //                     <li>4. Metadata associated with items you share through the Platform, such as the date, time, or location of shared photographs or videos.</li>
        //                 </ul>
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 Our Platform utilizes cookies to distinguish you from other users, enhancing your browsing experience and enabling us to continually improve our services. We collect cookie data stored on your device.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 When you participate in events or surveys, we may request certain Personal Information from you, which can identify you. If we utilize a third-party service provider to conduct these surveys, you will be informed prior to completing the survey.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 We may access your mobile device's contact list, but only with your explicit permission. You have the option to deny us access to this information.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 "Location Information" encompasses data obtained from your GPS, IP address, and/or public posts containing location details. You may share the following location information with us and other Platform users when utilizing specific location-based features, such as entering your Prasad Delivery Address or any other features introduced on our Platform. Additionally, we derive location information from your IP address, device, or internet service when you access the Platform, aiding in the prevention of multiple or fraudulent log-ins to your account.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 Any information you provide to our customer support team regarding assistance or support you may need when using our Platform.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 <Typography variant="h5" className="mb-10 mt-10 fw-bold">"Device Data" encompasses, but is not limited to, the following:</Typography>
        //                 <ul>
        //                     <li>1. Identifiers: Unique identifiers such as device IDs obtained from games, apps, or accounts you use.</li>
        //                     <li>2. Device attributes: Information such as operating system details, hardware and software versions, battery status, signal strength, available storage space, browser type, app names and types, and installed plugins.</li>
        //                     <li>3. Network and connections: Details such as the name of your mobile operator or internet service provider (ISP), language settings, time zone, phone number, IP address, and connection speed.</li>
        //                     <li>4. Device operations: Information regarding actions and behaviours performed on the device, such as whether an application window is active or in the background.</li>
        //                     <li>5. Device signals: Collection of Bluetooth signals and information about nearby Wi-Fi access points, beacons, and cell towers.</li>
        //                     <li>6. Data from device settings: Information you authorize us to access via device settings, such as GPS location, camera usage, or access to photos.</li>
        //                     <li>7. Event Information: Any details you provide, such as Family Members’ Names, Gotra, Wishes, etc., when submitting entries for any events offered on the Platform from time to time.</li>
        //                     <li>8. Media: We may access your mobile device's media gallery, including but not limited to images and videos stored on your phone. Rest assured, we always seek your explicit permission before accessing your images, and you have the option to decline such access.</li>
        //                 </ul>
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 <Typography variant="h5" className="mb-10 mt-10 fw-bold">Service Provision and Improvement</Typography>
        //                 <ul>
        //                     <li>1. To help provide you with the Services.</li>
        //                     <li>2. To observe, improve, and administer the quality of Service.</li>
        //                     <li>3. To analyze how the Platform is used and diagnose technical problems.</li>
        //                     <li>4. To remember basic information provided by you for effective access.</li>
        //                     <li>5. To notify you about any changes to the Platform.</li>
        //                     <li>6. To develop new features, improve existing features, and incorporate user feedback and requests.</li>
        //                     <li>7. To understand how you use the Platform to enhance your user experience.</li>
        //                     <li>8. To optimize your overall user experience on our Platform.</li>
        //                 </ul>
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 <Typography variant="h5" className="mb-10 mt-10 fw-bold">Identity and Security</Typography>
        //                 <ul>
        //                     <li>1. To confirm your identity and determine your eligibility to use the Platform and avail the Services.</li>
        //                     <li>2. To verify your identity and maintain account security.</li>
        //                     <li>3. To enforce the Company’s Terms of Use and other policies.</li>
        //                     <li>4. To protect our rights and the rights of other Platform users.</li>
        //                     <li>5. To regulate the Platform and conduct business operations such as troubleshooting, data analysis, testing, research, security, fraud detection, account management, and surveys.</li>
        //                 </ul>
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 <Typography variant="h5" className="mb-10 mt-10 fw-bold">Communication and User Support</Typography>
        //                 <ul>
        //                     <li>1. To facilitate communication and provide user support.</li>
        //                     <li>2. For the purpose of sending administrative notices, Service-related alerts, and similar communication.</li>
        //                     <li>3. To keep you informed about Platform changes, including updates to this Privacy Policy.</li>
        //                     <li>4. To handle support issues you may encounter.</li>
        //                 </ul>
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 <Typography variant="h5" className="mb-10 mt-10 fw-bold">Legal and Regulatory Compliance</Typography>
        //                 <ul>
        //                     <li>1. To enable the Company to comply with its legal and regulatory obligations.</li>
        //                     <li>2. In accordance with TRAI regulations, to inform you that we may reach out to users registered on the National Do Not Call (DND) registry through calls and SMS for essential communications related to our services.</li>
        //                 </ul>
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 <Typography variant="h5" className="mb-10 mt-10 fw-bold">Personalization and Location-Based Services
        //                 </Typography>
        //                 <ul>
        //                     <li>1. To connect you with other Platform users through various features of the Platform.</li>
        //                     <li>2. To provide location-based services that you choose to use.</li>
        //                     <li>3. To customize the Platform experience for your mobile device.</li>
        //                     <li>4. To facilitate the sharing of Platform content through applications installed on your mobile device.</li>
        //                 </ul>
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 <Typography variant="h5" className="mb-10 mt-10 fw-bold">Data Analysis and Market Research</Typography>
        //                 <ul>
        //                     <li>1. To do market research, troubleshooting, protection against error, project planning, fraud, and other criminal activity.</li>
        //                     <li>2. To pseudonymize and aggregate information, including Personal Information, for user data analysis (e.g., region, phone model, operating system, language, and system version) to better understand user behavior.</li>
        //                     <li>3. To pseudonymize and aggregate information, including Personal Information, for collecting web and account traffic statistics related to third-party services accessed via the Platform.</li>
        //                     <li>4. To evaluate and improve the effectiveness of advertising, marketing, and promotional activities.</li>
        //                 </ul>
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 <Typography variant="h5" className="mb-10 mt-10 fw-bold">Account Management</Typography>
        //                 <ul>
        //                     <li>1. Creating and managing your Platform user account.</li>
        //                     <li>2. Verifying your identity to enforce our terms, conditions, and policies.</li>
        //                     <li>3. Facilitating your participation in events and potentially awarding prizes, if applicable.</li>
        //                 </ul>
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 <Typography variant="h5" className="mb-10 mt-10 fw-bold">Media and Storage Management</Typography>
        //                 <ul>
        //                     <li>1. Facilitating the sharing of media (videos and images) through the Platform.</li>
        //                     <li>2. Checking if your device has sufficient storage for downloading content from the Platform for sharing via WhatsApp and/or Facebook.</li>
        //                 </ul>
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 <Typography variant="h5" className="mb-10 mt-10 fw-bold">Event Participation</Typography>
        //                 Enabling your participation in events and potentially awarding prizes, if applicable.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 When you use our Platform to share content, you have control over who can view it. For instance, when you share content from our Platform on Facebook, you can specify whether to share it with a friend, a group of friends, or all your friends. Similarly, when using WhatsApp or another mobile app to share Platform content, you choose the recipients within your community. We do not oversee nor are we responsible for how such individuals (whom you choose to share content with via sharing options like WhatsApp or Facebook available on our Platform) utilize the information you share with them.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 Public content, such as posts or comments on your user profile or community pages, is accessible to all, including search engines. Any information voluntarily disclosed for posting on the Platform, including profile page details, can be viewed by anyone. When you submit, post, or share content in a public community on the Platform, it may be viewed and redistributed by others.
        //                 <br></br>
        //                 <br></br>
        //                 You should carefully consider whom you share content with, as individuals who can view your activity on our Platform may choose to further distribute it both within and outside the intended audience, including individuals not originally targeted.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 Communities on Temple Dekho can be Private or Public. In Private Communities, members require approval from Admins or Founders to join. Admins or Founders can change a Community's security from Private to Public and vice versa.
        //                 <br></br>
        //                 <br></br>
        //                 When you share a post in the Community, users can download or re-share it across platforms. Your comments and likes on others' posts are visible to their viewers.
        //                 <br></br>
        //                 <br></br>
        //                 You can also set the Community to private, allowing only approved followers to see your posts. Users can create and share content about you, which may appear on various platforms. We do not rent or sell your Personal Information unless anonymized as per our Terms.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 We may share your Personal Information with any entity within our corporate group. The term "Group" refers to any entity controlled by us, controlling us, or under common control with us, whether directly or indirectly.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 <Typography variant="h5" className="mb-10 mt-10 fw-bold">We may share your information, including Personal Information, with specific third parties under the following conditions:</Typography>
        //                 <ul>
        //                     <li>1. Affiliates: This includes business partners, suppliers, and subcontractors who assist in providing, understanding, and enhancing the Service and their own services as necessary for the performance of any contract we enter with you.</li>
        //                     <li>2. Government and Law Enforcement: We may share your personal data or information with government bodies or law enforcement agencies if we have a good-faith belief that such sharing is reasonably necessary to comply with legal obligations or government requests, protect the rights, property, or safety of the Company, our customers, or the public, or to address issues related to public safety, fraud, security, or technical matters.
        //                         Additionally, we may disclose your information, including Personal Information, in the following circumstances:</li>
        //                     <li className="mt-10">1.Business Transfers: If the Company or a substantial portion of its assets is acquired by a third party, personal data held about customers will be among the transferred assets. If we are involved in a merger, acquisition, bankruptcy, reorganization, or sale of assets where your information may be transferred or subject to a different Privacy Policy, we will notify you in advance. You can opt out of any such new policy by deleting your account before the transfer occurs.</li>
        //                     <li>2.Enforcement of Terms and Agreements: Disclosure may be necessary to enforce our Terms and/or any other agreements.</li>
        //                 </ul>
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 To safeguard the information we gather, we have instituted suitable technical and security measures. If you have been issued (or have chosen) a username and password to access the Platform, it is your responsibility to keep them confidential. Please refrain from sharing your password with anyone.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 We store your personal information on servers provided by Microsoft Azure, located at 15010 NE 36th Street, Microsoft Campus Building 92, Redmond, WA 98052 and Google LLC, located at (1101 S Flower St, Burbank, California 91502, USA, both in India and internationally. Microsoft Azure and Google Cloud Platform employ security measures to safeguard against the loss, misuse, and alteration of information. Detailed information about these security measures can be found at their respective websites: https://azure.microsoft.com/en-in and https://cloud.google.com. For further details, you can review the privacy policies of Microsoft Azure and Google Cloud Platform at https://privacy.microsoft.com/en-us/privacystatement and https://policies.google.com/privacy.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 The Company may regularly update this Privacy Policy. Whenever changes are made, we will post the updated Privacy Policy at this link for your awareness. It is your responsibility to check this page regularly to stay informed about any revisions to this Privacy Policy.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 Regrettably, internet information transmission is not entirely secure. While we strive to protect your Personal Information, we cannot guarantee the security of any data transmitted to the Platform; transmission is at your own risk. We employ stringent procedures and security features to prevent unauthorized access once we receive your information.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 You have the option to delete or remove content from your user account/profile at any time. However, please note that we retain access to the history of your activities and account on our Platform. You can correct, update, supplement, or delete Personal Information from your account by logging in and visiting your profile page. Additionally, you can opt out of receiving unwanted email communications from us by following the instructions provided in the message.
        //                 <br></br>
        //                 <br></br>
        //                 Until your account is deleted, you will continue to receive system emails. According to Section 5(6) of the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011 ("Rules"), you have the right to request a review, correction, or amendment of the information we collect about you at any time.
        //                 <br></br>
        //                 <br></br>
        //                 Under Section 5(7) of the Rules, you also have the right to withdraw your consent to the collection of your information for future processing. However, please be aware that withdrawing your consent may impact your ability to fully utilize the Platform. For example, access to features such as posting pictures from your mobile device may be restricted if you do not grant us permission to access your media folder and camera.
        //                 <br></br>
        //                 <br></br>
        //                 You may also request that we refrain from processing your personal data for marketing purposes. You can exercise any of these rights by contacting us at support@templedekho.com or on our Customer Support Number. Please note that we require a reasonable period of 30 (thirty) days to process your requests.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 We do not retain your Sensitive Personal Information (further defined below in this paragraph) longer than necessary for lawful purposes. While we will consider your request to delete other content, copies of any public content may be retained indefinitely in our systems, including cached and archived pages of the Platform, or if other users have copied or saved that information. Additionally, due to the nature of the internet, copies of your content, even if removed or deleted from your account, may persist elsewhere online indefinitely.
        //                 <br></br>
        //                 <br></br>
        //                 "Sensitive Personal Information" includes passwords and any other information classified as sensitive under Section 3 of the Rules.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 Occasionally, the Platform may include links to websites of partner networks, advertisers, affiliates, or other websites and mobile applications. Please be aware that these websites have their own privacy policies, and we do not assume any responsibility or liability for these policies. Prior to submitting any personal data to these websites or mobile applications, please review their respective privacy policies.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 Certain content displayed on the Platform is not hosted by us but rather embedded from third-party sources. These "embeds" include content hosted by external parties and integrated into the Platform. For instance, YouTube videos or Giphy gifs that appear within a Platform post are examples of such embeds. When you access a Platform page containing an embedded YouTube video, for instance, data about your activity is transmitted to YouTube as if you were visiting their site directly.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 Regarding privacy, third-party embeds on the Platform operate independently from our control. We do not regulate the data collection practices or subsequent use by third parties. Therefore, these third-party embeds are governed solely by the privacy policy of the respective third-party service provider and are not covered by this Privacy Policy.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 Certain embeds on the Platform may prompt you to provide Personal Information, such as your email address, through a form. While we strive to maintain a secure environment and prevent misuse on the Platform, we cannot control how third parties handle your information in such cases. As previously noted, these actions fall outside the scope of this Privacy Policy. Therefore, exercise caution when encountering embedded forms on the Platform requesting your email address or other Personal Information. Ensure you fully understand the recipient and their intended use of your information before submission. We advise against submitting Personal Information through embedded forms to third parties.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">

        //                 <ul>
        //                     <li>1. Cookies are small files that websites place on your computer while you browse the web. Similar to many other websites, we use cookies to understand how people use our Platform and to enhance their functionality.</li>
        //                     <li>2. A pixel is a small piece of code embedded on a web page or in an email notification. Like many websites, we use pixels to track interactions with specific web or email content. This helps us analyze and enhance our Platform and personalize your experience.</li>
        //                     <li>3. Local storage is a standard technology that allows websites or applications to store information locally on your computer or mobile device. We utilize local storage to tailor the content you see based on your past interactions with our Platform.</li>
        //                 </ul>
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 These technologies serve various purposes, including enhancing your experience, ensuring security for both you and our users, and providing efficient services on our Platform. They enable easier navigation, faster access to features, and personalized content. For example, they help us retain authentication details to facilitate specific services, remember your language preferences, and offer location-based services like Tracking Your Shipment. Additionally, these technologies allow us to gather data on your Platform usage, such as popular pages and encountered errors, and aggregate visitor statistics over time. It's important to note that while these cookies do not personally identify you, they may collect personally identifiable information like your name and profile picture solely for functional purposes. We maintain transparency by clearly outlining what information is collected, its intended use, and any parties with whom it may be shared.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 We utilize two types of cookies on our Platform: "session cookies" and "persistent cookies." Session cookies are temporary and remain on your device until you exit our Platform, while persistent cookies persist on your device for a longer period or until manually deleted, depending on the cookie's lifetime and your app settings.
        //                 <br></br>
        //                 <br></br>
        //                 Additionally, certain pages may employ pixel tags (also known as clear gifs) to gather information, which may be shared with third parties supporting our promotional efforts and Platform development. For instance, data on Platform visitors' usage may be shared with our third-party advertising agency to enhance the targeting of internet banner advertisements on the Platform. This information, however, is not personally identifiable, although it may be associated with your personal information.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">

        //                 <ul className="mb-10">
        //                     <li>1. Essential Cookies: These cookies are crucial for the proper functioning of our Platform and enable you to access its features, such as secure log-in authentication and protection against fraudulent activities. Without these cookies, the Platform cannot retain your actions from previous visits within the same session, but they do not identify you individually.</li>
        //                     <li>2. Analytical Cookies: These cookies allow us to analyze how our Platform is used, helping us continuously enhance its performance. They provide insights into visitor interactions, including areas visited, time spent on pages, and encountered issues like error messages. These cookies collect data anonymously and do not identify you personally.</li>
        //                     <li>3. Preference Cookies: Functionality cookies help the Platform remember your preferences (such as language settings and customization preferences), maintain accessibility options, and indicate when you are logged in. They are essential for delivering requested services and enhancing your user experience on our Platform.</li>
        //                 </ul>
        //                 If you choose not to accept these cookies, it could impact the performance and functionality of the Platform, potentially limiting access to its content. These cookies might gather personally identifiable information that you have provided, such as your name or profile picture. We are committed to transparency regarding the data we collect, how it is utilized, and with whom it is shared.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 We partner with several suppliers who may set cookies on your device while you visit our Platform, enabling these third parties to provide their respective services.
        //                 <br></br>
        //                 <br></br>
        //                 Visiting our Platform may result in receiving cookies from third-party websites or domains. We strive to identify these cookies in advance so you can choose whether to accept them. Further details about these cookies may be found on the respective third party's website.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 Initially, most internet browsers are configured to accept cookies automatically. You have the option to modify these settings to block cookies or to receive notifications when cookies are sent to your device. There are various methods available for managing cookies.
        //                 <br></br>
        //                 <br></br>
        //                 Disabling the cookies we utilize may affect your experience on the Platform. For instance, you may be unable to access certain parts of our Platform or receive personalized content.
        //                 <br></br>
        //                 <br></br>
        //                 If you access the Platform using different devices (such as computers, smartphones, or tablets), you must adjust the cookie preferences for each browser on each device accordingly.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 We regularly revise this Cookie Policy to align with updates to our Platform and services. Should there be substantial changes in how we gather, utilize, or disclose information stored in cookies, we will publish these modifications in this Cookie Policy and update the "Last Updated" date at the beginning of the Privacy & Cookie Policy.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 If you have previously disabled certain cookies, we may continue to utilize information collected from those cookies before your preference was disabled. However, we will cease using the disabled cookie to gather any additional information.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 We may occasionally send you essential notifications related to our services, such as when we temporarily suspend the Platform for maintenance, or for security, privacy, or administrative purposes. These notifications are typically delivered via email or text message. Please note that you cannot opt out of these essential announcements, as they are not promotional and are intended solely to safeguard your account and inform you about significant Platform updates.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 The Company has implemented necessary security practices and procedures to safeguard Personal Information. The Company processes Personal Information in a manner compatible with the purposes for which it was collected or authorized by the user. Users have access to their Personal Information on the Platform and can correct, amend, or delete inaccurate information.
        //                 <br></br>
        //                 <br></br>
        //                 The Company employs commercially reasonable precautions to protect your information against loss, theft, unauthorized access, disclosure, reproduction, use, or amendment.
        //                 <br></br>
        //                 <br></br>
        //                 Information collected from you may be transferred to, stored, and processed at any location within or outside India. By submitting information on the Platform, you consent to this transfer, storage, and processing. The Company will take reasonable steps to ensure your information is treated securely and in accordance with this Privacy Policy.
        //                 <br></br>
        //                 <br></br>
        //                 By using the Platform, you acknowledge the inherent security risks of data transmission over the internet. Therefore, the use of the Platform is at your own risk, and the Company is not liable for any information disclosure due to transmission errors, unauthorized third-party access, or other acts beyond its reasonable control. You agree not to hold the Company responsible for any security breaches.
        //                 <br></br>
        //                 <br></br>
        //                 If the Company becomes aware of a breach of your information’s security, it will promptly notify you and take appropriate action to remedy the breach to the best of its ability.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 Your consent for the collection, use, and disclosure of your Personal Information will remain valid until you withdraw it in writing. If you withdraw your consent, the Company will cease processing the relevant Personal Information, except where it has other legal grounds for processing under applicable laws. The Company will respond to your request within a reasonable timeframe. You may withdraw your consent at any time by contacting the Company at support@templedekho.com.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 You may request the deletion of your account at any time through the Settings Privacy menus on the Platform. Upon receiving your request, we will delete your account and Personal Information unless retention is required by legal or regulatory obligations. Please note that processing deletion requests may take some time. Once the account is deleted, we will not use your Personal Information for any processing, except as required to comply with legal obligations in accordance with this Policy.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 You are responsible for maintaining the accuracy of the information you provide, such as contact details during account registration. The Company relies on users to provide accurate and relevant information and to inform the Company of any changes. If you wish to correct or update any of your personal data held by us, you may submit your request via email at support@templedekho.com.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 The Company may periodically update this Privacy Policy to reflect changes in its information practices. Any revisions will take effect immediately upon posting the revised Privacy Policy. In the event of any significant changes, the Company will notify you through a notice on the Services before the changes take effect. We encourage you to check this page regularly for the latest updates on our privacy practices.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 If you have any questions about this Privacy Policy or the Company's information practices, you can reach out to us at support@templedekho.com. We will make reasonable efforts to promptly address any requests, questions, or concerns you may have regarding the use of your Personal Information. Please note that, except as required by law, we may not respond to inquiries unrelated to this policy or our privacy practices.
        //             </Typography>

        //             <Typography variant="body1" className="mt-30 fw-bold">
        //                 By reading this Privacy Policy, you acknowledge and agree to all the provisions stated herein.
        //             </Typography>

        //             <Typography variant="body1" className="mb-20">
        //                 <Typography variant="body1" className="mt-10 fw-bold">Omkara Infotech</Typography>
        //                 Contact - +91 9980130928
        //                 <br></br>
        //                 Email - <a href="mailto:info@omkarainfotech.com" className="text-primary">info@omkarainfotech.com</a>
        //                 <br></br>
        //                 Address - 5th Floor Continental Chambers 15A, Hemanta Basu Sarani Kolkata - 700001
        //             </Typography>


        //         </div>
        //         {/* <Typography variant="h5" className="mb-10 fw-bold">
        //             PRIVACY POLICY CONTENT & USER AGREEMENT
        //         </Typography>
        //         <Typography variant="body1" className="mb-30 text">
        //             By utilizing our Services and accessing the Platform, or by providing us with your information through any means, you are agreeing to the terms outlined in this Privacy Policy. Your use of our Services constitutes express consent to our practices regarding the collection, use, and disclosure of your Personal Information (as defined below) as described in this Privacy Policy. By continuing to use our Services and the Platform, you acknowledge and agree to abide by both this Privacy Policy and our Terms of Use. If you do not agree with these terms, please refrain from subscribing to our Services, using the Platform, or providing us with any of your information.
        //         </Typography>

        //         <Typography variant="h5" className="mb-10 fw-bold">
        //             PRIVACY POLICY EXCLUSIONS
        //         </Typography>
        //         <Typography variant="body1" className="mb-30 text">
        //             This Privacy Policy applies only to information collected by the Company through the Platform, as outlined in the "Collection of Information" section above. It does not cover any unsolicited information you provide through this Platform or by any other means. Such unsolicited information, including information posted in public areas of the Platform, is considered non-confidential, and the Company is free to use and disclose it without restriction.

        //             The Company also protects your Personal Information offline as specified in this Privacy Policy. Access to your Personal Information is limited to employees, agents, partners, and third parties who the Company reasonably believes need the information to provide Services to you. However, the Company is not responsible for the confidentiality, security, or distribution of your Personal Information by our partners and third parties outside the scope of our agreement with them.
        //         </Typography>

        //         <Typography variant="h5" className="mb-10 fw-bold">
        //             DATA COLLECTION & USAGE
        //         </Typography>
        //         <Typography variant="body1" className="mb-30 text">

        //         </Typography>



        //         <Typography variant="body1" className="mb-10 text">
        //             If there are any questions or concerns regarding this privacy policy, you may contact our Grievance Officer
        //             using the information below:
        //         </Typography>

        //         <Typography variant="body1" className="mb-30 text">
        //             <b>Subham Sen</b> <br />
        //             Omkara Infotech Pvt Ltd.: 5th Floor Continental Chambers 15A, Hemanta Basu Sarani Kolkata - 700001
        //         </Typography>

        //         <Typography variant="body1" className="mb-30 text">
        //             <Link href="mailto:privacy@Omkarainfotech.com" className="text-primary">
        //                 privacy@Omkarainfotech.com
        //             </Link>
        //             <br />
        //             We also have a privacy officer who may be contacted in relation to any privacy or data protection issues
        //             through the above email address (privacy@omkarainfotech.com).
        //         </Typography>

        //         <Typography variant="body1" className="mb-30 text">
        //             <b>Omkara Infotech</b> <br />
        //             Contact - +91 9980130928 <br />
        //             Email -{' '}
        //             <Link href="mailto:info@omkarainfotech.com" className="text-primary">
        //                 info@omkarainfotech.com
        //             </Link>
        //             <br />
        //             Address - 5th Floor Continental Chambers 15A, Hemanta Basu Sarani Kolkata - 700001
        //         </Typography> */}
        //     </Container>
        // </Box>
        <Box className="pageContainer termsAndCondition">
            <Container className="container padding-0">
                <div className="sectionHead text-center style-4 mb-40">
                    <small className="title_small d-none"></small>
                    <Typography className="mainTitle mb-30">
                        Temple Dekho <span>Terms</span> <span>and Condition</span>
                    </Typography>
                </div>
                <div className="tAndcInfoDetails mb-40">
                    <Typography variant="body1" className="mb-20">
                        PLEASE CAREFULLY REVIEW THESE TERMS OF USE. BY USING THE PLATFORM, YOU AGREE TO BE BOUND BY ALL OF THE FOLLOWING TERMS AND CONDITIONS AS WELL AS THE PRIVACY POLICY.
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Introduction</Typography>
                        These Terms of Use govern your access to and use of our website located at <a href="https://templedekho.com">(https://templedekho.com)</a> and/or the Temple Dekho mobile application (together, the "Platform") provided by Omkara Infotech Pvt. Ltd. ("Temple Dekho", "Company", "we", "us", and "our"), a private company incorporated in India under the Companies Act, 2013, with its registered office at 15A, Hemanta Basu Sarani, Lal Dighi, B.B.D. Bagh, Kolkata, West Bengal 700001.
                        <br></br>
                        <br></br>
                        Adherence to these standards and Terms of Use is a prerequisite for engaging in business with Omakara Infotech Pvt. Ltd., as they establish the non-negotiable minimum standards that we expect our Vendors or Vendors and their sub-tier Vendors or subcontractors ("the Vendor") to uphold.
                        These Terms must be read in conjunction with the Temple Dekho Content Guidelines and Temple Dekho Privacy and Cookie Policy. Your use of the Services (as defined herein) is subject to these terms and conditions ("Terms of Use").
                        <br></br>
                        <br></br>
                        Please carefully review these Terms of Use, in addition to the Privacy Policy available at [URL] and all other rules and policies accessible or published on the Platform, as they govern your use of the Platform and the services provided therein.
                        By using or accessing the Platform, you indicate your acceptance of these Terms of Use and the Privacy Policy.
                        These Terms of Use constitute an electronic record as defined under the Information Technology Act, 2000 (as amended/re-enacted) and its accompanying rules. They are published in accordance with Rule 3(1) of the Information Technology (Intermediaries Guidelines and Digital Media Ethics Code) Rules, 2021, which mandates the publication of rules, regulations, privacy policies, and terms and conditions for access or usage of any application or website. This electronic record is generated by a computer system and does not require any physical or digital signature. Additionally, certain Services may be subject to additional terms and conditions specified by us from time to time, and your use of such services is subject to those additional terms and conditions, which are incorporated into these Terms of Use by reference.
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Terms</Typography>
                        By accessing this Website and Application, referred to henceforth as the "Platform," available at <a href="https://templedekho.com">(https://templedekho.com)</a>, you agree to abide by the Terms and Conditions of Use outlined herein and acknowledge your responsibility to comply with all applicable local laws. If you do not agree with any of these terms, you are not permitted to use this platform. The materials on this platform are protected by copyright and trademark law.
                        <br></br>
                        <br></br>
                        Our Services, as detailed below, and these Terms are in compliance with the Indian Penal Code, 1860, and the Information Technology Act, 2000, including all its amendments and regulations. By creating an account or using our Platform or any of our Services, you implicitly accept and consent to these Terms. However, please note that we do not claim compliance with the laws of any country other than India. If you intend to use our Services, ensure that such use is permitted within your jurisdiction.
                        <br></br>
                        <br></br>
                        By using our Platform, you consent to the access of your Personal Data, provided to us via the Website and Application, in the form of electronic records, books, registers, correspondences, information, documents, or other materials. We may provide data such as Name, Gotra, Date of Birth, or any other necessary information to the Temple Authorities for conducting the Puja Process.
                        <br></br>
                        <br></br>
                        Both you and we are obligated to adhere to certain rules while using our Platform, which are delineated in this document. Please review these Terms and all other associated hyperlinks carefully. By using our Platform, you agree to abide by these Terms. Furthermore, if you are accessing these services outside India, ensure compliance with your local laws.
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Changes to Terms of Service</Typography>
                        Our Platform is dynamic and undergoes rapid development. Consequently, we retain the right to modify the services we offer at our discretion. This includes the option to temporarily or permanently discontinue delivering Services or any features to you.
                        <br></br>
                        <br></br>
                        We reserve the privilege to add or remove features from our Platform and Services as needed. In instances where a change necessitates your approval, we will seek it accordingly. We encourage you to revisit this page periodically to remain informed about our latest enhancements and updates.
                        <br></br>
                        <br></br>
                        Please visit this page regularly to review any changes we may implement and any new services we may introduce or modify.
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Platform Eligibility Criteria</Typography>
                        Our Platform facilitates communication with your places of worship, temples, fellow devotees, and friends, enabling you to share images, videos, texts, and blogs. We tailor your newsfeed to display posts, pictures, videos, and suggest content available on our Platform ("Service/Services") based on your preferences.
                        <br></br>
                        <br></br>
                        You may only utilize our Services if you are capable of forming a binding agreement with us and are legally permitted to do so. If you are accepting these Terms on behalf of a company or other legal entity, you represent and warrant that you have the authority to bind such entity to these Terms, and references to "you" and "your" shall include the company.
                        <br></br>
                        <br></br>
                        Please ensure that your use of our services complies with applicable laws.
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Platform Materials Disclaimer</Typography>
                        All materials on the Temple Dekho Platform, available at <a href="https://templedekho.com">(https://templedekho.com)</a>, are provided "as is". Temple Dekho makes no warranties, whether expressed or implied, and hereby disclaims all other warranties. Additionally, Temple Dekho makes no representations regarding the accuracy or reliability of the materials on its Platform or any sites linked to it.
                        Temple Dekho is represented at all listed places/religious organizations by direct temple trusts & authorities, temple priests, or volunteers on the ground. Temple Dekho endeavors to ensure that every service/ritual is conducted on-site at the specified locations on behalf of devotees, mimicking offline scenarios.
                        <br></br>
                        <br></br>
                        Temple Dekho will provide photos and videos as proof of the authenticity of the Spiritual Services offered, subject to permission from the Spiritual Leaders or authorities of the respective places of worship. While we strive to provide relevant media indicating the completion of Spiritual Services, failure to do so does not entail liability or constitute fraud or cheating on our part.
                        <br></br>
                        <br></br>
                        The cost of offerings mentioned on the Platform includes temple charges, dakshina, and overheads such as packaging, delivery, and service charges.
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Revisions & Errata</Typography>
                        The materials featured on the Temple Dekho Platform may contain technical, typographical, or photographic errors.  Temple Dekho does not guarantee that any of the materials on this Platform are accurate, complete, or up-to-date.  Temple Dekho reserves the right to modify the materials on its Platform at any time without prior notice. However, Temple Dekho does not commit to updating the materials.
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Link Disclaimer</Typography>
                        Temple Dekho has not reviewed all of the websites linked to its Platform and is not responsible for the content of any such linked sites. The inclusion of any link does not imply endorsement by Temple Dekho of the linked site. The use of any linked website is at the user's own risk.
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Guidelines for Service Utilization</Typography>
                        We've designed a distinctive platform accessible in both English and regional languages. Our aim is to offer personalized content tailored to your preferences. Additionally, we enable content downloading and social media sharing to enhance your experience. To access our Services, registration on our Platform is required. Simply input your phone number and the One-Time-Password sent via SMS. By registering through the Utsav App mobile application, you grant us permission to access certain features of your mobile device, including your phone book, SMS inbox, gallery, storage, and camera. Rest assured, we do not access any information stored on your devices or computer without your explicit consent.
                        <br></br>
                        <br></br>
                        To deliver our Services effectively, access to specific features of your mobile device is necessary.
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Privacy Policy</Typography>
                        In order to efficiently deliver and introduce new Services to you, we collect specific information such as your phone number and name. Additionally, we may request and securely store additional information. This data is stored on Microsoft Azure and Google Cloud Platform servers, and is subject to the terms outlined in their respective privacy policies. The Temple Dekho Privacy Policy elucidates the methods by which we gather, utilize, share, and safeguard collected information. Furthermore, it delineates your rights under the law and provides instructions on how you can manage the data you provide to us.
                        <br></br>
                        <br></br>
                        Detailed information regarding the storage and utilization of this data can be found in the Temple Dekho Privacy Policy.
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">User Responsibilities</Typography>
                        In order to maintain a safe and secure service for our diverse community, collective effort is required from all parties involved. We kindly request that you make certain pledges to us in return for our dedication to providing our Services. Please be aware that you will bear full responsibility for the costs and consequences of any actions undertaken on the Temple Dekho Platform (including any breach of these Terms), as well as the commitments you make below.
                        <br></br>
                        <br></br>
                        By utilizing our Services, you consent to and acknowledge the following:
                        <Box className="ml-20">
                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Authenticity Requirement</Typography>
                            You must provide your accurate phone number to access our Services. Impersonating another individual or falsely representing yourself or someone else is strictly prohibited.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Device Protection</Typography>
                            We have implemented security measures to safeguard our Platform. While we strive to maintain a secure environment, we cannot guarantee immunity from hacking or malware attacks. To ensure the safety of your mobile device and computer, it is recommended to install anti-malware and antivirus software. You are also responsible for safeguarding your phone number and preventing multiple accounts from being associated with it. You will be held responsible for any content uploaded by accounts linked to your phone number and email address.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Content Regulation and Account Management</Typography>
                            The utilization of our Platform is governed by the Utsav App Content and Community Guidelines. If any user reports your content for violating these guidelines, we reserve the right to remove it from our Platform. Repeated violations may result in the termination of your account and a ban from further registration. If you wish to contest any content removal, you may contact us at support@templedekho.com.
                            <br></br>
                            <br></br>
                            We may take down any content shared on our Platform that contravenes the Temple Dekho Content and Community Guidelines.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Platform Use and Legal Compliance</Typography>
                            Our Platform is designed to accommodate a diverse range of languages, religions, and cultures, along with various types of content. Therefore, it is imperative that you accurately label the nature of the content you post by using appropriate hashtags. However, you are strictly prohibited from sharing content that is obscene, pornographic, harmful to minors, discriminatory, constitutes hate speech, incites violence or hatred against individuals, violates Indian laws, or is legally prohibited from sharing. We reserve the right to remove inappropriate content. Please refer to the Temple Dekho Content and Community Guidelines for further clarification.
                            <br></br>
                            <br></br>
                            Additionally, we may disclose your information to law enforcement authorities if we reasonably believe it is necessary to comply with legal obligations or government requests, protect rights or prevent harm to property, safety, customers, or the general public, or address matters related to public safety, fraud, security, or technology. You acknowledge, however, that we cannot be held responsible for any actions taken against you by third parties or users as a result of your Platform usage.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Content Ownership and Usage Rights</Typography>
                            We strongly uphold personal freedom and freedom of expression, allowing you to share various content types on our Platform, including pictures, images, and videos. It is important to note that we do not possess any ownership rights to the content you share; all rights remain exclusively yours. You agree not to utilize our Platform to violate or infringe upon our intellectual property rights or those of any third party. Any such content contravening the Temple Dekho Content and Community Guidelines may be subject to removal from the Platform. Additionally, if you utilize any content created by us, we shall retain ownership of the intellectual property rights vested in such content.
                            <br></br>
                            <br></br>
                            By sharing, posting, or uploading content using our Services, you grant us a non-exclusive, royalty-free, transferable, sub-licensable, worldwide license to utilize, distribute, reproduce, publicly perform or display, translate, and create derivative works of your content (in accordance with your privacy and application settings). You maintain the option to delete your content and/or account at any time, though your content may persist on the Platform if it has been shared with others. For further details on information usage and content control or deletion, please refer to the Temple Dekho Privacy Policy.
                            <br></br>
                            <br></br>
                            You remain fully responsible for the content you publish on our Platform. We do not endorse nor are we liable for any content shared or posted on or via our Platform, including any resulting consequences. The presence of our logo or other trademarks on your content does not imply our endorsement or support. Moreover, we bear no responsibility for the outcomes of any transactions you engage in with other Platform users or advertisers.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Intermediary Status and Non-Liability</Typography>
                            As defined by the Information Technology Act, 2000 and the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021, we operate as an intermediary. These Terms are published in compliance with Rule 3(1) of the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021, mandating the publication of rules, regulations, the Temple Dekho Privacy Policy, and Temple Dekho Terms of Use for accessing and using our Platform. Our role is confined to providing a platform for users to upload, share, and display content, whether created or shared by you or other users.
                            <br></br>
                            <br></br>
                            We do not exert control over the actions of users on the Platform and, therefore, bear no responsibility for the outcomes of such actions, whether online or offline. Furthermore, we disclaim responsibility for services and features offered by third parties, even if accessed through our Services. Our accountability for activities occurring on our Platform is strictly governed by Indian laws and is limited accordingly. You acknowledge that we shall not be liable for any loss of profits, revenues, information, or data, or any consequential, special, indirect, exemplary, punitive, or incidental damages arising from these Terms, even if we are aware of the possibility of such damages. This includes the deletion of your content, information, or account.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Prohibition of Service Disruption Attempts</Typography>
                            Our platform thrives on community collaboration. Hence, you agree not to access or utilize non-public sections of our Platform, Services, or technical infrastructure. It is prohibited to introduce trojans, viruses, or any other harmful software, or employ bots to extract user information from our Platform. Additionally, probing, scanning, or testing the vulnerability of any system, security, or authentication measures we have implemented is strictly forbidden. Tampering or attempting to tamper with our technical framework will result in the cancellation of your user profile and access to our services. We reserve the right to notify law enforcement authorities of such behavior and take legal action against you.
                        </Box>
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Permissions Granted To Us</Typography>
                        <Box className="ml-20">
                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Profile Information Sharing Authorization</Typography>
                            You agree to these Terms and grant us specific permissions to enhance your user experience. One such permission is the authorization to share your profile information with third parties. While our Platform remains freely accessible, we may share collected data, such as your username, profile pictures, and engagement patterns, to display sponsored content or advertisements. However, we do not endorse or guarantee the authenticity of any products advertised, and we are not liable to share revenue if you make purchases based on such advertisements. Prior consent will be sought if we intend to share sensitive personal information, as defined by applicable laws.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Permission for App Updates</Typography>
                            We consistently update our Platform and Services to improve user experience. To access our Platform, you may need to download the Temple Dekho mobile application and ensure it is regularly updated.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Consent for Cookie Usage</Typography>
                            We may utilize various technologies, such as cookies and web beacons, to gather and store information regarding your use of the Services and third-party websites. This includes the storage of cookies on your web browser for tracking usage data.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Data Retention Rights</Typography>
                            We reserve the right to retain certain information related to your Platform usage, as outlined in the Temple Dekho Privacy Policy. By using our Platform, you grant us the authority to process, store, and retain information provided by you.
                        </Box>
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Our Agreement and Dispute Resolution</Typography>
                        <Box className="ml-20">
                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Rights Under These Terms</Typography>
                            The rights and responsibilities outlined in these Terms are exclusively conferred upon you and cannot be transferred to any third party without our explicit consent. However, we reserve the right to assign our rights and obligations under these Terms to others.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Dispute Resolution Procedure</Typography>
                            In the event of any disputes, you agree that they will be governed by Indian laws, and the courts of Kolkata will have sole jurisdiction over such matters.
                        </Box>
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Business Ethics And Integrity</Typography>
                        We uphold principles of honesty, integrity, and impartiality in all our dealings. Omkara Infotech Private Limited adheres to all relevant anti-bribery and corruption laws, including the Prevention of Corruption Act, 1988. Vendors are required to ensure strict compliance with anti-bribery and corruption regulations.
                        <br></br>
                        <br></br>
                        We maintain a strict zero-tolerance stance towards bribery and corruption, as they contradict our core values. Neither bribery nor corruption, including payments made by third parties acting on our behalf such as agents, consultants, vendors, and contractors, are tolerated.

                        <Typography variant="body1" className="mb-10 mt-10 fw-bold">Vendors are expected to:</Typography>
                        <ul>
                            <li>1. Establish monitoring and enforcement mechanisms to ensure adherence to anti-bribery and corruption laws.</li>
                            <li>2. Invoice and claim payments accurately, supported by appropriate documentation.</li>
                            <li>3. Conduct all business transactions transparently, maintaining accurate records.</li>
                            <li>4. Refrain from offering gifts, entertainment, or any form of value to individuals, including government officials, customers, or their representatives, to gain business advantages.</li>
                            <li>5. Avoid engaging in bribery, kickbacks, or facilitation payments.</li>
                            <li>6. Adhere to all pertinent laws and regulations in their operating jurisdictions, encompassing corporate governance, occupational health and safety, labor standards, and environmental management.</li>
                            <li>7. Act ethically, fairly, and professionally in all interactions with Temple Dekho and its stakeholders, promptly reporting any breaches or potential violations of these standards.</li>
                            <li>8. Disclose any potential conflicts of interest to Temple Dekho and promptly inform us of any personal relationships with our employees that could influence their engagements.</li>
                        </ul>

                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Ethical Standards & Human Rights</Typography>
                        Omkara Infotech Pvt. Ltd. ("Temple Dekho") holds a firm commitment to upholding human rights standards throughout its operations. Our expectations for vendors encompass various aspects of ethical conduct and respect for human rights.

                        <Box className="ml-20">
                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Voluntary Labor and Non-Coercion</Typography>
                            Vendors are obligated to ensure that all workers, regardless of their employment status, join the workforce voluntarily and without coercion. The use of forced, bonded, or involuntary labor, including any form of human trafficking, is strictly prohibited.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Minimum Age Requirement</Typography>
                            Under no circumstances shall vendors employ individuals below the age of 15 or the minimum age specified by local laws, whichever is higher.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Employment Practices and Worker Validation</Typography>
                            Vendors must adhere to all legal requirements regarding employment eligibility and provide workers with a safe and fair working environment. Documentation verifying the eligibility of workers must be maintained.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Health, Safety, and Wages</Typography>
                            Vendors are expected to prioritize the health, safety, and well-being of their employees, providing adequate compensation and benefits as mandated by law. Additionally, compliance with environmental regulations is essential to ensure sustainability.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Non-Discrimination and Dignity</Typography>
                            Discrimination in any form, including but not limited to race, gender, religion, or disability, is strictly prohibited. Vendors must maintain a workplace culture that upholds the dignity and equality of all individuals.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Environmental Sustainability</Typography>
                            Vendors should actively pursue environmental sustainability measures, including efficient resource management and waste reduction.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Gifts, Hospitality, and Third-Party Representation</Typography>
                            Business gifts and hospitality should be modest, appropriate, and comply with legal and corporate guidelines. Vendors must not represent Utsav App without explicit permission and must adhere to confidentiality obligations.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Protection of Company Assets and Intellectual Property</Typography>
                            Assets, both tangible and intangible, must be utilized responsibly and protected from misappropriation. Intellectual property rights must be respected, and technology transfers should safeguard intellectual property.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Confidentiality and Privacy</Typography>
                            Vendors must maintain the confidentiality of sensitive information and respect the privacy of individuals with whom they interact.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Supply Chain Responsibility</Typography>
                            Vendors are expected to enforce ethical standards within their own supply chains, promoting fair labor practices and human rights principles.
                        </Box>
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Mechanism For Addressing Grievances</Typography>
                        In our ongoing efforts to prioritize user privacy and safety, we collaborate closely with governmental authorities to ensure the well-being of our users. Below are the various avenues available for addressing grievances:

                        <Box className="ml-20">
                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">User Reporting and Complaints</Typography>
                            Users have the option to report profiles and content that violate our community guidelines. Complaints can be lodged by clicking on the three dots adjacent to the offending post/comment/user profile and selecting the report option. Additionally, users can utilize the Help & Support feature in the Settings menu to report issues.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Notification and Compliance</Typography>
                            In the event of a complaint against a user or their uploaded content, an email notification outlining the next steps will be dispatched to the concerned individual. Adherence to the provided instructions is imperative. Failure to comply may result in the permanent closure of the user's account on Temple Dekho.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Alternate Channels for Complaints</Typography>
                            Users can also lodge complaints via support@templedekho.com or the WhatsApp Support Number +91 90733 00055. Upon submission, users will receive a unique ticket number generated by our Support Team, and subsequent actions will be taken in accordance with platform policies and governmental regulations.
                        </Box>
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Exemption From Liability</Typography>
                        We hereby disclaim any responsibility for losses or damages, whether direct or indirect, arising from inaccuracies, incompleteness of information, or breaches of warranties or guarantees attributable to any user of the Platform.
                        <br></br>
                        <br></br>
                        The Platform and Services are provided on an "as is" and "as available" basis, without any express or implied warranties, unless otherwise specified in writing. We do not guarantee the quality, uninterrupted provision, timeliness, security, or error-free nature of the Services or the Platform, nor their continued compatibility across devices, or correction of errors.
                        <br></br>
                        <br></br>
                        Under no circumstances shall we, our affiliates, successors, assigns, investors, directors, officers, employees, agents, service providers, or vendors be liable for any special, incidental, punitive, direct, indirect, or consequential damages arising from another user's breach of the Terms or reliance on the Services or the Platform.
                        <br></br>
                        <br></br>
                        Should any exclusion herein be deemed invalid for any reason, and we or any of our affiliates, officers, directors, or employees are found liable for loss or damage, our liability shall be limited to the charges or amounts paid to us for the use of the Platform or the Services in the month preceding the claim.
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Agreement To Idemnify</Typography>
                        You hereby agree to indemnify, defend, and hold harmless us, along with our subsidiaries, affiliates, and agents, as well as their respective officers, directors, employees, successors, and assigns, from and against any and all claims, proceedings, losses, damages, liabilities, costs, demands, or expenses (including attorney's fees) arising from:

                        <ul>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">(i) Your Use of the Platform and Services: </Typography>
                                You agree to indemnify us for any claims or losses arising from your use of the Platform and Services.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">(ii) Breach of Agreement: </Typography>
                                You agree to indemnify us for any losses resulting from your failure to fulfill your obligations under this Agreement.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">(iii) Violation of Third-Party Rights: </Typography>
                                You agree to indemnify us for any claims or damages resulting from your infringement of third-party rights, including intellectual property, privacy, or consumer protection rights.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">(iv) Legal Violations: </Typography>
                                You agree to indemnify us for any losses or claims arising from violations of law or contractual obligations.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">(v) Negligence or Misconduct: </Typography>
                                You agree to indemnify us for any losses or damages caused by your negligence or willful misconduct.
                            </li>
                        </ul>
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Unsolicited Submissions</Typography>
                        We welcome feedback and suggestions, but please note that if you provide us with any unsolicited material, we may use it without any obligation to compensate you and without any promise of confidentiality.
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">General Disclaimers</Typography>
                        If any part of these Terms is found to be unenforceable, the remaining provisions will still apply.
                        <br></br>
                        <br></br>
                        Any changes or waivers to our Terms must be made in writing and signed by us.
                        <br></br>
                        <br></br>
                        If we choose not to enforce any part of these Terms, such as reporting illegal actions or suspending accounts, it does not mean that we waive our rights to enforce them in the future.
                        <br></br>
                        <br></br>
                        We reserve all rights that are not explicitly granted to you.
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Overview of Services & Acceptence of Terms</Typography>
                        <Box className="ml-20">
                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Introduction to Services</Typography>
                            Our Services encompass digitizing temples, online puja bookings, and related offerings.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Acknowledgment and Consent to Terms</Typography>
                            By accessing, registering, or utilizing our Services, you agree to abide by these Terms of Use and the Privacy Policy. Failure to agree to these terms will result in the termination of service.
                        </Box>
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Access</Typography>
                        <Box className="ml-20">
                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Service Provision</Typography>
                            We may offer you selected Services for your personal use only, not for third-party benefit. This includes any performed services, provided hardware, or downloaded widgets. Any discrepancies in service must be reported within 7 days for resolution.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Security and Usage</Typography>
                            You commit not to tamper with security features or mechanisms preventing unauthorized use or copying of restricted content on the Platform.
                        </Box>
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Registration And Eligibility</Typography>
                        <Box className="ml-20">
                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Legal Capacity</Typography>
                            To access our Platform and Services, you must be capable of forming legally binding contracts under the Indian Contract Act, 1872. Minors and those deemed incompetent to contract under this act are ineligible to use our Services.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Age Requirement</Typography>
                            Individuals under the age of 18 are considered minors and are not permitted to register or use our Services. We reserve the right to deny access to anyone found ineligible.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Login Process</Typography>
                            You have the option to log in using your phone number as your unique identifier.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Prohibited Actions</Typography>
                            You agree not to provide false personal information, including fraudulent login details, or create accounts on behalf of others without explicit consent. Additionally, impersonating another individual using their login details is strictly prohibited.
                        </Box>
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Your Responsibilities</Typography>

                        <ul>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">1. Understanding and Consent: </Typography>
                                You confirm that you have thoroughly read and comprehended the Terms of Use and Privacy Policy, and you agree to abide by them.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">2. Compliance with Laws: </Typography>
                                You pledge to ensure that your usage of the Platform and Services complies with all applicable laws and regulations.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">3. Limited Rights: </Typography>
                                You acknowledge that you have no ownership rights to the Platform or its underlying technology, except for the right to use them as outlined in these Terms of Use.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">4. Compliance with Terms: </Typography>
                                You commit to using the Platform and Services in a manner consistent with these Terms of Use and the Privacy Policy.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">5. Non-commercial Use: </Typography>
                                You agree not to resell or commercially exploit the Services or use them unlawfully or harmfully in any manner.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">6. Prohibited Actions: </Typography>
                                You undertake not to attempt reverse engineering, transmission of harmful software, or interference with the proper functioning of the Platform or its networks.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">7. Content Restrictions: </Typography>
                                You will refrain from posting or transmitting content that is false, harmful, defamatory, or infringes upon the rights of others.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">8. Prohibited Content: </Typography>
                                You will not promote violence, hate speech, illegal activities, or any content that may degrade individuals or groups.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">9. Disparagement: </Typography>
                                You will not make false or malicious statements against us or the Services.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">10. Interference: </Typography>
                                You will not disrupt the proper functioning of the Platform or any activities conducted on it.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">11. Bypassing Measures: </Typography>
                                You will not attempt to bypass any security measures implemented by us.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">12. Spam: </Typography>
                                You will not engage in spamming or use autoresponders on the Platform.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">13. Crawling or Spidering: </Typography>
                                You will not use software or devices to crawl or spider any part of the Services.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">14. Unauthorized Use: </Typography>
                                You will not modify, adapt, or exploit the Services in any unauthorized manner.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">15. Preservation of Notices: </Typography>
                                You will not delete or modify any legal or proprietary notices within the Services.
                            </li>
                        </ul>
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Conditions of Use</Typography>

                        <Box className="ml-20">
                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Service Availability</Typography>
                            The Services will be provided on a best-efforts basis. We will make reasonable efforts to ensure that you can use the Services without undue disruption, interruption, or delay.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Communications and Promotions</Typography>
                            Upon logging into the Platform, you may receive updates, promotional materials, and other information related to the Services or new services we may offer. By using the Platform, you consent to receiving all such communications from us. You can opt-out of these communications by contacting us at support@templedekho.com.
                            By agreeing to these Terms of Use, you also consent to us arranging a call with you on your mobile number, even if your number is registered with the DND (Do Not Disturb) service provided by your mobile service provider.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Responsibility for Device Protection</Typography>
                            We disclaim any responsibility for any harm resulting from anyone’s use of or access to the Services. If you use our Services, you are responsible for taking necessary precautions to protect yourself and your devices from malware, viruses, spyware, trojan horses, worms, trap doors, and other harmful or destructive software.
                            You also agree that we shall not be liable for any damages that may result from your use or misuse of our Platform.
                        </Box>
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Intellectual Property</Typography>

                        <Box className="ml-20">
                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Ownership and Rights</Typography>
                            You agree and acknowledge that we are, and will remain, the owner of the Platform and the Services at all times. The copyright in works contained on the Platform and the Services, including but not limited to all features, functionality, software, design, text, sound recordings, and images, are our exclusive property or licensed by us, unless otherwise expressly stated. You may access the Platform as a bona fide visitor or only for your use of the Services offered.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Trademarks and Usage Restrictions</Typography>
                            All trademarks, service marks, trade names, trade dress, and other forms of intellectual property are proprietary to us. No information, code, algorithms, content, or material from the Platform or the Services may be copied, reproduced, republished, uploaded, posted, transmitted, or distributed in any way without our express written permission.
                        </Box>
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Third Party Links</Typography>

                        <Box className="ml-20">
                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Accessing Third-Party Links</Typography>
                            The Platform includes links to third-party websites and/or applications. You acknowledge that when you access a third-party link that leaves the Platform:
                            <ul>
                                <li>
                                    <Typography variant="body1" className="mb-10 mt-10">1. The website or application you enter is not controlled by the Company, and different terms of use and privacy policies may apply.</Typography>
                                </li>
                                <li>
                                    <Typography variant="body1" className="mb-10 mt-10">2. The inclusion of a link does not imply any endorsement by the Company of the third-party website and/or application, the website’s and/or application’s provider, or the information on the third-party website and/or application.</Typography>
                                </li>
                                <li>
                                    <Typography variant="body1" className="mb-10 mt-10">3. If you submit any information or details on any of those websites and/or applications, such information is governed by the terms of use and privacy policies of those third-party websites and/or applications. The Company disclaims all responsibility or liability with respect to these terms of use, policies, or the websites and/or applications.</Typography>
                                </li>
                            </ul>
                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Reading Third-Party Policies</Typography>
                            You are encouraged to carefully read the terms of use and privacy policy of any third-party website and/or application that you visit. The Company reserves the right to disable third-party links from the Platform, although the Company is under no obligation to do so.
                        </Box>
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Termination</Typography>

                        <Box className="ml-20">
                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Immediate Termination</Typography>
                            We may terminate your access to all or any part of the Service at any time, with or without cause, and with or without notice, effective immediately. Any suspected illegal, fraudulent, or abusive activity will also be grounds for terminating your access to the Platform and/or Services.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Rights Reserved</Typography>
                            We reserve the right, at our sole discretion, to (a) cease operating the Platform or any of the Services at any time without notice, and/or (b) terminate these Terms of Use.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Survival of Terms</Typography>
                            All provisions of these Terms of Use that by their nature should survive termination will survive termination. This includes, but is not limited to, clauses related to intellectual property (Clause 10), indemnity and limitation of liability (Clause 12), and disclaimer (Clause 14).
                        </Box>
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Disclaimer</Typography>

                        <Box className="ml-20">
                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Service Scope</Typography>
                            The Services provided on the Platform are solely related to Sanatana Dharma. You agree and acknowledge that this restriction is not discriminatory towards other religions or religious practices, and it does not imply disrespect or favoritism towards Sanatana Dharma over other religions.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">No Warranty on Astrological Effects</Typography>
                            We do not warrant or take responsibility for the reality or reliability of astrological effects on human physiology or any other products or services represented and sold on the Platform. No advice or information obtained by you through the Platform will create any warranty by the Company. We do not endorse or tolerate content promoting actions involving black magic, witchcraft, voodoo, or tantrism. We do not provide medical advice or solutions for physical or mental health issues, and users should seek appropriate medical advice for such concerns.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Third-Party Service Providers</Typography>
                            The advisors, consultants, and temple partners or representatives are third-party service providers and not employees of the Platform or the Company. You connect with these providers at your own risk, and we are not responsible for any advice, information, or services they provide. We do not endorse, recommend, verify, or guarantee the validity, accuracy, completeness, safety, legality, quality, or applicability of their content or services. We will not be liable for any disputes between you and any third-party service provider. We reserve the right to terminate the services of any third-party provider at any time without liability.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">No Special Relationship</Typography>
                            We do not have a special relationship or fiduciary duty to you, except as required by law. We have no control over and no duty to act on the effects the Services may have on you.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">"As Is" Basis</Typography>
                            The Services are provided on an “as is” basis. We do not guarantee or warrant the accuracy, adequacy, correctness, validity, completeness, or suitability for any purpose of the Services, and we accept no liability or responsibility for your reliance on the statements or claims made by us while rendering our Services.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Jurisdictional Use</Typography>
                            The Company makes no representation or warranty that the content on the Platform is appropriate for use or access outside the Republic of India. Users accessing the Platform or availing the Services from outside India do so at their own risk and are responsible for complying with the laws of their jurisdiction.
                        </Box>
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Governing Law</Typography>

                        <Box className="ml-20">
                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Admissibility in Proceedings</Typography>
                            A printed version of these Terms of Use and any notice given in electronic form shall be admissible in judicial or administrative proceedings to the same extent and under the same conditions as other business documents and records originally generated and maintained in printed form.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Jurisdiction and Applicable Law</Typography>
                            These Terms of Use shall be governed by and construed in accordance with the laws of India. You consent to the exclusive jurisdiction of the courts located in Bangalore, India. The use of the Service is not authorized in any jurisdiction that does not enforce all provisions of these Terms of Use, including this section.
                        </Box>
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Severability</Typography>
                        If any provision of these Terms of Use is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that the remaining Terms of Use will remain in full force and effect and enforceable. Failure to exercise any right herein shall not be deemed a waiver of any further rights. Waiver of compliance in any instance does not imply future waivers. For any waiver to be binding, it must be provided in writing by one of our authorized representatives.
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Modifications of Terms of Use</Typography>
                        We reserve the right, at our sole discretion, to modify or replace any part of these Terms of Use, or change, suspend, or discontinue the Services (including the availability of any feature, database, or content) at any time by posting a notice or sending you notice through our Service or via your provided contact details. We may impose limits on certain features and services or restrict your access to parts or all of the Services without notice or liability. It is your responsibility to check these Terms of Use periodically for changes. Your continued use of the Services following the posting of any changes constitutes acceptance of those changes.
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Miscellaneous</Typography>
                        Unless specified otherwise in these Terms of Use, all notices must be in writing and will be considered duly given when received or when receipt is electronically confirmed if sent by email. These Terms of Use and your use of the Services do not grant any rights or benefits to any person other than us and you. No third party is entitled to enforce any provision of these Terms of Use. We do not intend for any provision of these Terms of Use to be enforceable by a third party under any applicable law.
                    </Typography>

                    <Typography variant="body1" className="mt-30 fw-bold">
                        By using our Services, you confirm that you have fully read, understood, and voluntarily agree to all the provisions contained in these Terms of Use.
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="body1" className="mt-10 fw-bold">Last updated on: </Typography>
                        OMKARA INFOTECH PRIVATE LIMITED
                        <br></br>
                        support@templedekho.com
                        
                    </Typography>

                </div>
            </Container>
        </Box>
    );
};

export default TermsAndConditions;

import { useEffect, useState } from "react";
import AddressView from "../../../../Views/Profile/Views/Address/AddressView";
import {
  uid
} from "../../../../utils/HelperFunctions";
import { useDispatch, useSelector } from "react-redux";
import {
  UserService,
} from "td_server";
import { addAddressAction, deleteUserAddressAction, getUserGPSAddressAction } from "../../../../store/actions/userAction";
function AddressScreen() {
  const {userInfo,currentAddress} = useSelector((state: any) => state.user);
  const userPersonalInfo = userInfo&&userInfo.personalInfo?userInfo.personalInfo:{}
  const dispatch: any = useDispatch();
  const [createNewOrUpdateAddressView, setCreateNewOrUpdateAddressView] = useState({isCreate:false,isUpdate:false});
  const [position, setPosition]: any = useState({
    latitude: null,
    longitude: null,
  });
  const [userUsedGPSPosition, setUserUsedGPSPosition]: any = useState(false);
  const addressList = userPersonalInfo.address?userPersonalInfo.address:[]
  const userId = userInfo&&userInfo.id?userInfo.id:""
  const userMobileNumber =   userInfo&&userInfo.phoneNumber?userInfo.phoneNumber:""
  const [newAddressDetails, setNewAddressDetails] = useState({
    id: uid(),
    savedAs: "Address" + Number(addressList.length + 1),
    street: "",
    area: "",
    city: "",
    zip: "",
    state: "",
    formatted_address: "",
    phoneNumber: "",
    coordinates: {
      latitude: "",
      longitude: "",
    },
  });

  const [updateAddressDetails, setUpdateAddressDetails]:any = useState({
  });

  const getCurrentPosition = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: any) => {
          // console.log("User position",position)
          dispatch(
            getUserGPSAddressAction(
              position.coords.latitude,
              position.coords.longitude
            )
          );
          
        },
        (err: any) => {
          console.log(err)
          alert("Please grant location permission")
        }
      );
      // console.log("location gpS", position);
    } else {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((permissionStatus) => {
          if (permissionStatus.state === "denied") {
            alert("Please allow location access.");
          } else {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                // console.log("position data", position);
                dispatch(
                  getUserGPSAddressAction(
                    position.coords.latitude,
                    position.coords.longitude
                  )
                );
                
              },
              (err) => {
                alert("Please grant location permission")
              }
            );
          }
        });
    }
  };

  const saveNewAddress = () => {
    // console.log("New address",newAddressDetails)
    let formatAdd = newAddressDetails.formatted_address
    if(!newAddressDetails.formatted_address){
      formatAdd =
      newAddressDetails.street +
      " ," +
      newAddressDetails.area +
      " ," +
      newAddressDetails.city +
      " ," +
      newAddressDetails.state +
      " ," +
      newAddressDetails.zip +
      " ," +
      " India";
    }
    else{
      formatAdd =newAddressDetails.formatted_address
    }
    

    let addressPayload = { ...newAddressDetails, formatted_address: formatAdd };
    console.log("New address", addressPayload);
    if (userId) {
      dispatch(addAddressAction(addressPayload,false,null, null))
      setCreateNewOrUpdateAddressView({isCreate:false,isUpdate:false})
    } else {
      console.log("user id not present");
    }
  };

  const saveUpdatedAddress = () => {
    let formatAdd = updateAddressDetails.formatted_address
    if(!updateAddressDetails.formatted_address){
      formatAdd =
      updateAddressDetails.street +
      " ," +
      updateAddressDetails.area +
      " ," +
      updateAddressDetails.city +
      " ," +
      updateAddressDetails.state +
      " ," +
      updateAddressDetails.zip +
      " ," +
      " India";
    }
    else{
      formatAdd =updateAddressDetails.formatted_address
    }

    let addressPayload = { ...updateAddressDetails, formatted_address: formatAdd };
    console.log("Updated address", addressPayload);
    // return
    if (userId) {
      console.log("user id", userId);
      dispatch(addAddressAction(addressPayload,true,addressPayload.id, null))
      setCreateNewOrUpdateAddressView({isCreate:false,isUpdate:false})
    } else {
      console.log("user id not present");
    }
  };

  useEffect(() => {
    console.log("user per from use---54: ", userUsedGPSPosition)
    if(userUsedGPSPosition){
      // console.log("user per from use---54-current add: ", currentAddress)
      if(currentAddress){
        let extractFullCurrentAddress = currentAddress.address
        setNewAddressDetails({
          ...newAddressDetails,
          ...extractFullCurrentAddress
        });
      }
    }
  }, [userUsedGPSPosition,currentAddress]);

  const deleteAddress = (addressId:any)=>{
    dispatch(deleteUserAddressAction(addressId));
  }



  return (
    <div>
      <AddressView
        currentAddress={currentAddress}
        createNewOrUpdateAddressView={createNewOrUpdateAddressView}
        setCreateNewOrUpdateAddressView={setCreateNewOrUpdateAddressView}
        newAddressDetails={newAddressDetails}
        setNewAddressDetails={setNewAddressDetails}
        saveNewAddress={saveNewAddress}
        getCurrentPosition={getCurrentPosition}
        setUpdateAddressDetails={setUpdateAddressDetails}
        updateAddressDetails={updateAddressDetails}
        saveUpdatedAddress={saveUpdatedAddress}
        deleteAddress={deleteAddress}
        addressList={addressList}
        setUserUsedGPSPosition={setUserUsedGPSPosition}
      />
    </div>
  );
}

export default AddressScreen;

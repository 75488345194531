import React from 'react';

import { Container, Typography, Box, List, ListItem, ListItemText } from '@mui/material';
import "./AboutView.scss";


const AboutView = () => {
    return (
        <Box className="pageContainer aboutView">
            <Container className="container padding-0">
                <div className="sectionHead text-center style-4 mb-40">
                    <small className="title_small d-none"></small>
                    <Typography className="mainTitle mb-30">
                        About <span >Us</span>
                    </Typography>
                </div>
                <div className="aboutViewInfo mb-40">
                    <Box mb={5}>

                        <Typography paragraph>
                            Welcome to Temple Dekho, your trusted partner in experiencing divine moments through seamless online puja booking services across famous temples in India. Temple Dekho, a service provided by Omkara Infotech Private Limited, offers on-demand access to temples, enriching spiritual journeys through innovative technology solutions.
                        </Typography>
                        <Typography paragraph>
                            Omkara Infotech Pvt. Ltd. is a private limited company, identified by CIN: U72900WB2022PTC258090, and was established on November 9th, 2022, with its registered headquarters situated in Kolkata, West Bengal, India. Our company prides itself on a foundation of reliability, security, and technological innovation.
                        </Typography>
                        <Typography sx={{mt:"30px", mb:"5px"}} paragraph>
                            Managed by a team of dedicated professionals, our directors include:
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemText primary="Vivek Agarwal - Finance & Accounts Head" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Harshil Agarwal - Chief Marketing Officer" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Subham Sen - Chief Technical Officer" />
                            </ListItem>
                        </List>
                        <Typography paragraph>
                            At Temple Dekho, we uphold the highest standards of security and data integrity. All our products and services are safeguarded under ISO 27001 and SOC 2 Type 2 certifications, ensuring that your experience with us is not only convenient but also secure.
                        </Typography>
                        <Typography paragraph>
                            Whether you seek to perform rituals or simply connect spiritually with the divine, Temple Dekho offers you a platform where tradition meets technology. Our user-friendly website and mobile app provide easy access to a wide array of puja services, ensuring that your spiritual journey remains fulfilling and hassle-free.
                        </Typography>
                        <Typography paragraph>
                            Join us at Temple Dekho as we embark on a mission to make your pilgrimage and devotion an enriching experience, supported by the latest in digital convenience and safety.
                        </Typography>
                        <Typography paragraph>
                            For any inquiries or assistance, please feel free to reach out to us. We look forward to serving you on your spiritual quest.
                        </Typography>
                        <Typography paragraph>
                            <strong>Temple Dekho - Connecting Souls to Spirituality!</strong>
                        </Typography>
                    </Box>
                    <Box mb={5}>
                        <Typography variant="h5" gutterBottom className='fw-bold'>
                            Vision
                        </Typography>
                        <Typography paragraph>
                            Temple Dekho aims to lead in online puja bookings at India's sacred temples, prioritizing reliability, convenience, and spiritual enrichment. Our vision is to ensure everyone can effortlessly access and experience the profound blessings of spiritual connection through innovative technology solutions.
                        </Typography>
                    </Box>
                    <Box mb={5}>
                        <Typography variant="h5" gutterBottom className='fw-bold'>
                           Mission
                        </Typography>
                        <Typography paragraph>
                            Temple Dekho integrates technology with tradition to enhance spiritual journeys, providing secure and innovative solutions for convenient access to sacred rituals. We are committed to serving individuals who cannot visit temples due to busy schedules, advancing age, financial constraints or physical limitations but still seek the blessings and solace that spiritual practices bring to their lives.
                        </Typography>
                    </Box>
                </div>
            </Container>
        </Box>
    );
};

export default AboutView;

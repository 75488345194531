import pujaMainImg from "../../assets/puja-img.png";
import templeIcon from "../../assets/temple-icon.png";
import locationIcon from "../../assets/loc-icon.png";
import pricetagIcon from "../../assets/price-tag.png";
import viewPujaSteps from "../../assets/viewpujasteps.png";
import viewBless from "../../assets/bless-your-self.png";
import "./PujaInfoView.scss";
import pujaNameIcon from "../../../src/assets/puja-name-icon.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Grid,
  Stack,
  Typography,
  Button,
  Dialog,
  Tooltip,
  Snackbar,
  Alert,
  Radio,
  Badge,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from "@mui/material";
import TitleTypography from "../../components/typography/TitleTypography";
import ParagraphTypography from "../../components/typography/ParagraphTypography";
import PercentRoundedIcon from "@mui/icons-material/PercentRounded";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import SubtitleTypography from "../../components/typography/SubtitleTypography";
import SubtitleTypographyPrimary from "../../components/typography/SubtitleTypographyPrimary";
import RoundedButton from "../../components/button/RoundedButton";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { calculateActualPujaPrice } from "../../utils/HelperFunctions";
import ShareLink from "../../components/sharelink/sharelink";
import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import packageImg1 from "../../assets/Package-img1.png";
import packageImg2 from "../../assets/Package-img2.png";
import packageImg3 from "../../assets/Package-img3.png";
import packageImg6 from "../../assets/Package-img6.png";
import packageImg8 from "../../assets/Package-img8.png";
import listIcon from "../../assets/list-flower-icon.png";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CloseIcon from "@mui/icons-material/Close";

import { SyntheticEvent } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from "react-responsive-carousel";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { CAROUSEL_REMOVE_ARRAOW_ON_DEVICE, PUJA_PACKAGE_SCROLL_RESPONSIVE, VIRTUAL_PUJA_BOOKINGCAROUSAL_RESPONSIVE } from "../../utils/Constants";
import dayjs from "dayjs";
import PujaPackageCard from "../../components/card/PujaPackage/PujaPackageCard";

function PujaView(props: any) {
  // console.log("pujaDetails", props.pujaDetails);
  // console.log("Temple details in puja view selectedTempleDetails", props.selectedTempleDetails);
  const templeName =
    props.selectedTempleDetails &&
    props.selectedTempleDetails.personalInfo &&
    props.selectedTempleDetails.personalInfo.name
      ? props.selectedTempleDetails.personalInfo.name
      : "";
  const personalInfo = props.selectedTempleDetails?.personalInfo;
  const templeAddress =
    (personalInfo?.address?.street ||
      personalInfo?.address?.city ||
      personalInfo?.address?.area) +
    " , " +
    personalInfo?.address?.state;

  const getImageForBenefitsDataByBenefitId = (benefitId: string) => {
    const benefitList = props.benefitsList;
    const benefitFilterData = benefitList.filter(function (item: any) {
      return item.benefitId === benefitId;
    });
    // console.log("New bebefit list", benefitFilterData);
    if (benefitList.length > 0) {
      return benefitFilterData[0];
    } else {
      return null;
    }
  };
  const packageSelected = props.packageSelected;
  const pujaPrice = props?.pujaPriceInfo?.finalPrice;
  // const pujaPrice =
  //   props.packageSelected && props.packageSelected.acctualPrice ? props.packageSelected?.acctualPrice
  //     : props.pujaDetails?.acctualPrice;
  const actualPujaPriceAggregated = props?.pujaPriceInfo?.finalPrice;

  const couponCodeText = props.avialableCouponCode?.couponCode;
  console.log("Coupo code text", couponCodeText);
  //pagelink?singlepuja=${pujaDetails.pujaId}&&templeid=${templeDetails?.id}

  const [urlToShare, setUrlToShare] = useState<string>("");
  const templeAndPujaInfoPath = window.location.pathname;
  useEffect(() => {
    const templeIdComing = window.location?.pathname?.split("/")[2];
    const hostname =
      window.location.hostname === "localhost"
        ? "http://localhost:3000"
        : "https://" + window.location.hostname;
    const pujaId = templeAndPujaInfoPath?.split("/")[4];
    // setUrlToShare(
    //   `${hostname}/pagelink?singlepuja=${pujaId}&templeid=${templeIdComing}`
    // );
    console.log("Puja to share===>", props.pujaDetails);
    setUrlToShare(
      `${hostname}/pagelink?pujaNameKey=${props.pujaDetails?.pujaNameKey}&templeNameKey=${props.selectedTempleDetails?.nameKey}`
    );

    // }
  }, [props.selectedTempleDetails, props.pujaDetails]);

  const sortPackageData = (multiplePujaDetails: any) => {
    let multiplePuja = [...multiplePujaDetails];
    let data = multiplePuja.sort((a: any, b: any) =>
      Number(a.maxMemberAllowed) > Number(b.maxMemberAllowed) ? 1 : -1
    );

    return data;
  };

  const pujaBgImage =
    props.pujaDetails &&
    props.pujaDetails.pujaBg &&
    props.pujaDetails.pujaBg.uri
      ? props.pujaDetails.pujaBg.uri
      : pujaMainImg;
  let pujaPackages =
    props.pujaDetails && props.pujaDetails.multiplePujaDetails
      ? sortPackageData(props.pujaDetails.multiplePujaDetails)
      : [];

  const [open, setOpen] = useState<boolean>(false);

  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(couponCodeText);
  };

  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const bookedSingleDatePuja = () => {
    //setLoading(true)

    //props.preprocessingAndProceedToAddmemberScreen()
    if (props.cartInfo && props.cartInfo.length > 0) {
      props.setPopupPujaAlreadyAdded(true);
    } else {
      props.preprocessingAndProceedToAddmemberScreen();
    }
  };
  //Puja Package Radio btn
  const [radioSelectedValue, setRadioSelectedValue] = useState("option2");

  const handleChange = (event: any) => {
    setRadioSelectedValue(event.target.value);
  };

  const getDevoteeImage = (index: any) => {
    switch (index) {
      case "1":
        return packageImg1;
        break;
      case "2":
        return packageImg2;
        break;
      case "4":
        return packageImg3;
        break;
      case "6":
        return packageImg6;
        break;
      case "8":
        return packageImg8;
        break;

      default:
        return packageImg8;
        break;
    }
  };

  const minDate = dayjs().add(2, "day");

  const [expanded, setExpanded] = useState<string | false>("panel1");

  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Box className="pageContainer pujaPage">
      <Box className="pujaBlock">
        <Grid container spacing={1}>
          <Grid item xs={12} md={2} lg={3} xl={3} className="pagehdLeftBlock">
            {/* <LeftAddressCard
              header={props.TDTexts.CURRENTADDRESS}
              address={
                props.userAddressList && props.userAddressList.length > 0
                  ? props.userAddressList[0].formatted_address
                  : ""
              }
              footerButton="Edit or add an address"
            /> */}
          </Grid>
          <Grid item xs={12} md={8} lg={6} xl={6}>
            <Box className="pujainfoCenterBlock">
              <Box className="pujaCard">
                {/* <TitleTypography
                  title={`${props.pujaDetails?.pujaName}`}
                ></TitleTypography> */}
                <Box className="pujaCardBlock">
                  <Box className="pujaImgBlock">
                    <img src={pujaBgImage} alt=""></img>
                  </Box>
                  <Box className="pujaDescription">
                    <Box className="pujaNameInfo">
                      <Typography>{props.pujaDetails?.pujaName}</Typography>
                    </Box>
                    <Box className="nameLocPrice">
                      <List>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          className="twoItem"
                        >
                          {/* <ListItem>
                            <ListItemAvatar sx={{ minWidth: "50px" }}>
                              <Avatar
                                alt="Puja Icon"
                                className="squreShape pricetag"
                              >
                                <img src={pujaNameIcon}></img>
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText sx={{ pl: "6px" }} primary={props.pujaDetails?.pujaName} />
                          </ListItem> */}
                          <ListItem>
                            <ListItemAvatar sx={{ minWidth: "46px" }}>
                              <Avatar
                                alt="Temple Icon"
                                className="squreShape temple"
                              >
                                <img src={templeIcon}></img>
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={`${templeName}`} />
                          </ListItem>
                          <Box className="shareIcon">
                            <ShareLink urlToShare={urlToShare}></ShareLink>
                          </Box>
                        </Stack>

                        <ListItem>
                          <ListItemAvatar sx={{ minWidth: "46px" }}>
                            <Avatar
                              alt="Location Icon"
                              className="squreShape location"
                            >
                              <img src={locationIcon}></img>
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={`${templeAddress}`} />
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar sx={{ minWidth: "46px" }}>
                            <Avatar
                              alt="Pricetag Icon"
                              className="squreShape pricetag"
                            >
                              <img src={pricetagIcon}></img>
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            className="pujaPriceInfo"
                            sx={{ flex: "0 0 auto" }}
                            primary={`Price: ₹${
                              actualPujaPriceAggregated || "   "
                            }`}
                          />
                          {couponCodeText ? (
                            <ListItemText
                              sx={{ flex: "0 0 auto" }}
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClick()}
                            >
                              <Typography className="couponDiscountCode">
                                <PercentRoundedIcon />
                                {couponCodeText}
                              </Typography>
                            </ListItemText>
                          ) : null}
                        </ListItem>
                        { props?.pujaDetails?.isPujaDisable && <ListItemText sx={{color: 'red'}}>Pooja Booking Closed</ListItemText>}
                      </List>
                      <Button
                        type="button"
                        className="continueBtn desktopOnly"
                        onClick={() => {
                          if (props?.isSingleDatePuja) {
                            bookedSingleDatePuja();
                          } else {
                            props.setIsDateTimePicker(true);
                          }
                          //  props.setIsDateTimePicker(true);
                          // props.navigateToPujaDetailsAdditionScreen()
                        }}
                        disabled={props?.pujaDetails?.isPujaDisable}
                      >
                        { props?.pujaDetails?.isPujaDisable ? 'Booking Closed' : 'Continue to Book' }
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="overViewText">
                <SubtitleTypographyPrimary title="Puja Overview"></SubtitleTypographyPrimary>
                <ParagraphTypography
                  title={`${props.pujaDetails?.description}`}
                ></ParagraphTypography>
              </Box>

              <Box className="templeDekhoVideo">
                <SubtitleTypographyPrimary title="About “Temple Dekho”"></SubtitleTypographyPrimary>
                <Typography>
                  Please watch our feature video to discover the capabilities of
                  the <span>Temple Dekho</span> app.
                </Typography>
                <Button
                  onClick={() => props.navigateToSeeVideoView()}
                  variant="contained"
                  className="seeVideoBtn"
                >
                  See Video
                </Button>
              </Box>

              {packageSelected ? (
                <Box className="SelectPackage">
                  <SubtitleTypographyPrimary title="Select Puja Package"></SubtitleTypographyPrimary>
                  <Box className="packageItem">
                    {/* <Carousel
                      showArrows={false}
                      showThumbs={false}
                      showStatus={false}
                      infiniteLoop={false}
                      centerMode={true}
                      showIndicators={false}
                      centerSlidePercentage={33.33}
                    >
                      {pujaPackages.map((item: any, index: number) => {
                        let activeFlg = "inactive";
                        let pujaPrice = calculateActualPujaPrice(
                          Number(item.acctualPrice)
                        );
                        if (
                          packageSelected &&
                          packageSelected.packageId === item.packageId
                        ) {
                          activeFlg = "active";
                        }

                        return (
                          <PujaPackageCard
                            setPackageSelected={props.setPackageSelected}
                            getDevoteeImage={getDevoteeImage}
                            activeFlg={activeFlg}
                            packageDetails={item}
                            selectedTempleDetails={props.selectedTempleDetails}
                            pujaDetails={props.pujaDetails}
                          />
                        );
                      })}
                    </Carousel> */}
                    <Carousel
		                      responsive={PUJA_PACKAGE_SCROLL_RESPONSIVE}
		                      swipeable={true}
		                      draggable={true}
		                      showDots={false}
		                      keyBoardControl={true}
		                      removeArrowOnDeviceType={CAROUSEL_REMOVE_ARRAOW_ON_DEVICE}
		                      className="pujaPackCarousel"
		                    >
		                      {pujaPackages.map((item: any, index: number) => {
		                        let activeFlg = "inactive";
		                        let pujaPrice = calculateActualPujaPrice(
		                          Number(item.acctualPrice)
		                        );
		                        if (
		                          packageSelected &&
		                          packageSelected.acctualPrice === item.acctualPrice
		                        ) {
		                          activeFlg = "active";
		                        }
		
		                        return (
		                          <PujaPackageCard
		                            setPackageSelected={props.setPackageSelected}
		                            getDevoteeImage={getDevoteeImage}
		                            activeFlg={activeFlg}
		                            packageDetails={item}
		                            selectedTempleDetails={props.selectedTempleDetails}
		                            pujaDetails={props.pujaDetails}
		                          />
		                        );
		                      })}
 			                    </Carousel>
                  </Box>
                  <Box className="packagePujaInfo">
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={2}
                      className="packagePujaTitle"
                    >
                      <img
                        src={getDevoteeImage(packageSelected?.maxMemberAllowed)}
                        alt=""
                      />
                      <Box>
                        <Typography variant="h6">
                          {props.pujaDetails?.pujaName}
                        </Typography>
                        <Typography component="p">
                          Participants: Upto {packageSelected?.maxMemberAllowed}{" "}
                          Devotees
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                </Box>
              ) : null}

              <Box className="pujaBenefits">
                <SubtitleTypographyPrimary title="Benefits of Puja"></SubtitleTypographyPrimary>
                {props.pujaDetails &&
                  props.pujaDetails.benefitList &&
                  props.pujaDetails.benefitList.map((item: any, id: any) => {
                    let updatedBenefitsData = item;
                    // console.log("Benefir detais from map function", item);
                    const benefitDetais = getImageForBenefitsDataByBenefitId(
                      item.benefitId
                    );

                    if (benefitDetais) {
                      updatedBenefitsData = benefitDetais;
                    }
                    return (
                      <Box className="benefitsCard">
                        <SubtitleTypography
                          title={`${updatedBenefitsData.name}`}
                        ></SubtitleTypography>
                        <Box className="benefitCardImg">
                          <img src={`${updatedBenefitsData.url}`} alt=""></img>
                        </Box>
                        <ParagraphTypography
                          title={`${updatedBenefitsData.description}`}
                        ></ParagraphTypography>
                      </Box>
                    );
                  })}
              </Box>

              <Box className="pujaProccessSteps">
                <SubtitleTypographyPrimary title="Puja Process Steps"></SubtitleTypographyPrimary>
                <List>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Box className="listCounter">
                          <Typography
                            component="span"
                            className="pujaStepsListCount"
                          >
                            1
                          </Typography>

                          <Typography>Select and add puja</Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Box className="listCounter">
                          <Typography
                            component="span"
                            className="pujaStepsListCount"
                          >
                            2
                          </Typography>

                          <Typography>Select date</Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Box className="listCounter">
                          <Typography
                            component="span"
                            className="pujaStepsListCount"
                          >
                            3
                          </Typography>

                          <Typography>Enter Name & Gotra</Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Box className="listCounter">
                          <Typography
                            component="span"
                            className="pujaStepsListCount"
                          >
                            4
                          </Typography>

                          <Typography>Make payment</Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Box className="listCounter">
                          <Typography
                            component="span"
                            className="pujaStepsListCount"
                          >
                            5
                          </Typography>

                          <Typography>
                            Skilled pandits will conduct the Puja, and
                            notifications will be sent to your WhatsApp number
                            with puja video or picture.
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Box className="listCounter">
                          <Typography
                            component="span"
                            className="pujaStepsListCount"
                          >
                            6
                          </Typography>

                          <Typography>
                            Prasad will be delivered within 7 business days of
                            the puja.
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                </List>
              </Box>
              <Box
                onClick={() => props.navigateToPujaStepsView()}
                className="viewPujaSteps"
              >
                <img src={viewPujaSteps}></img>
              </Box>

              {/* FAQ */}

              {/* <Box className="chadhavaTestimonial">
                <SubtitleTypographyPrimary title="Devotee Experience"></SubtitleTypographyPrimary>
                <Carousel
                  responsive={FEATURE_TO_DISPLAY_CAUROSAL_RESPONSIVE}
                  swipeable={true}
                  draggable={true}
                  showDots={true}
                  keyBoardControl={true}
                  removeArrowOnDeviceType={CAROUSEL_REMOVE_ARRAOW_ON_DEVICE}
                >
                  <TestimonialCard />
                  <TestimonialCard />
                  <TestimonialCard />
                </Carousel>
              </Box> */}

              <Box className="chadhavaFaq">
                <SubtitleTypographyPrimary title="Frequently Asked Questions"></SubtitleTypographyPrimary>

                <Box className="chadhavaFaqList">
                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel1"}
                    onChange={handleChange1("panel1")}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel1" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel1"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                        // mb: expanded === "panel1" ? "10px" : "0"
                      }}
                    >
                      What is Temple Dekho?
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      Temple Dekho leads in virtual puja services as the most
                      trusted brand, making rituals reachable beyond
                      geographical barriers. We partnered with temples across
                      India so you can say no to long queues, bad weather or
                      language barriers. Temple Dekho: One Lifetime, Infinite
                      Blessings!
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel2"}
                    onChange={handleChange1("panel2")}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel2" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel2"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel2-content"
                      id="panel2-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                        // mb: expanded === "panel2" ? "10px" : "0"
                      }}
                    >
                      What makes your virtual puja services stand out? Why
                      should I pick Temple Dekho for Puja booking?
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      Your spiritual journey matters deeply to us. Collaborating
                      closely with sacred temples, we ensure every puja is
                      conducted with utmost reverence. Our verified Pandits from
                      top temples across India perform personalized Pujas by
                      reciting your name and Gotram following sacred traditional
                      methods. You receive video evidence, Prasad and our
                      goodies, that ensures a seamless online Puja experience.
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel3"}
                    onChange={handleChange1("panel3")}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel3" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel3"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel3-content"
                      id="panel3-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                        // mb: expanded === "panel3" ? "10px" : "0"
                      }}
                    >
                      How do you prioritize the security and privacy of Bhakts?
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      Temple Dekho is all about authenticity, integrity and
                      keeping your information safe! We are the only virtual
                      puja app in India with the highest level of certifications
                      like ISO 27001 and SOC 2 Type 2. So, when you book a Puja
                      with us, know that your data is in good hands. As India's
                      most trusted company, we make your experience secure and
                      seamless.
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel4"}
                    onChange={handleChange1("panel4")}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel4" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel4"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel4-content"
                      id="panel4-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                        // mb: expanded === "panel4" ? "10px" : "0"
                      }}
                    >
                      Who is designated to perform my Puja?
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      Your Puja will be conducted by our verified chief Pandits,
                      and sometimes generational Pandits from the temples we
                      have onboarded. They will perform your Puja by pronouncing
                      your name and Gotram following traditional rituals. You
                      will receive video evidence via WhatsApp within 2 days of
                      the Puja.
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel5"}
                    onChange={handleChange1("panel5")}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel5" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel5"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel5-content"
                      id="panel5-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                        // mb: expanded === "panel5" ? "10px" : "0"
                      }}
                    >
                      Can I participate in a Puja if I don't know my Gotram?
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      Certainly! But first, you must seek guidance from elders
                      in your family to learn about your Gotram. If that's not
                      feasible, adhere to the shastras, which recommend using
                      the Kashyap Gotra by default for Puja participation in
                      situations where you are unsure of your Gotram.
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel6"}
                    onChange={handleChange1("panel6")}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel6" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel6"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel6-content"
                      id="panel6-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                        // mb: expanded === "panel5" ? "10px" : "0"
                      }}
                    >
                      How do I get evidence of my Puja?
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      Our designated Pandits will conduct your Puja, pronouncing
                      your name and Gotram. The sacred ritual will be recorded
                      inside or outside the temple, adhering to regulations.
                      Within 2 days, receive the video on WhatsApp, and immerse
                      yourself in the spiritual essence of the Puja, firsthand.{" "}
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel7"}
                    onChange={handleChange1("panel7")}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel7" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel7"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel7-content"
                      id="panel7-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                        // mb: expanded === "panel5" ? "10px" : "0"
                      }}
                    >
                      Who should I reach out to for more details?
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      We are here to assist you with any information you need
                      regarding this unique spiritual experience of your online
                      Puja booking journey with us. You can reach us on WhatsApp
                      at +91 90733 00055, or you can email us at
                      support@templedekho.com.{" "}
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel8"}
                    onChange={handleChange1("panel8")}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel8" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel8"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel8-content"
                      id="panel8-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                        // mb: expanded === "panel5" ? "10px" : "0"
                      }}
                    >
                      How do I receive the prasad?
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      Within 7 business days, the holy Prasad and special Temple
                      Dekho gifts, carrying the divine essence of your Puja,
                      will be delivered to your doorstep. Experience the unique
                      blessing of your Puja, all from the comfort of your home!{" "}
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
              <Box className="blessContinue">
                <Box className="viewBless">
                  <img src={viewBless}></img>
                </Box>
                <Box className="continueCart">
                  <Box className="continueCenterBlock">
                    <Box className="continueLt">
                      ₹{`${actualPujaPriceAggregated}`}/-
                    </Box>
                    <Box className="continueRt">
                      <Button
                        type="button"
                        className="continueBtn"
                        disabled={props?.pujaDetails?.isPujaDisable}
                        onClick={() => {
                          if (props?.isSingleDatePuja) {
                            bookedSingleDatePuja();
                          } else {
                            props.setIsDateTimePicker(true);
                          }

                          // props.navigateToPujaDetailsAdditionScreen()
                        }}
                      >
                        { props?.pujaDetails?.isPujaDisable ? 'Booking Closed' : 'Continue'}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={2} lg={3} xl={3} className="pagehdRightBlock">
            {/* <RightBlockCard /> */}
          </Grid>
        </Grid>
        <Dialog
          className="dateTimingSelection"
          onClose={() => props.setIsDateTimePicker(false)}
          open={props.isDateTimePicker}
        >
          <Box className="dateTimePopup">
            {/* <TitleTypography title="Select Date">Select Date</TitleTypography> */}
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <TitleTypography title="Select Date">Select Date</TitleTypography>
              <IconButton
                aria-label="close"
                onClick={() => props.setIsDateTimePicker(false)}
                sx={{
                  position: "absolute",
                  right: 10,
                  top: 8,
                  fontSize: "12px",
                  color: "#d56939",
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>

            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="flex-start"
              className="pujaReminderSec"
            >
              <ErrorOutlineOutlinedIcon />
              <Typography className="pujaReminderText">
                The Booking for the puja will be closed two days before the
                scheduled date.{" "}
              </Typography>
            </Stack>

            <Box className="calendarInfo">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                  shouldDisableDate={props?.shouldDisableDate}
                  disablePast={true}
                  minDate={minDate}
                  onChange={(value: any) => {
                    props.setPujaDateAndTime({
                      ...props.pujaDateAndTime,
                      pujaDate: value,
                    });
                  }}
                />
              </LocalizationProvider>
            </Box>
            <Box className="btnContinue">
              <RoundedButton
                title="Continue"
                disabled={
                  props.pujaDateAndTime && props.pujaDateAndTime.pujaDate
                    ? false
                    : true
                }
                onClick={() => {
                  props.setIsDateTimePicker(false);
                  if (props.cartInfo && props.cartInfo.length > 0) {
                    props.setPopupPujaAlreadyAdded(true);
                  } else {
                    props.preprocessingAndProceedToAddmemberScreen();
                  }

                  //
                }}
              ></RoundedButton>
            </Box>
          </Box>
        </Dialog>

        <Dialog
          //className="dateTimingSelection"
          onClose={() => props.setPopupPujaAlreadyAdded(false)}
          open={props.popupPujaAlreadyAdded}
        >
          <Box style={{ padding: "1em" }}>
            <Typography>
              A puja has already been added to your cart. Would you like to
              replace the puja with selected puja ?
            </Typography>
            <Box sx={{ textAlign: "end" }}>
              <Button onClick={() => props.setPopupPujaAlreadyAdded(false)}>
                No
              </Button>
              <Button
                onClick={() => props.preprocessingAndProceedToAddmemberScreen()}
              >
                Yes
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Text copied to clipboard"
      />
    </Box>
  );
}

export default PujaView;

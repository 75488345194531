import verifytick from "../../assets/verify-tick.png";
import pujaMainImg from "../../assets/puja-img.png";
import "./VirtualPoojaBookingView.scss";
import Carousel from "react-multi-carousel";
import { Box, Grid, Paper, Typography, List, ListItem, ListItemAvatar, ListItemSecondaryAction, Avatar, ListItemText } from "@mui/material";
import LeftAddressCard from "../../components/card/LeftBlock/LeftAddressCard";
import RightBlockCard from "../../components/card/RightBlock/RightBlock";
import PujaListCard from "../../components/card/Puja/PujaListCard";
import ImageViewer from "../../components/imageviewer/ImageViewer";
import templeIcon from "../../../src/assets/temple-icon.png";
import locationIcon from "../../../src/assets/loc-icon.png";
import ratingIcon from "../../../src/assets/rate-star.png";
import VirtualPujaBookDialog from "../../components/dialog/VirtualPujaBookDialog";
import { VIRTUAL_PUJA_BOOKINGCAROUSAL_RESPONSIVE } from "../../utils/Constants";
function VirtualPujaBookingView(props: any) {
  const templeProfilePic = props.templeProfilePic();
  return (
    <Box className="pageContainer pujaBookingPage">
      <Box className="pujaBookingBlock">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2} lg={3} xl={3} className="pagehdLeftBlock">
              {/* <LeftAddressCard
                header="Current Address"
                address="198/3, 3 Cross 80 Feet Road, Next.."
                footerButton="Edit or add an address"
              /> */}
            </Grid>
            <Grid item xs={12} md={8} lg={6} xl={6}>
              <Box className="pujaBookingCenterBlock">
                <Box className="pujaBookingTopBanner">
                  <Box style={{ zIndex: -10 }}>
                    {props.templeMediaList.length > 0 ? (
                      <Carousel
                        responsive={VIRTUAL_PUJA_BOOKINGCAROUSAL_RESPONSIVE}
                        swipeable={true}
                        draggable={true}
                        showDots={true}
                        keyBoardControl={true}
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                      >
                        {props.templeMediaList.map((item2: any, id: any) => {
                          return (
                            <ImageViewer
                              id={id}
                              pictureUrl={item2.pictureUrl}
                            />
                          );
                        })}
                      </Carousel>
                    ) : null}
                  </Box>
                </Box>
                <Box className="pujaBookingTitle">
                  {/* <Typography>
                    {props.templePersonalInfo.name}{" "}
                    <ImageViewer id={0} pictureUrl={verifytick} />
                  </Typography> */}
                  <List>
                    <ListItem>
                      <ListItemAvatar sx={{ minWidth: "50px" }}>
                        <Avatar
                          alt="Temple Icon"
                          className="squreShape temple"
                        >
                          <img src={templeIcon}></img>
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText sx={{display:"flex", alignItems:"center"}} primary={props.templePersonalInfo?.name} secondary={<ImageViewer id={0} pictureUrl={verifytick} />} />
                      
                    </ListItem>
                    
                    <ListItem>
                      <ListItemAvatar sx={{ minWidth: "50px" }}>
                        <Avatar
                          alt="Location Icon"
                          className="squreShape location"
                        >
                          <img src={locationIcon}></img>
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={`${props.templePersonalInfo?.address?.area}, ${props.templePersonalInfo?.address?.state}` } />
                    </ListItem>

                    <ListItem>
                      <ListItemAvatar sx={{ minWidth: "50px" }}>
                        <Avatar
                          alt="Rating Icon"
                          className="squreShape rating"
                        >
                          <img src={ratingIcon}></img>
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={props?.templeDetails?.googleData?.rating} />
                    </ListItem>
                  </List>
                </Box>
                <Box className="templeCenterBlock">
                  {props.templeDetails &&
                    props.templeDetails.templePuja.map((item: any, id: any) => {                    
                      return (
                        <PujaListCard
                          pujaData={{pujaDetails:item}}
                          pujaName={item.pujaName}
                          templeProfilePic={item?.pujaBg?.uri || templeProfilePic}
                          description={item.description}
                          benefits={item.benefits}
                          acctualPrice={item.acctualPrice}
                          onClickAddPuja={props.onClickAddPuja}
                          temple={props.templePersonalInfo}
                          devoteeCount={item?.maxMemberAllowed}
                          benefitList = {item?.benefitList}
                          isPujaDisable={item?.isPujaDisable}
                        />
                      );
                    })}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={2} lg={3} xl={3} className="pagehdRightBlock">
              {/* <RightBlockCard /> */}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <VirtualPujaBookDialog
        setAskUserToModifyCartPopUp={props.setAskUserToModifyCartPopUp}
        userConfirmUpdatePujaDetails={props.userConfirmUpdatePujaDetails}
        askUserToModifyCartPopUp={props.askUserToModifyCartPopUp}
      />
    </Box>
  );
}

export default VirtualPujaBookingView;

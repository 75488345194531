import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Navigate } from "react-router-dom";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Paper,
  makeStyles,
  Box,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./styles.scss";
import {
  getDateFormatedOrderTime,
  getDateFormatedForBookingDate,
} from "../../utils/HelperFunctions";
import { APPROLES, orderType } from "../../utils/Constants";
interface Data {
  [key: string]: string;
}

interface ViewAllDetailsProps {
  isOpen: boolean;
  onClose: () => void;
  orderId: string;
}

const ViewOrderDetailsModal: React.FC<ViewAllDetailsProps> = ({
  isOpen,
  onClose,
  orderId,
}) => {
  const { userInfo } = useSelector((state: any) => state.user);
  const { userOrder } = useSelector((state: any) => state.order);
  const [orderDetails, setOrderDetails] = React.useState<any>({});
  const [expandedAccordion, setExpandedAccordion] = useState<string | null>(
    "panel-3"
  );

  useEffect(() => {
    if (orderId && orderId.length > 0) {
      const orderDetails = userOrder.filter(
        (item: any) => item.orderId == orderId
      );
      setOrderDetails(orderDetails[0]);
    }
  }, [orderId]);

  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.userType === APPROLES.SUPERADMIN.roleId ||
        userInfo.userType === APPROLES.ADMIN.roleId)
    ) {
      setIsSuperAdmin(true);
    } else {
      setIsSuperAdmin(true);
      setExpandedAccordion("panel-3");
    }
  }, [userInfo]);

  const scrollRef: any = useRef(null);

  const scrollToBottom = () => {
    if (scrollRef.current) {
      //scrollRef.scrollIntoView({ behavior: "smooth" })
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    //scrollToBottom();
  }, [orderDetails]);

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpandedAccordion(isExpanded ? panel : null);
      if (isExpanded) {
        scrollToBottom();
      }
    };

  console.log(`orderDetails-----------`, orderDetails);

  console.log(`orderDetails-----------`, orderDetails)

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="change-status-title"
      className="orderModal"
    >
      <Box className="modalContents">
        <Stack className="modalHeader">
          <Typography id="change-status-title" variant="h6" gutterBottom>
            Order id : <span> {orderId} </span>
          </Typography>
          <Button onClick={onClose} variant="outlined" className="btnClose">
            <CloseIcon />
          </Button>
        </Stack>

        <Box className="modalBody">
          <Box ref={scrollRef} className="orderDetails">
            <Typography variant="body1">
              <span style={{ fontWeight: "bold" }}>Temple Name </span> :{" "}
              {orderDetails?.templeDetails?.name}
            </Typography>
            <Typography variant="body1">
              <span style={{ fontWeight: "bold" }}>Booked For </span> :{" "}
              {getDateFormatedForBookingDate(orderDetails?.pujaDate)}
            </Typography>
            {isSuperAdmin && (
              <Typography variant="body1">
                <span style={{ fontWeight: "bold" }}>Order Status </span> :{" "}
                {orderDetails?.orderStatus?.length
                  ? orderDetails?.orderStatus[
                      orderDetails?.orderStatus?.length - 1
                    ]?.orderStatus
                  : ""}
              </Typography>
            )}
            <Typography variant="body1">
              <span style={{ fontWeight: "bold" }}>Order Booking Date </span> :{" "}
              {getDateFormatedOrderTime(orderDetails?.orderBookingDate)}
            </Typography>
            <Typography variant="body1">
              <span style={{ fontWeight: "bold" }}>Order User Name </span> :{" "}
              {orderDetails?.orderUserDetails?.orderUserName}
            </Typography>
            <Typography variant="body1">
              <span style={{ fontWeight: "bold" }}>Phone Number </span> :{" "}
              {orderDetails?.deliveryAddress?.phoneNumber &&
              orderDetails.deliveryAddress.phoneNumber.length > 3
                ? orderDetails?.deliveryAddress?.phoneNumber
                : orderDetails?.orderUserDetails?.phoneNumber}
            </Typography>

            <Typography variant="body1">
              <span style={{ fontWeight: "bold" }}>WhatsApp Number </span> :{" "}
              {orderDetails?.whatsAppNumber}
            </Typography>

            <Typography variant="body1">
              <span style={{ fontWeight: "bold" }}>Puja Name </span> :{" "}
              {orderDetails?.orderType === orderType.puja
                ? orderDetails?.pujaName
                : "Chadhava"}
            </Typography>

            {orderDetails?.orderType === orderType.puja && (
              <Typography variant="body1">
                <span style={{ fontWeight: "bold" }}>Delivery Address </span> :{" "}
                {orderDetails?.deliveryAddress?.formatted_address}
              </Typography>
            )}

            <Typography variant="body1">
              <span style={{ fontWeight: "bold" }}>Puja Value</span> :{" "}
              {orderDetails?.orderData?.payment?.orderValue}
            </Typography>
            <Divider sx={{ opacity: 0.6, paddingBottom: "5px" }} />
            <Box className="accordionList">
              {orderDetails?.orderType === orderType.chadhava && (
                <>
                  <Accordion
                    sx={{ boxShadow: "none" }}
                    expanded={expandedAccordion === `panel-4`}
                    onChange={handleChange("panel-4")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{ padding: "0px 16px" }}
                    >
                      <Typography variant="body1">
                        <span style={{ fontWeight: "bold" }}>
                          Chadhava Item List
                        </span>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TableContainer component={Paper}>
                        <Table aria-label="chadhavalist">
                          <TableHead>
                            <TableRow>
                              <TableCell className="tableHeaderCell">
                                Chadhava Image
                              </TableCell>
                              <TableCell className="tableHeaderCell">
                                Chadhava Item
                              </TableCell>
                              <TableCell className="tableHeaderCell">
                                Price
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {orderDetails?.chadhavaList?.map(
                              (el: any, index: number) => (
                                <TableRow key={index}>
                                  <TableCell>
                                    <img
                                      width="50px"
                                      height="50px"
                                      src={el.image}
                                    ></img>
                                  </TableCell>
                                  <TableCell>{el.name}</TableCell>
                                  <TableCell>
                                    {" "}
                                    {el.priceInfo?.finalPrice}
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                </>
              )}
              <Accordion
                sx={{ boxShadow: "none" }}
                expanded={expandedAccordion === `panel-3`}
                onChange={handleChange("panel-3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ padding: "0px 16px" }}
                >
                  <Typography variant="body1">
                    <span style={{ fontWeight: "bold" }}>
                      Puja Member Details
                    </span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer component={Paper}>
                    <Table aria-label="puja-member-details-table">
                      <TableHead>
                        <TableRow>
                          <TableCell className="tableHeaderCell">
                            Full Name
                          </TableCell>
                          <TableCell className="tableHeaderCell">
                            Gotram
                          </TableCell>
                          <TableCell className="tableHeaderCell">
                            Puja Name
                          </TableCell>
                          <TableCell className="tableHeaderCell">
                            Manokamna/Wish
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {orderDetails?.orderUserDetails?.groupDetails?.map(
                          (member: any, index: number) => (
                            <TableRow key={index}>
                              <TableCell>{member.fullName}</TableCell>
                              <TableCell>
                                {member.gotram ? member.gotram : "N/A"}
                              </TableCell>
                              <TableCell> {orderDetails?.pujaName}</TableCell>
                              <TableCell>{member.manokamna}</TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        </Box>
        <Box className="modalFooter">
          <Button
            onClick={onClose}
            variant="outlined"
            className="btnCancel grey"
          >
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ViewOrderDetailsModal;

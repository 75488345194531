import { useRef, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import profiletopimg from "../../assets/profiletopimg.jpg";
import "./ProfileView.scss";
import AvatarGenerator from "../../components/profilePic/GenerateAvatar";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";
import { updateUserProfileImageAction } from "../../store/actions/userAction";

interface profileCardInfo {
  cardId: string;
  cardTitle: string;
  cardDesc: string;
}

interface profileViewProps {
  profileMenu: profileCardInfo[];
}

function ProfileView(props: any) {
  const [selectedCard, setSelectedCard] = useState(props.profileMenu[0]);
  const [ppFile, setPPFile]: any = useState(null);
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const inputFileRef: any = useRef();
  const isActive = (path: string) => {
    let modifiedPath = "/profile/" + path;
    if (
      location.pathname === modifiedPath ||
      location.pathname === modifiedPath + "/"
    ) {
      return "active";
    } else {
      return "inactive";
    }
  };

  const ppUrl = props.userPersonalInfo?.profilePictureUrl;
  const userFName = props.userPersonalInfo?.firstName;
  const userLName = props.userPersonalInfo?.lastName;
  const userMobile = props.userDetails?.phoneNumber;

  const handelDragOver = (event: any) => {
    event.preventDefault();
  };
  const handelDrop = (event: any) => {
    event.preventDefault();
    console.log("drop event--->", event.dataTransfer.files);
    setFileToState(event.dataTransfer.files[0]);
  };

  const setFileToState = async (file: any) => {
    setPPFile(file);
  };

  const setProfilePicture = () => {
    const userId =
      props.userDetails && props.userDetails.id ? props.userDetails.id : "";
    const sourceData = {
      uri: ppFile,
      platform: "web",
    };
    console.log("User id source data", userId, sourceData);
    dispatch(updateUserProfileImageAction(userId, sourceData));
    props.setProfilePicChange(false)
    setPPFile(null);
    // UserService.updateUserProfileImage(userId, sourceData)
    //   .then((res) => {
    //     console.log("response pp upload", res)
    //     dispatch(fetchUserDetails({ mobileNo: userMobile }))
    //     props.setProfilePicChange(false)
    //   })
    //   .catch((err) => console.log("eerrr", err));
  };

  const [isSmActive, setIsSmActive] = useState(false);

  const handlePdWrapperClick = () => {
    setIsSmActive(!isSmActive);
  };
  console.log("Sm active flag", isSmActive);
  return (
    <Box className="profileContent">
      <Box className="profileTopBanner">
        <Box
          className="profileTopContent"
          onClick={() => props.setProfilePicChange(true)}
        >
          {ppUrl ? (
            <img src={ppUrl} alt="" />
          ) : (
            <AvatarGenerator firstName={userFName} lastName={userLName} />
          )}

          <Typography className="profileName">
            {userFName} {userLName}
          </Typography>
        </Box>
      </Box>
      <Box className="container profileContents">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={3}>
            <Box className="profileBackground profTab">
              {props.profileMenu.map((item: any, id: any) => {
                return (
                  <Box className="pdWrapper" onClick={handlePdWrapperClick} key={`pdWrapper${id}`}>
                    <Box
                      className={`profileDetail ${isActive(item.cardId)}`}
                      onClick={() => navigate(item.cardId)}
                    >
                      <Box className="profileText">
                        <Typography className="pTitle">
                          {item.cardTitle}
                        </Typography>
                        <Typography className="pSubTitle">
                          {item.cardDesc}
                        </Typography>
                      </Box>
                      <Box className="profileArrow">
                        <KeyboardArrowRightIcon />
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            <Box
              className={`profileBackground profCont ${
                isSmActive ? "smActive" : "smInactive"
              }`}
            >
              <Box className="profileOutlet">
                <Outlet />
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Profile Edit Modal */}
        <Dialog
          className="profileInfoEdit"
          onClose={() => props.setProfilePicChange(false)}
          open={props.profilePicChange}
        >
          <Box className="profileEditBlock">
            <Box className="profileHeader">
              <Typography>
                {userFName} {userLName}
              </Typography>
            </Box>

            <Box
              className="uploadImageArea"
              onClick={() => inputFileRef.current.click()}
              onDragOver={handelDragOver}
              onDrop={handelDrop}
              // style={{width:"30em",height:"40em"}}
            >
              <Box className="upImgText">
                <Typography>
                  Click here to upload <span>or Drop here</span>
                </Typography>
              </Box>

              <input
                type="file"
                onChange={(e: any) => {
                  console.log(e.target.files);
                  setPPFile(e.target.files[0]);
                }}
                hidden
                ref={inputFileRef}
              ></input>
              {/* <button onClick={()=>inputFileRef.current.click()}>Browse file</button> */}
              <Box className="profileImg">
                {ppFile ? (
                  <img
                    style={{ width: "10em", height: "10em" }}
                    alt=""
                    src={URL.createObjectURL(ppFile)}
                  ></img>
                ) : ppUrl ? (
                  <img
                    style={{ width: "10em", height: "10em" }}
                    src={ppUrl}
                    alt=""
                  />
                ) : (
                  <AvatarGenerator firstName={userFName} lastName={userLName} />
                )}
              </Box>
            </Box>
            <Box className="profileBtnBlock">
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  props.setProfilePicChange(false)
                  setPPFile(null);
                }}
              >
                Remove picture
              </Button>
              <Button variant="contained" onClick={() => setProfilePicture()}>
                Update Picture
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Box>
    </Box>
  );
}

export default ProfileView;

import AppBar from "@mui/material/AppBar";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowRightAltRoundedIcon from "@mui/icons-material/ArrowRightAltRounded";
import templeLogo from "../../assets/temple-logo-icon.png";
import userImg from "../../assets/profiletopimg.jpg";
import langImg from "../../assets/lang.svg";
import searchdak from "../../assets/postprofile.jpg";
import searchbirla from "../../assets/postprofile2.jpg";
import searchkalipuja from "../../assets/kalipuja-search.jpg";
import searchpandit from "../../assets/pandit-search.jpg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import RightDrawer from "../drawer/RightDrawer";
import GetAppIcon from "@mui/icons-material/GetApp";
import "./Header.scss";
import { useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import AvatarGenerator from "../profilePic/GenerateAvatar";
import { Box, Grid, Typography, Button, Tooltip } from "@mui/material";
import TitleTypography from "../typography/TitleTypography";
import SubtitleTypography from "../typography/SubtitleTypography";
import Dialog from "@mui/material/Dialog";
import RoundedButton from "../button/RoundedButton";
import { TextContext } from "../../text/TextProvider";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ModeEditRoundedIcon from "@mui/icons-material/ModeEditRounded";

function Header(props: any) {
  const navigate = useNavigate();
  const [searchBoxPopUp, setSearchBoxPopUp] = useState(false);
  const [notificationBoxPopup, setNotificationBoxPopUp] = useState(false);
  const [openRightSidePanel, setOpenRightSidePanel] = useState(false);
  const [openLanguageSelection, setOpenLanguageSelection] = useState(false);
  const [userAddressInfoAggregated, setUserAddressInfoAggregated]: any =
    useState(null);
  const location = useLocation();
  var { userInfo, currentAddress } = useSelector((state: any) => state.user);
  const personalInfo = userInfo?.personalInfo;
  const { cartInfo } = useSelector((state: any) => state.cart);
  const textContext: any = useContext(TextContext);
  const isActive = (path: string) => {
    return location.pathname === path;
  };
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const ppUrl = personalInfo?.profilePictureUrl || "";
  const userFName = personalInfo?.firstName || "";
  const userLName = personalInfo?.lastName || "";
  const userAddressList = personalInfo?.address || [];

  const generateUserAddressData = () => {
    if (currentAddress) {
      return currentAddress.address;
    } else if (userAddressList && userAddressList.length > 0) {
      return userAddressList[0];
    } else {
      return null;
    }
  };

  useEffect(() => {
    const aggregatedAdresss = generateUserAddressData();
    setUserAddressInfoAggregated(aggregatedAdresss);
  }, [currentAddress, userInfo]);

  const addressCityName =
    userAddressInfoAggregated && userAddressInfoAggregated.city
      ? userAddressInfoAggregated.city
      : "Temple Dekho";
  const addressData =
    userAddressInfoAggregated && userAddressInfoAggregated.formatted_address
      ? userAddressInfoAggregated.formatted_address
      : "Connecting Souls to Spirituality";
  const shotrenedAddress =
    addressData.length > 32
      ? addressData.substring(0, 32) + "..."
      : addressData;

  // console.log("User info in header", userInfo);
  return (
    <Box>
      {windowDimensions.width < 900 ? (
        <Box className="headerSm">
          <Box className="headerSmLeft">
            <Box className="addressText">
              <Typography>
                {addressCityName}{" "}
                {userAddressInfoAggregated ? (
                  <ModeEditRoundedIcon
                    onClick={() => navigate("/profile/address")}
                  />
                ) : null}
              </Typography>
              <Typography>{shotrenedAddress}</Typography>
            </Box>
          </Box>
          <Box className="headerSmRight">
            {/* <Box className="headLang">
              <Button
                className="btn btn-default"
                onClick={() => setOpenLanguageSelection(true)}
              >
                <img src={langImg}></img>
              </Button>
            </Box> */}
            <Box
              className={isActive("/apps") ? "displayNone" : "appDownloadInfo"}
            >
              <Link to={"/apps"} className="downloadBtnText" target="_blank">
                <Tooltip title="Download App">
                  <GetAppIcon sx={{ color: "#d56939", marginRight: "10px" }} />
                </Tooltip>
              </Link>
            </Box>

            <Box className="headProfile">
              {userInfo ? (
                <Box
                  className="profileBlock"
                  onClick={() => setOpenRightSidePanel(true)}
                >
                  <Link to="#">
                    {ppUrl ? (
                      <img
                        style={{ width: "34px", height: "34px" }}
                        src={ppUrl}
                        alt=""
                      />
                    ) : (
                      <AvatarGenerator
                        firstName={userFName}
                        lastName={userLName}
                      />
                    )}
                  </Link>
                </Box>
              ) : (
                <Box
                  className="profileBlock"
                  onClick={() => navigate("/login")}
                >
                  {/* <Link to={"/profile/personalInfo"}> */}
                  <Link to="#">
                    <Button
                      className="logoutProfile"
                      variant="text"
                      sx={{ padding: "0 8px" }}
                      startIcon={
                        <i
                          style={{ fontSize: "24px", color: "#d56939" }}
                          className="bi bi-person-circle"
                        ></i>
                      }
                    >
                      Login
                    </Button>
                  </Link>
                </Box>
              )}
            </Box>
          </Box>
          <RightDrawer
            openRightSidePanel={openRightSidePanel}
            setOpenRightSidePanel={setOpenRightSidePanel}
          />
        </Box>
      ) : (
        <Box className="headerMainNav">
          <AppBar component="nav">
            <nav className="headerContainer">
              <Grid
                container
                spacing={2}
                className="row d-flex align-items-center mainGrid"
              >
                <Grid item md={3} lg={3} xl={3} className="hdLeftGrid">
                  <Box className="headerLeft">
                    <Box className="logo">
                      <NavLink to="/" className={isActive("/") ? "active" : ""}>
                        <img src={templeLogo} alt="" width={24} />
                      </NavLink>
                    </Box>
                    {/* <Box
                      className="searchAutoPopulate"
                      onClick={() => setSearchBoxPopUp(!searchBoxPopUp)}
                    >
                      <Box className="searchBlock">
                        <form className="d-flex" role="search">
                          <input
                            className="form-control searchInput"
                            type="search"
                            placeholder="Search your thoughts..."
                            aria-label="Search"
                          ></input>
                          <button
                            className="btn btnSecondary btnSearch"
                            type="submit"
                          >
                            <i className="bi bi-search"></i>
                          </button>
                        </form>
                      </Box>
                    </Box> */}
                    <Box className="logoText">
                      <NavLink to="/" className={isActive("/") ? "active" : ""}>
                        <Typography className="logoTextTitle">
                          Temple Dekho
                        </Typography>
                        <Typography className="logoTextSubTitle">
                          Connecting Souls to Spirituality
                        </Typography>
                      </NavLink>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6} lg={6} xl={6} className="hdCenterGrid">
                  <Box className="headerCenter">
                    <Box className="navLink">
                      <ul className="links">
                        <li>
                          <NavLink
                            to="/"
                            className={
                              isActive("/") || isActive("/") ? "active" : ""
                            }
                          >
                            Home
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/temples"
                            className={isActive("/temples") ? "active" : ""}
                          >
                            Temples
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/pujas"
                            className={isActive("/pujas") ? "active" : ""}
                          >
                            Pujas
                          </NavLink>
                        </li>

                        <li>
                          <NavLink
                            to="/chadhavaTemples"
                            className={
                              isActive("/chadhavaTemples") ? "active" : ""
                            }
                          >
                            Chadhava
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/posts"
                            className={isActive("/posts") ? "active" : ""}
                          >
                            Blog
                          </NavLink>
                        </li>

                        <li>
                          <NavLink
                            to="/livedarshan"
                            className={isActive("/livedarshan") ? "active" : ""}
                          >
                            Live Darshan
                          </NavLink>
                        </li>
                      </ul>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={3} lg={3} xl={3} className="hdRightGrid">
                  <Box className="headerRight">
                    <Box
                      className={
                        isActive("/apps") ? "displayNone" : "appDownloadInfo"
                      }
                    >
                      <Button variant="contained" className="downloadBtnInfo">
                        <Link
                          to={"/apps"}
                          className="downloadBtnText"
                          target="_blank"
                        >
                          Download App
                        </Link>
                      </Button>
                    </Box>
                    {/* <Box className="headLang">
                      <Button
                        className="btn btn-default"
                        onClick={() => setOpenLanguageSelection(true)}
                      >
                        <img src={langImg}></img>
                      </Button>
                    </Box> */}
                    {/* <Box
                      className="notificationBlock"
                      onClick={() =>
                        setNotificationBoxPopUp(!notificationBoxPopup)
                      }
                    >
                      <Link to={"/#"}>
                        <NotificationsIcon />
                      </Link>
                    </Box> */}
                    <Box
                      className="cartBlock"
                      style={{
                        marginRight:
                          cartInfo && cartInfo.length > 0 ? "15px" : "3px",
                      }}
                    >
                      {cartInfo && cartInfo.length > 0 ? (
                        <>
                          {" "}
                          <span className="top-0 start-100 translate-middle badge rounded-pill">
                            {cartInfo.length}
                            <span className="visually-hidden">
                              unread messages
                            </span>
                          </span>
                          <Link to={"/checkoutV2/reviewCart"}>
                            <ShoppingCartIcon />
                          </Link>
                        </>
                      ) : (
                        <Link to={"/checkoutV2/reviewCart"}>
                          <Button
                            className="cartProfile"
                            variant="text"
                            startIcon={<AddShoppingCartIcon />}
                          >
                            Cart
                          </Button>
                        </Link>
                      )}
                    </Box>
                    {userInfo ? (
                      <Box
                        className="profileBlock"
                        onClick={() => setOpenRightSidePanel(true)}
                      >
                        {/* <Link to={"/profile/personalInfo"}> */}
                        <Link to="#">
                          {ppUrl ? (
                            <img
                              style={{ width: "34px", height: "34px" }}
                              src={ppUrl}
                              alt=""
                            />
                          ) : (
                            <AvatarGenerator
                              firstName={userFName}
                              lastName={userLName}
                            />
                          )}
                        </Link>
                      </Box>
                    ) : (
                      <Box
                        className="profileBlock"
                        onClick={() => navigate("/login")}
                      >
                        {/* <Link to={"/profile/personalInfo"}> */}
                        <Link to="#">
                          {/* <img
                            style={{ width: "34px", height: "34px" }}
                            src={
                              "https://png.pngtree.com/png-clipart/20191122/original/pngtree-user-icon-isolated-on-abstract-background-png-image_5192004.jpg"
                            }
                            alt=""
                          /> */}

                          <Button
                            className="logoutProfile"
                            variant="text"
                            startIcon={
                              <i
                                style={{ fontSize: "24px", color: "#d56939" }}
                                className="bi bi-person-circle"
                              ></i>
                            }
                          >
                            Login
                          </Button>
                        </Link>
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>

              {searchBoxPopUp ? (
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setSearchBoxPopUp(false);
                  }}
                >
                  <Box className="searchBoxPopUp">
                    <Box className="searchMenu">
                      <Box className="recent">
                        <h6>Rrecommendation</h6>
                      </Box>
                      <Box className="edit">
                        <a href="#">Edit</a>
                      </Box>
                    </Box>
                    <Box className="searchItem">
                      <Box className="recentProfile">
                        <Box className="rpImage">
                          <img src={searchbirla} alt="" />
                        </Box>
                        <Box className="rpName">
                          <h6>Birla Temple</h6>
                        </Box>
                      </Box>
                      <Box className="closeIcon">
                        <a href="#">
                          <i className="bi bi-x"></i>
                        </a>
                      </Box>
                    </Box>
                    {/* {templesSuggestions &&
                  templesSuggestions.organisation &&
                  templesSuggestions.organisation.map((item: any, id: any) => {
                    if (id < 5) {
                      return (
                        <div className="searchItem">
                          <div className="recentProfile">
                            <div className="rpImage">
                              <img
                                src={
                                  item.personalInfo &&
                                  item.personalInfo.media &&
                                  item.personalInfo.media.pictures &&
                                  item.personalInfo.media.pictures[0] &&
                                  item.personalInfo.media.pictures[0].pictureUrl
                                    ? item.personalInfo.media.pictures[0]
                                        .pictureUrl
                                    : ""
                                }
                                alt=""
                              />
                            </div>
                            <div className="rpName">
                              <h6>
                                {item.personalInfo && item.personalInfo.name
                                  ? item.personalInfo.name
                                  : ""}
                              </h6>
                            </div>
                          </div>
                          <div className="closeIcon">
                            <a href="#">
                              <i className="bi bi-x"></i>
                            </a>
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })} */}
                  </Box>
                </OutsideClickHandler>
              ) : null}

              {notificationBoxPopup ? (
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setNotificationBoxPopUp(false);
                  }}
                >
                  <Box className="notificationPannel">
                    <Box className="notificationDetails">
                      <Box className="unreadMark"></Box>
                      <Box className="messageText">
                        <h6>
                          Your order has been picked up <p>Now</p>
                        </h6>
                      </Box>
                    </Box>
                    <Box className="solidLine"></Box>
                    <Box className="notificationDetails">
                      <Box className="unreadMark"></Box>
                      <Box className="messageText">
                        <h6>
                          Your order has been picked up <p>1 h ago</p>
                        </h6>
                      </Box>
                    </Box>
                    <Box className="solidLine"></Box>
                    <Box className="notificationDetails">
                      <Box className="unreadMark"></Box>
                      <Box className="messageText">
                        <h6>
                          Lorem ipsum dolor sit amet, <p>3 h ago</p>
                        </h6>
                      </Box>
                    </Box>
                    <Box className="solidLine"></Box>
                    <Box className="notificationDetails">
                      <Box className="unreadMark"></Box>
                      <Box className="messageText">
                        <h6>
                          Lorem ipsum dolor sit amet, <p>5 h ago</p>
                        </h6>
                      </Box>
                    </Box>
                    <Box className="solidLine"></Box>
                    <Box className="moreBtn">
                      <button>
                        Load more
                        <ArrowRightAltRoundedIcon />
                      </button>
                    </Box>
                  </Box>
                </OutsideClickHandler>
              ) : null}
              <Box className="toggleBtn">
                <MenuIcon />
              </Box>
            </nav>
          </AppBar>

          <RightDrawer
            openRightSidePanel={openRightSidePanel}
            setOpenRightSidePanel={setOpenRightSidePanel}
          />
        </Box>
      )}
      <Dialog
        className="startPostInput langWindow"
        onClose={() => setOpenLanguageSelection(false)}
        open={openLanguageSelection}
      >
        <Box className="langContainer">
          <FormControl className="langFormControl">
            <RadioGroup
              className="langRadioGroup"
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="english"
              name="radio-buttons-group"
            >
              <FormLabel id="demo-radio-buttons-group-label">
                Choose Language
              </FormLabel>
              <Box className="langBtn">
                <FormControlLabel
                  value="english"
                  control={<Radio />}
                  label="English"
                  onClick={() => textContext.setTextData("ENGLISH")}
                />
                {/* <RoundedButton
                  title="English"
                  onClick={() => textContext.setTextData("ENGLISH")}
                ></RoundedButton> */}
                <span className="langLetter">A</span>
              </Box>
              <Box className="langBtn">
                <FormControlLabel
                  value="हिन्दी"
                  control={<Radio />}
                  label="हिन्दी"
                  onClick={() => textContext.setTextData("HINDI")}
                />
                {/* <RoundedButton
                  title="हिन्दी"
                  onClick={() => textContext.setTextData("HINDI")}
                ></RoundedButton> */}
                <span className="langLetter">अ</span>
              </Box>
              <Box className="langBtn">
                <FormControlLabel
                  value="বাংলা"
                  control={<Radio />}
                  label="বাংলা"
                  onClick={() => textContext.setTextData("BENGALI")}
                />
                {/* <RoundedButton
                  title="বাংলা"
                  onClick={() => textContext.setTextData("BENGALI")}
                ></RoundedButton> */}
                <span className="langLetter">ক</span>
              </Box>
            </RadioGroup>
          </FormControl>
        </Box>
      </Dialog>
    </Box>
  );
}

export default Header;

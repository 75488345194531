import ReviewCartView from "../../../Views/CheckoutV2/ReviewCart/ReviewCartView";
import { useEffect, useState } from "react";
import CartView from "../../../Views/Checkout/Views/Cart/CartView";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { env } from "../../../env";
import {
  uid,
  getDateToday,
  getFormattedDate,
  getDateNowInUtc,
  generateTransactionId,
  generateUniqueNumberString,
  createPaymentData,
  PRICES,
  calculateAdminCommission,
  calculatePlatformFee,
  calculateGSTPrice,
  calculatePujaPaymentPrice,
  calculateActualPujaPrice,
} from "../../../utils/HelperFunctions";
import { OrderService, CommonService, CouponService } from "td_server";
import Razorpay from "razorpay";
import {
  setPujaMembers,
  setSelectedPuja,
  setSelectedTempleDetailsForPuja,
} from "../../../store/slices/pujaSlice";
import CartCard from "../../../components/card/Cart/CartCard";
import {
  emptyCartAction,
  getPriceOfPuja,
  updateCartAction,
} from "../../../store/actions/cartAction";
import { setSelectedAddressInCart } from "../../../store/slices/cartSlice";
import axios, { AxiosResponse } from "axios";
import { placeOrderAction } from "../../../store/actions/orderAction";
import { Box, Button, Dialog, Typography } from "@mui/material";
import successIcon from "../../../assets/success.png";
import errorIcon from "../../../assets/error.png";
// import "../../../../Views/Checkout/Views/Cart/CartView.scss";
import CartNoItem from "../../../components/card/Cart/NoItemInCart";
import { updateCouponsUsageAction } from "../../../store/actions/CouponCodeAction";
import templeLogo from "../../../assets/temple-logo-icon.png";
import { addAnalytics } from "../../../store/actions/AnalyticsAction";
import { getChadhavaForTempleList } from "../../../store/actions/ChadhawaAction";
import { orderType } from "../../../utils/Constants";

function ReviewCartScreen() {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const [chooseAddressBox, setChooseAddressBox] = useState(false);
  const [orderCreatedScreen, setOrderCreatedScreen] = useState(false);
  const [orderResultDetails, setOrderResultDetails] = useState("");
  const [errorPopup, setErrorPopUp]: any = useState({
    isError: false,
    errorMessages: [],
  });
  const [bookingFailedPopup, setBookingFailedPopUp]: any = useState(false);
  const [couponPopup, setCouponPopUp]: any = useState(false);
  const [selectedCartItem, setSelectedCartItem]: any = useState(null);
  const [allActiveCoupons, setAllActiveCoupons] = useState<any>([]);
  const [panditDakshina, setPanditDakshina] = useState<number>(0);

  useEffect(() => {
    if (couponPopup) {
      // setIsPackageCouponCode(false);
    }
  }, [couponPopup]);

  const [chadhavaList, setChadhavaList] = useState<any>([]);
  const { templeChadhavaList } = useSelector((state: any) => state.chadhawa);
  useEffect(() => {
    if (templeChadhavaList?.length > 0) {
      const updatedResponse = templeChadhavaList.map((item: any) => ({
        ...item,
        count: 0,
      }));
      console.log("response", updatedResponse);
      setChadhavaList(updatedResponse);
      //setChadhavaList(templeChadhavaList);
    }
  }, [templeChadhavaList]);

  // useEffect(() => {
  //   if (selectedCartItem?.templeDetails?.id) {

  //     dispatch(getChadhavaForTempleList(selectedCartItem.templeDetails.id));
  //     //getChadhavaForTemple(templeId);
  //   }
  // }, [selectedCartItem?.templeDetails?.id]);

  // const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [couponApplyResponse, setCouponApplyResponse] = useState({
    isValid: false,
    isApplied: false,
    finalPrice: 0,
    couponData: null,
    isError: false,
    errorDetails: "",
    actualPujaPrice: 0,
    discount: 0,
    gst: 0,
    priceBreakup: {},
  });
  const [couponCodeData, setCouponCodeData]: any = useState("");
  const [priceBreakupData, setPriceBreakupData]: any = useState({
    actualPujaPrice: 0,
    gst: 0,
    finalPrice: 0,
    priceBreakup: null,
  });
  const [previousPriceData, setPreviousPriceData]: any = useState("");
  const [avialableCouponCode, setAvailableCouponCode]: any = useState(null);
  const { selectedPuja, selectedTempleDetailsForPuja, pujaMembers } =
    useSelector((state: any) => state.puja);
  const { cartInfo, selectedAddressInCart } = useSelector(
    (state: any) => state.cart
  );
  var { userInfo } = useSelector((state: any) => state.user);

  const userPersonalInfo =
    userInfo && userInfo.personalInfo ? userInfo.personalInfo : {};
  const pujaDetails = selectedCartItem ? selectedCartItem.pujaDetails : null;
  const templeDetails = selectedCartItem
    ? selectedCartItem.templeDetails
    : null;
  const pujaPackage = selectedCartItem ? selectedCartItem.pujaPackage : null;
  const memberDetails = selectedCartItem ? selectedCartItem.members : [];
  const windowRefrence: any = window;

  useEffect(() => {
    if (env === "prod") {
      windowRefrence?.fbq("track", "AddToCart");
    }
    setSelectedCartItem(cartInfo[0]);
    if (userPersonalInfo.address && userPersonalInfo.address.length > 0) {
      dispatch(setSelectedAddressInCart(userPersonalInfo.address[0]));
    }
  }, [cartInfo, userPersonalInfo]);

  useEffect(() => {
    if (cartInfo[0] && cartInfo[0]?.orderType === orderType.chadhava) {
      setCouponApplyResponse({
        ...couponApplyResponse,
        isApplied: false,
        isValid: false,
      });
      setPriceQuoteForChadhava();
      // console.log(`----------Set Coupon Code--------------------`)
      // if (allActiveCoupons && allActiveCoupons.length > 0) {
      //   let couponData = allActiveCoupons[0];
      //   checkValidityOfcoupon(couponData.couponCode);
      // }
    }
  }, [cartInfo]);

  // useEffect(() => {
  //   CouponService.getAllActiveCouponsByDate().then((res: any) => {
  //     setAllActiveCoupons(res?.couponList);
  //   });
  // }, []);

  useEffect(() => {
    if (allActiveCoupons && allActiveCoupons.length > 0) {
      let couponData = allActiveCoupons[0];
      checkValidityOfcoupon(couponData.couponCode);
    }
  }, [allActiveCoupons]);

  useEffect(() => {
    console.log(`----------setPriceQuoteForPuja----------`, selectedCartItem);

    if (selectedCartItem) {
      if (selectedCartItem?.orderType === orderType.chadhava) {
        if (couponApplyResponse.isApplied) {
          setPriceQuoteForChadhavaWithCouponData(couponApplyResponse);
        } else {
          setPriceQuoteForChadhava();
        }
      } else {
        if (couponApplyResponse.isApplied) {
          setPriceQuoteForPujaWithCouponData(couponApplyResponse);
        } else {
          setPriceQuoteForPuja();
        }
      }
    }
  }, [couponApplyResponse, selectedCartItem]);

  const fetchCouponForPuja = async (
    templeDetails: any,
    pujaDetails: any,
    pujaPackage: any
  ) => {
    let couponsList: any = [];
    console.log("Puja list check--->", templeDetails, pujaDetails, pujaPackage);
    if (templeDetails && pujaDetails) {
      let response1 = await CouponService.getActiveCouponsByPujaIdCurrentDate(
        templeDetails?.id,
        pujaDetails?.pujaId,
        pujaPackage?.packageId
      );
      console.log("Response 1 ", response1);
      if (response1?.couponList && response1?.couponList?.length > 0) {
        couponsList = response1.couponList;
      }
    }

    let response3 = await CouponService.getAllActiveCouponsByDate();
    // console.log("Coupon code For all available", couponsList,response3.couponList);
    if (response3?.couponList && response3?.couponList?.length > 0) {
      couponsList = [...couponsList, ...response3.couponList];
      setAllActiveCoupons(couponsList);
    } else {
      couponsList = [...couponsList];
      setAllActiveCoupons(couponsList);
    }

    if (
      couponsList.length > 0 &&
      couponsList.length > avialableCouponCode?.length
    ) {
      setAvailableCouponCode(couponsList[0]);
    }
  };

  useEffect(() => {
    if (templeDetails && pujaDetails) {
      fetchCouponForPuja(templeDetails, pujaDetails, pujaPackage);
    }
  }, [templeDetails, pujaDetails, pujaPackage]);

  useEffect(() => {
    if (avialableCouponCode) {
      checkValidityOfcoupon(avialableCouponCode?.couponCode);
      setCouponCodeData(avialableCouponCode?.couponCode);
    }
  }, [avialableCouponCode]);

  const setPriceQuoteForPuja = async () => {
    if (selectedCartItem?.orderType === orderType.chadhava) {
      return;
    }
    console.log("Templ puja", templeDetails, pujaDetails, pujaPackage);
    let payload = {
      templeId: templeDetails?.id,
      pujaId: pujaDetails?.pujaId,
      packageId: pujaPackage?.packageId,
    };
    console.log("Payload", payload);
    const pujaPriceData = await OrderService.getPriceDetailsOfPuja(payload);

    console.log("Fetch function screen price res", payload, pujaPriceData);

    setPriceBreakupData({
      ...pujaPriceData,
    });
    setPreviousPriceData(pujaPriceData?.finalPrice);
  };

  const setPriceQuoteForPujaWithCouponData = (couponApplyResponse: any) => {
    if (couponApplyResponse && couponApplyResponse.priceBreakup) {
      setPriceBreakupData({
        ...couponApplyResponse,
      });
    }
  };

  const setPriceQuoteForChadhavaWithCouponData = (couponApplyResponse: any) => {
    if (couponApplyResponse && couponApplyResponse.priceBreakup) {
      const priceData = {
        ...couponApplyResponse,
        finalPrice:
          Number(couponApplyResponse.finalPrice) + Number(panditDakshina),
        actualFinalPrice: Number(couponApplyResponse.finalPrice),
      };
      setPriceBreakupData({
        ...priceData,
      });
    }
  };

  const getChadhavaTotalValue = (item: any) => {
    const selectedChadhvaList = selectedCartItem?.selectedChadhvaList?.filter(
      (item: any) => item?.count > 0
    );
    const totalPrices = selectedChadhvaList?.reduce(
      (totals: any, item: any) => {
        const finalPrice =
          Math.round(Number(item.priceInfo.finalPrice)) * item.count;
        totals.finalPriceTotal += finalPrice;
        return totals;
      },
      { finalPriceTotal: 0 }
    );

    return Math.round(totalPrices?.finalPriceTotal) || 0;
  };

  useEffect(() => {
    const selectedChadhvaList = selectedCartItem?.selectedChadhvaList?.filter(
      (item: any) => item?.count > 0
    );
    const totalPrices = selectedChadhvaList?.reduce(
      (totals: any, item: any) => {
        const finalPrice =
          Math.round(Number(item.priceInfo.finalPrice)) * item.count;
        totals.finalPriceTotal += finalPrice;
        return totals;
      },
      { finalPriceTotal: 0 }
    );

    let couponDiscount = 0;

    if (couponApplyResponse && couponApplyResponse?.isApplied) {
      // couponDiscount =  couponApplyResponse?.discount
      setPriceBreakupData((prevState: any) => ({
        ...prevState,
        finalPrice:
          Number(couponApplyResponse?.finalPrice) + Number(panditDakshina),
      }));
    } else {
      setPriceBreakupData((prevState: any) => ({
        ...prevState,
        finalPrice:
          Number(totalPrices?.finalPriceTotal) + Number(panditDakshina),
      }));
    }
  }, [panditDakshina]);

  const setPriceQuoteForChadhava = () => {
    const selectedChadhvaList = getSelectedChadhvaList() || [];
    const totalPrices = selectedChadhvaList?.reduce(
      (totals: any, item: any) => {
        const finalPrice =
          Math.round(Number(item.priceInfo.finalPrice)) * item.count;
        const calculatedPrice =
          Math.round(Number(item.priceInfo.calculatedPrice)) * item.count;
        const gst = Math.round(Number(item.priceInfo.gst)) * item.count;

        totals.finalPriceTotal += finalPrice;
        totals.calculatedPriceTotal += calculatedPrice;
        totals.gstTotal += gst;
        return totals;
      },
      { finalPriceTotal: 0, calculatedPriceTotal: 0, gstTotal: 0 }
    );

    const priceData = {
      actualPujaPrice: totalPrices?.calculatedPriceTotal,
      delivery: 0,
      gst: totalPrices?.gstTotal?.toFixed(2),
      finalPrice: Number(totalPrices?.finalPriceTotal) + Number(panditDakshina),
      priceBreakup: {
        basePrice: 0,
        delivery: 0,
        packaging: 0,
        goodies: 0,
        adminCommission: 0,
        platformFee: 0,
      },
    };

    setPriceBreakupData({
      ...priceData,
    });

    setPreviousPriceData(Number(priceData?.finalPrice));
  };

  const changeDeliverytAddress = (address: any) => {
    dispatch(setSelectedAddressInCart(address));
  };

  const editPujaOptions = (selectedCartItem: any) => {
    dispatch(setSelectedTempleDetailsForPuja(selectedCartItem.templeDetails));
    dispatch(setSelectedPuja(selectedCartItem.pujaDetails));
    dispatch(setPujaMembers(selectedCartItem.members));
    navigate("/checkoutV2/addDevotee");
  };

  const clearCart = () => {
    dispatch(emptyCartAction());
    navigate("/temples");
  };

  const checkForAnyFormError = () => {
    let isErrorInForm = false;
    let errorMsg = [];
    if (!selectedAddressInCart) {
      isErrorInForm = true;
      errorMsg.push({
        errType: "addressNotGiven",
        message: "Address not provided",
      });
    }
    if (!memberDetails || memberDetails.length === 0) {
      isErrorInForm = true;
      errorMsg.push({
        errType: "memberNotSelected",
        message: "No members added for puja please add atleast 1 member",
      });
    }

    if (isErrorInForm) {
      setErrorPopUp({ isError: true, errorMessages: errorMsg });
      return false;
    }
    return true;
  };

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const placeOrder = async (e: any) => {
    e.preventDefault();
    const res = await loadRazorpayScript();

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    //check for error
    const isFormValid = checkForAnyFormError();
    //error checking complete
    if (isFormValid) {
      // let basePrice = priceDetails.basePrice;
      let orderValue = Math.round(priceBreakupData?.finalPrice);
      const _basePrice = priceBreakupData?.priceBreakup?.basePrice;
      const priceBreakup = {
        basic: _basePrice,
        adminCommission: priceBreakupData?.priceBreakup?.adminCommission,
        delivery: priceBreakupData?.priceBreakup?.delivery,
        packaging: priceBreakupData?.priceBreakup?.packaging,
        goodies: priceBreakupData?.priceBreakup?.goodies,
        platformFee: priceBreakupData?.priceBreakup?.platformFee,
        gst: priceBreakupData.gst,
      };

      const transectionId = generateTransactionId();
      const orderId =
        selectedCartItem?.orderType === orderType.puja
          ? `PO${generateUniqueNumberString()}`
          : `CO${generateUniqueNumberString()}`;
      const orderData = {
        amount: Number(Number(orderValue) * 100).toString(),
        currency: "INR",
        receipt: "receipt#1",
        notes: {
          key1: orderId,
          key2: "value2",
        },
      };

      try {
        const rpOrderIdResponse = await CommonService.generateRazorPayOrderId(
          orderData
        );

        var rpOrderId = rpOrderIdResponse?.id;

        const rpSecreats = await CommonService.getRazorPayCredentials();
        const rpCredentials = rpSecreats?.credentials;
        // console.log("Razor pay secreats",rpSecreats.credentials);
        if (rpOrderId && rpCredentials) {
          // const oderId = rpOrderId;
          const options = {
            key: rpCredentials.KEY,
            key_secreat: rpCredentials.SECRET,
            order_id: rpOrderId,
            amount: Number(Number(orderValue) * 100).toString(),
            name: "Temple Dekho",
            description: "Test Transaction",
            image: templeLogo,
            handler: function (response: any) {
              if (
                response.razorpay_payment_id &&
                response.razorpay_payment_id.length > 0
              ) {
                console.log("priceBreakup", priceBreakup);

                let newCartData = [];
                let newCartInfo = cartInfo;
                if (selectedCartItem?.orderType === orderType.chadhava) {
                  const singleCartData = cartInfo[0];
                  const selectedChadhvaList = getSelectedChadhvaList();
                  console.log(`singleCartData`, singleCartData);
                  console.log(`selectedChadhvaList`, selectedChadhvaList);
                  let cartPayload = { ...singleCartData, selectedChadhvaList };
                  console.log(`cartPayload`, cartPayload);
                  newCartData.push(cartPayload);
                  newCartInfo = newCartData;
                }

                dispatch(
                  placeOrderAction(
                    newCartInfo,
                    userInfo,
                    selectedAddressInCart,
                    orderValue,
                    transectionId,
                    orderId,
                    response.razorpay_payment_id,
                    priceBreakup
                  )
                );
                if (couponApplyResponse.isApplied) {
                  const couponData: any = couponApplyResponse.couponData;
                  const userPhNo = userInfo.phoneNumber;
                  dispatch(
                    updateCouponsUsageAction(couponData.couponCode, userPhNo)
                  );
                }

                // setOrderResultDetails(orderId);
                // setOrderCreatedScreen(true);
                navigate("/success", {
                  state: {
                    type: "payment",
                    title: "Payment Successful!",
                    message: `Thank you! Your payment of Rs.${orderValue}  has been received.`,
                    orderValue,
                    orderId,
                    transectionId,
                    priceBreakup,
                    orderType: selectedCartItem?.orderType,
                    razorpay_payment_id: response.razorpay_payment_id,
                  },
                });
              } else {
                //setBookingFailedPopUp(true);
                navigate("/error", {
                  state: {
                    message: `Sorry for inconvenience. Error happened while booking the Puja.Please try again after sometime`,
                  },
                });
              }
            },
            prefill: {
              name:
                userPersonalInfo.firstName + " " + userPersonalInfo.lastName,
              email: userPersonalInfo.email,
              contact: userInfo.phoneNumber,
            },
            notes: {
              address: "TempleDekho Office",
            },
            theme: {
              color: "#F37254",
            },
          };

          var pay = new (window as any).Razorpay(options);
          pay.open();
        }
      } catch (error) {
        console.log("Error:", error);
        setBookingFailedPopUp(true);
      }

      return true;
    }
  };

  // useEffect(() => {
  //   if (couponCodeData) {
  //     checkValidityOfcoupon();
  //     // setIsPackageCouponCode(true);
  //   }
  // }, [couponCodeData]);

  const getSelectedChadhvaList = () => {
    return (
      selectedCartItem?.selectedChadhvaList?.filter(
        (item: any) => item?.count > 0
      ) || []
    );
  };

  const checkValidityOfcoupon = async (couponCodeData: any) => {
    if (couponCodeData) {
      try {
        let payload;

        if (selectedCartItem.orderType === orderType.chadhava) {
          payload = {
            couponCode: couponCodeData,
            userPhoneNo: userInfo?.phoneNumber,
            templeId: templeDetails?.id,
            orderType: selectedCartItem.orderType,
            chadhavaList: getSelectedChadhvaList()?.map((item: any) => {
              return {
                id: item?.id,
                count: item?.count,
              };
            }),
          };
        } else {
          payload = {
            couponCode: couponCodeData,
            userPhoneNo: userInfo?.phoneNumber,
            templeId: templeDetails?.id,
            pujaId: pujaDetails?.pujaId,
            packageId: pujaPackage?.packageId,
            orderType: selectedCartItem.orderType,
          };
        }

        const couponValRes2 = await CouponService.verifyCouponAndGetPrice(
          payload
        );

        if (couponValRes2.isError === false && couponValRes2.finalPrice) {
          setCouponApplyResponse({
            ...couponApplyResponse,
            isValid: true,
            isError: false,
            actualPujaPrice: couponValRes2.actualPujaPrice,
            finalPrice: couponValRes2.finalPrice,
            couponData: couponValRes2.couponData,
            discount: couponValRes2.discount,
            gst: couponValRes2.gst,
            priceBreakup: couponValRes2.priceBreakup,
            isApplied: true,
          });
          setCouponPopUp(false);
        } else {
          setCouponApplyResponse({
            ...couponApplyResponse,
            isValid: false,
            isApplied: false,
            isError: true,
            errorDetails: "Invalid coupon applied",
          });
        }
      } catch (err: any) {
        setCouponApplyResponse({
          ...couponApplyResponse,
          isApplied: false,
          isError: true,
          errorDetails: "Invalid coupon applied",
        });
      }
    }
  };

  const navigateToAddressScreen = () => {
    navigate("/profile/address");
  };

  const navigateToAddMembersScreen = () => {
    navigate("/checkoutV2/addDevotee");
  };

  const navigateToLoginScreen = () => {
    // set the redirect uri
    localStorage.setItem("redirectUri", "/checkoutV2/reviewCart");
    navigate("/login");
  };

  useEffect(() => {
    if (cartInfo?.length > 0 && cartInfo[0]?.whatsAppNumber) {
      let analyticsData: any = {
        dateUTC: getDateNowInUtc(),
        key: "puja_cart_view_opened",
        eventData: {
          applicationType: "Web",
          userName: "",
        },
        count: 0,
      };

      analyticsData = { ...analyticsData, cartInfo: cartInfo };
      analyticsData["eventData"]["whatsAppNumber"] =
        cartInfo[0]?.whatsAppNumber;

      let user: any = localStorage.getItem("userInfo");
      let userData = null;
      if (user && user !== "undefined" && user !== "null") {
        userData = JSON.parse(user);
      }

      if (userData) {
        analyticsData.eventData.userName = userData?.personalInfo?.firstName;
        analyticsData["eventData"]["userId"] = userData?.id;
        analyticsData["eventData"]["phoneNumber"] = userData?.phoneNumber;
      } else {
        analyticsData.eventData.userName = "Guest User";
      }
      dispatch(addAnalytics(analyticsData));
      console.log("Analytics Save cart", analyticsData);
    }

    return () => {};
  }, [cartInfo]);

  useEffect(() => {
    if (selectedCartItem && selectedCartItem?.members.length === 0) {
      navigateToAddMembersScreen();
    }
  }, [selectedCartItem]);

  const handleSetCouponCodeData = (event: any) => {
    setCouponCodeData(event);
  };

  const updateCart = (cartPayload: any) => {
    let newCartData = [];
    newCartData.push(cartPayload);
    dispatch(updateCartAction(newCartData));
  };

  const handleIncrement = (item: any) => {
    const singleCartData = cartInfo[0];
    const selectedChadhvaList = singleCartData.selectedChadhvaList.map(
      (chadhava: any) => {
        if (chadhava.id === item.id) {
          const updatedCount = chadhava.count + 1;
          return { ...chadhava, count: updatedCount };
        } else {
          return chadhava;
        }
      }
    );

    let cartPayload = { ...singleCartData, selectedChadhvaList };
    updateCart(cartPayload);
  };

  const handleDecrement = (item: any) => {
    const singleCartData = cartInfo[0];
    const selectedChadhvaList = singleCartData.selectedChadhvaList.map(
      (chadhava: any) => {
        if (chadhava.id === item.id) {
          const updatedCount = chadhava.count > 0 ? chadhava.count - 1 : 0;
          return { ...chadhava, count: updatedCount };
        } else {
          return chadhava;
        }
      }
    );

    let cartPayload = { ...singleCartData, selectedChadhvaList };
    updateCart(cartPayload);
  };

  const handRedirectToChadhavaPage = () => {
    if (templeDetails?.id) {
      navigate("/chadhavaDetails/" + templeDetails?.nameKey);
      dispatch(emptyCartAction());
    }
  };

  return (
    <div>
      {cartInfo && cartInfo.length > 0 ? (
        <ReviewCartView
          userDetails={userInfo}
          userPersonalInfo={userPersonalInfo}
          priceBreakup={priceBreakupData}
          pujaDetails={pujaDetails}
          templeDetails={templeDetails}
          memberDetails={memberDetails}
          selectedAddressDetails={selectedAddressInCart}
          chooseAddressBox={chooseAddressBox}
          setChooseAddressBox={setChooseAddressBox}
          changeDeliverytAddress={changeDeliverytAddress}
          editPujaOptions={() =>
            editPujaOptions(selectedCartItem ? selectedCartItem : cartInfo[0])
          }
          placeOrder={placeOrder}
          setOrderCreatedScreen={setOrderCreatedScreen}
          orderCreatedScreen={orderCreatedScreen}
          orderResultDetails={orderResultDetails}
          cartDetails={selectedCartItem ? selectedCartItem : cartInfo[0]}
          clearCart={clearCart}
          errorPopup={errorPopup}
          setErrorPopUp={setErrorPopUp}
          bookingFailedPopup={bookingFailedPopup}
          setBookingFailedPopUp={setBookingFailedPopUp}
          couponPopup={couponPopup}
          setCouponPopUp={setCouponPopUp}
          setCouponCodeData={handleSetCouponCodeData}
          couponCodeData={couponCodeData}
          checkValidityOfcoupon={checkValidityOfcoupon}
          couponApplyResponse={couponApplyResponse}
          setCouponApplyResponse={setCouponApplyResponse}
          navigateToAddressScreen={navigateToAddressScreen}
          navigateToAddMembersScreen={navigateToAddMembersScreen}
          navigateToLoginScreen={navigateToLoginScreen}
          setPriceQuoteForPuja={setPriceQuoteForPuja}
          allActiveCoupons={allActiveCoupons}
          chadhavaList={chadhavaList}
          handleIncrement={handleIncrement}
          handleDecrement={handleDecrement}
          handRedirectToChadhavaPage={handRedirectToChadhavaPage}
          setPanditDakshina={setPanditDakshina}
          previousPriceData={previousPriceData}
          getChadhavaTotalValue={getChadhavaTotalValue}
        />
      ) : (
        <CartNoItem />
      )}
      <Dialog
        className="OrderResult"
        onClose={() => {
          setOrderCreatedScreen(false);
          navigate("/home");
        }}
        open={orderCreatedScreen}
      >
        <Box className="successCard">
          <Box className="successIcon">
            <img src={successIcon} alt="" />
          </Box>
          <Typography mt="30px">Your order Created Successfully</Typography>
          <Typography>Order Id : {orderResultDetails}</Typography>
          <Button
            variant="outlined"
            onClick={() => {
              setOrderCreatedScreen(false);
              navigate("/home");
            }}
          >
            Ok
          </Button>
        </Box>
      </Dialog>
      <Dialog
        className="errorMsg"
        onClose={() => setBookingFailedPopUp(false)}
        open={bookingFailedPopup}
      >
        <Box className="errorCard">
          <Box className="errorIcon">
            <img src={errorIcon} alt="" />
          </Box>
          <Typography>
            Sorry for inconvenience. Error happened while booking the Puja.
            Please try again after sometime !
          </Typography>
          <Button onClick={() => setBookingFailedPopUp(false)}>Ok</Button>
        </Box>
      </Dialog>
    </div>
  );
}

export default ReviewCartScreen;

import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  List,
  ListItem,
  Divider,
  ButtonGroup,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import { zodiacSign } from "../../utils/Constants";
import "./HoroscopeView.scss";
import TitleTypography from "../../components/typography/TitleTypography";
import {
  CalendarMonthOutlined,
  Label,
  LocationCityOutlined,
  MapOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";

import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { googleMapKey } from "../../configs/firebaseConfig";
import discoverRashiSign from "../../assets/rashi-discover-sign.png";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CityAutocomplete from "../../components/AutoComplete/CityAutocomplete";
import ariesRashi from "../../assets/horoscopes/aries.png";

import AriesBanner from "../../assets/horoscopes/ariesBanner.png";
import appShareAd from "../../assets/shareapp-img.png";
import SubtitleTypography from "../../components/typography/SubtitleTypography";
import { capitalizeWords, formatDate } from "../../utils/HelperFunctions";
import PujaCategoryList from "../../components/PujaCategoryList/PujaCategoryList";

import ReactDOM from "react-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import HoroscopeCarousel from "./HoroscopeCarousel";
import ShareLink from "../../components/sharelink/sharelink";
import ShareLinkDialog from "../../components/dialog/ShareLinkDialog";
import { getSignInfo } from "./HoroscopeCalculation";

const HoroscopeDetailsView = (props: any) => {
  const horoscopeData = props.horoscopeData;
  const selectedLanguage: any = props.selectedLanguage;
  const selectedSign = props.selectedSign;
  const imageData = props.imageData;
  console.log(props?.selectedItem);
  const [rashiResult, setRashiResult] = useState<any>({
    rashiName: "",
    rashiImage: "",
  });
  const nativeViewCheck = props.parentViewCheck;
  const [israshiDescClicked, setIsRashiDescClicked] = useState(false);
  // For Open Share Modal
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const handleOpenShareDialog = () => {
    setShareDialogOpen(true);
  };

  const handleCloseShareDialog = () => {
    setShareDialogOpen(false);
  };

  //Know Rashi Modal
  const [openModal, setOpenModal] = useState(false);
  const [fullName, setFullName] = useState("");
  const [birthDate, setBirthDate] = useState(null);
  const [fbirthDate, setfBirthDate] = useState("");
  const [ftime, setfTime] = useState("");
  const [time, setTime] = useState(null);
  const [location, setLocation] = useState<any>(null);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [isCalculationDone, setIsCalculationDone] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Added loading state
  const [utcOffsetMinutes, setUtcOffsetMinutes] = useState<string>("");
  const [errors, setErrors] = useState<{
    fullName: boolean;
    birthDate: boolean;
    birthTime: boolean;
    utcOffsetMinutes: boolean;
  }>({
    fullName: false,
    birthDate: false,
    birthTime: false,
    utcOffsetMinutes: false,
  });

  const handleOpen = () => setOpenModal(true);

  const handleClose = () => {
    setCalcSign(false);
    setOpenModal(false);
    setRashiResult({
      rashiName: "",
      rashiImage: "",
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setIsSubmit(true);
    const newErrors = {
      fullName: !fullName.trim(),
      birthDate: !fbirthDate,
      birthTime: !ftime,
      utcOffsetMinutes: !utcOffsetMinutes,
    };
    setErrors(newErrors);
    if (
      !newErrors.fullName &&
      !newErrors.birthDate &&
      !newErrors.birthTime &&
      !newErrors.utcOffsetMinutes
    ) {
      const strDateTime = `${fbirthDate}T${ftime}:00${utcOffsetMinutes}`;
      props.handleSubmit(strDateTime, fullName, location);
      setIsLoading(true);
      setIsCalculationDone(false);
      setTimeout(() => {
        setIsCalculationDone(true);
        setIsLoading(false);
      }, 2000);
    } else {
      setIsLoading(false);
    }
  };

  const handleCityChange = (city: any | null) => {
    if (city) {
      setUtcOffsetMinutes(city.utc_offset_minutes);
      setLocation(city);
    } else {
      console.log("No city selected");
    }
  };
  const handleSetError = () => {
    setTimeout(() => {
      const newErrors = {
        fullName: !fullName.trim(),
        birthDate: !fbirthDate,
        birthTime: !ftime,
        utcOffsetMinutes: !utcOffsetMinutes,
      };
      setErrors(newErrors);
    }, 100);
  };

  useEffect(() => {
    handleSetError();
  }, [fullName, fbirthDate, ftime, utcOffsetMinutes]);

  useEffect(() => {
    if (props.calcMoonSing?.length > 0) {
      const sunSign = getSignInfo(props.calcMoonSing);
      console.log(`---SS---${sunSign}`);
      setRashiResult(sunSign);
      handleClickOpen();
    }
  }, [props.calcMoonSing]);

  //Calculate Your Sign Dialog Modal
  const [calcSign, setCalcSign] = React.useState(false);

  const handleClickOpen = () => {
    setCalcSign(true);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleDateChange = (newValue: Dayjs | null) => {
    if (newValue) {
      const formattedDate = newValue.format("YYYY-MM-DD");
      console.log("Formatted Date:", formattedDate);

      setfBirthDate(formattedDate);
      setBirthDate(null);
    } else {
      setBirthDate(null);
    }
  };

  const handleTimeChange = (newValue: Dayjs | null) => {
    if (newValue) {
      const formattedTime = newValue.format("HH:mm");
      console.log("Formatted Time:", formattedTime);
      setTime(null);
      setfTime(formattedTime);
    } else {
      setTime(null);
    }
  };
  const handleViewDetails = () => {
    setIsRashiDescClicked(true);
    props.navigateToHoriscopeDetails(rashiResult?.rashiName);
    handleClose();
  };

  return (
    <Box
      className={`rashiPage ${nativeViewCheck ? "pageContainer" : "pageContainerNative"
        }`}
    >
      <Box className="rashiBlock">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              md={2}
              lg={3}
              xl={3}
              className="pagehdLeftBlock"
            ></Grid>
            <Grid item xs={12} md={8} lg={6} xl={6} className="pageCenterBlock">
              <div className="chooseRashi">
                <Box className="rashiMainContent">
                  <Box className="rshiDetails">
                    <Box className="rashiBanner">
                      {/* <img src={imageData?.img} /> */}
                      <HoroscopeCarousel
                        selectedItem={props?.selectedItem}
                        navigateToSelectedSignIndex={
                          props.navigateToSelectedSignIndex
                        }
                      />
                    </Box>
                    <Box className="dayLang">
                      <Box className="daysRashi">
                        <Button
                          className="btnToday"
                          variant={
                            props.isTodaySelected ? "contained" : "outlined"
                          }
                          onClick={() => props.getHoroscopeDataForToDay()}
                        >
                          Today
                        </Button>
                        <Button
                          className="btnToday"
                          variant={
                            props.isTodaySelected ? "outlined" : "contained"
                          }
                          onClick={() => props.getHoroscopeDataForTomorrow()}
                        >
                          Tomorrow
                        </Button>
                      </Box>
                      <Box className="langSelect">
                        <ButtonGroup
                          variant="outlined"
                          aria-label="Basic button group"
                        >
                          <Button
                            variant={
                              props.selectedLanguage === "english"
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() => props.changeLanguage("english")}
                          >
                            A
                          </Button>
                          <Button
                            variant={
                              props.selectedLanguage === "hindi"
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() => props.changeLanguage("hindi")}
                          >
                            अ
                          </Button>
                        </ButtonGroup>
                      </Box>
                    </Box>

                    {horoscopeData ? (
                      <Box className="rashiDescription">
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          className="topHeaderInfo"
                        >
                          <Typography variant="h5">
                            {props?.selectedDate
                              ? formatDate(props?.selectedDate)
                              : " "}
                          </Typography>
                          <Box className="knowRashiModal">
                            <Button variant="contained" onClick={handleOpen}>
                              Know Your Rashi
                            </Button>
                            <Dialog
                              open={openModal}
                              onClose={handleClose}
                              className="rashiModalInfo"
                            >
                              <DialogContent sx={{ p: "20px" }}>
                                <Box className="rashiInputSec">
                                  <Grid container spacing={2}>
                                    {rashiResult?.rashiName?.length === 0 && (
                                      <Grid item xs={12}>
                                        <Stack
                                          direction="row"
                                          justifyContent="space-between"
                                          alignItems="center"
                                          className="discoverRashiModalTitle"
                                        >
                                          <Stack
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            spacing={1}
                                          >
                                            <img
                                              src={discoverRashiSign}
                                              alt="Discover Rashi Sign"
                                            />
                                            <Typography>
                                              Discover Your Sign
                                            </Typography>
                                          </Stack>
                                          <IconButton
                                            aria-label="close"
                                            onClick={handleClose}
                                            className="topCloseIconBtn"
                                          >
                                            <CloseIcon />
                                          </IconButton>
                                        </Stack>
                                      </Grid>
                                    )}

                                    {/* Form Section */}
                                    {rashiResult?.rashiName?.length === 0 && (
                                      <Grid item xs={12}>
                                        <Box
                                          component="form"
                                          onSubmit={handleSubmit}
                                          className="discoverRashiModalInput"
                                        >
                                          <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                          >
                                            <Grid
                                              container
                                              rowSpacing={2}
                                              columnSpacing={{
                                                xs: 2,
                                                sm: 2,
                                                md: 3,
                                              }}
                                            >
                                              <Grid item xs={12} md={6} pb={{ xs: 0, sm: 0, md: 1 }}>
                                                <TextField
                                                  value={fullName}
                                                  onChange={(e) =>
                                                    setFullName(e.target.value)
                                                  }
                                                  variant="outlined"
                                                  label="Full Name *"
                                                  placeholder="Enter here"
                                                  fullWidth
                                                />
                                                {isSubmit &&
                                                  errors.fullName && (
                                                    <Typography
                                                      variant="body2"
                                                      color="error"
                                                    >
                                                      Full Name is required
                                                    </Typography>
                                                  )}
                                              </Grid>

                                              <Grid item xs={12} md={6} pb={{ xs: 0, sm: 0, md: 1 }}>
                                                <CityAutocomplete
                                                  onChangeHandle={
                                                    handleCityChange
                                                  }
                                                  variant="outlined"
                                                  label="Birth Location *"
                                                />
                                                {isSubmit &&
                                                  errors.utcOffsetMinutes && (
                                                    <Typography
                                                      variant="body2"
                                                      color="error"
                                                    >
                                                      Birth Location is required
                                                    </Typography>
                                                  )}
                                              </Grid>

                                              <Grid item xs={12} md={6}>
                                                <DatePicker
                                                  label="Birth Date *"
                                                  value={birthDate}
                                                  onChange={handleDateChange}
                                                  sx={{ width: "100%" }}
                                                  slots={{
                                                    textField: TextField,
                                                  }}
                                                  slotProps={{
                                                    textField: {
                                                      variant: "outlined",
                                                    },
                                                  }}
                                                  maxDate={dayjs()}
                                                  format="DD/MM/YYYY"
                                                />
                                                {isSubmit &&
                                                  errors.birthDate && (
                                                    <Typography
                                                      variant="body2"
                                                      color="error"
                                                    >
                                                      Birth Date is required
                                                    </Typography>
                                                  )}
                                              </Grid>

                                              <Grid item xs={12} md={6}>
                                                <TimePicker
                                                  label="Time of Birth (HH:MM) *"
                                                  value={time}
                                                  onChange={handleTimeChange}
                                                  sx={{ width: "100%" }}
                                                  slots={{
                                                    textField: TextField,
                                                  }}
                                                  slotProps={{
                                                    textField: {
                                                      variant: "outlined",
                                                    },
                                                  }}
                                                />
                                                {isSubmit &&
                                                  errors.birthTime && (
                                                    <Typography
                                                      variant="body2"
                                                      color="error"
                                                    >
                                                      Birth Time is required
                                                    </Typography>
                                                  )}
                                              </Grid>
                                            </Grid>
                                          </LocalizationProvider>
                                          <Button
                                            type="submit"
                                            variant="contained"
                                            className="calculateBtn"
                                            endIcon={
                                              <KeyboardDoubleArrowRightIcon />
                                            }
                                            size="large"
                                          >
                                            Calculate Your Sign
                                          </Button>
                                        </Box>
                                      </Grid>
                                    )}
                                  </Grid>

                                  {/* Result Section After Calculation */}
                                  {rashiResult?.rashiName?.length > 0 && (
                                    <>
                                      <Typography
                                        variant="h6"
                                        sx={{ m: 0, p: "16px 0" }}
                                        id="customized-dialog-title"
                                      >
                                        Your Sign! Based on our calculation.
                                      </Typography>

                                      {isLoading && (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: {
                                              xs: "auto",
                                              sm: "auto",
                                              md: "550px",
                                            },
                                            height: {
                                              xs: "250px",
                                              sm: "250px",
                                              md: "180px",
                                            },
                                          }}
                                        >
                                          <CircularProgress />
                                        </Box>
                                      )}

                                      {!isLoading && (
                                        <DialogContent
                                          sx={{ width: "100%", p: 0 }}
                                        >
                                          <Box className="calcBtnModalContent">
                                            <Box
                                              className="selectedRashi"
                                              onClick={() => {
                                                setIsRashiDescClicked(true);
                                                props.navigateToHoriscopeDetails(
                                                  rashiResult?.rashiName
                                                );
                                              }}
                                            >
                                              <img
                                                src={rashiResult?.rashiImage}
                                                alt=""
                                              />
                                            </Box>
                                            <Box className="userInfo">
                                              <Typography component="p">
                                                Pranam Bhakt! Based on the
                                                date, time, and place of your
                                                birth, your Janma Rashi is
                                                determined to be{" "}
                                                <span>
                                                  {rashiResult?.rashiName}
                                                </span>
                                                .
                                              </Typography>
                                            </Box>
                                          </Box>
                                        </DialogContent>
                                      )}

                                      <DialogActions className="calcModalBottomBtn">
                                        <Button
                                          variant="contained"
                                          className="viewRashiBtn"
                                          onClick={handleViewDetails}
                                        >
                                          View Details
                                        </Button>
                                        <Button
                                          variant="outlined"
                                          onClick={handleClose}
                                        >
                                          Close
                                        </Button>
                                      </DialogActions>
                                    </>
                                  )}
                                </Box>
                              </DialogContent>
                            </Dialog>
                          </Box>
                        </Stack>

                        <Box className="contDetails">
                          <SubtitleTypography
                            title={`${capitalizeWords(
                              selectedSign
                            )} Daily Horoscope`}
                          ></SubtitleTypography>
                          <Typography paragraph={true}>
                            {horoscopeData?.dailyHoroscope[selectedLanguage]}
                          </Typography>
                        </Box>

                        <Box className="contDetails">
                          <SubtitleTypography
                            title={`${capitalizeWords(
                              selectedSign
                            )}  Health and Wellness Horoscope`}
                          ></SubtitleTypography>
                          <Typography paragraph={true}>
                            {horoscopeData?.healthAndWellness[selectedLanguage]}
                          </Typography>
                        </Box>

                        <Box className="contDetails">
                          <SubtitleTypography
                            title={`${capitalizeWords(
                              selectedSign
                            )} Love and Relationship Horoscope`}
                          ></SubtitleTypography>
                          <Typography paragraph={true}>
                            {
                              horoscopeData?.loveAndRelationship[
                              selectedLanguage
                              ]
                            }
                          </Typography>
                        </Box>

                        <Box className="contDetails">
                          <SubtitleTypography
                            title={`${capitalizeWords(
                              selectedSign
                            )} Career and Money Horoscope`}
                          ></SubtitleTypography>
                          <Typography paragraph={true}>
                            {horoscopeData?.careerAndMoney[selectedLanguage]}
                          </Typography>
                        </Box>

                        <Box>
                          <Box className="categoryList">
                            <Box className="fullWidthBorder"></Box>
                            <Typography className="categoryListTitle">
                              Explore Puja Categories
                            </Typography>
                            <Box>
                              <PujaCategoryList
                                isNativeView={!nativeViewCheck}
                              />
                            </Box>
                          </Box>

                          <Box className="shareAppAd">
                            <Box className="fullWidthBorder"></Box>
                            <Box className="shareIcon">
                              <ShareLinkDialog
                                open={shareDialogOpen}
                                onClose={handleCloseShareDialog}
                                url={props?.urlToShare}
                              />
                            </Box>
                            <img
                              src={appShareAd}
                              onClick={(e) => setShareDialogOpen(true)}
                              alt=""
                            />
                          </Box>
                          {/* <Box className="shareAppAd">
                            <Box className="fullWidthBorder"></Box>
                            {nativeViewCheck ? (<><Box className="shareIcon">
                            
                              <ShareLinkDialog open={shareDialogOpen} onClose={handleCloseShareDialog} url={props?.urlToShare} />
                            </Box>
                              <img src={appShareAd} onClick={e=>setShareDialogOpen(true)} alt="" />
                              </>
                            ) : (
                              <img src={appShareAd} alt="" style={{ cursor: "pointer" }} onClick={() => props.shareNativeAd()} />

                            )}
                          </Box> */}
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100vh",
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    )}
                  </Box>
                </Box>
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              md={2}
              lg={3}
              xl={3}
              className="pagehdRightBlock"
            >
              {/* <RightBlockCard /> */}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default HoroscopeDetailsView;

import PromotionalPujaView from "../../Views/Puja/PromotionalPujaView";
import { useDispatch, useSelector } from "react-redux";

import { Outlet, useNavigate, Navigate,useLocation } from "react-router-dom";

import { useEffect, useState } from "react";

import { getOneImageForTemple } from "../../utils/HelperFunctions";
import { getTempleDetailsById } from "../../store/actions/templeAction";
import { setSelectedPuja,setSelectedTempleDetailsForPuja } from "../../store/slices/pujaSlice";
import { Box } from "@mui/material";
import ParentView from "../../Views/PrarentView/ParentView";
import { getPromotionalPujaListById,getPromotionalPujaListByNameKey } from "../../store/actions/pujaAction";
function PromotioanlPujaScreen(props:any) {
  const location = useLocation();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const {promotionalList,slectedPromotionalPujaOrganisations} = useSelector((state: any) => state.puja);
  const [promotionalPujaDetails,setPromotionalPujaDetails]:any = useState(null)

  useEffect(()=>{
    let keyType = props?.keyType
    if(keyType&&keyType==="id"){
      const promotionalPujaId = location.pathname.split("/")[2];
      dispatch(getPromotionalPujaListById(promotionalPujaId))
    }
    else{
      const params = new URLSearchParams(location.search);
      const promotionalPujaId = params.get('pujaNameKey')
      dispatch(getPromotionalPujaListByNameKey(promotionalPujaId))
      .then()
    }

  },[])

  useEffect(()=>{
    console.log("Promotional list fetch data===>")
    let keyType = props?.keyType
    if(keyType&&keyType==="id"){
      const promotionalPujaId = location.pathname.split("/")[2];
      const selectedPromotionalPujaDetailsFiltered = promotionalList&&promotionalList.length>0?promotionalList.filter(function(item:any){return item.id===promotionalPujaId}):[]
      var promotionalPujaDetailsTemp = null
      if(selectedPromotionalPujaDetailsFiltered&&selectedPromotionalPujaDetailsFiltered.length>0){
        promotionalPujaDetailsTemp = selectedPromotionalPujaDetailsFiltered[0]
        setPromotionalPujaDetails(promotionalPujaDetailsTemp)
      }
    }
    else{
      const params = new URLSearchParams(location.search);
      const promotionalPujaId = params.get('pujaNameKey')
      const selectedPromotionalPujaDetailsFiltered = promotionalList&&promotionalList.length>0?promotionalList.filter(function(item:any){return item.pujaNameKey===promotionalPujaId}):[]
      var promotionalPujaDetailsTemp = null
      if(selectedPromotionalPujaDetailsFiltered&&selectedPromotionalPujaDetailsFiltered.length>0){
        promotionalPujaDetailsTemp = selectedPromotionalPujaDetailsFiltered[0]
        setPromotionalPujaDetails(promotionalPujaDetailsTemp)
      }
    }

  },[promotionalList])



  const onClickParticipate = (templeId:any,pujaId:any)=>{
    navigate(`/templePujaInfo?templeNameKey=${templeId}&pujaNameKey=${pujaId}`)
  }
  return (
    <ParentView>
        <PromotionalPujaView promotionalPujaDetails={promotionalPujaDetails} slectedPromotionalPujaOrganisations={slectedPromotionalPujaOrganisations} onClickParticipate={onClickParticipate}/>
    </ParentView>
  );
}

export default PromotioanlPujaScreen;

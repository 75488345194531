import React from "react";
import { Box, Typography, Stack, Icon } from "@mui/material";
import NumerologicImg from "../../../../assets/NumerologicImg.png";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import "./AIGurujinumerologic.scss";

const AIGurujinumerologic: React.FC<any> = ({ cardsSection }) => {
  
  return (
    <Box className="numerologyListSec">
      <Box className="cardTopImg">
        <Box component="img" src={cardsSection?.imageUrl} className="numerologyImg" />
      </Box>

      {/* Title */}
      <Typography variant="h6" className="cardTopTitle">
        {cardsSection?.title}
      </Typography>

      {/* List Item */}
      <Box className="listArea">
       
        {cardsSection.insights?.map((item: any, index: number) => (
          <Stack
            direction="row"
            alignItems="baseline"
            spacing={1}
            className="cardListItem"
            key={`cardListItem${index}`}
          >
            <Box className="dotIcon">
              <FiberManualRecordIcon />
            </Box>
            <Box>
              <Typography>{item}.</Typography> {/* Render item content */}
            </Box>
          </Stack>
        ))}

        {/* <Stack direction="row" alignItems="baseline" spacing={1} className="cardListItem">
          <Box className="dotIcon">
            <FiberManualRecordIcon />
          </Box>
          <Box>
            <Typography>You have a strong connection to creativity, self-expression, and communication (DN 3).</Typography>
          </Box>
        </Stack>
        <Stack direction="row" alignItems="baseline" spacing={1} className="cardListItem">
          <Box className="dotIcon">
            <FiberManualRecordIcon />
          </Box>
          <Box>
            <Typography>Your inner drive is focused on individuality, innovation, and pioneering new ideas (SUN 1).</Typography>
          </Box>
        </Stack>
        <Stack direction="row" alignItems="baseline" spacing={1} className="cardListItem">
          <Box className="dotIcon">
            <FiberManualRecordIcon />
          </Box>
          <Box>
            <Typography>You present yourself as creative, optimistic, and sociable, with a flair for self-expression (PN 3).</Typography>
          </Box>
        </Stack> */}
      </Box>
      <Box>{cardsSection?.description}</Box>
    </Box>
  );
};

export default AIGurujinumerologic;

import React, { useEffect, useState } from 'react';
import { Button, FormControl, Grid, InputLabel, MenuItem, Modal, Select, Typography, Stack, TextField, Divider, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { DELIVERY_STATUS_LIST, MODIFICATION_TYPE_LIST, PUJA_STATUS_LIST } from '../../utils/Constants';
import { updateOrderStatusAction } from '../../store/actions/orderAction';
import { useDispatch } from 'react-redux';




interface ChangeStatusProps {
  isOpen: boolean;
  onClose: () => void;
  orderIds: any;
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const ChangeStatusModal: React.FC<ChangeStatusProps> = ({ isOpen, onClose, orderIds }) => {

  const dispatch: any = useDispatch();
  const [selectedStatus, setSelectedStatus] = React.useState("Active");
  const [modificationType, setModificationType] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [remark, setRemark] = useState<string>('');
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const [agentPhone, setAgentPhone] = useState<string>('');
  const [awbNumber, setAwbNumber] = useState<string>('');
  const [awbError, setAwbError] = useState<string>('');
  const handleModificationTypeChange = (event: any) => {
    setModificationType(event.target.value as string);
    setStatus('');
    setVideoFile(null);
    setAgentPhone('');
    setAwbNumber('');
    setAwbError('')
  };

  const reset = () => {
    setModificationType('')
    setStatus('');
    setVideoFile(null);
    setAgentPhone('');
    setAwbNumber('');
    setRemark('')
    setAwbError('')
  }

  useEffect(() => {
    reset();
  }, [
    orderIds
  ]
  )


  const handleStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedStatus(event.target.value as string);

  };

  const handleVideoFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {

      setVideoFile(files[0]);

    }
  };

  const saveStatus = async (orderId: string) => {
    const isDeliveryShipped = modificationType === MODIFICATION_TYPE_LIST[1] && status === DELIVERY_STATUS_LIST[1];
    const isAwbNumberProvided = !isDeliveryShipped || (isDeliveryShipped && awbNumber);
    if (!isAwbNumberProvided) {
      setAwbError('AWB number is mandatory for DELIVERY with SHIPPED status.');
      return;
    }

    const formDataObject: any = {
      modificationType,
      pujaStatus: modificationType === MODIFICATION_TYPE_LIST[0] ? status : "",
      deliveryStatus: modificationType === MODIFICATION_TYPE_LIST[1] ? status : "",
      remarks: remark,

      orderId,
      awbNumber: awbNumber ? awbNumber : "",
      agentPhone: agentPhone ? agentPhone : ""
    };



    if (videoFile) {
      formDataObject.videoUrl = { "uri": videoFile, "type": videoFile?.type, "Platform": "web", "isSuccess": true, "multipleArr": [] };

    }


    try {
      await dispatch(updateOrderStatusAction(formDataObject,
        orderId)).then((e: any) => { console.log('updateOrderStatusAction:', e) })
    } catch (error) {
      console.log("Error", error)
    }


  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (orderIds?.length) {
      orderIds.map((id: any) => {
        saveStatus(id)
      })
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose} aria-labelledby="change-status-title" className='orderModal'>
      <Box className="modalContents">
        <Stack className='modalHeader'>
          <Typography id="change-status-title" variant="h6" gutterBottom>
            Update Order Status
          </Typography>
          <Button onClick={onClose} variant='outlined' className="btnClose">
            <CloseIcon />
          </Button>
        </Stack>

        <Box className="modalBody">
          <form>
            <Grid container>
              <Grid item xs={12} md={12}>
                <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                  <InputLabel id="modification-type-label">Modification Type</InputLabel>
                  <Select
                    labelId="modification-type-label"
                    id="modification-type-select"
                    value={modificationType}
                    label="Modification Type"
                    onChange={handleModificationTypeChange}
                  >
                    {MODIFICATION_TYPE_LIST.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                {modificationType && (
                  <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                    <InputLabel id="status-label">{modificationType == MODIFICATION_TYPE_LIST[0] ? "Puja Status" : "Delivery Status"}</InputLabel>
                    <Select
                      labelId="status-label"
                      id="status-select"
                      value={status}
                      label={modificationType == MODIFICATION_TYPE_LIST[0] ? "Puja Status" : "Delivery Status"}
                      onChange={(event: any) =>
                        setStatus(event.target.value as string)
                      }
                    >

                      {modificationType === MODIFICATION_TYPE_LIST[0]
                        ? PUJA_STATUS_LIST.map((status) => (
                          <MenuItem key={status} value={status}>
                            {status}
                          </MenuItem>
                        ))
                        : DELIVERY_STATUS_LIST.map((status) => (
                          <MenuItem key={status} value={status}>
                            {status}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}

              </Grid>

              {status === DELIVERY_STATUS_LIST[2] && modificationType === MODIFICATION_TYPE_LIST[1] && (
                <Grid item xs={12} md={12} sx={{ textAlign: "end" }}>
                  <FormControl sx={{ width: "100%", marginBottom: "8px" }}>
                    <TextField
                      id="agent-phone"
                      label="Agent's Phone Number"
                      value={agentPhone}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAgentPhone(event.target.value)}
                    />
                  </FormControl>
                </Grid>
              )}

              {status === DELIVERY_STATUS_LIST[1] && modificationType === MODIFICATION_TYPE_LIST[1] && (
                <Grid item xs={12} md={12} sx={{ textAlign: "end" }}>
                  <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                    <TextField
                      id="awb-number"
                      label="AWB Number*"
                      value={awbNumber}
                      error={!!awbError}
                      helperText={awbError}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setAwbNumber(event.target.value); }}
                    />
                  </FormControl>
                </Grid>
              )}

              {status === PUJA_STATUS_LIST[3] && modificationType === MODIFICATION_TYPE_LIST[0] && (

                <Grid item xs={12} md={12} sx={{ textAlign: "end" }}>
                  <FormControl sx={{ width: "35%", marginBottom: "16px" }}>

                    {/* <input
                    accept="video/*"
                    id="video-upload"
                    type="file"
                    onChange={handleVideoFileChange}
                    style={{ display: 'none' }}
                  />
                  <label htmlFor="video-upload">
                    <Button variant="contained" component="span">
                      Choose File
                    </Button>
                  </label> */}

                    {/* <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}

                  >
                    Upload Video
                    <VisuallyHiddenInput type="file" onChange={handleVideoFileChange} id="video-upload" />
                  </Button>
                  {videoFile && <span>{videoFile.name}</span>} */}
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12} md={12}>
                <TextField
                  id="remark"
                  label="Remark"
                  multiline
                  rows={4}
                  variant="outlined"
                  value={remark}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setRemark(event.target.value)}
                  sx={{ width: "100%", marginBottom: "16px" }}
                />
              </Grid>
            </Grid>
          </form>
        </Box>
        <Box className="modalFooter">
          <Button onClick={onClose} variant="outlined" className="btnCancel grey">Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary" className="btnSave">Save</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ChangeStatusModal;

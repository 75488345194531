import AddDevoteeDetailsView from "../../../Views/CheckoutV2/AddDevotee/AddDevoteeView";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import {
  uid,
  getDateToday,
  getFormattedDate,
  getDateNowInUtc,
} from "../../../utils/HelperFunctions";
import dayjs, { Dayjs } from "dayjs";
import { useNavigate } from "react-router-dom";
import { setPujaMembers } from "../../../store/slices/pujaSlice";
import { updateCartAction } from "../../../store/actions/cartAction";
import { addAnalytics } from "../../../store/actions/AnalyticsAction";
import CartNoItem from "../../../components/card/Cart/NoItemInCart";
import { getUserOrder } from "../../../store/actions/orderAction";
import { Box, TextField, Typography } from "@mui/material"; // Import necessary MUI components

function AddDevoteeDetailsScreen() {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const initialized = useRef(false);
  const { selectedPuja, selectedTempleDetailsForPuja, pujaMembers } =
    useSelector((state: any) => state.puja);

  const { cartInfo } = useSelector((state: any) => state.cart);
  var { userInfo } = useSelector((state: any) => state.user);
  const userPersonalInfo =
    userInfo && userInfo.personalInfo ? userInfo.personalInfo : null;
  const singleCartData = cartInfo && cartInfo.length > 0 ? cartInfo[0] : null;

  // const templeAndPujaDetails = useSelector((state: any) => state.TempleSlice);
  useEffect(() => {
    setWhatsAppNumber(singleCartData?.whatsAppNumber);
  }, [singleCartData]);

  const [gotram, setGotram] = useState<string>("");
  const [manokamna, setmanokamna] = useState<string>("");
  const [membersInfo, setMembersInfo]: any = useState([]);
  const [popUpDevoteePreviousBooking, setPopUpDevoteePreviousBooking]: any =
    useState(false);
  const currentPujaDetails = selectedPuja ? selectedPuja : {};
  const currentTempleDetails = selectedTempleDetailsForPuja
    ? selectedTempleDetailsForPuja
    : {};

  const [whatsAppNumber, setWhatsAppNumber] = useState("");
  const [validationMessage, setValidationMessage] = useState<string>(""); // State for validation message

  useEffect(() => {
    let oneCartData = cartInfo && cartInfo.length > 0 ? cartInfo[0] : null;
    let maxMemberAllowed = 1;
    if (
      oneCartData?.pujaPackage?.maxMemberAllowed ||
      oneCartData?.pujaDetails?.maxMemberAllowed
    ) {
      maxMemberAllowed = Number(
        oneCartData?.pujaPackage?.maxMemberAllowed ||
          oneCartData?.pujaDetails?.maxMemberAllowed
      );
    }

    let tempmembersInfo: any = [];

    if (oneCartData?.members[0]?.gotram) {
      setGotram(oneCartData?.members[0]?.gotram);
    }

    for (let i = 0; i < maxMemberAllowed; i++) {
      if (
        oneCartData &&
        oneCartData.members &&
        oneCartData.members &&
        oneCartData.members.length > 0 &&
        oneCartData.members[i]
      ) {
        tempmembersInfo = [...tempmembersInfo, oneCartData.members[i]];
      } else {
        tempmembersInfo = [
          ...tempmembersInfo,
          { id: uid(), fullName: "", gotram: "" },
        ];
      }
    }

    setMembersInfo(tempmembersInfo);
  }, [cartInfo]);

  const updateMemberInfo = (key: any, info: any) => {
    let tempmemberData = [...membersInfo];
    tempmemberData[key] = info;
    setMembersInfo(tempmemberData);
  };
  const [whatsAppNumberError, setWhatsAppNumberError] =
    useState<boolean>(false);
    
  const isValidForm = (): boolean => {
    const whatsappNumberRegex = /^[0-9]{10}$/; // Regex for exactly 10 digits
    if (!whatsappNumberRegex.test(whatsAppNumber)) {
      setWhatsAppNumberError(true);
      return false;
    }
    setWhatsAppNumberError(false);
    return true;
    
  };

  useEffect(() => {
    setWhatsAppNumberError(false);
  }, [whatsAppNumber]);

  const proceedToCart = () => {
    if (!isValidForm()) {
      return false;
    }

    let enteredmembersInfo = [];
    for (let i = 0; i < membersInfo.length; i++) {
      console.log(membersInfo[i]);
      let tempMember: any = Object.assign({}, membersInfo[i]);

      //-----------------------------
      if (gotram?.trim()?.length > 0) {
        tempMember["gotram"] = gotram;
      }
      if (manokamna?.trim()?.length > 0) {
        tempMember["manokamna"] = manokamna;
      }
      //-----------------------------

      if (membersInfo[i].fullName) {
        enteredmembersInfo.push(tempMember);
      }
    }

    let cartPayload = {
      ...singleCartData,
      members: enteredmembersInfo,
      whatsAppNumber: whatsAppNumber,
    };

    //-----------********* SPACE TO HANDEL ERRORS IN FORM -----------********//
    //-----------********* SPACE TO HANDEL ERRORS IN FORM -----------********//

    //let newCartData = [...cartInfo,cartDataPayload]
    let newCartData = [];
    newCartData.push(cartPayload);
    dispatch(updateCartAction(newCartData));
    navigate("/checkoutV2/reviewCart");
  };

  const [prevUserGroup, setPrevUserGroup] = useState<any>([]);
  const { userOrder } = useSelector((state: any) => state.order);
  useEffect(() => {
    if (userInfo) { // Pehle check karo ki userInfo exist karta hai
      dispatch(getUserOrder(userInfo))
        .then((data: any) => {
          console.log("Response order");
        })
        .catch((error: any) => {
          console.log("ERR in order", error);
        });
    }
  }, [userInfo]);
  

  useEffect(() => {
    let groupDetails: any[] = [];

    if (userOrder.length) {
      groupDetails = userOrder.reduce((acc: any[], el: any) => {
        return [...acc, ...el.orderUserDetails.groupDetails];
      }, []);
    }
    setPrevUserGroup(groupDetails);
  }, [userOrder]);

  const selectPrevGroupMember = (selectedPrevMemberInfo: any) => {
    let tempmembersInfo: any = JSON.parse(JSON.stringify(membersInfo));
    membersInfo?.some((member:any, index=0) => {
      if (membersInfo.length === 1) {
        tempmembersInfo[0].fullName = "";
      }
      if ( !tempmembersInfo[index].fullName) {
        if (selectedPrevMemberInfo?.fullName) {
          tempmembersInfo[index].fullName = selectedPrevMemberInfo.fullName;
        } else {
          // console.warn("selectedPrevMemberInfo is undefined or has no fullName");
        }
        return true; 
      }
      return false; 
    });

    setMembersInfo(tempmembersInfo);
    setPopUpDevoteePreviousBooking(false);
  };



  console.log("membersInfo");

  return (
    <div>
      {cartInfo && cartInfo.length > 0 ? (
        <AddDevoteeDetailsView
          cartInfo={cartInfo}
          pujaDetails={currentPujaDetails}
          templeDetails={currentTempleDetails}
          membersInfo={membersInfo}
          updateMemberInfo={updateMemberInfo}
          proceedToCart={proceedToCart}
          whatsAppNumber={whatsAppNumber}
          setWhatsAppNumber={setWhatsAppNumber}
          selectPrevGroupMember={selectPrevGroupMember}
          prevUserGroup={prevUserGroup}
          setPopUpDevoteePreviousBooking={setPopUpDevoteePreviousBooking}
          popUpDevoteePreviousBooking={popUpDevoteePreviousBooking}
          addmanokamna={setmanokamna}
          addGotram={setGotram}
          gotram={gotram}
          manokamna={manokamna}
          whatsAppNumberError={whatsAppNumberError}
        />
      ) : (
        <CartNoItem />
      )}
    </div>
  );
}

export default AddDevoteeDetailsScreen;

import Avatar from '@mui/material/Avatar';
function AvatarGenerator(props: any) {

    if(props.firstName&&props.firstName.length&&props.lastName&&props.lastName.length){
        const fLetter = props.firstName[0]
        const lLetter = props.lastName[0]
        return(
            <Avatar sx={{ bgcolor: "#d56939" }}>{fLetter} {" "} {lLetter}</Avatar>
        )    
    }
    else return null
    

}

export default AvatarGenerator
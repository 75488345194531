import {createSlice} from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    userInfo: null,
    isLoading: false,
    error: null,
    addressList: [],
    allOrganisationUser: [],
    currentAddress: null,
    allNormalUsers : [],
  },
  reducers: {
    setUser: (state, action) => {
      state.userInfo = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setAddressList: (state, action) => {
      state.addressList = action.payload;
    },
    setCurrentAddress: (state, action) => {
      state.currentAddress = action.payload;
    },
    setAllOrganisationUser: (state, action) => {
      state.allOrganisationUser = action.payload;
    },
    setAllNormalUser: (state, action) => {
      state.allNormalUsers = action.payload;
    },
    
  },
});

export const {
  setUser,
  setLoading,
  setError,
  setAddressList,
  setCurrentAddress,
  setAllOrganisationUser,
  setAllNormalUser
} = userSlice.actions;

export default userSlice.reducer;

import {createSlice} from '@reduxjs/toolkit';
import { popularTemples } from '../../utils/Constants';

const templeSlice = createSlice({
  name: 'temple',
  initialState: {
    isLoading: false,
    allTempleList:[],
    allPujaList:[],
    mergedList:[],
    verifiedTempleList: popularTemples,
    unVerifiedTempleList: [],
    panchangData: null,
    error: null,
    shaktipeethList: [],
    selectedTempleDetails:null,
    benefitsList:[],
    darshanList:[]
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setAllTempleList: (state, action) => {
      state.allTempleList = action.payload;
    },
    setAllPujaList: (state, action) => {
      state.allPujaList = action.payload;
    },
    setMergedList: (state, action) => {
      state.mergedList = action.payload;
    },
    setVerifiedTempleList: (state, action) => {
      state.verifiedTempleList = action.payload;
    },
    setUnverifiedTempleList: (state, action) => {
      state.unVerifiedTempleList = action.payload;
    },
    setPanchangData: (state, action) => {
      state.panchangData = action.payload;
    },
    setShaktipeeth: (state, action) => {
      state.shaktipeethList = action.payload;
    },
    setSelectedTempleDetails:(state,action)=>{
      state.selectedTempleDetails = action.payload;
    },
    setBenefitsList:(state,action)=>{
      state.benefitsList = action.payload;
    },
    setDarshanList:(state,action)=>{
      state.darshanList = action.payload;
    }
  },
});

export const {
  setLoading,
  setError,
  setVerifiedTempleList,
  setUnverifiedTempleList,
  setPanchangData,
  setAllTempleList,
  setAllPujaList,
  setMergedList,
  setShaktipeeth,
  setSelectedTempleDetails,
  setBenefitsList,
  setDarshanList
} = templeSlice.actions;

export default templeSlice.reducer;

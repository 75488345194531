import {createSlice} from '@reduxjs/toolkit';

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState: {
    isLoading: false,
    analyticsList:[],
    paginatedAnalytics:{},
    error:""
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setAnalyticsList: (state, action) => {
      state.analyticsList = action.payload;
    },
    setPaginatedAnalyticsList: (state, action) => {
      state.paginatedAnalytics = action.payload;
    },
  },
});

export const {
  setLoading,
  setError,
  setAnalyticsList,
  setPaginatedAnalyticsList
} = analyticsSlice.actions;

export default analyticsSlice.reducer;

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import edit from "../../../../assets/edit.png";
import deleteicon from "../../../../assets/delete.png";
import share from "../../../../assets/share.png";
import plus from "../../../../assets/plus-icon.png";
import MyLocationRoundedIcon from '@mui/icons-material/MyLocationRounded';
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { validatePhoneNumber } from "../../../../utils/HelperFunctions"

export default function AddressView(props: any) {
  const addressList = props.addressList
  return (
    <Box>
      <CreateOrEditAddressView {...props}></CreateOrEditAddressView>
      <Box className="addressView">
        {/* <Box className="addressViewTitle">
          <p>Add a new address</p>
        </Box> */}

        {addressList.map((item: any, id: any) => {
          return (
            <Box className="homeAddress">
              <Typography className="homeTitle">{item.savedAs}</Typography>
              <Box className="homeAddressContent">
                <Typography>Street: {item.street}</Typography>
                <Typography>{item.formatted_address}</Typography>
                <Typography>City: {item.city}</Typography>
                <Typography>State/province/area: {item.state}</Typography>
                {item.phoneNumber ? (
                  <Typography>Phone number {item.phoneNumber}</Typography>
                ) : null}

                <Typography>Zip code {item.zip}</Typography>
              </Box>
              <Box className="homeAddressBtn">
                <img
                  src={edit}
                  alt=""
                  onClick={() => {
                    props.setCreateNewOrUpdateAddressView({
                      isCreate: false,
                      isUpdate: true,
                    })
                    props.setUpdateAddressDetails(item)
                  }

                  }
                />
                <img src={deleteicon} alt="" onClick={() => props.deleteAddress(item.id)} />
                {/* <img src={share} alt="" /> */}
              </Box>
            </Box>
          );
        })}

        <Button
          variant="contained"
          onClick={() =>
            props.setCreateNewOrUpdateAddressView({
              isCreate: true,
              isUpdate: false,
            })
          }
        >
          <img src={plus} alt="" /> Add New address
        </Button>
      </Box>
    </Box>
  );
}

const CreateOrEditAddressView = (props: any) => {
  const { createNewOrUpdateAddressView, getCurrentPosition, newAddressDetails, setNewAddressDetails, saveNewAddress, updateAddressDetails, setUpdateAddressDetails, saveUpdatedAddress, setCreateNewOrUpdateAddressView } = props

  const [PhoneNumberInputError, setPhoneNumberInputError] = useState(false);
  const handlePhoneNumberChange = (val: any, type: any) => {
    const value: any = val

    if (type == "add") {
      setNewAddressDetails({
        ...newAddressDetails,
        phoneNumber: value,
      })
    } else {
      setUpdateAddressDetails({
        ...updateAddressDetails,
        phoneNumber: value,
      })
    }
    if (validatePhoneNumber(value)) {
      setPhoneNumberInputError(false);
    } else {
      setPhoneNumberInputError(true);
    }
    if (val?.length === 0) {
      setPhoneNumberInputError(false);
      return;

    }
  };

  return (
    <>
      {createNewOrUpdateAddressView?.isCreate && (

        <Box sx={{ marginBottom: "1rem", borderBottom: "1px solid #ebedf6", paddingBottom: "1.5rem" }}>
          <Typography sx={{ marginBottom: "0.8rem", fontWeight: "500", textTransform: "uppercase" }}>Add a New Address</Typography>
          <Button variant="contained" sx={{ boxShadow: "none" }} startIcon={<MyLocationRoundedIcon />} onClick={() => {
            getCurrentPosition()
            props.setUserUsedGPSPosition(true)
          }}>
            Use My current location
          </Button>
          <br></br>
          <br></br>

          <TextField
            sx={{ width: "50%" }}
            id="standard-basic"
            label="Name"
            variant="standard"
            value={newAddressDetails.savedAs}
            onChange={(e: any) =>
              setNewAddressDetails({
                ...newAddressDetails,
                savedAs: e.target.value,
              })
            }
          />

          <TextField
            sx={{ width: "50%" }}
            id="standard-basic"
            label="Street/House/Apt No"
            variant="standard"
            value={newAddressDetails.street}
            onChange={(e: any) =>
              setNewAddressDetails({
                ...newAddressDetails,
                street: e.target.value,
              })
            }
          />

          <TextField
            sx={{ width: "50%" }}
            id="standard-basic"
            label="Area"
            variant="standard"
            value={newAddressDetails.area}
            onChange={(e: any) =>
              setNewAddressDetails({
                ...newAddressDetails,
                area: e.target.value,
              })
            }
          />

          <TextField
            sx={{ width: "50%" }}
            id="standard-basic"
            label="City/District/Town"
            variant="standard"
            value={newAddressDetails.city}
            onChange={(e: any) =>
              setNewAddressDetails({
                ...newAddressDetails,
                city: e.target.value,
              })
            }
          />

          <TextField
            sx={{ width: "50%" }}
            id="standard-basic"
            label="State"
            variant="standard"
            value={newAddressDetails.state}
            onChange={(e: any) =>
              setNewAddressDetails({
                ...newAddressDetails,
                state: e.target.value,
              })
            }
          />
          <TextField
            sx={{ width: "50%" }}
            id="standard-basic"
            label="Pincode"
            variant="standard"
            value={newAddressDetails.zip}
            onChange={(e: any) =>
              setNewAddressDetails({
                ...newAddressDetails,
                zip: e.target.value,
              })
            }
          />
          <TextField
            sx={{ width: "50%" }}
            id="standard-basic"
            label="Alternate Phonenumber"
            variant="standard"
            value={newAddressDetails.phoneNumber}
            error={PhoneNumberInputError}
            helperText={PhoneNumberInputError ? 'Invalid phone number format' : ''}
            onChange={(e: any) =>
              handlePhoneNumberChange(e.target.value, 'add')
            }
          />

          <br></br>
          <br></br>
          <TextField
            fullWidth
            label="Formatted Address"
            id="fullWidth"
            placeholder="By GPS"
            value={newAddressDetails.formatted_address}
            onChange={(e: any) =>
              setNewAddressDetails({
                ...newAddressDetails,
                formatted_address: e.target.value,
              })
            }
          />
          <br></br>
          <br></br>
          <Button
            variant="contained"
            sx={{
              boxShadow: "none",
              '&:hover': {
                boxShadow: "none",
              },
            }}
            onClick={() => saveNewAddress()}>
            Save
          </Button>
          <Button
            variant="contained"
            onClick={() =>
              setCreateNewOrUpdateAddressView({
                isCreate: false,
                isUpdate: false,
              })
            }
            sx={{
              boxShadow: "none",
              marginLeft: "10px",
              '&:hover': {
                boxShadow: "none",
              },
            }}
          >
            Cancel
          </Button>
        </Box>
      )}

      {createNewOrUpdateAddressView?.isUpdate && (

        <Box sx={{ marginBottom: "1rem", borderBottom: "1px solid #ebedf6", paddingBottom: "1.5rem" }}>
          <Typography sx={{ marginBottom: "0.8rem", fontWeight: "500", textTransform: "uppercase" }}>Edit Address</Typography>
          <TextField
            style={{ width: "50%" }}
            id="standard-basic"
            label="Name"
            variant="standard"
            value={updateAddressDetails.savedAs}
            onChange={(e: any) =>
              setUpdateAddressDetails({
                ...updateAddressDetails,
                savedAs: e.target.value,
              })
            }
          />

          <TextField
            style={{ width: "50%" }}
            id="standard-basic"
            label="Street/House/Apt No"
            variant="standard"
            value={updateAddressDetails.street}
            onChange={(e: any) =>
              setUpdateAddressDetails({
                ...updateAddressDetails,
                street: e.target.value,
              })
            }
          />

          <TextField
            style={{ width: "50%" }}
            id="standard-basic"
            label="Area"
            variant="standard"
            value={updateAddressDetails.area}
            onChange={(e: any) =>
              setUpdateAddressDetails({
                ...updateAddressDetails,
                area: e.target.value,
              })
            }
          />

          <TextField
            style={{ width: "50%" }}
            id="standard-basic"
            label="City/District/Town"
            variant="standard"
            value={updateAddressDetails.city}
            onChange={(e: any) =>
              setUpdateAddressDetails({
                ...updateAddressDetails,
                city: e.target.value,
              })
            }
          />

          <TextField
            style={{ width: "50%" }}
            id="standard-basic"
            label="State"
            variant="standard"
            value={updateAddressDetails.state}
            onChange={(e: any) =>
              setUpdateAddressDetails({
                ...updateAddressDetails,
                state: e.target.value,
              })
            }
          />
          <TextField
            style={{ width: "50%" }}
            id="standard-basic"
            label="Pincode"
            variant="standard"
            value={updateAddressDetails.zip}
            onChange={(e: any) =>
              setUpdateAddressDetails({
                ...updateAddressDetails,
                zip: e.target.value,
              })
            }
          />
          <TextField
            style={{ width: "50%" }}
            id="standard-basic"
            label="Alternate Phonenumber"
            variant="standard"
            value={updateAddressDetails.phoneNumber}
            error={PhoneNumberInputError}
            helperText={PhoneNumberInputError ? 'Invalid phone number format' : ''}
            onChange={(e: any) =>
              handlePhoneNumberChange(e.target.value, 'edit')

            }
          />

          <br></br>
          <br></br>
          <TextField
            fullWidth
            label="Formatted Address"
            id="fullWidth"
            placeholder="By GPS"
            value={updateAddressDetails.formatted_address}

            onChange={(e: any) =>
              setUpdateAddressDetails({
                ...updateAddressDetails,
                formatted_address: e.target.value,
              })
            }
          />
          <br></br>
          <br></br>
          <Button variant="contained"
            sx={{
              boxShadow: "none",
              '&:hover': {
                boxShadow: "none",
              },
            }}
            onClick={() => {
              saveUpdatedAddress()
              props.setUserUsedGPSPosition(false)
            }}>
            Save
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setCreateNewOrUpdateAddressView({
                isCreate: false,
                isUpdate: false,
              })
              props.setUserUsedGPSPosition(false)
            }
            }
            sx={{
              boxShadow: "none",
              marginLeft: "10px",
              '&:hover': {
                boxShadow: "none",
              },
            }}
          >
            Cancel
          </Button>
        </Box>
      )}

    </>


  )
}



import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  List,
  ListItem,
  Divider,
  TextField,
} from "@mui/material";
import { zodiacSign } from "../../utils/Constants";
import "./HoroscopeView.scss";
import TitleTypography from "../../components/typography/TitleTypography";
import {
  CalendarMonthOutlined,
  Label,
  LocationCityOutlined,
  MapOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";

import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { googleMapKey } from "../../configs/firebaseConfig";
import discoverRashiSign from "../../assets/rashi-discover-sign.png";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CityAutocomplete from "../../components/AutoComplete/CityAutocomplete";
import ariesRashi from "../../assets/horoscopes/aries.png";
import { getSignInfo, getSunSign } from "./HoroscopeCalculation";

const HoroscopeView = (props: any) => {
  const [israshiDescClicked, setIsRashiDescClicked] = useState(false);
  const [fullName, setFullName] = useState<string>("");
  const [birthDate, setBirthDate] = useState<Dayjs | null>(null);
  const [fbirthDate, setfBirthDate] = useState("");
  const [ftime, setfTime] = useState("");
  const [time, setTime] = useState<Dayjs | null>(null);
  const [utcOffsetMinutes, setUtcOffsetMinutes] = useState<string>("");
  const [rashiResult, setRashiResult] = useState<any>(null);
  const [isSubmit,setIsSubmit] = useState<boolean>(false)
  const [location, setLocation] = useState<any>(null);

  const [errors, setErrors] = useState<{
    fullName: boolean;
    birthDate: boolean;
    birthTime: boolean;
    utcOffsetMinutes: boolean;
  }>({
    fullName: false,
    birthDate: false,
    birthTime: false,
    utcOffsetMinutes: false,
  });

  const handleCityChange = (city: any | null) => {
    if (city) {
      setUtcOffsetMinutes(city.utc_offset_minutes);
      setLocation(city);
    } else {
      console.log("No city selected");
    }
    
  };

  useEffect(() => {
    handleSetError();
  }, [fullName,fbirthDate,ftime,utcOffsetMinutes])

  useEffect(() => {
    if (props.calcMoonSing.length > 0) {
      const sunSign = getSignInfo(props.calcMoonSing);
      setRashiResult(sunSign);
      handleClickOpen();
    }
  }, [props.calcMoonSing]);


  

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setIsSubmit(true)
    const newErrors = {
      fullName: !fullName.trim(),
      birthDate: !fbirthDate,
      birthTime: !ftime,
      utcOffsetMinutes: !utcOffsetMinutes,
    };
    setErrors(newErrors);

    if (
      !newErrors.fullName &&
      !newErrors.birthDate &&
      !newErrors.birthTime &&
      !newErrors.utcOffsetMinutes
    ) {
      const strDateTime = `${fbirthDate}T${ftime}:00${utcOffsetMinutes}`;
      props.handleSubmit(strDateTime, fullName,location);
    }
  };

  const nativeViewCheck = props.parentViewCheck

  //Calculate Your Sign Dialog Modal
  const [calcSign, setCalcSign] = React.useState(false);

  const handleClickOpen = () => {
    setCalcSign(true);
  };
  const handleClose = () => {
    setCalcSign(false);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleDateChange = (newValue: Dayjs | null) => {
    if (newValue) {
     
      const formattedDate = newValue.format("YYYY-MM-DD");
      console.log("Formatted Date:", formattedDate);
      
      setfBirthDate(formattedDate);
      setBirthDate(newValue);
    } else {
      setBirthDate(null);
    }
   
  };

  const handleSetError =()=>{
  
    
    setTimeout(() => {
      const newErrors = {
        fullName: !fullName.trim(),
        birthDate: !fbirthDate,
        birthTime: !ftime,
        utcOffsetMinutes: !utcOffsetMinutes,

      };
      setErrors(newErrors);
    }, 100);
   
    
  }

  const handleTimeChange = (newValue: Dayjs | null) => {
    if (newValue) {
      const formattedTime = newValue.format("HH:mm");
      console.log("Formatted Time:", formattedTime);
      setTime(newValue);
      setfTime(formattedTime);
    } else {
      setTime(null);
    }
   
  };

  return (
    <Box className={`rashiPage ${nativeViewCheck ? 'pageContainer' : 'pageContainerNativeHoro'}`}>
      <Box className="rashiBlock">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              md={2}
              lg={3}
              xl={3}
              className="pagehdLeftBlock"
            ></Grid>
            <Grid item xs={12} md={8} lg={6} xl={6} className="pageCenterBlock">
              <div className="chooseRashi">
                <Grid
                  container
                  spacing={{ xs: 1, md: 2 }}
                  columns={{ xs: 12, sm: 12, md: 12 }}
                  className="rashiMainContent"
                >
                  {zodiacSign.map((item: any, id: any) => {
                    return (
                      <Grid item xs={3} sm={3} md={2} key={id}>
                        <Box
                          className="rashiCard"
                          onClick={() => {
                            setIsRashiDescClicked(true);
                            props.navigateToHoriscopeDetails(item.key);
                          }}
                        >
                          <p>
                            <img
                              style={{ width: "50px", height: "50px" }}
                              src={item.img}
                            />
                          </p>
                          {/* <h6>{item.name}</h6> */}
                        </Box>
                      </Grid>
                    );
                  })}
                  <Grid item xs={12} sm={12} md={12}>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      className="discoverRashiTitle"
                      spacing={1}
                    >
                      <img src={discoverRashiSign} alt="" />
                      <Typography>Discover Your Sign</Typography>
                    </Stack>
                    <Box
                      className="discoverRashiInput"
                      component="form"
                      onSubmit={handleSubmit}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={12} lg={6} xl={6}>
                            <TextField
                              label="Full Name *"
                              value={fullName}
                              onChange={(e) => setFullName(e.target.value)}
                              variant="outlined"
                              fullWidth
                            />
                            { isSubmit &&  errors.fullName && (
                              <Typography variant="body2" color="error">
                                Full Name is required
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={12} md={12} lg={6} xl={6}>
                            <CityAutocomplete
                              onChangeHandle={handleCityChange}
                            />
                            {isSubmit &&  errors.utcOffsetMinutes && (
                              <Typography variant="body2" color="error">
                                Birth Location is required
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={12} md={12} lg={6} xl={6}>
                            <DatePicker
                              label="Enter Your Birth Date *"
                              value={birthDate}
                              onChange={handleDateChange}
                              sx={{ width: "100%" }}
                              maxDate={dayjs()}
                              format="DD/MM/YYYY"
                            />
                            {isSubmit &&  errors.birthDate && (
                              <Typography variant="body2" color="error">
                                Birth Date is required
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={12} md={12} lg={6} xl={6}>
                            <TimePicker
                              label="Enter Your Time of Birth (HH:MM) *"
                              value={time}
                              onChange={handleTimeChange}
                              sx={{ width: "100%" }}
                            />
                            {isSubmit &&  errors.birthTime && (
                              <Typography variant="body2" color="error">
                                Birth Time is required
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </LocalizationProvider>

                      <Button
                        type="submit"
                        variant="contained"
                        className="calculateBtn"
                        endIcon={<KeyboardDoubleArrowRightIcon />}
                        size="large"
                      >
                        Calculate Your Sign
                      </Button>

                      <Dialog
                        onClose={handleClose}
                        className="calculateBtnModal"
                        aria-labelledby="customized-dialog-title"
                        open={calcSign}
                      >
                        <DialogTitle
                          sx={{ m: 0, p: 2, pb: 0 }}
                          id="customized-dialog-title"
                        >
                          Your Sign! Based on our calculation.
                        </DialogTitle>
                        <IconButton
                          aria-label="close"
                          onClick={handleClose}
                          sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                        <DialogContent
                          sx={{
                            width: { lg: "500px", sm: "500px", xs: "310px" },
                          }}
                        >
                          <Box className="calcBtnModalContent">
                            <Box className="selectedRashi">
                              <img src={ariesRashi} alt="" />
                              <Typography variant="h6">Aries</Typography>
                            </Box>
                            <Box className="userInfo">
                              <Typography component="p">
                                Pranam Bhakt! Based on the date, time, and place
                                of your birth, your Janma Rashi is determined to
                                be {rashiResult?.rashiName}.
                              </Typography>
                            </Box>
                          </Box>
                        </DialogContent>
                        <DialogActions
                          className="calcModalBottomBtn"
                          sx={{ p: 2 }}
                        >
                          <Button
                            variant="contained"
                            onClick={() => {
                              setIsRashiDescClicked(true);
                              props.navigateToHoriscopeDetails(
                                rashiResult?.rashiName
                              );
                            }}
                          >
                            View Details
                          </Button>
                          <Button variant="outlined" onClick={handleClose}>
                            Close
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              md={2}
              lg={3}
              xl={3}
              className="pagehdRightBlock"
            >
              {/* <RightBlockCard /> */}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default HoroscopeView;

export const PRICES = {
  adminCommission: 20, // value is in percentage
  delivery: 350, // value in amount
  packaging: 50, // value in amount
  goodies: 30, // value in amount
  platformFee: 20, // value is in percentage
  gst: 18, // value is in percentage
};

export const generateUniqueNumberString = () => {
  const timestamp = new Date().getTime();
  const counter = Math.floor(Math.random() * 1000000);
  const uniqueNumberString = (timestamp + counter)
    .toString()
    .padStart(12, "0")
    .slice(-6);

  return uniqueNumberString;
};

export const calculateAdminCommission = (price: any) => {
  const { adminCommission } = PRICES;
  const commission = (adminCommission / 100) * Number(price);
  return Math.round(commission);
};

export const calculatePlatformFee = (price: any) => {
  const { platformFee } = PRICES;
  const fee = (platformFee / 100) * Number(price);
  return Math.round(fee);
};

export const calculatePujaPaymentPrice = (price: any, couponDiscount: any) => {
  const { delivery, packaging, goodies } = PRICES;
  const total =
    Number(price) -
    Number(couponDiscount) +
    Number(delivery) +
    Number(packaging) +
    Number(goodies) +
    calculateAdminCommission(price) +
    calculatePlatformFee(price);
  // console.log(
  //   'couponDiscount',
  //   price,
  //   delivery,
  //   packaging,
  //   goodies,
  //   calculatePlatformFee(price),
  //   calculateAdminCommission(price),
  //   price +
  //     delivery +
  //     packaging +
  //     goodies +
  //     calculateAdminCommission(price) +
  //     calculatePlatformFee(price),
  //   couponDiscount,
  // );

  // nee to check with legal team about gst calculatiom
  // removing basic price for gst as of now
  const tax = calculateGSTPrice(total, couponDiscount);
  const totalPrice = total + tax;
  return totalPrice;
};

export const calculateActualPujaPrice = (price: any) => {
  const { delivery, packaging, goodies } = PRICES;
  const total =
    Number(price) +
    Number(delivery) +
    Number(packaging) +
    Number(goodies) +
    calculateAdminCommission(price) +
    calculatePlatformFee(price);
  return total;
};

export const calculateGSTPrice = (price: any, couponDiscount: any) => {
  const { gst } = PRICES;
  const total = Number(price) - Number(couponDiscount);
  const tax = (Number(gst) / 100) * total;
  return Math.ceil(tax - 1);
};

import { useEffect } from "react";
import PanchangView from "../../Views/Panchang/PanchangView";
import ParentView from "../../Views/PrarentView/ParentView";
import { useDispatch, useSelector } from "react-redux";
import { getPanchangData } from "../../store/actions/templeAction";
import { AuthService, PanchangService } from "td_server";
import moment from "moment";
import dayjs from "dayjs";
import { generatePanchangPayload } from "../../utils/HelperFunctions";
function PanchangScreen() {
  const dispatch: any = useDispatch();
  const { panchangData } = useSelector((state: any) => state.temple);
  const { userInfo, currentAddress } = useSelector((state: any) => state.user);
  const getPanchangDataForSelectedDate = (dateEvent: any) => {
    const formattedDate = moment(dayjs(dateEvent).toString())
      .utc()
      .format("YYYY-MM-DD hh:mm:ss");
    const panchangPaylad = generatePanchangPayload(
      currentAddress,
      formattedDate
    );
    console.log("Panchang payload", panchangPaylad);
    dispatch(getPanchangData(panchangPaylad));
  };

  useEffect(() => {
    const todayDate = moment()
      .utcOffset("+05:30")
      .format("YYYY-MM-DDTHH:mm:ss[Z]");
    // console.log("Today date",todayDate)
    const panchangData = generatePanchangPayload(currentAddress, todayDate);
    console.log("Pancahng payload formed",panchangData)
    dispatch(getPanchangData(panchangData));
  }, [currentAddress]);

  return (
    <ParentView>
      <PanchangView
        panchangData={panchangData}
        getPanchangDataForSelectedDate={getPanchangDataForSelectedDate}
      />
    </ParentView>
  );
}

export default PanchangScreen;

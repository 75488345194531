import {setLoading, setCartItems, setCart, setPujaPriceInfo} from './../slices/cartSlice';
// import AsyncStorage from '@react-native-async-storage/async-storage';
import {OrderService} from "td_server"
export const updateCartAction = (cartItems: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    localStorage.setItem('cartInfo', JSON.stringify(cartItems));
    dispatch(setCartItems(cartItems));
    dispatch(setLoading(false));
    return Promise.resolve('');
  } catch (error: any) {
    //console.log('CART ERROR : ', error);
    dispatch(setLoading(false));
    return Promise.reject('');
  }
};

export const setCartAction = (cartItems: any) => async (dispatch: any) => {
  try {
    dispatch(setCart(cartItems));
    return Promise.resolve('');
  } catch (error: any) {
    //console.log('SET CART ERROR : ', error);
    return Promise.reject('');
  }
};

export const emptyCartAction = () => async (dispatch: any) => {
  try {
    localStorage.removeItem('cartInfo');
    dispatch(setCartItems([]));
    return Promise.resolve('');
  } catch (error: any) {
    return Promise.reject('');
  }
};

export const getPriceOfPuja = (data:any) => async (dispatch: any) => {
  try {
    let priceData = await OrderService.getPriceDetailsOfPuja(data)
    console.log("Price data",priceData)
    if(priceData){
      setPujaPriceInfo(priceData)
    }
    
    return Promise.resolve('');
  } catch (error: any) {
    return Promise.reject('');
  }
};

// export const validateAndGetCouponDiscountAction =
//   () => async (dispatch: any) => {
//     try {
//       return Promise.resolve(0);
//     } catch (error: any) {
//       return Promise.reject('');
//     }
//   };

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ParentView from "../../Views/PrarentView/ParentView";
import HoroscopeView from "../../Views/Horoscope/HoroscopeView";
import { useDispatch, useSelector } from "react-redux";
import { HoroscopeService } from "td_server";

const HoroscopeScreen = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [parentViewCheck, setParentViewCheck] = useState(true);
  const [calcMoonSing, setcalcMoonSing] = useState<string>("");
  useEffect(() => {
    const pathData = location.pathname;
    if (pathData.includes("nativeViews")) {
      props.setIsMobileView(true);
      setParentViewCheck(false);
    }
  }, []);

  const navigateToHoriscopeDetails = (sign: any) => {
    const pathData = location.pathname;
    if (pathData.includes("nativeViews")) {
      navigate("/nativeViews/horoscopeDetails?zodiacName=" + sign);
    } else {
      navigate("/horoscopeDetails?zodiacName=" + sign);
    }
  };

  const handleSubmit = async (date: string, name: string, city: any) => {
    setcalcMoonSing("");
    const pData = {
      dateOfBirth: date,
      latitude: city?.lat,
      longitude: city?.lng,
      language: "en",
    };
    console.log('payload',pData)
    const response = await HoroscopeService.getMoonSign(pData);
    if(response?.moonsign?.sign){
      setcalcMoonSing(response.moonsign.sign)
    }
  };

  return (
    <>
      {parentViewCheck ? (
        <ParentView>
          <HoroscopeView
            navigateToHoriscopeDetails={navigateToHoriscopeDetails}
            calcMoonSing={calcMoonSing}
            parentViewCheck={parentViewCheck}
            handleSubmit={handleSubmit}
          />
        </ParentView>
      ) : (
        <HoroscopeView
          navigateToHoriscopeDetails={navigateToHoriscopeDetails}
          handleSubmit={handleSubmit}
          calcMoonSing={calcMoonSing}
          parentViewCheck={parentViewCheck}
          
        />
      )}
    </>
  );
};

export default HoroscopeScreen;

import React, { useEffect, useState } from "react";
import "./AIGurujitext.scss";
import { Stack, Box, Typography } from "@mui/material";
import valmikiImg from "../../../../assets/valmikiImg.png";
import Nameste from "../../../../assets/nameste.png";
import playIcon from "../../../../assets/playIcon.png";
import pauseIcon from "../../../../assets/pauseIcon.png";

interface AIGurujitextProps {
  text: string;
  transcribeAudio: (
    text: string,
    id: number,
    play: boolean,
    onComplete: () => void
  ) => void;
  time: string;
  id: number;
  isPlayingAudioId: number;
  isPlaying: boolean;
}

const AIGurujitext: React.FC<AIGurujitextProps> = ({
  text,
  transcribeAudio,
  time,
  id,
  isPlayingAudioId,
  isPlaying,
}) => {
  const [isPlayingThisAudio, setIsPlayingThisAudioId] = useState(false);

  useEffect(() => {
    let _isPlayingThisAudio =
      id === isPlayingAudioId && isPlaying ? true : false;

    setIsPlayingThisAudioId(_isPlayingThisAudio);
  }, [isPlayingAudioId, isPlaying]);

  const handleToggleAudio = () => {
    if (!isPlayingThisAudio) {
      transcribeAudio(text, id, true, () => {});
    } else if (isPlayingThisAudio) {
      transcribeAudio("", id, false, () => {});
    }
  };
  return (
    <Box className="chatContainer">
      {/* --- Own Identity Area --- */}
      <Stack
        className="pranamBhaktSec"
        direction="row"
        justifyContent="left"
        alignItems="flex-end"
        spacing={2}
      >
        <Box className="pranamBhaktLeft">
          <Stack direction="row" alignItems="flex-start" spacing={1}>
            <Box className="smallValmikiImg">
              <Box component="img" className="valmikiImg" src={valmikiImg} />
            </Box>
            <Box>
              <Stack
                direction="row"
                alignItems="flex-start"
                className="pranamBhaktTextArea"
                spacing={1}
              >
                <Box className="mgsCard">
                  <Stack direction="row" alignItems="flex-start" spacing={1}>
                    <Box>
                      <Box
                        component="img"
                        className="namestereadIcon"
                        src={Nameste}
                      />
                    </Box>
                    <Box className="replyMgs">
                      <Typography
                        className="messageText"
                        sx={{ whiteSpace: "pre-line" }}
                      >
                        {text}
                      </Typography>
                    </Box>
                    <Box className="mgsTime">
                      <Typography className="namestetimestamp">
                        {time}
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Box>

        {/* --- Play/Pause Icon Section --- */}
        <Box className="pranamBhaktRightIcon">
          <Box
            component="img"
            className="musicicon"
            src={isPlayingThisAudio ? pauseIcon : playIcon}
            onClick={handleToggleAudio}
            sx={{ cursor: "pointer" }}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default AIGurujitext;

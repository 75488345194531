import React, { ReactNode, useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Button, Slide, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import "./AIGurujiModal.scss";

interface ChatbotDialogProps {
  children: ReactNode;
  openDialog: boolean;
  //closeModal:()=>void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const AIGurujiModal: React.FC<ChatbotDialogProps> = ({ children, openDialog }) => {
  const [open, setOpen] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog])
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
    //closeModal();
  };



  useEffect(() => {
    const updateMedia = () => {
      setIsDesktop(window.innerWidth >= 900); // Adjust this value for your breakpoint
    };
    updateMedia();
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  return (
    <>
      <Dialog
        open={open}
        // onClose={handleClose}
        hideBackdrop
        disableScrollLock={isDesktop}
        TransitionComponent={Transition}
        className="aiGurujiModal"
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
      >
        {/* <DialogTitle>
          Chatbot
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle> */}
        <DialogContent className="aiGurujiModalContent">
          {children}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AIGurujiModal;

/* eslint-disable @typescript-eslint/no-unused-vars */

import { TempleService } from "td_server";
  
  import { setLoading, setChadhavaTempleList,setChadhavaForTemple, setTempleChadhavaList } from "../slices/ChadhawaSlice";
  import { getAllTempleList } from "./templeAction";

export const loading = () => async (dispatch: any, getState: any) => {
  try {
    dispatch(setLoading(true));

    return Promise.resolve("");
  } catch (e) {
    //console.log('Analytics LIST ERROR : ', e);
    dispatch(setLoading(false));
    return Promise.reject("");
  }
};

export const getChadhavaTempleList =
  () => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.getChadhavaTempleList();
      if (response && response?.chadhavaOrganisations?.length > 0) {
        dispatch(setChadhavaTempleList(response?.chadhavaOrganisations));
      }
      dispatch(setLoading(false));
      console.log("response", response);
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

  export const getChadhavaForTemple =
  (id: string) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.getChadhavaForTemple(id);
      if (response && response.length > 0) {
        dispatch(setChadhavaForTemple(response));
      }
      dispatch(setLoading(false));
      console.log("response", response);
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

  export const addChadhavaDetailsForTemple =
  (id:string,data: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.addChadhavaDetailsForTemple(id,data);
      if (response && response.length > 0) {
        dispatch(setChadhavaForTemple(response));
      }
      dispatch(setLoading(false));
      console.log("response", response);
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

  export const updateChadhavaDetailsForTemple =
  (orgId:string,id:string,data: any) => async (dispatch: any, getState: any) => {
    
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.updateChadhavaDetailsForTemple(orgId,id,data);
      if (response && response.length > 0) {
        dispatch(setChadhavaForTemple(response));
      }
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

  export const deleteChadhavaItem =
  (orgId:string,id:string) => async (dispatch: any, getState: any) => {
    
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.deleteChadhavaItem(orgId,id);
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

  export const updateChadhavaBenefitsForTemple =
  (orgId:string,data: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      
      const response: any = await TempleService.updateChadhavaBenefitsForTemple(orgId,data);
      if (response && response.length > 0) {
        dispatch(setChadhavaForTemple(response));
      }
      dispatch(setLoading(false));
       
      //console.log("response", response);
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

  export const updateChadhavaDateForTemple = (orgId:string,data: string[]) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));

    //   const response: any = await TempleService.updateChadhavaDateForTemple(orgId,data);
    //   if (response && response.length > 0) {
    //     dispatch(setChadhavaForTemple(response));
    //   }
    //   dispatch(setLoading(false));
       
    //   console.log("response", response);
    //   return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };
  
  export const getChadhavaForTempleList = (id: string) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.getChadhavaForTemple(id);
      if (response && response.length > 0) {
        dispatch(setTempleChadhavaList(response));
      }
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

  export const resetChadhavaList = () => async (dispatch: any, getState: any) => {
    try {
     
      dispatch(setChadhavaForTemple([]));
      dispatch(setLoading(false));
      return Promise.resolve([]);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

  






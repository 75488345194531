import React, { useState } from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Container, Typography, Link, Box, IconButton, Grid } from '@mui/material';
import './PujaVideoView.scss';

const PujaVideoView = () => {
    //For click to start video
    const [showVideo, setShowVideo] = useState(false);

    const handleThumbnailClick = () => {
        setShowVideo(true);
    };

    return (
        // <Box className="pageContainer">
        //     Puja Video
        // </Box>

        <Box className="pageContainer pujaVideo">
            <Box className="templeBlock">
                <Grid container spacing={1}>
                    <Grid item xs={12} md={3} lg={3} xl={3} className="pagehdLeftBlock"></Grid>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                        <Box className="pujaVideoArea">
                            <Typography className="pujaVideoTitle">Product Video</Typography>
                            <Box className="liveDarshanView">
                                {/* <iframe width="100%" height="400" src="https://firebasestorage.googleapis.com/v0/b/templedekhodev.appspot.com/o/Video%2Fpujasteps%2FTemple%20Dekho_Intro%20(1).mp4?alt=media&token=4dac6252-6b75-448b-89f5-792ece33eab4" title=" Video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe> */}

                                <Box position="relative" width="100%" paddingBottom="56.25%" height={0}>
                                    {!showVideo && (
                                        <Box
                                            component="img"
                                            src="https://firebasestorage.googleapis.com/v0/b/templedekhodev.appspot.com/o/Video%2Fpujasteps%2FTemple_Dekho_Video.png?alt=media&token=5eb3d2ce-1877-49c5-8a40-9dc8bccdbb12"
                                            alt="Thumbnail"
                                            sx={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                width: "100%",
                                                height: "100%",
                                                cursor: "pointer",
                                                objectFit: "cover"
                                            }}
                                            onClick={handleThumbnailClick}
                                        />
                                    )}
                                    {showVideo && (
                                        <Box
                                            component="iframe"
                                            width="100%"
                                            height="400px"
                                            src="https://firebasestorage.googleapis.com/v0/b/templedekhodev.appspot.com/o/Video%2Fpujasteps%2FTemple%20Dekho_Intro%20(1).mp4?alt=media&token=4dac6252-6b75-448b-89f5-792ece33eab4"
                                            title="Video player"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            sx={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                border: "none"
                                            }}
                                            allowFullScreen
                                        />
                                    )}
                                    {!showVideo && (
                                        <IconButton
                                            onClick={handleThumbnailClick}
                                            sx={{
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                transform: "translate(-50%, -50%)",
                                                color: "white",
                                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                                '&:hover': {
                                                    backgroundColor: "rgba(0, 0, 0, 0.7)"
                                                }
                                            }}
                                        >
                                            <PlayArrowIcon sx={{ fontSize: '3rem' }} />
                                        </IconButton>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3} lg={3} xl={3} className="pagehdRightBlock"></Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default PujaVideoView;

import React from "react";
import { Box, Typography } from "@mui/material";
import "./AIGurujinumerologicalpredictions.scss";

const AIGurujinumerologicalpredictions: React.FC<any> = ({
  responseText,
  numberCalculations,
}) => {
  return (
    <Box className="numerology-container">
      <Typography className="numerology-title">{responseText}</Typography>

      {numberCalculations?.map((calculation: any, index: number) => (
        <Box key={index} className="numerology-card">
          <Box className="card-text">
            <Typography variant="body1" className="numerology-heading">
              {calculation?.title}
            </Typography>
            <Typography variant="body2" className="numerology-equation">
              {calculation?.calculation}
            </Typography>
          </Box>
          <Box className="card-number">
            <Typography variant="h3">{calculation?.result}</Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default AIGurujinumerologicalpredictions;

import React, { useEffect, useState } from "react";
import { removeFbclid } from "../../utils/HelperFunctions";
import "./Modal.css"; // Import CSS file for styling

const OpenInAppModal = ({ isOpen, onClose }: any) => {
  const [isVisible, setIsVisible] = useState(isOpen);

  // Function to handle closing the modal

  const [openMobileAppMessage, setOpenMobileAppMessage] = useState(
    'Kindly click the "Open" button to schedule this puja.'
  );
  const handleClose = () => {
    setIsVisible(false);
    onClose();
  };

  useEffect(() => {
    if (isFacebookBrowser()) {
      setOpenMobileAppMessage(
        "Please tap on the three dots located in the top-right corner and choose 'Open in external browser' to open the puja in the mobile app.\n\n\nकृपया ऊपर दाहिने कोने में स्थित तीन डॉट्स पर टैप करें और 'बाहरी ब्राउज़र में खोलें' का चयन करें मोबाइल एप्लिकेशन में पूजा को खोलने के लिए।"
      );
    } else {
      setOpenMobileAppMessage(
        "Please click on the 'Open' button to schedule this puja.\n\n\nकृपया 'ओपन' बटन पर क्लिक करें इस पूजा को शेड्यूल करने के लिए।"
      );
    }
  }, []);
  const isFacebookBrowser = () => {
    const ua = navigator.userAgent || navigator.vendor;
    return ua.indexOf("FBAN") > -1 || ua.indexOf("FBAV") > -1;
  };
  return (
    <>
      {isVisible && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Open in the Mobile App</h2>
            <p>{openMobileAppMessage}</p>
            {!isFacebookBrowser() && (
              <div className="button-container">
                <button className="appsCancelBtn" onClick={handleClose}>Cancel</button>
                <button
                  className="appsOpenBtn"
                  onClick={() => {
                    handleClose();
                    let openUrlAndroi = removeFbclid(window.location.href);
                    const urlSearchParams = new URLSearchParams(
                      window.location.search
                    );
                    //  const hasFbclid = urlSearchParams.has("fbclid");
                    // if (hasFbclid) {
                    //   //window.open(openUrlAndroi, "_blank");
                    //   // window.location.href = openUrlAndroi;
                    // } else {
                    const url = `intent://${openUrlAndroi}#Intent;scheme=pagelink;package=com.omkara.templedekho.dev;end`;
                    window.location.replace(url);
                    //}
                  }}
                >
                  Open
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default OpenInAppModal;

import React from 'react'
import "./AIGurujitextSender.scss";
import { Button, Stack, Box, Avatar, Typography } from "@mui/material";
import valmikiImg from "../../../../assets/valmikiImg.png";
import gpticon from "../../../../assets/gpticon.png";
import Nameste from "../../../../assets/nameste.png";
import musicicon from "../../../../assets/music icon.png";
const AIGurujitextSender: React.FC<any> = ({ text,time }) => {
  return (
    <Box className="chatContainer">
      {/* --- Own Identity Area --- */}
      <Stack
        className="pranamBhaktSec"
        direction="row"
        justifyContent="right"
        alignItems="flex-start"
        spacing={1}
      >
        <Box className="pranamBhaktLeft">
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-end"
            spacing={1}
          >
            {/* <Box className="pranamBhaktRightIcon">
              <Box component="img" className="musicicon" src={musicicon} />
            </Box> */}
            <Box>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                className="pranamBhaktSenderTextArea"
                spacing={1}
              >
                <Box>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                  >
                    {/* <Box
                      component="img"
                      className="namestereadIcon"
                      src={Nameste}
                    /> */}
                    <Box className="senderMgs">
                    <Typography className="messageText">
                      {/* Pranam bhakt. I’m Valmiki, your virtual spiritual assistant. */}
                      {text}
                    </Typography>
                    </Box>
                  </Stack>
                </Box>
                <Box className="senderTime">
                  <Typography className="namestetimestamp">{time}</Typography>
                </Box>
              </Stack>
              {/* <Typography className="poweredByText">
                Powered by <span>QuarkGen AI</span>
                <Box component="img" className="quarkGenLogo" src={gpticon} />
              </Typography> */}
            </Box>
          </Stack>
        </Box>

        {/* <Box className="smallValmikiImg">
          <Box component="img" className="valmikiImg" src={valmikiImg} />
        </Box> */}
      </Stack>
      {/* <Typography variant="body1" className="textarea">
        “Valmiki - Your Own AI Guruji”, a cutting-edge spiritual bot powered
        by AI. It delivers wisdom drawn from vast, pre-trained knowledge and
        keeps evolving with continuous learning.
      </Typography> */}
    </Box>
  )
}

export default AIGurujitextSender

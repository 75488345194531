import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  Grid,
  Box,
  Divider,
  Tooltip,
  Typography,
  TextField,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from "@mui/icons-material/Close";

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
  XIcon,
  LinkedinIcon,
  LinkedinShareButton,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
} from "react-share";
import InstagramIcon from "@mui/icons-material/Instagram";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MessengerIcon from "@mui/icons-material/Message";
interface ShareLinkBoxProps {
  url: string;
}


const ShareLinkBox: React.FC<ShareLinkBoxProps> = ({ url }) => {
  return (
    <Box sx={{ padding: "20px 0" }}>
      <Grid
        container
        spacing={{ xs: 1, md: 2 }}
        justifyContent="flex-start"
        alignItems="center"
        textAlign="center"
        wrap="nowrap"
      >
        <Grid item xs="auto">
          <Typography sx={{fontWeight: 600, color: "#181818"}}>Share</Typography>
        </Grid>
        <Grid item xs="auto">
          <FacebookShareButton url={url}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>
        </Grid>
        <Grid item xs="auto">
          <TwitterShareButton url={url}>
            <XIcon size={32} round />
          </TwitterShareButton>
        </Grid>
        <Grid item xs="auto">
          <WhatsappShareButton url={url}>
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
        </Grid>
        <Grid item xs="auto">
          <EmailShareButton url={url}>
            <EmailIcon size={32} round />
          </EmailShareButton>
        </Grid>
        <Grid item xs="auto">
          <LinkedinShareButton url={url}>
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ShareLinkBox;

import Carousel from "react-bootstrap/Carousel";
import pandit from "../../assets/pandit-carousel.svg";
import templeSingle from "../../assets/temple-single-carousel.svg";
import templeWide from "../../assets/temple-wide-carousel.svg";
import { Box, Typography } from "@mui/material";
function CarouselComponent() {
  return (
    <Box>
      <Carousel data-bs-theme="dark" className="mainCarousel">
        <Carousel.Item>
          <Box className="carouselInnerItem">
            <img src={templeSingle} alt=""></img>
            <Typography>
              Experience divine convenience: Book temple pujas anytime, anywhere
              with Temple Dekho.
            </Typography>
          </Box>
        </Carousel.Item>
        <Carousel.Item>
          <Box className="carouselInnerItem">
            <img src={templeWide} alt=""></img>
            <Typography>
              Elevate your spiritual journey with seamless temple puja booking through Temple Dekho.
            </Typography>
          </Box>
        </Carousel.Item>
        <Carousel.Item>
          <Box className="carouselInnerItem">
            <img src={pandit} alt=""></img>
            <Typography>
              Elevate your home pujas with ease - Book a pandit for hassle-free house pujas at your fingertips!
            </Typography>
          </Box>
        </Carousel.Item>
      </Carousel>
    </Box>
  );
}

export default CarouselComponent;

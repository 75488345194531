const BengaliTextConstants = {
    PUJA:"পূজা",
    PUJAS:"পূজা",
    TEMPLES:"মন্দির",
    CURRENTADDRESS:"বাড়ি",
    EDITORADDADDRESS:"১৯৮/৩, ৩ ক্রস ৮০ ফুট রোড, পরবর্তী..",
    PLEASEENTERMOBILENO:"লগইন বা সাইন আপ করতে আপনার মোবাইল নম্বর লিখুন",
    UPCOMINGPUJAS:"আসন্ন পূজা",
    SPIRITUALINFORMATION:"আধ্যাত্মিক তথ্য",
    MOSTPOPULARTEMPLES:"সবচেয়ে জনপ্রিয় মন্দির",

}
export default BengaliTextConstants ;
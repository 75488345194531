import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Paper,
  Stack,
  List,
  TextField,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Alert,
} from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { zodiacSign } from "../../../utils/Constants";
import "./HoroscopeManageView.scss";
import CheckIcon from '@mui/icons-material/Check';

type HoroscopeSection = 'dailyHoroscope' | 'healthAndWellness' | 'loveAndRelationship' | 'careerAndMoney';

interface HoroscopeData {
  type: string; // DAILY | WEEKLY | MONTHLY | YEARLY
  date: string; // "DD-MM-YYYY"
  horoscopes: {
    [key: string]: {
      [section in HoroscopeSection]: { hindi: string; english: string };
    };
  };
}

const initialData: HoroscopeData = {

  type: "DAILY",
  date: "",
  horoscopes: {
    aries: { dailyHoroscope: { hindi: "", english: "" }, healthAndWellness: { hindi: "", english: "" }, loveAndRelationship: { hindi: "", english: "" }, careerAndMoney: { hindi: "", english: "" } },
    taurus: { dailyHoroscope: { hindi: "", english: "" }, healthAndWellness: { hindi: "", english: "" }, loveAndRelationship: { hindi: "", english: "" }, careerAndMoney: { hindi: "", english: "" } },
    gemini: { dailyHoroscope: { hindi: "", english: "" }, healthAndWellness: { hindi: "", english: "" }, loveAndRelationship: { hindi: "", english: "" }, careerAndMoney: { hindi: "", english: "" } },
    cancer: { dailyHoroscope: { hindi: "", english: "" }, healthAndWellness: { hindi: "", english: "" }, loveAndRelationship: { hindi: "", english: "" }, careerAndMoney: { hindi: "", english: "" } },
    leo: { dailyHoroscope: { hindi: "", english: "" }, healthAndWellness: { hindi: "", english: "" }, loveAndRelationship: { hindi: "", english: "" }, careerAndMoney: { hindi: "", english: "" } },
    virgo: { dailyHoroscope: { hindi: "", english: "" }, healthAndWellness: { hindi: "", english: "" }, loveAndRelationship: { hindi: "", english: "" }, careerAndMoney: { hindi: "", english: "" } },
    libra: { dailyHoroscope: { hindi: "", english: "" }, healthAndWellness: { hindi: "", english: "" }, loveAndRelationship: { hindi: "", english: "" }, careerAndMoney: { hindi: "", english: "" } },
    scorpio: { dailyHoroscope: { hindi: "", english: "" }, healthAndWellness: { hindi: "", english: "" }, loveAndRelationship: { hindi: "", english: "" }, careerAndMoney: { hindi: "", english: "" } },
    sagittarius: { dailyHoroscope: { hindi: "", english: "" }, healthAndWellness: { hindi: "", english: "" }, loveAndRelationship: { hindi: "", english: "" }, careerAndMoney: { hindi: "", english: "" } },
    capricorn: { dailyHoroscope: { hindi: "", english: "" }, healthAndWellness: { hindi: "", english: "" }, loveAndRelationship: { hindi: "", english: "" }, careerAndMoney: { hindi: "", english: "" } },
    aquarius: { dailyHoroscope: { hindi: "", english: "" }, healthAndWellness: { hindi: "", english: "" }, loveAndRelationship: { hindi: "", english: "" }, careerAndMoney: { hindi: "", english: "" } },
    pisces: { dailyHoroscope: { hindi: "", english: "" }, healthAndWellness: { hindi: "", english: "" }, loveAndRelationship: { hindi: "", english: "" }, careerAndMoney: { hindi: "", english: "" } },
  },
};

const HoroscopeManageView = (props: any) => {

  //Horoscope Calendar
  const [open, setOpen] = useState(false);
  const [openSuccessMessage, setOpenSuccessMessage] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string>(dayjs().format('DD-MM-YYYY'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {

    if (props.horoscopeData?.length > 0) {
      setData(props.horoscopeData[0])
    } else {
      setData(initialData)
    }

  }, [props.horoscopeData])

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseSM = () => {
    setOpenSuccessMessage(false)
  };

  const handleDateChange = (date: any) => {
    setSelectedDate(dayjs(date).format('DD-MM-YYYY'));
    setData({...data,date:dayjs(date).format('DD-MM-YYYY')})
    handleClose();
  };


  useEffect(() => {
    props.onDateChange(selectedDate)
  }, [selectedDate])

  const [data, setData] = useState<HoroscopeData>(initialData);
  const [selectedSign, setSelectedSign] = useState<string>("aries");


  const handleInputChange = (
    e: any,
    section: HoroscopeSection,
    lang: 'hindi' | 'english'
  ) => {
    const { value } = e.target;

    setData(prevData => ({
      ...prevData,
      date:selectedDate,
      horoscopes: {
        ...prevData.horoscopes,
        [selectedSign]: {
          ...prevData.horoscopes[selectedSign],
          [section]: {
            ...prevData.horoscopes[selectedSign][section],
            [lang]: value,
          },
        },
      },
    }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
   
    props.handleSubmit(data);
    setOpenSuccessMessage(true)
  };

  const handleClear = () => {
    // Handle form clearing logic here
    setData(initialData);
  };





  return (
    <Box className="pageContainer horoscopePage">
      <Box className="horoscopeBlock">
        <Grid container spacing={1}>
          <Grid item xs={12} md={2} lg={0} xl={0} className="pagehdLeftBlock">
          </Grid>
          <Grid item xs={12} md={8} lg={12} xl={12} className="horoscopeCenterBlock">
            {/* Horoscope View */}
            <Box className="calendarBtn">
              <Box className="calendarBlock">
                <Typography variant="h6">Select Desired Date</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<CalendarTodayIcon />}
                  onClick={handleClickOpen}
                  className="middleTextBtn"
                  fullWidth
                >
                  {selectedDate}
                </Button>
              </Box>
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Select a date</DialogTitle>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendar
                    // value={dayjs(props.pujaDateAndTime.pujaDate)}
                    onChange={(date: any) => {
                      handleDateChange(date)
                    }}
                    minDate={dayjs()}
                  />
                </LocalizationProvider>

                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>

            <Box className="rashiItem">

              <Box className="mainCategories searchMainCategories">
                <List className="searchCatgList">
                  {zodiacSign.map((item: any, id: any) => {
                    return (
                      <ListItem onClick={e => { e.preventDefault(); setSelectedSign(item.key) }} key={item.key} className={selectedSign === item.key ? 'active' : ''}>
                        <ListItemAvatar>
                          <Avatar className="imgCircle">
                            <img src={item.img}></img>
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          className="listTitle"
                          secondary={item.name}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </Box>


            </Box>

            <form id="horoscopeForm" onSubmit={handleSubmit}>


              <Box className="horoscopeDetails">
                {["dailyHoroscope", "healthAndWellness", "loveAndRelationship", "careerAndMoney"].map(section => (
                  <Box key={section} className="detailsListText">
                    <Typography variant="h6">
                      {section === "dailyHoroscope"
                        ? "Daily Horoscope"
                        : section === "healthAndWellness"
                          ? "Health & Wellness Horoscope"
                          : section === "loveAndRelationship"
                            ? "Daily Love & Relationship Horoscope"
                            : "Career & Money Horoscope"}
                    </Typography>
                    <TextField
                      label="Enter english text"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={2}
                      sx={{ marginBottom: '10px' }}
                      name="english"
                      value={data.horoscopes[selectedSign][section as HoroscopeSection].english}
                      onChange={e => handleInputChange(e, section as HoroscopeSection, "english")}
                    />
                    <TextField
                      label="Enter hindi text"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={2}
                      name="hindi"
                      value={data.horoscopes[selectedSign][section as HoroscopeSection].hindi}
                      onChange={e => handleInputChange(e, section as HoroscopeSection, "hindi")}
                    />
                  </Box>
                ))}
              </Box>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                className="bottomBtn"
                spacing={2}
              >
                
                <Button
                  className="clearBtn"
                  variant="outlined"
                  color="primary"
                  onClick={handleClear}
                >
                  Clear
                </Button>
                <Button
                  className="submitBtn"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
              </Stack>
            </form>
          </Grid>
          <Grid item xs={12} md={2} lg={0} xl={0} className="pagehdRightBlock">
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={openSuccessMessage}
        autoHideDuration={500}
        onClose={handleCloseSM}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert  icon={<CheckIcon fontSize="inherit" />} severity="success" sx={{ width: '100%' }}>
          Form submitted successfully!
        </Alert>
      </Snackbar>
    </Box>
    
  );
};

export default HoroscopeManageView;

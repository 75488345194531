import React, { useState, forwardRef, HTMLAttributes, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CityAutocomplete from "../../../../components/AutoComplete/CityAutocomplete";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import "./AIGurujiBirthChartForm.scss";
import dayjs, { Dayjs } from "dayjs";

const labels = {
  EN: {
    fullName: "Full Name*",
    fullNamePlaceholder: "Enter here",
    dateOfBirth: "Date of Birth*",
    gender: "Gender",
    male: "Male",
    female: "Female",
    others: "Others",
    preferNotToSay: "Prefer not to say",
    timeOfBirth: "Time of Birth (HH:MM)*",
    birthLocation: "Birth Location*",
    cityPlaceholder: "Start typing city...",
    generate: "Generate",
    title: "Enter Your Details",
    selectGender: "Select here",
    horoscope: "GENERATE ASTROLOGY",
    numerology: "GENERATE NUMEROLOGY",
    fullNameError: "Full Name is required",
    dateOfBirthError: "Date of Birth is required",
    timeOfBirthError: "Time of Birth is required",
    birthLocationError: "Birth Location is required",
  },
  HI: {
    fullName: "पूरा नाम*",
    fullNamePlaceholder: "यहां दर्ज करें",
    dateOfBirth: "जन्म तिथि*",
    gender: "लिंग",
    male: "पुरुष",
    female: "महिला",
    others: "अन्य",
    preferNotToSay: "नहीं कहना पसंद करते हैं",
    timeOfBirth: "जन्म का समय (HH:MM)*",
    birthLocation: "जन्म स्थान*",
    cityPlaceholder: "शहर लिखना प्रारंभ करें...",
    generate: "उत्पन्न करें",
    title: "अपनी जानकारी दर्ज करें",
    selectGender: "यहाँ चुनें",
    horoscope: "राशिफल जाने",
    numerology: "अंकज्योतिष जाने",
    fullNameError: "पूरा नाम आवश्यक है",
    dateOfBirthError: "जन्मतिथि आवश्यक है",
    timeOfBirthError: "जन्म का समय आवश्यक है",
    birthLocationError: "जन्म स्थान आवश्यक है",
  },
};

interface BirthChatProps extends HTMLAttributes<HTMLDivElement> {
  buttonName: string;
  handleSubmitForm: (data: any, formdata: any) => void;
  isDisabled: any;
  selectedLang: String;
  formFilledData: any;
}

const AIGurujiBirthChartForm = forwardRef<HTMLDivElement, BirthChatProps>(
  (
    { buttonName, handleSubmitForm, isDisabled, selectedLang, formFilledData },
    ref
  ) => {
    const [gender, setGender] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState<Dayjs | null>(null);
    const [timeOfBirth, setTimeOfBirth] = useState<Dayjs | null>(null);

    const [formData, setFormData] = useState({
      fullName: "",
      gender: "",
      dateOfBirth: null as Dayjs | null,
      timeOfBirth: null as Dayjs | null,
      birthLocation: "",
      nation: "",
    });

    useEffect(() => {
      if (formFilledData) {
        const initialData = JSON.parse(formFilledData);
        setFormData({
          fullName: initialData.fullName || "",
          gender: initialData.gender || "",
          dateOfBirth: initialData.dateOfBirth
            ? dayjs(initialData.dateOfBirth)
            : null,
          timeOfBirth: initialData.timeOfBirth
            ? dayjs(initialData.timeOfBirth)
            : null,
          birthLocation: initialData.birthLocation || "",
          nation: initialData.nation || "",
        });
      }
    }, [formFilledData]);

    const [errors, setErrors] = useState({
      fullName: "",
      gender: "",
      dateOfBirth: "",
      timeOfBirth: "",
      birthLocation: "",
      nation: "",
    });
    const langLabels =
      selectedLang && labels[selectedLang === "EN" ? "EN" : "HI"]
        ? labels[selectedLang === "EN" ? "EN" : "HI"]
        : labels.EN;

    const validateForm = () => {
      let tempErrors = { ...errors };
      tempErrors.fullName = formData.fullName ? "" : langLabels.fullNameError;
      tempErrors.dateOfBirth = formData.dateOfBirth
        ? ""
        : langLabels.dateOfBirthError;
      tempErrors.timeOfBirth = formData.timeOfBirth
        ? ""
        : langLabels.timeOfBirthError;
      tempErrors.birthLocation = formData.birthLocation
        ? ""
        : langLabels.birthLocationError;
      setErrors(tempErrors);

      return Object.values(tempErrors).every((error) => error === "");
    };

    const handleFullName = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const isValid = /^[A-Za-z\s]*$/.test(value);
      //   if (value === " " && !formData.fullName) {
      //     return;
      //   }
      //   setFormData((prevData) => ({ ...prevData, fullName: value }));
      //   if (value.trim()) {
      //     setErrors((prevErrors) => ({ ...prevErrors, fullName: "" }));
      //   }
      // };
      if (isValid || value === "") {
        setFormData((prevData) => ({ ...prevData, fullName: value }));
        setErrors((prevErrors) => ({ ...prevErrors, fullName: "" }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, fullName: "" }));
      }
    };

    const handleDateChange = (newDate: Dayjs | null) => {
      setFormData((prevData) => ({ ...prevData, dateOfBirth: newDate }));
      if (newDate) {
        setErrors((prevErrors) => ({ ...prevErrors, dateOfBirth: "" }));
      }
    };

    const handleTimeChange = (newTime: Dayjs | null) => {
      setFormData((prevData) => ({ ...prevData, timeOfBirth: newTime }));
      if (newTime) {
        setErrors((prevErrors) => ({ ...prevErrors, timeOfBirth: "" }));
      }
    };

    const handleCityChange = (city: any) => {
      setFormData((prevData) => ({
        ...prevData,
        birthLocation: city?.label,
        nation: city?.countryCode,
      }));
      if (city?.label) {
        setErrors((prevErrors) => ({ ...prevErrors, birthLocation: "" }));
      }
    };

    const handleGenderChange = (event: any) => {
      setFormData((prevData) => ({ ...prevData, gender: event.target.value }));
      if (event.target.value) {
        setErrors((prevErrors) => ({ ...prevErrors, gender: "" }));
      }
    };
    const submitForm = (e: React.FormEvent) => {
      e.preventDefault();
      setFormData((prevData) => ({
        ...prevData,
        fullName: prevData.fullName.trim(),
        birthLocation: prevData.birthLocation.trim(),
      }));

      if (validateForm()) {
        const {
          fullName,
          dateOfBirth,
          timeOfBirth,
          birthLocation,
          nation,
          gender,
        } = formData;
        const day = dateOfBirth ? dateOfBirth.date() : "00";
        const month = dateOfBirth ? dateOfBirth.month() + 1 : "00";
        const year = dateOfBirth ? dateOfBirth.year() : "00";
        const hours = timeOfBirth ? timeOfBirth.hour() : "00";
        const minutes = timeOfBirth ? timeOfBirth.minute() : "00";
        const data = {
          question: "Please tell me about my future",
          name: fullName,
          year,
          month,
          day,
          hour: hours,
          minutes,
          gender,
          city: birthLocation,
          nation,
        };

        handleSubmitForm(data, formData);
      }
    };

    return (
      <Box ref={ref} className="birthChartFormInfo">
        <Typography variant="h6" className="formTitle">
          {langLabels.title}
          <span>*</span>
        </Typography>

        <Grid container spacing={1} className="innerFormDetails">
          <Grid item xs={12}>
            <label>{langLabels.fullName}</label>
            <TextField
              variant="standard"
              placeholder={langLabels.fullNamePlaceholder}
              fullWidth
              onChange={handleFullName}
              value={formData?.fullName}
              error={!!errors.fullName}
              helperText={errors.fullName}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <label>{langLabels.dateOfBirth}</label>
                  <DatePicker
                    disabled={isDisabled}
                    value={formData?.dateOfBirth}
                    onChange={handleDateChange}
                    sx={{ width: "100%" }}
                    format="DD/MM/YYYY"
                    disableFuture={true}
                    slotProps={{
                      textField: {
                        variant: "standard",
                        error: !!errors.dateOfBirth,
                        helperText: errors.dateOfBirth,
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={6}>
                <label>{langLabels.gender}</label>
                <Select
                  id="gender-select"
                  value={formData?.gender}
                  onChange={handleGenderChange}
                  variant="standard"
                  fullWidth
                  displayEmpty
                  error={!!errors.gender}
                  disabled={isDisabled}
                >
                  <MenuItem value="" disabled>
                    <em style={{ color: "#ababab" }}>
                      {langLabels.selectGender}
                    </em>
                  </MenuItem>
                  <MenuItem value="female">{langLabels.female}</MenuItem>
                  <MenuItem value="male">{langLabels.male}</MenuItem>
                  <MenuItem value="other">{langLabels.others}</MenuItem>
                  <MenuItem value="preferNotToSay">
                    {langLabels.preferNotToSay}
                  </MenuItem>
                </Select>
                {errors.gender && (
                  <Typography variant="body2" color="error">
                    {errors.gender}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <label>{langLabels.timeOfBirth}</label>
              <TimePicker
                value={formData?.timeOfBirth}
                disabled={isDisabled}
                onChange={handleTimeChange}
                sx={{ width: "100%" }}
                slotProps={{
                  textField: {
                    variant: "standard",
                    error: !!errors.timeOfBirth,
                    helperText: errors.timeOfBirth,
                    placeholder: "HH:MM",
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <label>{langLabels.birthLocation}</label>
            {isDisabled ? (
              <TextField
                value={formData?.birthLocation}
                disabled={isDisabled}
                fullWidth
                variant="standard"
              />
            ) : (
              <CityAutocomplete
                onChangeHandle={handleCityChange}
                variant="standard"
                label={""}
                error={!!errors.birthLocation}
              />
            )}

            {errors.birthLocation && (
              <Typography variant="body2" color="error">
                {errors.birthLocation}
              </Typography>
            )}
          </Grid>
        </Grid>

        <Button
          variant="contained"
          className="generateBtn"
          endIcon={<KeyboardDoubleArrowRightIcon />}
          onClick={submitForm}
          disabled={isDisabled}
        >
          {buttonName === "Horoscope"
            ? langLabels.horoscope
            : langLabels.numerology}
        </Button>
      </Box>
    );
  }
);

export default AIGurujiBirthChartForm;

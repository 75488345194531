import React, { useEffect, useState } from 'react';
import { Button, FormControl, Grid, InputLabel, MenuItem, Modal, Select, Typography, TextField, Divider, Stack, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import './styles.scss';

interface UpdatePaymentModalProps {
  isOpen: boolean;
  onClose: () => void;
  orderId: string;
}

const UpdatePaymentModal: React.FC<UpdatePaymentModalProps> = ({ isOpen, onClose, orderId }) => {

  return (
    <Modal open={isOpen} onClose={onClose} aria-labelledby="change-status-title" className='orderModal'>

      <Box className="modalContents">
        <Stack className='modalHeader'>
          <Typography id="change-status-title" variant="h6" gutterBottom>
            Update Payment
          </Typography>
          <Button onClick={onClose} variant='outlined' className="btnClose">
            <CloseIcon />
          </Button>
        </Stack>

        <Box className="modalBody">
          <Typography id="change-status-title" variant="body1">
            Payment details should be display here.
          </Typography>
        </Box>

        <Box className="modalFooter">
          <Button onClick={onClose} variant="outlined" className="btnCancel grey">Close</Button>
        </Box>
      </Box>
    </Modal>
  );

};

export default UpdatePaymentModal;

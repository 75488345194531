import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
};

const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    showLoader: state => {
      state.isLoading = true;
    },
    hideLoader: state => {
      state.isLoading = false;
    },
  },
});

export const {showLoader, hideLoader} = loaderSlice.actions;
export const selectLoader = (state: any) => state.loader.isLoading;
export default loaderSlice.reducer;

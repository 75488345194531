import * as React from "react";
import { Typography } from "@mui/material";

export default function TitleTypography(props: any) {

  const typoGraphyDefaultCss = {
    fontSize: "24px",
  };

  return (
    <Typography 
    style={{
      fontSize: props.fontSize || typoGraphyDefaultCss.fontSize,
    }}
    >
        {props.title}
    </Typography>
  );
}

import React, { useEffect, useState } from "react";
import BlogCategoryView from "../../Views/Blogs/BlogCategoryView";
import ParentView from "../../Views/PrarentView/ParentView";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { getAllBlogBYType } from "../../store/actions/BlogAction";
import { useDispatch, useSelector } from "react-redux";

const BlogCategoryScreen = (props: any) => {
  const [parentViewCheck, setParentViewCheck] = useState(true);
  const navigate = useNavigate();
  const { type } = useParams();
  const dispatch: any = useDispatch();
  const [postSelectedByType, setPostSelectedByType] = useState([]);
  const { isLoading, error, allPostBySelectedTypes } = useSelector(
    (state: any) => state.blogs
  );

  useEffect(() => {
    dispatch(getAllBlogBYType([type]));
  }, [type]);

  //Native Check
  const location = useLocation();
  useEffect(() => {
    const pathData = location.pathname;

    if (pathData.includes("nativeViews")) {
      props.setIsMobileView(true);
      setParentViewCheck(false);
    }
  }, [location]);

  useEffect(() => {
    setPostSelectedByType(allPostBySelectedTypes);
  }, [allPostBySelectedTypes]);

  const handleBackToBlog = () => {
    const url = parentViewCheck ? `/posts` : `/nativeViews/posts`;
    navigate(url);
  };

  return (
    <>
      {parentViewCheck ? (
        <ParentView>
          <BlogCategoryView
            parentViewCheck={parentViewCheck}
            allPostOfType={postSelectedByType}
            backToBlog={handleBackToBlog}
          />
        </ParentView>
      ) : (
        <BlogCategoryView
          parentViewCheck={parentViewCheck}
          allPostOfType={postSelectedByType}
          backToBlog={handleBackToBlog}
        />
      )}
    </>
  );
};

export default BlogCategoryScreen;

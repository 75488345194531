import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import LightModeIcon from '@mui/icons-material/LightMode';
import Brightness3Icon from '@mui/icons-material/Brightness3';
import BedtimeIcon from '@mui/icons-material/Bedtime';
import "./AIGurujiRashiCard.scss";

const AIGurujiRashiCard: React.FC<any> = ({ sunSign, moonSign }) => {
  return (
    <TableContainer component={Paper} className="rashiCardTable">
      <Table>
        <TableHead>
          {/* <TableRow>
            <TableCell>Rashi Name</TableCell>
            <TableCell>Position</TableCell>
          </TableRow> */}
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell><BedtimeIcon className="moonIcon" />Rashi(Vedic) according to Moon Sign</TableCell>
            <TableCell>{moonSign}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell><LightModeIcon className="sunIcon" />Rashi according to Sun Sign</TableCell>
            <TableCell>{sunSign}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AIGurujiRashiCard;
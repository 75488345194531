import { Box, useStyleConfig } from "@chakra-ui/react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

function CardChakra(props: any) {
  const { variant, children, ...rest } = props;
  const styles = useStyleConfig("Card", { variant });

  return (
    <Card style={{ marginLeft: 16, marginTop: 16, marginRight: 16, marginBottom: 16 }} className="mainCommonCard">
      <CardContent>{children}</CardContent>
    </Card>
  );
}

export default CardChakra;

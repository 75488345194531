import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Divider } from '@mui/material';
import "./DeleteConfirmDialog.scss";

interface DeleteConfirmDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  //itemName: string; // Name of the item being deleted (optional)
  msg: string
}

const DeleteConfirmDialog: React.FC<DeleteConfirmDialogProps> = ({ open, onClose, onConfirm, msg }) => {
  return (
    <Dialog className="deleteConfirmModal" open={open} onClose={onClose}>
      <DialogTitle className="deleteConfirmModal">Confirm Deletion</DialogTitle>
      <Divider className="titleDivider" />
      <DialogContent>
        <Typography sx={{ fontWeight: 500, color: "#6e6e6e" }}>
          {msg}
        </Typography>
      </DialogContent>
      <DialogActions className="confirmBtn">
        <Button className="confirmBtnCancel" onClick={onClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button className="confirmBtnDelete" onClick={onConfirm} color="primary" variant="contained">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmDialog;

import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Box,
} from "@mui/material";
import MultiDateCalendar from "../../../Screen/MultiDateCalendar/MultiDateCalendar";
import { Stack } from "react-bootstrap";
interface ChadhawaFormModalProps {
  isOpen: boolean;
  temple: any | null;
  onClose: () => void;
  onSave: (temple: any) => void;
}

const ChadhawaFormModal: React.FC<ChadhawaFormModalProps> = ({
  isOpen,
  temple,
  onClose,
  onSave,
}) => {
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    itemDescription: "",
    acctualPrice: "",
    isMostOfferedTag: false,
    image:""
  });

  const daysArray: string[] = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const [selectedDates, setSelectedDates] = useState<string[]>([]);
  const [selectedDays, setsetSelectedDays] = useState<string[]>([]);
  const [days, setDays] = useState<string[]>(daysArray);
  const [localImage,setLocalImage] = useState<any>('');

  const setSaledarDate = (date: string[]) => {
    const isDay = (item: any) =>daysArray.includes(item);
    const isDate = (item: any) => /\d{4}-\d{2}-\d{2}/.test(item);
    setsetSelectedDays(date.filter(isDay))
    setSelectedDates(date.filter(isDate))
  };

  
  const resetForm = ()=>{
    setLocalImage('')
    setsetSelectedDays([])
    setSelectedDates([])
    setFormData({
      id: "",
      name: "",
      itemDescription: "",
      acctualPrice: "",
      isMostOfferedTag: false,
      image:""
    });
  }

  useEffect(()=>{
    resetForm();
  },[])

  useEffect(() => {
    resetForm();
    console.log(temple)
    if (temple) {
      if (temple?.restrictionChadavaDate) {
        setSaledarDate(temple?.restrictionChadavaDate);
      }
      setFormData(temple);
    } 
  }, [temple]);

  
  const handleDateChange = (dates: string[]) => {
    setSelectedDates(dates);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked, files } = e.target;
    if (type === "file" && files) {
      const reader = new FileReader();
      
        // When file is read successfully, set the src of the image
        reader.onload = (e)=> { 
          setLocalImage(e.target?.result)
        };
        reader.readAsDataURL(files[0]);  

      setFormData((prev) => ({
        ...prev,
        imageFile: files[0],
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const handleSubmit = () => {
    const dates = [...selectedDays, ...selectedDates];
    const newData: any = Object.assign({}, formData);
    newData["restrictionChadavaDate"] = dates;
    console.log(newData);
    onSave(newData);
  };

  const handleSetSelectedDays = (days: any) => {
    setsetSelectedDays((prev) => [...prev, days]);
  };

  const handleRemoveSelectedDays = (dayToRemove: string) => {
    setsetSelectedDays((prev) =>
      prev.filter((day: any) => day !== dayToRemove)
    );
  };
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{temple ? "Edit" : "Add"} Chadhawa Item</DialogTitle>
      <DialogContent sx={{ minWidth: 400 }}>
        <TextField
          name="name"
          label="Name"
          fullWidth
          margin="normal"
          value={formData.name}
          onChange={handleChange}
        />
        <Stack className="chooseImgInfo">
          <TextField
            name="image"
            label="Upload Image"
            type="file"
            fullWidth
            margin="normal"
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              accept: "image/*",
            }}
          />
          { localImage?.length ==0 && formData?.image?.length > 0 && (<img src={formData?.image} />)}
          { localImage?.length > 0  && (<img src={localImage} />)}
        
        </Stack>
        
        <TextField
          name="itemDescription"
          label="Description"
          fullWidth
          margin="normal"
          value={formData.itemDescription}
          onChange={handleChange}
        />
        <TextField
          name="acctualPrice"
          label="Price"
          fullWidth
          margin="normal"
          value={formData.acctualPrice}
          onChange={handleChange}
        />

        <Box className="day-buttons-container">
          {days &&
            days?.map((el: any, index: number) => (
              <Box
                className={`day ${selectedDays?.includes(el) ? "active_day" : ""}`}
                key={`days${index}`}
                onClick={(e) => {
                  selectedDays?.includes(el)
                    ? handleRemoveSelectedDays(el)
                    : handleSetSelectedDays(el);
                }}
              >
                {el}
              </Box>
            ))}
        </Box>
        <Box className="chadhavaAdminCalendar">
          <MultiDateCalendar
            selectedDates={selectedDates}
            onDateChange={handleDateChange}
          />
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.isMostOfferedTag}
              onChange={handleChange}
              name="isMostOfferedTag"
            />
          }
          label="Is Most Offered"
        />
      </DialogContent>
      <DialogActions className='chadhawaForm modalFooter'>
        <Button onClick={onClose} className='btnCancel grey'>Cancel</Button>
        <Button onClick={handleSubmit} variant='outlined' color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChadhawaFormModal;

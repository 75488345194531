import { Box, Grid } from "@mui/material";

function LiveDarshanView(props: any) {
  console.log("Darsha n data ===",props.darshanDataFortemple)
  const ytCode = props.darshanDataFortemple&&props.darshanDataFortemple.ytEmbedCode?props.darshanDataFortemple.ytEmbedCode:""
  return (
    <Box className="pageContainer liveDarshanPage">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={2} lg={3} xl={3} className="pagehdLeftBlock">

          </Grid>
          <Grid item xs={12} md={8} lg={6} xl={6} className="pageCenterBlock">
            <Box className="liveDarshanView">
                {/* <iframe width="100%" height="500" src={`https://www.youtube.com/embed/${ytCode}?autoplay=1`} title="Temple Dekho" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen ></iframe> */}
                <iframe width="100%" height="400" src={`https://www.youtube.com/embed/${ytCode}`} title="Hill Climb Racing Gameplay Walkthrough Part 18 - Dragster  ( iOS, Android )" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            </Box>
          </Grid>
          <Grid item xs={12} md={2} lg={3} xl={3} className="pagehdRightBlock">

          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default LiveDarshanView;

import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Typography,
  TextField,
  Stack,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import couponApplyIcon from "../../../assets/coupon-tick.png";
//import couponPercentIcon from "../../../../assets/coupon-percent.png";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import "./CouponCodeDialog.scss";
const CouponCodeDialog: React.FC<any> = ({
  open,
  onClose,
  allActiveCoupons,
  setCouponCodeData,
  checkValidityOfcoupon,
  couponApplyResponse,
  couponCodeType
}) => {
  const [couponCode, setCouponCode] = useState<string>("");

  const handleApplyCoupon = (couponCodeSelected: string) => {
    setCouponCode(couponCodeSelected);
    setCouponCodeData(couponCodeSelected);
    checkValidityOfcoupon(couponCodeSelected);
  };

  const handleRemoveCoupon = () => {};

  const applyCouponCode = () => {
    console.log("Coupon code", couponCode);
    // handleSetCouponApplyResponse();
    // onClose();
    checkValidityOfcoupon(couponCode);
  };

 const [couponList,setCouponList] = useState<any>([])

  useEffect(() => {
    
   if(allActiveCoupons?.length > 0){
    const filterType = ["ALL",couponCodeType] 
    const  newCouponList = allActiveCoupons?.filter((el:any) =>  filterType.includes(el.couponType)) 
    setCouponList(newCouponList)
    setCouponCode(newCouponList[0]?.couponCode)
   }

  }, [allActiveCoupons])

  return (
    <Dialog open={open} onClose={onClose}>
      {/* <DialogTitle>Apply Coupon</DialogTitle> */}
      <DialogTitle sx={{ m: 0, p: 2 }}>Apply Coupon</DialogTitle>
      <Button
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 2,
          top: 13,
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
      >
        <CloseIcon />
      </Button>
      <Divider /> {/* Divider added here */}
      <DialogContent sx={{ width: { lg: "600px", md: "600px", xs: "310px" } }}>
        <TextField
          label="Code"
          variant="outlined"
          fullWidth
          value={couponCode}
          sx={{ mb: 1 }} // Margin-bottom for spacing
          onChange={(e: any) => setCouponCode(e.target.value)}
        />
        {couponApplyResponse.isError ? (
          <Box className="CouponNotValidText">
            <Typography>
              <ErrorRoundedIcon />
              Coupon code is not valid
            </Typography>
          </Box>
        ) : null}

        {/* {couponCode && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            className="couponCodeDialogTop"
          >
            <Box className="couponApplySec">
              <img src={couponApplyIcon} alt="" />
              <Box>
                <Typography className="couponText">
                  Devotee(s) saved <span>₹{couponApplyResponse?.discount}</span> with
                  coupon code {""}
                  <span>{couponCode}</span>
                </Typography>
              </Box>
            </Box>
            <Box className="removeBtn">
              <Button variant="text" onClick={handleRemoveCoupon}>
                Remove
              </Button>
            </Box>
          </Stack>
        )} */}

        <Box className="applyBtn">
          <Button
            variant="contained"
            onClick={(e: any) => applyCouponCode()}
            sx={{ width: "90%", borderRadius: "25px" }}
          >
            Apply Coupon
          </Button>
        </Box>

        <Box className="existingCouponCode">
          <Typography className="existingCodeTitle">
            Existing Coupons
          </Typography>
          <List className="existingCodeList">
            {couponList?.map((item: any, id: any) => (
              <ListItem
                key={id}
                sx={{ borderBottom: "1px solid #ccc", p: "8px 10px" }}
              >
                <ListItemText
                  className="codeText"
                  primary={`${item?.couponCode}`}
                />
                <ListItemSecondaryAction>
                  <Button
                    variant="text"
                    color="success"
                    className="existingCodeApplyBtn"
                    onClick={() => handleApplyCoupon(item?.couponCode)}
                  >
                    <Typography>Apply</Typography>
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Box>
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions> */}
    </Dialog>
  );
};

export default CouponCodeDialog;

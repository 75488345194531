import React from 'react'
import "./AIGurujiValmikiImg.scss";
import { Box, Grid, Typography } from '@mui/material';
import valmikiImg from "../../../../assets/valmiki.png";
import omImage from "../../../../assets/hinduism.gif";

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

const AIGurujiValmikiImg = () => {
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} className='gridLeft'>
            <Stack spacing={2}>
              <Box
                component="img"
                src={valmikiImg}
                className="valmikiImg"
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6} className='gridRight'>
            <Stack spacing={2}>
              <Box className="valmikiBannerText">
                <Box className="betaText">Beta.</Box>
                <Box className="valmikiTitle">Valmiki</Box>
                <Box className="valmikiSubTitle">Your own AI Guruji</Box>
                <Box className="animatedCircle">
                  <Box className="rotating-image-container">
                    <Box
                      component="img"
                      src={omImage}
                      className="animated-image"
                    />
                  </Box>
                </Box>
              </Box>

            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default AIGurujiValmikiImg

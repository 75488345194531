import {createSlice} from '@reduxjs/toolkit';

const ChadhawaSlice = createSlice({
  name: 'chadhawa',
  initialState: {
    isLoading: false,
    chadhavaTempleList:[],
    error:"",
    chadhavaList:[],
    templeChadhavaList:[],

    //calcMoonSign:""
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setChadhavaTempleList: (state, action) => {
      state.chadhavaTempleList = action.payload;
    },
    setChadhavaForTemple: (state, action) => {
      state.chadhavaList = action.payload;
    },
    setTempleChadhavaList: (state, action) => {
      state.templeChadhavaList = action.payload;
    },
    
  },
});

export const {
  setLoading,
  setError,
  setChadhavaTempleList,
  setChadhavaForTemple,
  setTempleChadhavaList
  //setCalcMoonSign
  
  
} = ChadhawaSlice.actions;

export default ChadhawaSlice.reducer;

/* eslint-disable */
import {
  Flex,
  Icon,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Select,
  HStack,

} from "@chakra-ui/react";
// Custom components
import EditIcon from "@mui/icons-material/Edit";
import Stack from '@mui/material/Stack';
import { Box, Checkbox } from '@mui/material';


import CardChakra from "../../components/card/TableChakra/CardChakra";
import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  usePagination,
  useSortBy,
  useTable,
  useFilters,
  useAsyncDebounce,
  Column,
} from "react-table";
import moment from "moment";
import {
  MdPlayArrow,
  MdDownload,
  MdEdit,
  MdPhone,
  MdLockClock,
} from "react-icons/md";
import "./analytics.css";
import DefaultColumnFilter from "./DefaultColumnFilter";
import Button from "@mui/material/Button";
import { getAllUsers } from "../../store/actions/userAction";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import UpgradeOutlinedIcon from '@mui/icons-material/UpgradeOutlined';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

export default function OrderTable(props) {
  const { templeList, columnsData, userOrder, OpenViewDetailsModal, handleDownloadExcel, OpenChangeStatusModal } = props;
  //console.log(JSON.stringify(userOrder));
  const editableField = ["orderStatus"];
  const [highlightEditingRow, setHighlightEditingRow] = useState(null);

  const columns = useMemo(() => columnsData, [columnsData]);
  const [data, setData] = useState(userOrder);
  const [paginationPageValue, setPaginationPageValue] = useState(0);
  //defaultColumn: { Filter: DefaultColumnFilter },

  useEffect(() => {
    setData(userOrder);
    setAllDataWithoutFilter(userOrder);
    filterDataLocally(userOrder);
    //console.log("page",modifiedDataArray);
  }, [userOrder]);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: paginationPageValue },
      defaultColumn: { Filter: DefaultColumnFilter },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    initialState,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;
  initialState.pageSize = 10;


  const textColor = useColorModeValue("secondaryRed.900", "black");
  const iconColor = useColorModeValue("#2185d0");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const [custFilterValue, setCustFilterValue] = useState({});
  const [allDataWithoutFilter, setAllDataWithoutFilter] = useState([]);

  // //Checked  order details


  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});


  const handleCheckboxChange = (id) => {
    console.log(id);
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [id]: !prevCheckedItems[id]
    }));
  };

  const handleCheckAllChange = () => {
    const newCheckedItems = {};
    const newIsCheckedAll = !isCheckedAll;
    for (const item of page) {
      newCheckedItems[item.original.docId] = newIsCheckedAll;
    }
    setIsCheckedAll(newIsCheckedAll);
    setCheckedItems(newCheckedItems);
  };



  const filterDataLocally = (modifiedDataArray) => {
    let dataArr = [...modifiedDataArray];

    if (Object.keys(custFilterValue).length) {
      dataArr = dataArr.filter(function (item) {
        let trueCounter = 0;
        for (var key in custFilterValue) {
          if (
            item[key] &&
            custFilterValue[key] &&
            item[key]?.toLowerCase().includes(custFilterValue[key]?.toLowerCase())
          ) {
            trueCounter++;
            // console.log("trueCounter",trueCounter,Object.keys(custFilterValue).length);
          }
        }
        if (Object.keys(custFilterValue).length === trueCounter) {
          return true;
        }
        return false;
      });
      setData(dataArr);
    } else {
      setData(dataArr);
    }
  };
  useEffect(() => {
    let dataArr = [...allDataWithoutFilter];
    filterDataLocally(dataArr);
  }, [custFilterValue]);
  return (
    <>
      <CardChakra direction="column" mh="400px" w="100%" px="0px" className="orderTableCard">
        <Flex px="" justify="space-between" mb="16px" align="center">
          <Text
            color={textColor}
            fontSize="15px"
            fontWeight="700"
            lineHeight="100%"
            style={{ color: textColor }}
          >
            {"All Orders"}
          </Text>
          <Box className="btnsBlocks">
          <Button
              size="sm"
              variant="outlined"
              style={{ backGroundColor: "blue", margin:"0px 5px" }}
              startIcon={<PublishedWithChangesIcon />}
              onClick={(e) => {
                e.preventDefault()
                const checkedIds = Object.keys(checkedItems).filter(key => checkedItems[key] === true);
                console.log(checkedIds);
                if(checkedIds?.length){
                  
                  OpenChangeStatusModal(checkedIds)
                }else{
                  alert('Please select orders which you want to change ')
                }
              }}
            >
              Change Status
            </Button>
            <Button
              size="sm"
              variant="outlined"
              style={{ backGroundColor: "blue", margin:"0px 5px" }}
              startIcon={<GroupAddOutlinedIcon />}
              onClick={(e) => {
              
                handleDownloadExcel('users');
              }}
            >
              Export All User 
            </Button>
            <Button
              size="sm"
              variant="outlined"
              style={{ backGroundColor: "blue", margin:"0px 5px"  }}
              startIcon={<UpgradeOutlinedIcon />}
              onClick={() => {
                handleDownloadExcel('order');
              }}
            >
              Export All Order
            </Button>
          </Box>
          
        </Flex>
        <div className="analyticsTable orderTable" style={{marginLeft: "0", marginRight: "0", width: "100%"}}>
          <Table
            {...getTableProps()}
            m="15px auto"
            size="sm"
            variant="striped"
            colorScheme="blackAlpha"
            display="block"
          >
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  <Th
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                    style={{ borderColor: borderColor }}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize="10px"
                      color="gray.400"

                    >
                      <Checkbox
                        isChecked={isCheckedAll}
                        onChange={handleCheckAllChange}
                      />
                    </Flex>
                  </Th>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      pe="2px"

                      key={index}
                      borderColor={borderColor}
                      style={{ borderColor: borderColor }}
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize="10px"
                        color="gray.400"
                      >
                        {column.render("Header")}
                      </Flex>
                      <span>
                        {column.canFilter ? (
                          <Input
                            variant="filled"
                            size="sm"
                            value={custFilterValue[column.id]}
                            onChange={(e) => {
                              let tempCustFilterRef = Object.assign(
                                {},
                                custFilterValue
                              );
                              if (
                                tempCustFilterRef[column.id] &&
                                e.target.value.length === 0
                              ) {
                                delete tempCustFilterRef[column.id];
                              } else {
                                tempCustFilterRef[column.id] = e.target.value;
                              }
                              setCustFilterValue(tempCustFilterRef);
                            }}
                            placeholder={"Filter"}
                            htmlSize={4}
                            width="auto"
                          />
                        ) : null}
                      </span>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()} overflow="auto">
              {page.map((row, rowindex) => {
                prepareRow(row);

                return (
                  <Tr {...row.getRowProps()} key={rowindex} style={{}}>
                    <Td
                      role="cell"
                      key={rowindex+88}
                      fontSize={{ sm: "10px" }}
                      minW={{ sm: "10px", md: "10px" }}
                      borderColor="transparent"
                      style={{ borderColor: "transparent" }}
                    >
              
                      <Checkbox
                        checked={checkedItems[row.original.docId] || false}
                        onChange={() => handleCheckboxChange(row.original.docId)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />


                    </Td>
                    {row.cells.map((cell, index) => {
                     // console.log("Tbody", row.id, row.original, cell);
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "20px", lg: "200px" }}
                          borderColor="transparent"
                          style={{ borderColor: "transparent" }}
                        >
                          {cell.column.id === "changeStatus" ||
                            cell.column.id === "viewAllDetails" ? (
                            <Button variant="outlined" onClick={() => {
                              cell.column.id === "changeStatus" ? OpenChangeStatusModal([row.original.docId]) : OpenViewDetailsModal(row.original.orderId)
                            }}>
                              {cell.column.Header}
                            </Button>
                          ) : (
                            <Text
                              color={"black"}
                              fontSize="large"
                              fontWeight="700"
                              style={{ color: "black" }}
                            >
                              {cell.value}
                            </Text>
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </div>
        <div className="pagination" style={{ margin: "25px 0px 0px 0px" }}>
          <HStack sx={{ width: "100%" }} justifyContent="space-between" alignItems="center">
            <Stack direction="row" spacing={1}>
              <Button
                size="sm"
                onClick={() => {
                  gotoPage(0);
                  setPaginationPageValue(0);
                }}
                disabled={!canPreviousPage}
              >
                {<KeyboardDoubleArrowLeftIcon/>}
              </Button>{" "}
              <Button
                size="sm"
                onClick={() => {
                  previousPage();
                  setPaginationPageValue(paginationPageValue - 1);
                }}
                disabled={!canPreviousPage}
              >
                {<KeyboardArrowLeftIcon/>}
              </Button>{" "}
              <Button
                size="sm"
                onClick={() => {
                  nextPage();
                  setPaginationPageValue(paginationPageValue + 1);
                }}
                disabled={!canNextPage}
              >
                {<KeyboardArrowRightIcon/>}
              </Button>{" "}
              <Button
                size="sm"
                onClick={() => {
                  gotoPage(pageCount - 1);
                  setPaginationPageValue(pageCount - 1);
                }}
                disabled={!canNextPage}
              >
                {<KeyboardDoubleArrowRightIcon/>}
              </Button>{" "}
            </Stack>
            <Stack direction="row" alignItems="center">
              <Text fontSize="sm">
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </Text>
              <Text fontSize="sm">
                | Go to page:{" "}
                <Input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                    gotoPage(page);
                    setPaginationPageValue(page);
                  }}
                  variant="filled"
                  size="xs"
                  width="20%"
                />
              </Text>
              <Select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                variant="filled"
                size={"xs"}
                width={"auto"}
              >
                {[10, 50, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </Select>
            </Stack>
          </HStack>
        </div>
      </CardChakra>
    </>
  );
}

import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function UpcomingPujasCard(props: any) {
  return (
    <Box className="pujaCardBox">
      <img src={props.image} alt="" />
      {/* <Box className="card-body">
        <Typography className="title">{props.templeName}</Typography>
        <Box className="content">
          <Box className="pujaNameDate">
            <Typography>{props.pujaName}<br></br>
              {props.pujaDate}
            </Typography>
          </Box>
          <Box className="bookingButton">
            <Link to="/" className="btn btn-primary border-0 btnRound">Book Puja</Link>
          </Box>
        </Box>
      </Box> */}
    </Box>
  )
}
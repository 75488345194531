import { Box, Button } from "@mui/material";

export default function FeaturesDisplayCard(props: any) {
  return (
    <Box className="card featureCard" onClick={props.onClick}>
      <Box className="cardInn">
        <img src={props.image} alt="" />
        {/* <Box className="viewMoreBtn">
          <Button variant="contained" onClick={props.onClick}>View More</Button>
        </Box> */}
      </Box>

    </Box>
  )
}
import {initializeApp} from "firebase/app";
import { getAuth,RecaptchaVerifier,signInWithPhoneNumber  } from "firebase/auth";
//Real application
const firebaseConfig = {
    apiKey: "AIzaSyD0pAn_bFooidjl3EpcdKxDnmO_fupqkdI",
    authDomain: "YOUR_AUTH_DOMAIN",
    projectId: "templedekhodev",
    storageBucket: "templedekhodev.appspot.com",
    messagingSenderId: "844085093093",
    appId: "1:844085093093:ios:7541e1c7f9dbc725092a0c",
   
  };

//Testing

// const firebaseConfig = {
//   apiKey: "AIzaSyDIAnPsdmhm9CvSq0gdE-b3c9LiM1HXvzs",
//   authDomain: "authproject-4d87a.firebaseapp.com",
//   databaseURL: "https://authproject-4d87a-default-rtdb.asia-southeast1.firebasedatabase.app",
//   projectId: "authproject-4d87a",
//   storageBucket: "authproject-4d87a.appspot.com",
//   messagingSenderId: "889161876373",
//   appId: "1:889161876373:web:15ffebcf7f213429d26c9c",
//   measurementId: "G-VMNQEY1X12"
// };
  export const googleMapKey =  "AIzaSyDAx2xJ9TEpGOR0MwOpNeZ3CpnLJPYAHyU" ;
  const app = initializeApp(firebaseConfig)
  export const auth = getAuth(app)
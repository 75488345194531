import React from "react";
import "./AIGurujiCalculationChart.scss";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
  Box,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const AIGurujiCalculationChart: React.FC<any> = ({
  currentPayload,
  handleScrollTobirthChartForm,
  id,
}) => {
  const date = new Date(
    currentPayload?.extra_parameters?.year,
    currentPayload?.extra_parameters?.month - 1,
    currentPayload?.extra_parameters?.day
  );

  const options: Intl.DateTimeFormatOptions = {
    month: "long",
    day: "numeric",
    year: "numeric",
    weekday: "long",
  };

  const formattedDate = date.toLocaleDateString("en-US", options);

  // Check if there’s an error in the current payload data
  const hasError = !currentPayload || !currentPayload.extra_parameters;

  return (
    <Box className="birthDetailsContainer">
      <Typography className="description">
        All calculations and charts are derived from the following input:
      </Typography>
      <Box className="editDetailsBtn">
        <Button
          variant="outlined"
          className="editBtn"
          onClick={(e) => {
            e.preventDefault();
            handleScrollTobirthChartForm(id); // Call the function
          }}
        >
          <EditIcon /> Edit Details
        </Button>
      </Box>

      {!hasError && (
        <TableContainer component={Paper} className="detailsOfTable">
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>{currentPayload?.extra_parameters?.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Date of Birth</TableCell>
                <TableCell>{formattedDate}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Gender</TableCell>
                <TableCell>
                  {currentPayload?.extra_parameters?.gender
                    ? currentPayload?.extra_parameters?.gender
                    : "N/A"}{" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Time of Birth</TableCell>
                <TableCell>
                  {currentPayload?.extra_parameters?.hour < 10
                    ? `0${currentPayload?.extra_parameters?.hour}`
                    : currentPayload?.extra_parameters?.hour}
                  {`:`}
                  {currentPayload?.extra_parameters?.minutes < 10
                    ? `0${currentPayload?.extra_parameters?.minutes}`
                    : currentPayload?.extra_parameters?.minutes}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>City of Birth</TableCell>
                <TableCell>{currentPayload?.extra_parameters?.city}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Country of Birth</TableCell>
                <TableCell>
                  {currentPayload?.extra_parameters?.nation}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default AIGurujiCalculationChart;

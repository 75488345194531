import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SuccessView from '../../Views/Success/SuccessView';
import ParentView from '../../Views/PrarentView/ParentView';
import { useLocation, useNavigate } from 'react-router-dom';
const SuccessScreen: React.FC = (props) => {

    const location = useLocation();
    const  [data,setData] = useState()
    const navigate = useNavigate()
    useEffect(()=>{
        const data = location.state;
       if(data){
        setData(data);
       }else{
        navigate("/");
       }
    },[location])
    
    return (
        <ParentView>
            {data && (<SuccessView data={data} ></SuccessView>)} 
        </ParentView>
      );
};
export default SuccessScreen;

import { getOfferData } from "../store/actions/authAction";
import { setCartAction } from "../store/actions/cartAction";
import {
  getPromotionalPujaList,
  getTemplePujaCategoryList,
} from "../store/actions/pujaAction";

import {
  getAllTempleList,
  getBenefitsList,
  getPanchangData,
  getTempleAndPujaList,
  getUnverifiedTempleList,
  getVerrifiedTempleList,
} from "../store/actions/templeAction";
function LoadInitialData(dispatch: any) {
  // dispatch(getUnverifiedTempleList({}));
  dispatch(getVerrifiedTempleList({}));
  // dispatch(getAllTempleList({}));
  dispatch(getTempleAndPujaList());
  dispatch(getPromotionalPujaList());
  dispatch(getBenefitsList());
  dispatch(getTemplePujaCategoryList());
  dispatch(getOfferData());

  if (localStorage["cartInfo"]) {
    let cartInfoFromLocalStorage = JSON.parse(
      localStorage.getItem("cartInfo") || ""
    );

    // console.log("cart info from llc", cartInfoFromLocalStorage);
    dispatch(setCartAction(cartInfoFromLocalStorage));
  }
}

export default LoadInitialData;

import React, { useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";

interface TinyMCEEditorProps {
  defaultValue?: string;
  onEditorChange: (content: string) => void;
}

export const TinyMCEEditor: React.FC<TinyMCEEditorProps> = ({
  defaultValue,
  onEditorChange,
}) => {
  const [content, setContent] = useState(defaultValue || "");

  const handleEditorChange = (content: string) => {
    console.log('content',content)
    setContent(content);
    onEditorChange(content);
  };

  useEffect(() => {
    setContent(defaultValue || "");
  }, [defaultValue]);

  return (
    <Editor
      apiKey="7b3u6y6ja4blluuv0g6q8e7biewheghejc5c8s97zfbansbn"
      init={{
        plugins: [
          "anchor",
          "autolink",
          "charmap",
          "codesample",
          "emoticons",
          "image",
          "link",
          "lists",
          "media",
          "searchreplace",
          "table",
          "visualblocks",
          "wordcount",
        ],
        toolbar:
          "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
        tinycomments_mode: "embedded",
        tinycomments_author: "Author name",
      }}
      value={content}
      onEditorChange={handleEditorChange}
    />
  );
};

import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Store } from "../utils/Constants";
import Header from "../components/header/Header";
function AuthHandlerRoutes() {
  var {userInfo} = useSelector((state: any) => state.user);


  return (
    <>
    <Header />
      <Outlet />
      </>  
    );
    
  //Older code 
  if (userInfo?.id) {
    return <Navigate to="/" />;
  }
   else {
    return (
  <>
  <Header />
    <Outlet />
    </>  
  );
  }
}

export default AuthHandlerRoutes;

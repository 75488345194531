import { Box, Typography } from "@mui/material";
import "./ChooseProfileView.scss";
import { Button } from "react-bootstrap";

interface profile {
  id: number;
  title: string;
}

interface profileProps {
profiles:profile[]
}
function ChooseProfileView(props:any) {
    return (
      <Box className="chooseProfile">
        <Typography>Choose profile</Typography>
        <Box className="profileBlock">
            {props.profiles.map((item:any,id:any)=>{
              return(
              <Box onClick={()=>props.selectOneProfile(item)} key={id} style={{cursor:"pointer"}} className="profilecards">
                <Box className="profileImg"><img src={item.img}></img></Box>
                <Typography>{item.title}</Typography>
              </Box>
              )
            })}
        </Box>
        <Box className="continueBtn"><Button onClick={()=>props.cancelAndGoToLogin()} className="btn-primary btnRound confirmBtn">Continue</Button></Box>
      </Box>
    );
  }
  
  export default ChooseProfileView;
  
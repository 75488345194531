import React from 'react';
import { Box, Button } from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Tooltip from '@mui/material/Tooltip';
import "./AIGurujiBackToMainMenu.scss";

const AIGurujiBackToMainMenu:React.FC<any> = ({handleBackToMainMenu}) => {
    const handleBackToTop = () => {
      handleBackToMainMenu()
    };

    return (
        <Box className="backToMain">
            <Tooltip title="Conversation will be removed" placement="top" arrow>
            <Button 
                variant="outlined" 
                color="primary" 
                onClick={handleBackToTop}
                
            >
                <RestartAltIcon/>Reset Chat
            </Button>
            </Tooltip>
        </Box>
    );
};

export default AIGurujiBackToMainMenu;

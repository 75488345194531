import React from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import "./AIGurujiExportButton.scss";

interface AIGurujiExportButton {
  handleDownload: ()=> void;
  isDownloading:boolean;
}

const AIGurujiExportButton: React.FC<AIGurujiExportButton> = ({handleDownload,isDownloading}) => {
 
  return (
    // <Box className="exportButton">
    //   <Button variant="contained" startIcon={<GetAppRoundedIcon />} onClick = {handleDownload}>Export</Button>
    // </Box>
    
    <Box className="exportButton">
      <Button 
        variant="contained" 
        startIcon={!isDownloading ? <GetAppRoundedIcon /> : null}
        onClick={handleDownload}
        disabled={isDownloading}
      >
        {isDownloading ? (
          <><CircularProgress size={24} color="inherit" />  <Typography sx={{color:"white"}}>Preparing to download ...</Typography></>
        ) : (
          'Export'
        )}
      </Button>
    </Box>
  );
};

export default AIGurujiExportButton;
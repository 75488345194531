import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Stack,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import "./PanchangView.scss";
import {
  getDateToShow,
  getFormattedDate,
  getFormattedDateForTithi,
  getFormattedTimeForDate,
} from "../../utils/HelperFunctions";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import WbTwilightRoundedIcon from '@mui/icons-material/WbTwilightRounded';
import NightlightRoundRoundedIcon from '@mui/icons-material/NightlightRoundRounded';
import NightsStayRoundedIcon from '@mui/icons-material/NightsStayRounded';


function PanchangView(props: any) {
  //Panchang Calendar
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const panchangBody = props.panchangData?.data;
  console.log("Panchang data--->", panchangBody);
  const tithi = panchangBody && panchangBody.tithi ? panchangBody.tithi : [];
  const auspicious =
    panchangBody && panchangBody.auspicious_period
      ? panchangBody.auspicious_period
      : [];

  const inauspicious =
    panchangBody && panchangBody.inauspicious_period
      ? panchangBody.inauspicious_period.slice(0, 3)
      : [];
  const inauspiciousRow2 =
    panchangBody &&
      panchangBody.inauspicious_period &&
      panchangBody.inauspicious_period.length > 3
      ? panchangBody.inauspicious_period.slice(
        3,
        panchangBody.inauspicious_period.length
      )
      : [];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDateChange = (event: any) => {
    setSelectedDate(new Date(dayjs(event).toString()));
    handleClose();
  };

  const formatDate = (date: any) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };
  //Timings
  const sunriseTime = getFormattedTimeForDate(props.panchangData?.data?.sunrise)
  const sunsetTime = getFormattedTimeForDate(props.panchangData?.data?.sunset)

  const moonriseTime = getFormattedTimeForDate(props.panchangData?.data?.moonrise)
  const moonsetTime = getFormattedTimeForDate(props.panchangData?.data?.moonset)

  return (
    <Box className="pageContainer templePage">
      <Box className="templeBlock">
        <Grid container spacing={1}>
          <Grid item xs={12} md={2} lg={3} xl={3} className="pagehdLeftBlock">
            {/* <LeftAddressCard
              header={props.TDTexts.CURRENTADDRESS}
              address={
                props.userAddressList && props.userAddressList.length > 0
                  ? props.userAddressList[0].formatted_address
                  : ""
              }
              footerButton="Edit or add an address"
            /> */}
          </Grid>
          <Grid item xs={12} md={8} lg={6} xl={6}>
            <Box className="calendarBtn">
              <Box display="flex" alignItems="center">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<CalendarTodayIcon />}
                  onClick={handleClickOpen}
                  className="middleTextBtn"
                  fullWidth
                >
                  {formatDate(selectedDate)}
                </Button>
              </Box>
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Select a date</DialogTitle>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendar
                    // value={dayjs(props.pujaDateAndTime.pujaDate)}
                    onChange={(e: any) => {
                      console.log("clicked date", e);
                      props.getPanchangDataForSelectedDate(e)
                      console.log("Panchang selected date", dayjs(e).toString())
                      handleDateChange(e)
                      // props.setPujaDateAndTime({
                      //   ...props.pujaDateAndTime,
                      //   pujaDate: e,
                      // });
                    }}
                  />
                </LocalizationProvider>
                {/* <DialogContent>
                  <TextField
                    type="date"
                    defaultValue={selectedDate.toISOString().split("T")[0]}
                    onChange={handleDateChange}
                    fullWidth
                  />
                </DialogContent> */}
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
            <Box className="panchangContent">
              <Box className="panchangCard">
                <Typography className="CardtopTitle">Tithi</Typography>

                <Box className="tithiDetails">
                  {tithi.map((item: any, id: any) => {
                    const tithiStart = getFormattedDateForTithi(item.start);
                    const tithistartDate = tithiStart.split("/")[0];
                    const tithiStartTime = getFormattedTimeForDate(item.start);
                    const tithiEnd = getFormattedDateForTithi(item.end);
                    const tithiendDate = tithiEnd.split("/")[0];
                    const tithiendTime = getFormattedTimeForDate(item.end);
                    return (
                      <Box className="tithiList">
                        <Typography component="p" className="tithiHeading">
                          {item.name}
                        </Typography>
                        <Typography component="p">
                          {tithistartDate}{getDateToShow(Number(tithistartDate))?.subText} {tithiStartTime} to {tithiendDate}
                          {getDateToShow(Number(tithiendDate))?.subText} {tithiendTime}
                        </Typography>
                      </Box>
                    );
                  })}
                  {/* <Box className="tithiList">
                    <Typography component="p" className="tithiHeading">
                      Dashami
                    </Typography>
                    <Typography component="p">
                      4th 12:20 PM to 5th 11:55 AM
                    </Typography>
                  </Box> */}
                </Box>
              </Box>
              <Box className="panchangCard">
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: "15px" }}
                >
                  <Typography className="CardtopTitle mb-0">
                    Auspicious
                  </Typography>
                  <Box className="greenRound"></Box>
                </Stack>
                <Box className="tithiDetails">
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    {auspicious.map((item: any, id: any) => {
                      const auspiciousStart = getFormattedDateForTithi(
                        item.period[0].start
                      );
                      const auspiciousStartDate = auspiciousStart.split("/")[0];
                      console.log("Auspicious start",auspiciousStartDate)
                      const auspiciousStartTime = getFormattedTimeForDate(
                        item.period[0].start
                      );
                      const auspiciousEnd = getFormattedDateForTithi(
                        item.period[0].end
                      );
                      const auspiciousEndDate = auspiciousEnd.split("/")[0];
                      const auspiciousEndTime = getFormattedTimeForDate(
                        item.period[0].end
                      );
                      return (
                        <Box className="auspiciousList">
                          <Typography component="p" className="tithiHeading">
                            {item.name}
                          </Typography>
                          <Typography component="p">
                            {auspiciousStartDate}{getDateToShow(Number(auspiciousStartDate))?.subText} {auspiciousStartTime} to{" "}
                            {auspiciousEndDate}{getDateToShow(Number(auspiciousEndDate))?.subText} {auspiciousEndTime}
                          </Typography>
                        </Box>
                      );
                    })}
                    {/* <Box className="auspiciousList">
                      <Typography component="p" className="tithiHeading">
                        Abhijit Muhurat
                      </Typography>
                      <Typography component="p">
                        5th 05:58 AM to 5th 06:57 PM
                      </Typography>
                    </Box>
                    <Box className="auspiciousList">
                      <Typography component="p" className="tithiHeading">
                        Amrit Kaal
                      </Typography>
                      <Typography component="p">
                        5th 05:23 PM to 5th 06:57 PM
                      </Typography>
                    </Box>
                    <Box className="auspiciousList">
                      <Typography component="p" className="tithiHeading">
                        Brahma Muhurat
                      </Typography>
                      <Typography component="p">
                        4th 11:11 PM to 4th 11:59 PM
                      </Typography>
                    </Box> */}
                  </Stack>
                </Box>
              </Box>
              <Box className="panchangCard">
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: "15px" }}
                >
                  <Typography className="CardtopTitle mb-0">
                    Inuspicious
                  </Typography>
                  <Box className="redRound"></Box>
                </Stack>
                <Box className="tithiDetails">
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{
                      borderBottom: "1px solid #626367",
                      paddingBottom: "10px",
                    }}
                  >
                    {inauspicious.map((item: any, id: any) => {
                      const inauspiciousStart = getFormattedDateForTithi(
                        item.period[0].start
                      );
                      const inauspiciousStartDate =
                        inauspiciousStart.split("/")[0];
                      const inauspiciousStartTime = getFormattedTimeForDate(
                        item.period[0].start
                      );
                      const inauspiciousEnd = getFormattedDateForTithi(
                        item.period[0].end
                      );
                      const inauspiciousEndDate = inauspiciousEnd.split("/")[0];
                      const inauspiciousEndTime = getFormattedTimeForDate(
                        item.period[0].end
                      );
                      return (
                        <Box className="inuspiciousList">
                          <Typography component="p" className="tithiHeading">
                            {item.name}
                          </Typography>
                          <Typography component="p">
                            {inauspiciousStartDate}{getDateToShow(Number(inauspiciousStartDate))?.subText} {inauspiciousStartTime} to{" "}
                            {inauspiciousEndDate}{getDateToShow(Number(inauspiciousEndDate))?.subText} {inauspiciousEndTime}
                          </Typography>
                        </Box>
                      );
                    })}
                    {/* <Box className="inuspiciousList">
                      <Typography component="p" className="tithiHeading">
                        Rahu
                      </Typography>
                      <Typography component="p">
                        5th 02:10 AM to 5th 03:34 AM
                      </Typography>
                    </Box>
                    <Box className="auspiciousList">
                      <Typography component="p" className="tithiHeading">
                        Yamaganda
                      </Typography>
                      <Typography component="p">
                        5th 04:57 AM to 5th 06:20 AM
                      </Typography>
                    </Box>
                    <Box className="auspiciousList">
                      <Typography component="p" className="tithiHeading">
                        Gulika
                      </Typography>
                      <Typography component="p">
                        5th 07:43 AM to 5th 09:06 AM
                      </Typography>
                    </Box> */}
                  </Stack>
                  {inauspiciousRow2 && inauspiciousRow2.length > 0 ? (
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      sx={{ paddingTop: "10px" }}
                    >
                      {inauspiciousRow2.map((item: any, id: any) => {
                        const inauspiciousStart = getFormattedDateForTithi(
                          item.period[0].start
                        );
                        const inauspiciousStartDate =
                          inauspiciousStart.split("/")[0];
                        const inauspiciousStartTime = getFormattedTimeForDate(
                          item.period[0].start
                        );
                        const inauspiciousEnd = getFormattedDateForTithi(
                          item.period[0].end
                        );
                        const inauspiciousEndDate =
                          inauspiciousEnd.split("/")[0];
                        const inauspiciousEndTime = getFormattedTimeForDate(
                          item.period[0].end
                        );
                        return (
                          <Box className="inuspiciousList">
                            <Typography component="p" className="tithiHeading">
                              {item.name}
                            </Typography>
                            <Typography component="p">
                              {inauspiciousStartDate}{getDateToShow(Number(inauspiciousStartDate))?.subText} {inauspiciousStartTime}{" "}
                              to {inauspiciousEndDate}{getDateToShow(Number(inauspiciousEndDate))?.subText} {inauspiciousEndTime}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Stack>
                  ) : null}
                </Box>
              </Box>
              <Box className="panchangCard">
                <Typography className="CardtopTitle">Other Important Timings</Typography>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={6} md={3}>
                    <Box className="timingCard">
                      <LightModeRoundedIcon/>
                      <Typography>Sunrise</Typography>
                      <Typography>{sunriseTime}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Box className="timingCard">
                      <WbTwilightRoundedIcon/>
                      <Typography>Sunset</Typography>
                      <Typography>{sunsetTime}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Box className="timingCard">
                      <NightlightRoundRoundedIcon/>
                      <Typography>Moonrise</Typography>
                      <Typography>{moonriseTime}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Box className="timingCard">
                      <NightsStayRoundedIcon/>
                      <Typography>Moonset</Typography>
                      <Typography>{moonsetTime}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={2} lg={3} xl={3} className="pagehdRightBlock">
            {/* <RightBlockCard /> */}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default PanchangView;

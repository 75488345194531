import { Box, Typography } from "@mui/material";
import { Badge } from '@mui/base/Badge';

export default function TempleCard(props: any) {
  return (
    <Box className="ptBox" onClick={() => props.navigate("/temples/" + props.templeId)}>
      <Box className="cardImgBlock"> <img src={props.image} alt="" /></Box>
      <Box className="card-body ptBody">
        <Typography className="title">{props.templeName}</Typography>
        <Box className="content">
          <Typography>{props.templeDescription}</Typography>
        </Box>
        <Badge className="nwlyAddedbadge" slotProps={{ badge: {className: 'newly-added'} }}>Newly added</Badge>
      </Box>
    </Box>
  )
}
import { Box, Typography, Button } from "@mui/material";
import ModeEditRoundedIcon from '@mui/icons-material/ModeEditRounded';
import "./LeftAddressCard.scss";



export default function LeftAddressCard(props: any) {
  return (
    <Box className="pageLeftBlock">
      {props.header?<Typography>{props.header}</Typography>:null}
      
      <Typography>{props.address}</Typography>
      <Button onClick={()=>props.onClick()}>{props.footerButton}<ModeEditRoundedIcon/></Button>
    </Box>
  )
}
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../configs/firebaseConfig";
import { checkUserDetails } from "../store/actions/authAction";
import { setAuthUser, setConfirmation } from "../store/slices/authSlice";
import { setGlobalLoader } from "../store/actions/loaderAction";
function CheckUserFromOAuth(dispatch:any){

   
  try{
    dispatch(setGlobalLoader(true))
    let userDataFromSession :any= localStorage.getItem("userInfo")
    let parsedUserData = null
    if(userDataFromSession&&userDataFromSession!=='undefined'&&userDataFromSession!=='null'){
      parsedUserData = JSON.parse(userDataFromSession)
    }
  

    // console.log("User data",parsedUserData)
    if(parsedUserData.id){
      dispatch(setGlobalLoader(true))
     dispatch(checkUserDetails(parsedUserData.phoneNumber))
      // dispatch(checkUserDetails("+917278091832"))
    }
      else{
        dispatch(setGlobalLoader(false))
      }
  }
  catch(err) {
    console.log("Error happened in O auth reload")
  }
  finally{
    console.log("Not coming to finally block")
    dispatch(setGlobalLoader(false))
  }
 
}

export default CheckUserFromOAuth
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
const subscriptionKey = '0aa3523aa55d4a0a95c94aac3ab9f987';
const region = 'centralindia';
const translationApiKey = 'a41134eb2a5b4456b4b35e63fb66c922';

export const startSpeechRecognition = (onRecognized, onError) => {
  var autoDetectSourceLanguageConfig = SpeechSDK.AutoDetectSourceLanguageConfig.fromLanguages(["en-IN","hi-IN"]);
  console.log('autoDetectSourceLanguageConfig')
  const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(subscriptionKey, region);
  speechConfig.setProperty(SpeechSDK.PropertyId.Speech_SegmentationSilenceTimeoutMs, "3000")
  //speechConfig.speechRecognitionLanguage = 'ar-AE';

  const audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
  const recognizer = SpeechSDK.SpeechRecognizer.FromConfig(speechConfig, autoDetectSourceLanguageConfig, audioConfig);
  //const recognizer = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);

  recognizer.recognizeOnceAsync(result => {
    if (result.reason === SpeechSDK.ResultReason.RecognizedSpeech) {
      let languageDetectionResult = SpeechSDK.AutoDetectSourceLanguageResult.fromResult(result);
      let detectedLanguage = languageDetectionResult.language;
      console.log('detectedLanguage',detectedLanguage)
      onRecognized(result.text,detectedLanguage);
    } else {
      onError(result.errorDetails);
    }
  });

  return recognizer;
};

export const startSpeechRecognitionWithLang = (language, onRecognized, onError) => {
  const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(subscriptionKey, region);
  speechConfig.speechRecognitionLanguage = language === 'EN'?'en-IN':'hi-IN';
  speechConfig.setProperty(SpeechSDK.PropertyId.Speech_SegmentationSilenceTimeoutMs, "3000");
  const audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
  const recognizer = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);
  recognizer.recognizeOnceAsync(result => {
    if (result.reason === SpeechSDK.ResultReason.RecognizedSpeech) {
      onRecognized(result.text, language);
    } else {
      onError(result.errorDetails);
    }
  });
  return recognizer;
};


export const stopSpeechRecognition = (recognizer) => {
  if (recognizer) {
    recognizer.close();
  }
};

// export const stopSpeechRecognition = (recognizer) => {
//   recognizer.stopContinuousRecognitionAsync();
// };

// export const  translateAndSynthesize = async (transText,lang)=> {
//     try {
          
//         let xlang = "";
//         let voiceName="";
//         if(lang === 'arabic'){
//           xlang = "ar-AE"
//           voiceName = "ar-SA-ZariyahNeural";
//         }else{
//            voiceName = "en-IN-AnanyaNeural";
//             xlang = "en-US"
//         }

        
    
//         const ssmlData = `
//            <speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="${xlang}">
//              <voice name="${voiceName}">
//                 <mstts:express-as style="customerservice" >
//                 <prosody rate="1%" pitch="5%">${transText}</prosody>
//                 </mstts:express-as></voice>
//             </speak>`
//         // Synthesize SSML data
//         const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(subscriptionKey, region);
//         speechConfig.speechRecognitionLanguage = xlang 
//         const synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, undefined);
//         const result = await synthesizer.speakSsmlAsync(ssmlData);
//         console.info('Result Speech Synthesizer', result);
//         if (result?.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {
//             const audioBlob = result.audioData;
//             const audioUrl = URL.createObjectURL(new Blob([audioBlob], { type: 'audio/wav' }));
//            // localAudio.srcObject = audioUrl;
//             //return audioUrl;
//             const audio = new Audio(audioUrl);
//             audio.play();
//         }
//     } catch (error) {
//         console.error('Translate And Synthesize :: ',error);
//     }
    
// }

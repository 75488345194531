import PanditBookingView from "../../Views/PanditBooking/PanditBookingView";
import ParentView from "../../Views/PrarentView/ParentView";
function PanditBookingScreen() {
  return (
    <ParentView>
      <PanditBookingView />
    </ParentView>
  );
}

export default PanditBookingScreen;

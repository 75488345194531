import { Box, Typography, Button } from "@mui/material";
import sunrise from "../../../assets/sunrise.png";
import sunset from "../../../assets/sunset.png";
import { getDateToShow, getFormattedTimeForDate } from "../../../utils/HelperFunctions";
import moment from "moment";
export default function DailyDetailsCard(props: any) {
  // console.log("Panchang Card data",props.panchangData)
  const todayDate = moment().date();
  const fullDateData = moment().toString();
  const month = fullDateData.split(" ")[1];
  const year = fullDateData.split(" ")[3];
  // console.log("Today day india",todayDate.toString(),month)
  const sunriseTime = getFormattedTimeForDate(
    props.panchangData?.data?.sunrise
  );
  const sunsetTime = getFormattedTimeForDate(props.panchangData?.data?.sunset);
  const vara = props.panchangData?.response?.data?.vaara;
  const panchangBody = props.panchangData?.data;
  // console.log("Panchang data--->",panchangBody)
  const subhMuhurta =
    panchangBody &&
      panchangBody.auspicious_period &&
      panchangBody.auspicious_period.length
      ? panchangBody.auspicious_period[0].period[0]
      : null;
  const subhMuhurtaStart = getFormattedTimeForDate(subhMuhurta?.start);
  const subhMuhurtaEnd = getFormattedTimeForDate(subhMuhurta?.end);

  var inauspiciousMuhurta = { start: "", end: "" };
  if (
    panchangBody &&
    panchangBody.inauspicious_period &&
    panchangBody.inauspicious_period.length
  ) {
    const inauspiciousArray = panchangBody.inauspicious_period;
    for (let i = 0; i < inauspiciousArray.length; i++) {
      if (inauspiciousArray[i].name === "Rahu") {
        inauspiciousMuhurta = inauspiciousArray[i].period[0];
      }
    }
  }

  const inauspiciousMuhurtaStart = getFormattedTimeForDate(
    inauspiciousMuhurta?.start
  );
  const inauspicioushhMuhurtaEnd = getFormattedTimeForDate(
    inauspiciousMuhurta?.end
  );


  // const subhmuhurth = props.panchangData?.response?.data?.vaara
  return (
    <Box className="card featureCard" onClick={props.onClick}>
      <Box className="firstCardDes">
        <Box className="card-body">
          <Typography
            className="panchangTitle"
            sx={{
              background: `linear-gradient(-45deg, rgba(0, 0, 0, 0) 25%, hsla(0, 0%, 100%, 0.7) 50%, rgba(0, 0, 0, 0) 75%), 
            linear-gradient(-45deg, #d8a820, #d66f35)`,
              backgroundSize: "250% 250%, 100% 100%",
              animation: "gradient 15s ease infinite",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              display: "inline-block",
              fontWeight: "bold",
              '@keyframes gradient': {
                '0%': { backgroundPosition: '0% 50%' },
                '50%': { backgroundPosition: '100% 50%' },
                '100%': { backgroundPosition: '0% 50%' },
              },
            }}
          >
            Daily Panchang
          </Typography>
          <Box className="upperDes">
            <Box className="upperLeft">
              <Typography style={{ fontSize: "16px", fontWeight: "bold" }}>
                {todayDate}
                <sup>{getDateToShow(todayDate)?.subText}</sup>
              </Typography>
              <Typography>
                {month} {year},
              </Typography>
              <Typography>{vara}</Typography>
            </Box>
            <Box className="upperRight">
              <Typography style={{ fontSize: "16px", fontWeight: "bold" }}>
                Shubh Muhurta:
              </Typography>
              <Typography className="d-flex">
                {subhMuhurtaStart} to {subhMuhurtaEnd}
              </Typography>
              <Typography style={{ fontSize: "16px", fontWeight: "bold" }}>
                Rahukaal:
              </Typography>
              <Typography className="d-flex mb-0">
                {inauspiciousMuhurtaStart} to {inauspicioushhMuhurtaEnd}
              </Typography>
            </Box>
          </Box>

          <Box className="lowerDes">
            <Box className="sunrise">
              <img src={sunrise} alt="" />
              <Box className="cBlock">
                <Typography className="fwb">Sunrise</Typography>
                <Typography className="mb-0">{sunriseTime}</Typography>
              </Box>
            </Box>
            <Box className="sunset">
              <img src={sunset} alt="" />
              <Box className="cBlock">
                <Typography className="fwb">Sunset</Typography>
                <Typography className="mb-0">{sunsetTime}</Typography>
              </Box>
            </Box>
          </Box>
          {/* <Box className="viewMoreBtn">
            <Button variant="contained" onClick={props.onClick}>View More</Button>
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
}

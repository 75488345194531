import { useEffect, useState } from "react";
import MyOrdersView from "../../Views/MyOrders/MyOrdersView";
import { OrderService } from "td_server";
import { useSelector ,useDispatch} from "react-redux";
import { getUserOrder } from "../../store/actions/orderAction";
import ParentView from "../../Views/PrarentView/ParentView";
import ViewOrderDetailsModal from "../../components/modal/ViewOrderDetailsModel";

function MyOrdersScreen() {
  const dispatch: any = useDispatch();
  // const userDetails = useSelector((state: any) => state.AuthSlice.userDetails);
  const { userInfo } = useSelector((state: any) => state.user);
  const {userOrder} = useSelector((state: any) => state.order)
  const [isViewDetailsModalOpen, setIsViewDetailsModalOpen] = useState(false);
  const [selectedOrderId,setselectedOrderId] = useState(''); 
  const [ordersList,setOrdersList] = useState<any>([]); 
  // const currentUser = userDetails && userDetails.user ? userDetails.user : {};
  useEffect(() => {
    dispatch(getUserOrder(userInfo))
    .then((data:any)=>{
      console.log("Response order")
    })
    .catch((error:any) => {
      console.log("ERR in order",error);
    })
  }, [userInfo]);

  

  useEffect(()=>{
     const tdata:any =  [...userOrder];
     const fdata = tdata.sort((a:any, b:any) => new Date(b.orderBookingDate).getTime() - new Date(a.orderBookingDate).getTime());
     setOrdersList(fdata);
  },[userOrder])

  const OpenViewDetailsModal  = (orderId:string) => {
    setIsViewDetailsModalOpen(true);
    setselectedOrderId(orderId)
  }  
  const handleCloseViewDetailsModal = () => {
    setIsViewDetailsModalOpen(false);
    setselectedOrderId('')
 };

  return (
    <ParentView>
      <MyOrdersView orderHistory={ordersList} OpenViewDetailsModal={OpenViewDetailsModal}/>
      <ViewOrderDetailsModal isOpen={isViewDetailsModalOpen} onClose={handleCloseViewDetailsModal} orderId={selectedOrderId} />
    </ParentView>
    
  );
}

export default MyOrdersScreen;

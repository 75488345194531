import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ParentView from "../../Views/PrarentView/ParentView";
import HoroscopeDetailsView from "../../Views/Horoscope/HoroscopeDetailsView";
import { HoroscopeService } from "td_server";
import moment from "moment";
import { zodiacSign } from "../../utils/Constants";
import { setMessageToReactNative } from "../../utils/WebNativeFunctions";
const HoroscopeDetailsScreen = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState();
  const [horoscopeData, setHoroscopeData] = useState(null);
  const [selectedSign, setSelectedSign] = useState<string>("");
  const [selectedLanguage, setSelectedLanguage] = useState("english");
  const [imageData, setImageData] = useState(null);
  const [isTodaySelected, setIsTodaySelected] = useState(true);
  const [parentViewCheck, setParentViewCheck] = useState(true);
  const [selectedItem, setSelectedItem] = useState<any>(0);
  const [calcMoonSing, setcalcMoonSing] = useState<string>("");
  const getQueryParams = (search: string) => {
    return new URLSearchParams(search);
  };

  useEffect(() => {
    console.log("Location data", location.pathname);

    // Function to get query parameters

    // Get the zodiacName from the URL
    const queryParams = getQueryParams(location.search);
    const zName: any = queryParams.get("zodiacName")?.toLocaleLowerCase();
    setSelectedSign(zName);

    let signImageWxtract: any = zodiacSign.filter(
      (item: any) => item.key === zName
    );
    setSelectedItem(signImageWxtract[0]?.position);
    //-------------------------------------------------------

    const pathData = location.pathname;
    
    if (pathData.includes("nativeViews")) {
      props.setIsMobileView(true);
      setParentViewCheck(false);
    }


  }, [location]);

  const [urlToShare,setUrlToShare]  = useState<string>('')



  useEffect(() => {
    getHoroscopeDataForToDay();
    const hostname = window.location.hostname === 'localhost' ? 'http://localhost:3000' : 'https://'+ window.location.hostname;
    setUrlToShare(`${hostname}/pagelink?horoscopedetails=${selectedSign}`)
  }, [selectedSign]);

  const getHoroscopeData = async (date: any) => {
    const data = await HoroscopeService.getHoroscopeByDate(date);
    

    if (data && data.length > 0) {
      const payload = data[0];
      const zodiacData = payload?.horoscopes[selectedSign];
      
      setHoroscopeData(zodiacData);
      setSelectedDate(date);
    } else {
      setHoroscopeData(null);
    }
  };

  const changeLanguage = (lang: any) => {
    setSelectedLanguage(lang);
  };

  const getHoroscopeDataForToDay = () => {
    let todayDate = moment().local().format("DD-MM-YYYY");
    console.log("Today date", todayDate);
    getHoroscopeData(todayDate);
    setIsTodaySelected(true);
  };

  const getHoroscopeDataForTomorrow = () => {
    let tomorrowDate = moment().local().add(1, "day").format("DD-MM-YYYY");
    console.log("Tomorrow date", tomorrowDate);
    getHoroscopeData(tomorrowDate);
    setIsTodaySelected(false);
  };

  const navigateToSelectedSignIndex = (signIndex: any) => {
    if (parentViewCheck) {
      navigate(`/horoscopeDetails?zodiacName=${zodiacSign[signIndex].key}`);
    } else {
      navigate(
        `/nativeViews/horoscopeDetails?zodiacName=${zodiacSign[signIndex].key}`
      );
    }
  };
  const shareNativeAd = ()=>{ 
    console.log('shareNativeAd')
    const hostname = 'https://'+ window.location.hostname;
    const url = `${hostname}/pagelink?horoscopedetails=${selectedSign}`;
    const data = {value:url,isCategory:false}
    setMessageToReactNative(JSON.stringify(data))
  }
  const handleSubmit = async (date: string, name: string, city: any) => {
    setcalcMoonSing("");
    const pData = {
      dateOfBirth: date,
      latitude: city?.lat,
      longitude: city?.lng,
      language: "en",
    };
    const response = await HoroscopeService.getMoonSign(pData);
    if(response?.moonsign?.sign){
      setcalcMoonSing(response.moonsign.sign)
    }

  };
  const navigateToHoriscopeDetails = (sign: any) => {
    const pathData = location.pathname;
    if (pathData.includes("nativeViews")) {
      navigate("/nativeViews/horoscopeDetails?zodiacName=" + sign);
    } else {
      navigate("/horoscopeDetails?zodiacName=" + sign);
    }
  };

  
  return (
    <>
      {parentViewCheck ? (
        <ParentView>
          <HoroscopeDetailsView
            selectedSign={selectedSign}
            horoscopeData={horoscopeData}
            selectedLanguage={selectedLanguage}
            selectedDate={selectedDate}
            imageData={imageData}
            changeLanguage={changeLanguage}
            getHoroscopeDataForTomorrow={getHoroscopeDataForTomorrow}
            getHoroscopeDataForToDay={getHoroscopeDataForToDay}
            isTodaySelected={isTodaySelected}
            parentViewCheck={parentViewCheck}
            navigateToSelectedSignIndex={navigateToSelectedSignIndex}
            selectedItem={selectedItem}
            shareNativeAd={shareNativeAd}
            urlToShare={urlToShare}
            handleSubmit={handleSubmit}
            calcMoonSing={calcMoonSing}
            setcalcMoonSing={setcalcMoonSing}
            navigateToHoriscopeDetails={navigateToHoriscopeDetails}
          />
        </ParentView>
      ) : (
        <HoroscopeDetailsView
          selectedSign={selectedSign}
          horoscopeData={horoscopeData}
          selectedLanguage={selectedLanguage}
          selectedDate={selectedDate}
          imageData={imageData}
          changeLanguage={changeLanguage}
          getHoroscopeDataForTomorrow={getHoroscopeDataForTomorrow}
          getHoroscopeDataForToDay={getHoroscopeDataForToDay}
          isTodaySelected={isTodaySelected}
          parentViewCheck={parentViewCheck}
          navigateToSelectedSignIndex={navigateToSelectedSignIndex}
          selectedItem={selectedItem}
          shareNativeAd={shareNativeAd}
          urlToShare={urlToShare}
          handleSubmit={handleSubmit}
          calcMoonSing={calcMoonSing}
          setcalcMoonSing={setcalcMoonSing}
          navigateToHoriscopeDetails={navigateToHoriscopeDetails}
        />
      )}
    </>
  );
};

export default HoroscopeDetailsScreen;

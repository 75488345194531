import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';

import ParentView from '../../Views/PrarentView/ParentView';
import ErrorView from '../../Views/Error/ErrorView';
import { useLocation, useNavigate } from 'react-router-dom';


const ErrorScreen: React.FC = (props) => {
    const location = useLocation();
    const  [message,setMessage] = useState("")
    const navigate = useNavigate()
    useEffect(()=>{
        const data = location.state;
       if(data){
         setMessage(data?.message)
       }else{
         navigate("/");
       }
    },[location])

    return (
        <ParentView>
           {message.length > 0 && (<ErrorView message={message}></ErrorView>)} 
        </ParentView>
      );
};
export default ErrorScreen;

import ResetPasswordView from "../../Views/Auth/ResetPassword/ResetPasswordView";
function ResetPasswordScreen() {
  return (
    <div>
        <ResetPasswordView />
    </div>
  );
}

export default ResetPasswordScreen;

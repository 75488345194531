import React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import "./SocialMediaAdd.scss";

const SocialMediaAdd = () => {
  return (
    <Box className="socialMediaAddSec">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={3}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            className="socialMediaList"
            component="a"
            href="https://www.facebook.com/officialtempledekho/"
            target="_blank"
          >
            <FacebookIcon />
            <Box className="socialMediaText">
              <Typography variant="h6">
                Facebook
              </Typography>
              <Typography component="p">
                Chanel for Community
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            className="socialMediaList"
            component="a"
            href="https://www.linkedin.com/company/temple-dekho/"
            target="_blank"
          >
            <LinkedInIcon />
            <Box className="socialMediaText">
              <Typography variant="h6">
                Linkedin
              </Typography>
              <Typography component="p">
                Latest News & Update
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            className="socialMediaList"
            component="a"
            href="https://www.instagram.com/temple.dekho.official?igsh=dXowdDR2b2R2eWxv"
            target="_blank"
          >
            <InstagramIcon />
            <Box className="socialMediaText">
              <Typography variant="h6">
                Instagram
              </Typography>
              <Typography component="p">
                Latest News & Update
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            className="socialMediaList bNone"
            component="a"
            href="https://www.youtube.com/@TempleDekhoOfficial/"
            target="_blank"
          >
            <YouTubeIcon />
            <Box className="socialMediaText">
              <Typography variant="h6">
                Youtube
              </Typography>
              <Typography component="p">
                Latest News & Update
              </Typography>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SocialMediaAdd;

import {createSlice} from '@reduxjs/toolkit';

const orderSlice = createSlice({
  name: 'cart',
  initialState: {
    isLoading: false,
    error: null,
    orderList: [],
    userOrder: [],
    userDonation: [],
  },
  reducers: {
    setOrderList: (state, action) => {
      state.orderList = action.payload;
    },
    setUserOrderList: (state, action) => {
      state.userOrder = action.payload;
    },
    setUserDonationList: (state, action) => {
      state.userDonation = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    placeOrder: (state, action) => {
      state.orderList = action.payload;
    },
  },
});

export const {
  setOrderList,
  setUserOrderList,
  setLoading,
  setError,
  placeOrder,
  setUserDonationList,
} = orderSlice.actions;

export default orderSlice.reducer;

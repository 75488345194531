import "./PujaSubcategoriesView.scss";
import { Box, Grid, Typography, Button, Stack } from "@mui/material";
import PujaListCard from "../../components/card/Puja/PujaListCard";
import ShareLink from "../../components/sharelink/sharelink";
import { useEffect, useState } from "react";

function PujaSubCategoriesView(props: any) {
  console.log("Promotional puja props", props);
  const subCategoryDetails = props.subCategoryDetails;

  const [urlToShare,setUrlToShare]  = useState<string>('')

  useEffect(() => {
  
    const hostname = window.location.hostname === 'localhost' ? 'http://localhost:3000' : 'https://'+ window.location.hostname;
    setUrlToShare(`${hostname}/pagelink?subcategory=${props?.subCategoryDetails?.subCategoryId}`)
 
  }, [props.subCategoryDetails]);
  

  return (
    <Box className="pageContainer pujaSubCategory">
      <Box className="templeBlock">
        <Grid container spacing={1}>
          <Grid item xs={12} md={2} lg={3} xl={3} className="pagehdLeftBlock">
            {/* <LeftAddressCard
              header={props.TDTexts.CURRENTADDRESS}
              address={
                props.userAddressList && props.userAddressList.length > 0
                  ? props.userAddressList[0].formatted_address
                  : ""
              }
              footerButton="Edit or add an address"
            /> */}
          </Grid>
          <Grid item xs={12} md={8} lg={6} xl={6}>
            <Box className="pujaSubCategoryCenterBlock">
              {/* <Box
                className="promoPujaBanner"
                style={{
                  backgroundImage: `url(${props.subCategoryDetails?.url})`,
                }}
              ></Box> */}
              <Box className="catgIndivisualDetails">
                <Box className="bannerImgBlock">
                  <img
                    className="pujaSubCategoryBanner"
                    src={props.subCategoryDetails?.url}
                    alt={"Image"}
                  />
                </Box>
                <Box className="categoryDescription">
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    className="desTopTitle"
                  >
                    <Typography className="categoryTitle">
                      {subCategoryDetails?.name}
                    </Typography>
                    <Box className="shareIcon">
                      <ShareLink urlToShare={urlToShare}></ShareLink>
                    </Box>
                  </Stack>
                  <Typography>{subCategoryDetails?.description}</Typography>
                </Box>
              </Box>

              <Box className="categoryPujaTitle">
                <Typography variant="h6">Offering Pujas</Typography>
              </Box>

              <Box className="templeCenterBlock">
                {props.allPujaList && props.allPujaList.length > 0 ? (
                  props.allPujaList.map((item: any, id: any) => {
                    return (
                      <PujaListCard
                        pujaData={item}
                        pujaName={item.pujaDetails.pujaName}
                        templeProfilePic={item.pujaDetails.pujaBg?.uri}
                        description={item.pujaDetails.description}
                        benefits={item.pujaDetails.benefits}
                        acctualPrice={item.pujaDetails.acctualPrice}
                        onClickAddPuja={props.onClickAddPuja}
                        temple={item.personalInfo}
                        devoteeCount={item.pujaDetails?.maxMemberAllowed}
                        benefitList={item?.pujaDetails?.benefitList}
                        isPujaDisable={item?.pujaDetails?.isPujaDisable}
                      />
                    );
                  })
                ) : (
                  <Box className="noPujaItem">
                    <Typography>Puja not avaliable at this moment</Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={2} lg={3} xl={3} className="pagehdRightBlock">
            {/* <RightBlockCard /> */}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default PujaSubCategoriesView;

import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Button, Grid, Box, Divider, Tooltip, Typography, TextField } from '@mui/material';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';

import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    EmailIcon,
    XIcon,
    LinkedinIcon,
    LinkedinShareButton,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
} from 'react-share';
import InstagramIcon from '@mui/icons-material/Instagram';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MessengerIcon from '@mui/icons-material/Message';
interface ShareDialogProps {
    open: boolean;
    onClose: () => void;
    url: string;
}



const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ShareLinkDialog: React.FC<ShareDialogProps> = ({ open, onClose, url }) => {

    const handleCopyLink = () => {
        navigator.clipboard.writeText(url);
        // alert('Link copied to clipboard! Open Instagram and paste the link to share.');

        //For Copy Link Text
        navigator.clipboard.writeText(url).then(() => {
            setCopySuccess('Link copied!');
            setTimeout(() => setCopySuccess(''), 2000);
        });
    };

    const [copySuccess, setCopySuccess] = useState('');
    //End Copy Link Text

    const handleMessengerShare = () => {
        window.open(`fb-messenger://share?link=${encodeURIComponent(url)}`, '_blank');
    };


    return (
        <Dialog open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            keepMounted
            sx={{ maxWidth: "500px", margin: "auto" }}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                Share this Link
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
            >
                <CloseIcon />
            </IconButton>
            <Divider />
            <DialogContent sx={{ padding: "20px 24px 30px 24px" }}>
                <Grid container spacing={2} justifyContent="flex-start" textAlign="center">
                <Grid item md={4} xs={3}>
                    <FacebookShareButton url={url}>
                        <FacebookIcon size={32} round />
                        <Typography sx={{ fontSize: "14px", fontWeight: "500", marginTop: "6px", color: "#000" }}>Facebook</Typography>
                    </FacebookShareButton>
                </Grid>
                <Grid item md={4} xs={3}>
                    <TwitterShareButton url={url}>
                        <XIcon size={32} round />
                        <Typography sx={{ fontSize: "14px", fontWeight: "500", marginTop: "6px", color: "#000" }}>Twitter</Typography>
                    </TwitterShareButton>
                </Grid>
                <Grid item md={4} xs={3}>
                    <WhatsappShareButton url={url}>
                        <WhatsappIcon size={32} round />
                        <Typography sx={{ fontSize: "14px", fontWeight: "500", marginTop: "6px", color: "#000" }}>WhatsApp</Typography>
                    </WhatsappShareButton>
                </Grid>
                <Grid item md={4} xs={3}>
                    <EmailShareButton url={url}>
                        <EmailIcon size={32} round />
                        <Typography sx={{ fontSize: "14px", fontWeight: "500", marginTop: "6px", color: "#000" }}>Email</Typography>
                    </EmailShareButton>
                </Grid>
                <Grid item md={4} xs={3}>
                    <LinkedinShareButton url={url}>
                        <LinkedinIcon size={32} round />
                        <Typography sx={{ fontSize: "14px", fontWeight: "500", marginTop: "6px", color: "#000" }}>Linkedin</Typography>
                    </LinkedinShareButton>
                </Grid>
                <Grid item md={4} xs={3}>
                    <Tooltip title="Copy link to share on Instagram">
                        <IconButton sx={{ p: 0, display: "inline-block", fontSize: "1rem" }} onClick={handleCopyLink}>
                            <InstagramIcon style={{ fontSize: 32, color: '#E4405F' }} />
                            <Typography sx={{ fontSize: "14px", fontWeight: "500", marginTop: "6px", color: "#000" }}>Instagram</Typography>
                            {/* <ContentCopyIcon style={{ fontSize: 16, position: 'absolute', right: -10, top: 10 }} /> */}
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item md={12}>
                    <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', backgroundColor: "#ebebeb", borderRadius: "6px" }}>
                        <TextField
                            value={url}
                            variant="outlined"
                            size="small"
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        border: 'none',
                                    },
                                    '&:hover fieldset': {
                                        border: 'none',
                                    },
                                    '&.Mui-focused fieldset': {
                                        border: 'none',
                                    },
                                },
                            }}
                        />
                        <Tooltip title="">
                            <IconButton onClick={handleCopyLink} sx={{ '&:hover': { backgroundColor: 'transparent', } }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        boxShadow: 'none',
                                        '&:hover': {
                                            boxShadow: 'none',
                                        },
                                        '&:active': {
                                            boxShadow: 'none',
                                        },
                                        textTransform: "capitalize"
                                    }}
                                >
                                    Copy link
                                </Button>
                                {/* <ContentCopyIcon color="primary" /> */}
                            </IconButton>
                        </Tooltip>
                    </Box>
                    {copySuccess && (
                        <Typography sx={{ mt: 2, color: 'green' }}>
                            {copySuccess}
                        </Typography>
                    )}
                </Grid>




                {/* <Grid item> >
                            <FacebookMessengerIcon size={32} round  appid={}/>
                        </FacebookMessengerShareButton>
                    </Grid> */}



                {/* <Grid item>
                        <Tooltip title="Copy link to share on Instagram">
                            <IconButton onClick={handleCopyLink}>
                                <InstagramIcon style={{ fontSize: 32, color: '#E4405F' }} />
                                <ContentCopyIcon style={{ fontSize: 16, position: 'absolute', right: -10, top: 10 }} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title="Share on Messenger">
                            <IconButton onClick={handleMessengerShare}>
                                <MessengerIcon style={{ fontSize: 32, color: '#0078FF' }} />
                            </IconButton>
                        </Tooltip>
                    </Grid> */}
            </Grid>
        </DialogContent>
            {/* <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Close
                    </Button>
                </DialogActions> */}
        </Dialog >
    );
};

export default ShareLinkDialog;

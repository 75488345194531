import React, { useEffect, useState } from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Box,
  Grid,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Chip,
  Theme,
  useTheme,
  SelectChangeEvent,
  Stack,
  Autocomplete,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { TinyMCEEditor } from "../../../components/Editor/Editor";
import "./PostForm.scss";

import { APPROLES, postTypes } from "../../../utils/Constants";
import { useSelector } from "react-redux";
import { CheckIcon } from "@chakra-ui/icons";

type PostFormProps = {
  onSubmit: (data: any) => Promise<void>;
  postList: any;
  handleBack: () => void;
  editMode: string;
  postTypes: any;
};

const BlogPostForm: React.FC<PostFormProps> = ({
  onSubmit,
  handleBack,
  postList,
  editMode,
  postTypes,
}) => {
  const theme = useTheme();
  const [isEditMode, setIsEditMode] = useState<boolean>(true);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [localImage, setLocalImage] = useState<any>("");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const { userInfo } = useSelector((state: any) => state.user);
  const [userType, setuserType] = useState<string>("");
  const [formData, setFormData] = useState({
    id: "",
    title: "",
    description: "",
    shortDescription: "",
    metaDescription: "",
    metaTag: "",
    author: "",
    types: [],
    coverImageUrl: "",
    thumbImageUrl: "",
    showInHomePage: false,
    isPublished: false,
    readingTime: 1,
  });

  const [formErrors, setFormErrors] = useState({
    title: "",
    description: "",
    shortDescription: "",
    metaDescription: "",
    MetaTag: "",
    types: "",
    coverImageUrl: "",
    readingTime: "",
  });

  useEffect(() => {
    if (userInfo) {
      setuserType(userInfo?.userType);
    }
  }, [userInfo]);

  useEffect(() => {
    if (editMode === "edit" && postList) {
      setIsEditMode(false);
      // console.log("---From Edit---", postList);
      setFormData({
        id: postList.id,
        title: postList.title,
        description: postList.description,
        shortDescription: postList.shortDescription,
        metaDescription: postList.metaDescription,
        metaTag: postList.metaTag,
        author: postList.author,
        types: postList.types,
        coverImageUrl: postList.coverImageUrl,
        thumbImageUrl: postList.thumbImageUrl,
        showInHomePage: postList.showInHomePage,
        isPublished: postList.isPublished,
        readingTime: postList.readingTime,
      });
    }
  }, [postList, editMode]);

  // Access the loading state from Redux
  const { isLoading } = useSelector((state: any) => state.blogs);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked, files } = e.target;
    // console.log("---value---4754---",value)
    if (type === "file" && files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setLocalImage(e.target?.result);
      };
      reader.readAsDataURL(files[0]);

      setFormData((prev) => ({
        ...prev,
        imageFile: files[0],
      }));
      setFormErrors((prev: any) => ({ ...prev, coverImageUrl: "" }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));
      if (type !== "checkbox") {
        setFormErrors((prev: any) => ({ ...prev, [name]: "" }));
      }
    }
  };
  // const [blogType, setBlogType] = React.useState<string[]>([]);

  const handleChangeMultiSelect = (event: any, value: any) => {
    // const value = event.target.value;
    // console.log(`handleChangeMultiSelect`, value);
    // // const calculatedValue = typeof value === 'string' ? value.split(',') : value;
    // // setBlogType(calculatedValue)
    setFormData((prev: any) => ({
      ...prev,
      types: value,
    }));
    setFormErrors((prev: any) => ({ ...prev, types: "" }));
  };

  const handleSetDescription = (content: string) => {
    setFormData((prev) => ({
      ...prev,
      description: content,
    }));
    setFormErrors((prev: any) => ({ ...prev, description: "" }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    // console.log("FORM SUMBIT CALL FROM FORM");
    event.preventDefault();
    if (isSubmit) {
      return; // Exit early
    }
    const newErrors: any = {};
    if (formData?.title?.trim().length === 0)
      newErrors.title = "Title is required.";
    if (formData?.description?.length === 0)
      newErrors.description = "Description is required.";
    if (formData?.shortDescription?.trim().length === 0)
      newErrors.shortDescription = "Short Description is required.";
    if (formData?.metaDescription?.trim().length === 0)
      newErrors.metaDescription = "Short Description is required.";
    if (formData?.metaTag?.trim().length === 0)
      newErrors.metaTag = "Short Description is required.";
    if (formData.types.length < 1) newErrors.types = "Post Types is required.";
    if (localImage?.length === 0 && formData?.coverImageUrl?.length === 0) {
      newErrors.coverImageUrl = "Cover image URL is required.";
    }
    if (Object.keys(newErrors).length === 0) {
      setIsSubmit(true);
      onSubmit(formData);

      setFormErrors({
        title: "",
        description: "",
        shortDescription: "",
        metaDescription: "",
        MetaTag: "",
        types: "",
        coverImageUrl: "",
        readingTime: "",
      });
    }

    setFormErrors(newErrors);
  };

  const hostname =
    window.location.hostname === "localhost"
      ? "http://localhost:3000"
      : "https://" + window.location.hostname;

  const postLink = `${hostname}/pagelink?blogId=${postList?.id}`;

  const handleClickCopyClipboard = () => {
    navigator.clipboard
      .writeText(postLink)
      .then(() => {
        setOpenSnackbar(true);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleCloseSnackbar = (event: any) => {
    setOpenSnackbar(false);
  };

  return (
    <Box className="blogContainer">
      <Box className="blogHeader">
        <Typography variant="h4">
          <IconButton onClick={handleBack} color="primary">
            <KeyboardBackspaceIcon />
          </IconButton>
          {editMode === "edit" ? "Edit" : "Add"} Post
        </Typography>
      </Box>

      <form onSubmit={handleSubmit}>
        <Box mb={1}>
          <TextField
            label="Title *"
            variant="outlined"
            name="title"
            value={formData.title}
            onChange={handleChange}
            fullWidth
          />
          {formErrors.title && formErrors.title.length > 0 && (
            <Typography variant="body2" color="error">
              {`${formErrors?.title}`}
            </Typography>
          )}
        </Box>
        {editMode === "edit" && (
          <Box mb={3} mt={2}>
            <TextField
              label="Post Link"
              variant="outlined"
              value={postLink}
              onChange={handleChange}
              disabled
              fullWidth
            />
            <Button variant="contained" onClick={handleClickCopyClipboard}>
              Copy to Clipboard
            </Button>
          </Box>
        )}

        <Box mb={3} className="imageUpSec">
          <TextField
            name="coverImageUrl"
            label="Upload Image *"
            type="file"
            fullWidth
            margin="normal"
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              accept: "image/*",
            }}
          />
          {formErrors.coverImageUrl && formErrors.coverImageUrl.length > 0 && (
            <Typography variant="body2" color="error">
              {`${formErrors?.coverImageUrl}`}
            </Typography>
          )}
          {localImage?.length === 0 && formData?.coverImageUrl?.length > 0 && (
            <img src={formData?.coverImageUrl} />
          )}
          {localImage?.length > 0 && <img src={localImage} />}
        </Box>

        <Box mb={3} className="multiSelectItem">
          <FormControl fullWidth>
            <Autocomplete
              multiple
              freeSolo
              id="tags-outlined"
              options={postTypes}
              getOptionLabel={(option) => option}
              onChange={handleChangeMultiSelect}
              defaultValue={
                editMode === "edit"
                  ? [...postList?.types.map((it: any) => it)]
                  : []
              }
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Post Types *"
                  placeholder="Select Types"
                />
              )}
            />
            {formErrors.types && formErrors?.types?.length > 0 && (
              <Typography variant="body2" color="error">
                {`${formErrors?.types}`}
              </Typography>
            )}
          </FormControl>
        </Box>

        <Box mb={3}>
          <TextField
            label="Short Description *"
            variant="outlined"
            name="shortDescription"
            value={formData.shortDescription}
            onChange={handleChange}
            fullWidth
          />
          {formErrors.shortDescription &&
            formErrors.shortDescription.length > 0 && (
              <Typography variant="body2" color="error">
                {`${formErrors?.shortDescription}`}
              </Typography>
            )}
        </Box>

        <Box mb={3}>
          <TextField
            label="Meta Tag *"
            variant="outlined"
            name="MetaTag"
            value={formData.metaTag}
            onChange={handleChange}
            fullWidth
          />
          {formErrors.MetaTag && formErrors.MetaTag.length > 0 && (
            <Typography variant="body2" color="error">
              {`${formErrors?.MetaTag}`}
            </Typography>
          )}
        </Box>

        <Box mb={3}>
          <TextField
            label="Meta Description *"
            variant="outlined"
            name="MetaDescription"
            value={formData.metaDescription}
            onChange={handleChange}
            fullWidth
          />
          {formErrors.metaDescription &&
            formErrors.metaDescription.length > 0 && (
              <Typography variant="body2" color="error">
                {`${formErrors?.metaDescription}`}
              </Typography>
            )}
        </Box>

        <Box mb={3}>
          <TextField
            label="Reading Time"
            variant="outlined"
            name="readingTime"
            type="number"
            InputProps={{
              inputProps: {
                min: 1,
              },
            }}
            value={formData.readingTime}
            onChange={handleChange}
            fullWidth
          />
        </Box>

        <Box mb={2}>
          <TinyMCEEditor
            defaultValue={formData.description}
            onEditorChange={(content: string) => handleSetDescription(content)}
          />
          {formErrors.description && formErrors.description.length > 0 && (
            <Typography variant="body2" color="error">
              {`${formErrors?.description}`}
            </Typography>
          )}
        </Box>

        {(userType === APPROLES.SUPERADMIN.roleId ||
          userType === APPROLES.ADMIN.roleId) && (
          <>
            <Box mb={1} className="checkBoxArea">
              <FormControlLabel
                control={
                  <Checkbox
                    name="isPublished"
                    checked={formData.isPublished}
                    onChange={handleChange}
                  />
                }
                label="Is Published"
              />
            </Box>

            <Box mb={1} className="checkBoxArea">
              <FormControlLabel
                control={
                  <Checkbox
                    name="showInHomePage"
                    checked={formData.showInHomePage}
                    onChange={handleChange}
                  />
                }
                label="Show In Home Page"
              />
            </Box>
          </>
        )}

        <Stack
          className="bottomBtnArea"
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmit}
            startIcon={isSubmit && <CircularProgress size={20} />}
          >
            {isSubmit ? "Submitting..." : "Submit"}
          </Button>

          <Button variant="outlined" onClick={handleBack}>
            Cancel
          </Button>
        </Stack>
      </form>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={1000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          icon={<CheckIcon fontSize="inherit" />}
          severity="success"
          sx={{ width: "100%" }}
        >
          Text copied to clipboard!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default BlogPostForm;

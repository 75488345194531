import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ParentView from '../../Views/PrarentView/ParentView';
import TermsAndConditionsView from '../../Views/TermsAndConditions/TermsAndConditionsView';

const ContactUsScreen: React.FC = () => {
    useEffect(() => {

    }, []);

    return (
        <ParentView>
            <TermsAndConditionsView />
        </ParentView>
    );
};

export default ContactUsScreen;

/* eslint-disable @typescript-eslint/no-unused-vars */

import { BlogService } from "td_server";
import { getAllTempleList } from "./templeAction";
import { setAllPostBySelectedTypes, setAllPosts, setAllPostTypes, setAllPublishedPosts, setLoading,setSingleSelectedPost } from "../slices/BlogsSlice";

export const loading = () => async (dispatch: any, getState: any) => {
  try {
    dispatch(setLoading(true));
    return Promise.resolve("");
  } catch (e) {
    dispatch(setLoading(false));
    return Promise.reject("");
  }
};


export const createBlog =
  (data: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await BlogService.createBlog(data);
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getAllBlog = () => async (dispatch: any, getState: any) => {
  try {
    dispatch(setLoading(true));
    const response: any = await BlogService.getAllBlog();
    if (response && response.length > 0) {
      dispatch(setAllPosts(response));
    }
    dispatch(setLoading(false));
    return Promise.resolve(response);
  } catch (e) {
    dispatch(setLoading(false));
    return Promise.reject("");
  }
};

export const getAllBlogBYType = (types:any) => async (dispatch: any, getState: any) => {
  try {
    dispatch(setLoading(true));
    const response: any = await BlogService.getBlogsByTypes(types);
    if (response && response.length > 0) {
      dispatch(setAllPostBySelectedTypes(response));
    }
    dispatch(setLoading(false));
    return Promise.resolve(response);
  } catch (e) {
    dispatch(setLoading(false));
    return Promise.reject("");
  }
  
};

export const getAllPostTypes = () => async (dispatch: any, getState: any) => {
  try {
    dispatch(setLoading(true));
    const response: any = await BlogService.getAvailableTypeList();
    
    if (response && response.length > 0) {
      dispatch(setAllPostTypes(response));
    }
    dispatch(setLoading(false));
    return Promise.resolve(response);
  } catch (e) {
    dispatch(setLoading(false));
    return Promise.reject("");
  }
};

export const getAllPublishedBlog = () => async (dispatch: any, getState: any) => {
  try {
    dispatch(setLoading(true));
    const response: any = await BlogService.getAllPublishedBlog();
    if (response && response.length > 0) {
      dispatch(setAllPublishedPosts(response));
    }
    dispatch(setLoading(false));
    return Promise.resolve(response);
  } catch (e) {
    dispatch(setLoading(false));
    return Promise.reject("");
  }
};

export const updateBlog =
  ( id: string,data:any) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await BlogService.updateBlog(id,data)
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const deleteBlogPost =
  (id: string) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await BlogService.deleteBlogPost(id);
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };
  

  export const getBlogById = (id: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await BlogService.getBlogById(id);
      dispatch(setLoading(false));
      dispatch(setSingleSelectedPost(response)) 
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };
  export const countLikesOnBlog = (blogId: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await BlogService.countLikesOnBlog(blogId);
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

  export const addLikeToBlog = (blogId: any, userId: string) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await BlogService.addLikeToBlog(blogId,userId);
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

  export const hasUserLikedBlog = (blogId: any, userId: string) => async (dispatch: any, getState: any) => {
    // console.log(`hasUserLikedBlog---`,blogId,userId)
    try {
      dispatch(setLoading(true));
      const response: any = await BlogService.hasUserLikedBlog(blogId,userId);
      // console.log("------LL---", response);
      
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };
  export const removeLikeFromBlog = (blogId: any, userId: string) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await BlogService.removeLikeFromBlog(blogId,userId);
      // console.log("------LL---", response);
      
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };
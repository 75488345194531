/* eslint-disable @typescript-eslint/no-unused-vars */

import { AiGurujiChatService } from "td_server";
import {
  setLoading,
  setChatByUserId,
  startDownload,
  finishDownload,
} from "../slices/gurujiChatSlice";
import { getDynamicPdfFileName } from "../../utils/HelperFunctions";
import { setMessageToReactNative } from "../../utils/WebNativeFunctions";

export const loading = () => async (dispatch: any, getState: any) => {
  try {
    dispatch(setLoading(true));
    return Promise.resolve("");
  } catch (e) {
    dispatch(setLoading(false));
    return Promise.reject("");
  }
};

export const createChat =
  (userId: any, data: any, userPhone: any) =>
  async (dispatch: any, getState: any) => {
    try {
      console.log(`createChat --- `);
      dispatch(setLoading(true));
      const response: any = await AiGurujiChatService.createAIChatByUserId(
        userId,
        data,
        userPhone
      );
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const updateChat =
  (userId: any, chatHistory: any, currentPayload: any) =>
  async (dispatch: any, getState: any) => {
    console.log(`updateChat --- `);
    try {
      dispatch(setLoading(true));
      const response: any = await AiGurujiChatService.updateAIChatByChatId(
        userId,
        chatHistory,
        currentPayload
      );
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getChatByUserId =
  (userId: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await AiGurujiChatService.getAIChatByUserId(userId);
      console.log(`Action ------getChatByUserId--------`, response);
      if (response) {
        dispatch(setChatByUserId(response));
      }
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      console.log(`Action ------getChatByUserId-------- err`, e);
      dispatch(setChatByUserId(null));
      dispatch(setLoading(false));
      return Promise.reject(null);
    }
  };

export const deleteChat =
  (id: string) => async (dispatch: any, getState: any) => {
    console.error(`deleteChat`, id);
    try {
      dispatch(setLoading(true));
      const response: any = await AiGurujiChatService.deleteAIChatByChatId(id);
      dispatch(setLoading(false));
      dispatch(setChatByUserId(null));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const downloadGurujiChatPDF =
  (payload: any, isMobileView: boolean) =>
  async (dispatch: any, getState: any) => {
    dispatch(startDownload());
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic dGVtcGxlZGVraG9hcGl1c2VyOnhjdEAzNGFzMTF6Y3Y="
      );

      myHeaders.append("Content-Type", "application/json");
      const raw = JSON.stringify(payload);
      const requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: raw,
      };

      const hostname =
        window.location.hostname === "localhost"
          ? "https://apnamandir-test.azurewebsites.net"
          : "https://" + window.location.hostname;
      fetch(`${hostname}/td/v2/dataToPdf/getAiGuruJiChatPdf`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);

          if (isMobileView && !data.isError) {
            setMessageToReactNative(
              JSON.stringify({ isChatPayoad: true, pdfUrl: data?.pdfUrl })
            );
            dispatch(finishDownload());
            return;
          }
          window.location.href = data?.pdfUrl;
          dispatch(finishDownload());
        })
        .catch((error) => console.error("Error:", error));
    } catch (e) {
      dispatch(finishDownload());
      return Promise.reject("");
    }
  };

import { Box, Button, ButtonGroup, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import React from 'react';
import "./AIGurujiHeader.scss";

const AIGurujiHeader: React.FC<any> = (props) => {
    return (
        <Box className="aiGurujiHeader">
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                className="aiGurujiHeaderContent"
            >
                <Box className="aiGurujiHeaderLeft">
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        className="headerLeftInfo"
                    >
                        <IconButton
                            aria-label="close"
                            className="topCloseIconBtn"
                            onClick={e=>{e.preventDefault();props.setHandleClose()}}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography className="leftTitle">Valmiki <small> - Your own AI Guruji. <span className='betaText'>Beta.</span></small> </Typography>
                        
                    </Stack>
                </Box>
                <Box className="aiGurujiHeaderRight">
                {/* <IconButton
                            aria-label="close"
                            className="topCloseIconBtn"
                            onClick={e=>{e.preventDefault();props.setHandleClose()}}
                        >
                            <CloseIcon />
                        </IconButton> */}
                        
                    <Box className="langSelect">
                        <ButtonGroup
                            variant="outlined"
                            aria-label="Basic button group"
                        >
                            <Button
                               variant={props.selectedLang === "EN" ? "contained" : "outlined"}
                                onClick={(e) => {e.preventDefault(); props.handleLanguageSwitch("EN")}}
                                sx={{cursor:"pointer"}}
                            >
                                A
                            </Button>
                            <Button
                               variant={props.selectedLang === "HI" ? "contained" : "outlined"}
                                onClick={(e) => {e.preventDefault(); props.handleLanguageSwitch("HI")}}
                                sx={{cursor:"pointer"}}
                            >
                                अ
                            </Button>
                        </ButtonGroup>
                    </Box>
                </Box>
            </Stack>

        </Box>
    )
}

export default AIGurujiHeader;
import {createSlice} from '@reduxjs/toolkit';

const gurujiChatSlice = createSlice({
  name: 'gurujichat',
  initialState: {
    isLoading: false,
    error:"",
    userChat:null,
    isDownloading:false
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setChatByUserId: (state, action) => {
      state.userChat = action.payload;
    },
    startDownload: (state) => {
      state.isDownloading = true; 
    },
    finishDownload: (state) => {
      state.isDownloading = false; 
    },
    
  },
});

export const {
  setLoading,
  setError,
  setChatByUserId,
  startDownload,
  finishDownload
  
} = gurujiChatSlice.actions;

export default gurujiChatSlice.reducer;

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ParentView from "../../Views/PrarentView/ParentView";
import EventView from "../../Views/Event/EventView";

const EventScreen = (props: any) => {
  const [parentViewCheck, setParentViewCheck] = useState(true);
  const location = useLocation();
  useEffect(() => {
    console.log("Location data", location.pathname);

    const pathData = location.pathname;

    if (pathData.includes("nativeViews")) {
      props.setIsMobileView(true);
      setParentViewCheck(false);
    }
  }, [location]);

  console.log("data in PP",parentViewCheck)

  return (
    <>
      {parentViewCheck ? (
        <ParentView>
          <EventView parentViewCheck={parentViewCheck} />
        </ParentView>
      ) : (
        <EventView parentViewCheck={parentViewCheck}/>
      )}
    </>
  );
};

export default EventScreen;

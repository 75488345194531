import like from "../../../assets/like.png";
import message from "../../../assets/message.png";
import share from "../../../assets/share.png";
import redlike from "../../../assets/redlike.png";
import verifytick from "../../../assets/verify-tick.png";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  UserService,
  TempleService,
  PanchangService,
  PostService,
} from "td_server";
import PostViewer from "./PostViewer";
import Carousel from "react-multi-carousel";
import { Box } from "@mui/material";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
export default function PostCard(props: any) {
  const [showAddCommentInput, setShowAddCommentInput] = useState(false);
  const userDetails = useSelector((state: any) => state.AuthSlice.userDetails);
  const [commentText, setCommentText] = useState("");
  const [numberComments, setNumberOfComments] = useState(
    props.postData.numberOfComments
  );
  const [numberLikes, setNumberOfLikes] = useState(
    props.postData.numberOfLikes
  );
  const [checkIfUserAlreadyLiked, setCheckIfUserAlreadyLiked] = useState(false);
  const ppUrl =
    userDetails &&
    userDetails.user &&
    userDetails.user.personalInfo &&
    userDetails.user.personalInfo.profilePictureUrl
      ? userDetails.user.personalInfo.profilePictureUrl
      : "";
  const userFName =
    userDetails &&
    userDetails.user &&
    userDetails.user.personalInfo &&
    userDetails.user.personalInfo.firstName
      ? userDetails.user.personalInfo.firstName
      : "";
  const userLName =
    userDetails &&
    userDetails.user &&
    userDetails.user.personalInfo &&
    userDetails.user.personalInfo.lastName
      ? userDetails.user.personalInfo.lastName
      : "";

  useEffect(() => {
    PostService.checkUserLikedPost(props.postData.id, userDetails.user.id).then(
      (response: any) => {
        // console.log("response from if user already checked the post", response);
        setCheckIfUserAlreadyLiked(response);
      }
    );
  }, []);
  const addCommentToPost = () => {
    let payload = {
      postId: props.postData.id,
      commentData: {
        userId: userDetails.user.id,
        userName: userFName + " " + userLName,
        comment: commentText,
        userImageUrl: ppUrl,
        reply: [],
      },
      updateType: "comment",
    };

    console.log("comment payload", payload);
    PostService.updatePost(payload, "comment").then((response: any) => {
      console.log("comment update response", response);
      setShowAddCommentInput(false);
      setNumberOfComments(numberComments + 1);
    });

  };

  const addLikeToPost = () => {
    if (!checkIfUserAlreadyLiked) {
      let payload = {
        postId: props.postData.id,
        likeData: {
          userId: userDetails.user.id,
          userName: userFName + " " + userLName,
          reactionType: "love",
          userImageUrl: ppUrl,
        },
        updateType: "like",
      };

      console.log("Like payload", payload);
      // setNumberOfLikes(numberLikes+1)
      PostService.updatePost(payload, "like").then((response: any) => {
        console.log("Like update response", response);
        setNumberOfLikes(numberLikes + 1);
      });
    } else {
      console.log("you have already liked the post");
    }
  };

  const manageLikesCountMessage = () => {
    if (checkIfUserAlreadyLiked) {
      return "You and " + numberLikes + " others";
    } else {
      return numberLikes;
    }
  };
  const getNormalDate = () => {
    let sec =
      props.postData &&
      props.postData.postDetails &&
      props.postData.postDetails.postDateTime &&
      props.postData.postDetails.postDateTime.seconds
        ? props.postData.postDetails.postDateTime.seconds
        : "0";
    let normalDate = new Date(sec * 1000).toLocaleString();

    return normalDate;
  };

  const responsive3 = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Box className="recentPostBox">
      <Box className="postTop">
        <Box className="postProfile">
          <img src={props.postProfileImage} alt="" />
        </Box>
        <Box className="postTitle">
          <h6>
            {props.postUserName} <img src={verifytick} alt="" />
          </h6>
          <p>{getNormalDate()}</p>
        </Box>
      </Box>
      <Box className="postText">
        <p>{props.postDescription}</p>
      </Box>
      <Box className="postImage" style={{width:"600px",height:"500px"}}>
        {/* Image and video posts */}
        <Carousel
          responsive={responsive3}
          swipeable={true}
          draggable={true}
          showDots={true}
          keyBoardControl={true}
          removeArrowOnDeviceType={["tablet", "mobile"]}
        >
          {props.postData.postMedia.map((item: any, id: any) => {
            return (
              <PostViewer
                key={id}
                fileType={item.postMediaType}
                url={item.postMediaFileLink}
              />
            );
          })}
        </Carousel>
      </Box>
      <Box className="card-body">
        <Box className="content">
          <Box className="icons">
            <ul>
              <li onClick={() => addLikeToPost()}>
                <Link to="/">
                  <img src={like} alt="" />
                </Link>
              </li>
              <li onClick={() => setShowAddCommentInput(!showAddCommentInput)}>
                <Link to="/">
                  <img src={message} alt="" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src={share} alt="" />
                </Link>
              </li>
            </ul>
          </Box>
          <Box
            onClick={() => {
              console.log("clicked comments");
              props.setShowCommentForPost({
                shouldOpened: true,
                postId: props.postData.id,
              });
            }}
          >
            {numberComments} comment
          </Box>
          <Box className="likeText">
            <p>
              {manageLikesCountMessage()}
              {/* {checkIfUserAlreadyLiked?"You and":""}{numberLikes} */}
              <img src={redlike} alt="" />
            </p>
          </Box>
        </Box>
      </Box>
      {showAddCommentInput ? (
        <Box className="addComment ">
          <img src={ppUrl} style={{ width: "25px", height: "25px" }}></img>
          <input onChange={(e) => setCommentText(e.target.value)}></input>
          <Button onClick={() => addCommentToPost()}>Post</Button>
        </Box>
      ) : null}
    </Box>
  );
}

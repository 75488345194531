import {createSlice} from '@reduxjs/toolkit';

const BlogSlice = createSlice({
  name: 'blogs',
  initialState: {
    isLoading: false,
    AllPosts:[],
    allPublishedPosts:[],
    error:"",
    singleSelectedPost:"",
    allPostBySelectedTypes: [],
    allPostTypes:[],
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setAllPosts: (state, action) => {
      state.AllPosts = action.payload;
    },
    setAllPublishedPosts: (state, action) => {
      state.allPublishedPosts = action.payload;
    },  
    setSingleSelectedPost: (state, action) => {
      state.singleSelectedPost = action.payload;
    },
    setAllPostBySelectedTypes: (state,action)=>{
      state.allPostBySelectedTypes = action.payload;
    },
    setAllPostTypes: (state,action)=>{
      state.allPostTypes = action.payload;
    }
  },
});

export const {
  setLoading,
  setError,
  setAllPosts,
  setAllPublishedPosts,
  setSingleSelectedPost,
  setAllPostBySelectedTypes,
  setAllPostTypes,
} = BlogSlice.actions;

export default BlogSlice.reducer;

import React from 'react';
import { Box, Typography } from '@mui/material';
import "./AIGurujiPrediction.scss";

const AIGurujiPrediction: React.FC<any> = ({ predictionText }) => {
  return (
    <Box className="gurujiPredictionContent">
      <Typography className="predictionTitle">Astrological Prediction Summary</Typography>
      <Box className="predictionCard">
        <Typography sx={{ whiteSpace: "pre-line" }}>{predictionText}</Typography>
      </Box>
    </Box>
  );
};

export default AIGurujiPrediction;
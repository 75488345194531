import { Box, Grid, Typography, Button } from "@mui/material";
import templeEmoji from "../../../assets/temple-emoji.png";
import pujaEmoji from "../../../assets/puja-emoji.png";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

export default function CartNoItem(props: any) {
  const navigate = useNavigate();

  return (
    <Box className="noItemCart">
      <Typography className="noItemText">No items added in cart. </Typography>
      <Box className="redirectBtn">
        <Button variant="outlined" onClick={() => navigate("/temples")}>Explore temples <img src={templeEmoji} alt="" /></Button>
        <Button variant="outlined" onClick={() => navigate("/pujas")}>Explore pujas <img src={pujaEmoji} alt="" /></Button>
      </Box>
    </Box>
  );
}

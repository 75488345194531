import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import AIGurujiModal from "./AIGurujiModal/AIGurujiModal";
import AIGurujiChatBackground from "./AIGurujiChatBackground/AIGurujiChatBackground";
import AIGurujiBirthChart from "./AIGurujiChatItem/AIGurujiBirthChart/AIGurujiBirthChart";
import AIGurujiCalculationChart from "./AIGurujiChatItem/AIGurujiCalculationChart/AIGurujiCalculationChart";
import AIGurujinumerologicalpredictions from "./AIGurujiChatItem/AIGurujinumerologicalpredictions/AIGurujinumerologicalpredictions";
import AIGurujinumerologic from "./AIGurujiChatItem/AIGurujinumerologic/AIGurujinumerologic";
import AIGurujiBirthChartForm from "./AIGurujiChatItem/AIGurujiBirthChartForm/AIGurujiBirthChartForm";
import "./AIGurujiView.scss";
import AIGurujiValmikiImg from "./AIGurujiChatItem/AIGurujiValmikiImg/AIGurujiValmikiImg";
import AIGurujiReligiousChat from "./AIGurujiChatItem/AIGurujiReligiousChat/AIGurujiReligiousChat";
import AIGurujitext from "./AIGurujiChatItem/AIGurujitext/AIGurujitext";
import AIGurujitextSender from "./AIGurujiChatItem/AIGurujitextSender/AIGurujitextSender";
import AIGurujiReportStringToTable from "./AIGurujiChatItem/AIGurujiReportStringToTable/AIGurujiReportStringToTable";
import AIGurujiError from "./AIGurujiChatItem/AIGurujiError/AIGurujiError";
import AIGurujiReligiousRowChat from "./AIGurujiChatItem/AIGurujiReligiousChat/AIGurujiReligiousRowChat";
import AIGurujiRashiCard from "./AIGurujiChatItem/AIGurujiRashiCard/AIGurujiRashiCard";
import AIGurujiPrediction from "./AIGurujiChatItem/AIGurujiPrediction/AIGurujiPrediction";
import AIGurujiExportButton from "./AIGurujiChatItem/AIGurujiExportButton/AIGurujiExportButton";

interface AIGurujiView {
  open: boolean;
  closeModal: () => void;
  onLanguageSwitch: any;
  chatMessage: any[];
  onMenuSelect: any;
  sendInpute: any;
  isLoading: boolean;
  isChatInputBoxDisebal: boolean;
  transcribeAudio: any;
  calculateNumerology: any;
  calculateHoroscope: any;
  selectedLang: string;
  isPlayingAudioId: any;
  handleBackToMainMenu: any;
  handleEditForm: any;
  availableButton: any;
  selectedServices: any;
  isPlaying: boolean;
  handleDownload: any;
  isDownloading: boolean;
  exportChatId: any;
  isMobileView: boolean;
}

const AINumerology: React.FC<any> = ({
  chat,
  handleScrollTobirthChartForm,
}) => {
  const data = chat?.formateResponse || null;
  return (
    <>
      {data && (
        <>
          <AIGurujinumerologicalpredictions
            responseText={data.response_text}
            numberCalculations={data.number_calculations}
          />

          {data.cards_section?.map((item: any, index: number) => (
            <AIGurujinumerologic key={index} cardsSection={item} />
          ))}
          <AIGurujiCalculationChart
            currentPayload={chat?.currentPayload}
            id={chat.id}
            handleScrollTobirthChartForm={handleScrollTobirthChartForm}
          />
        </>
      )}
    </>
  );
};

const AIHoroscope: React.FC<any> = ({ data, handleScrollTobirthChartForm }) => {
  return (
    <>
      <AIGurujiBirthChart
        birthChartUrl={data?.birth_chart_url}
        text="Birth Chart"
      />
      <AIGurujiBirthChart
        birthChartUrl={data?.lagna_chart_url}
        text="Lagan Chart"
      />
      <AIGurujiRashiCard sunSign={data?.sun_sign} moonSign={data?.moon_sign} />

      {data?.final_table_report &&
        data?.final_table_report.map((item: any) => (
          <AIGurujiReportStringToTable
            tableName={item?.name}
            reportString={item?.tableValue}
          />
        ))}

      <AIGurujiCalculationChart
        currentPayload={data?.currentPayload}
        id={data.id}
        handleScrollTobirthChartForm={handleScrollTobirthChartForm}
      />
      <AIGurujiPrediction predictionText={data?.response} />

      {/* <AIGurujiPositionTable /> */}
    </>
  );
};

const AIGurujiView: React.FC<AIGurujiView> = ({
  open,
  closeModal,
  onLanguageSwitch,
  chatMessage,
  onMenuSelect,
  sendInpute,
  isLoading,
  transcribeAudio,
  isChatInputBoxDisebal,
  calculateNumerology,
  calculateHoroscope,
  selectedLang,
  isPlayingAudioId,
  handleBackToMainMenu,
  handleEditForm,
  availableButton,
  selectedServices,
  isPlaying,
  handleDownload,
  isDownloading,
  exportChatId,
  isMobileView,
}) => {
  const birthChartFormRef = useRef<HTMLDivElement | null>(null);

  const handleScrollTobirthChartForm = (id: any) => {
    handleEditForm(id);
  };

  console.log(`chatMessages`, chatMessage);

  const boxRef = useRef<any>(null);
  const [matchedExport, setMatchedExport] = useState<any>(null);

  useEffect(() => {
    // Call scroll to last item function
    scrollToLastItem();

    if (chatMessage && chatMessage.length > 3) {
      let updatedExport: any = matchedExport || {};

      const newExport = chatMessage.reduce(
        (acc: any, _chatItem: any, _index: number) => {
          if (_chatItem?.service && _chatItem?.type === "receive") {
            const service = _chatItem?.service;

            // If the service hasn't been seen before or doesn't have a start
            if (!acc[service]) {
              acc[service] = { start: _index, end: -1 };
            } else {
              // If the service exists, we can update the end index
              acc[service].end = _index;
            }
          }
          return acc;
        },
        {}
      );

      // Only update if the newExport has changed
      if (JSON.stringify(updatedExport) !== JSON.stringify(newExport)) {
        setMatchedExport(newExport);
      }
    }
  }, [chatMessage]);

  useEffect(() => {
    if (matchedExport) {
      console.log("matchedExport\n", matchedExport);
    }
  }, [matchedExport]);

  const scrollToLastItem = () => {
    if (boxRef.current) {
      const children = boxRef.current.children;

      if (children.length > 0) {
        const lastItem = children[children.length - 1];
        const lastItemRect = lastItem.getBoundingClientRect();
        const boxRect = boxRef.current.getBoundingClientRect();

        // Scroll to the last item
        lastItem.scrollIntoView({ behavior: "smooth", block: "start" });

        // Adjust scroll position if the last item is taller than the chat box
        const offset = lastItemRect.height - boxRect.height;
        if (offset > 0) {
          boxRef.current.scrollBy({
            top: offset,
            behavior: "smooth",
          });
        }
      }
    }
  };

  return (
    <AIGurujiModal openDialog={open}>
      <AIGurujiChatBackground
        setHandleClose={closeModal}
        sendInpute={sendInpute}
        isLoading={isLoading}
        isChatInputBoxDisebal={isChatInputBoxDisebal}
        selectedLang={selectedLang}
        handleLanguageSwitch={onLanguageSwitch}
        chatMessageLength={chatMessage.length}
        handleBackToMainMenu={handleBackToMainMenu}
        lastChatItem={chatMessage[chatMessage.length - 1]}
        handleDownload={handleDownload}
        isMobileView={isMobileView}
      >
        <Box className="chatBackgroundInfo">
          <Box className="valmikiImage">
            <AIGurujiValmikiImg />
          </Box>
          {/* {!selectedLang && (
            <AIGurujiLanguageSelect onLanguageSwitch={onLanguageSwitch} />
          )} */}
          <div ref={boxRef}>
            {chatMessage &&
              chatMessage?.map((chat: any, index = 0) => {
                const isDisabled = chatMessage.length !== index + 1;
                return (
                  <>
                    {chat?.available_actions &&
                      chat?.available_actions?.length && (
                        <AIGurujiReligiousChat
                          onMenuSelect={onMenuSelect}
                          availableActions={chat.available_actions}
                          key={`AIGurujiReligiousChat ${index}`}
                          isDisabled={isDisabled}
                          direction="coloum"
                          selectedServices={selectedServices}
                        />
                      )}
                    {chat?.contentType === "text" &&
                      chat?.type === "receive" &&
                      chat?.response && (
                        <AIGurujitext
                          text={chat?.response}
                          transcribeAudio={transcribeAudio}
                          time={chat?.currentTime}
                          id={chat?.id}
                          isPlayingAudioId={isPlayingAudioId}
                          isPlaying={isPlaying}
                        />
                      )}
                    {chat?.contentType === "error" &&
                      chat?.type === "receive" &&
                      chat?.response && (
                        <AIGurujiError
                          text={chat?.response}
                          transcribeAudio={transcribeAudio}
                          time={chat?.currentTime}
                          id={chat?.id}
                          isPlayingAudioId={isPlayingAudioId}
                          isPlaying={isPlaying}
                        />
                      )}
                    {chat?.contentType === "text" &&
                      chat?.type === "send" &&
                      chat?.response && (
                        <AIGurujitextSender
                          text={chat?.response}
                          time={chat?.currentTime}
                        />
                      )}
                    {chat?.contentType === "menu" &&
                      chat?.service === "ai_numerology" && (
                        <AIGurujiBirthChartForm
                          buttonName="Numerology"
                          handleSubmitForm={calculateNumerology}
                          ref={birthChartFormRef}
                          isDisabled={isDisabled}
                          selectedLang={selectedLang}
                          formFilledData={
                            chat?.formData ? chat?.formData : null
                          }
                        />
                      )}

                    {chat?.contentType === "json" &&
                      chat?.service === "ai_numerology" && (
                        <AINumerology
                          chat={chat}
                          handleScrollTobirthChartForm={
                            handleScrollTobirthChartForm
                          }
                        />
                      )}

                    {chat?.contentType === "menu" &&
                      chat?.service === "ai_horoscope" && (
                        <AIGurujiBirthChartForm
                          buttonName="Horoscope"
                          handleSubmitForm={calculateHoroscope}
                          ref={birthChartFormRef}
                          isDisabled={isDisabled}
                          selectedLang={selectedLang}
                          formFilledData={
                            chat?.formData ? chat?.formData : null
                          }
                        />
                      )}

                    {chat?.contentType === "json" &&
                      chat?.service === "ai_horoscope" && (
                        <AIHoroscope
                          data={chat}
                          handleScrollTobirthChartForm={
                            handleScrollTobirthChartForm
                          }
                        />
                      )}
                    {chat.service &&
                    matchedExport &&
                    matchedExport[chat.service] &&
                    matchedExport[chat.service].end === index ? (
                      <AIGurujiExportButton
                        handleDownload={() =>
                          handleDownload(chat, matchedExport)
                        }
                        isDownloading={
                          (isDownloading && exportChatId === chat?.id) || false
                        }
                      />
                    ) : null}
                  </>
                );
              })}
          </div>
          {/* <AIGurujiNakshatra/> */}

          {availableButton && availableButton?.length > 0 && (
            <AIGurujiReligiousRowChat
              onMenuSelect={onMenuSelect}
              availableActions={availableButton}
              key={`AIGurujiReligiousChat`}
              isDisabled={false}
              direction="row"
              selectedServices={selectedServices}
            />
          )}
        </Box>
      </AIGurujiChatBackground>
    </AIGurujiModal>
  );
};

export default AIGurujiView;

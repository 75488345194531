import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Navigate } from "react-router-dom";
import { getAllTempleList } from "../../store/actions/templeAction";
import { getAllOrder } from "../../store/actions/orderAction";
import { getAllUsers } from "../../store/actions/userAction";
import AdminView from "../../Views/Admin/AdminView";
import {
  getDateFormatedOrderTime,
  getDateFormatedForBookingDate,
  splitCamelCase,
} from "../../utils/HelperFunctions";
import ParentView from "../../Views/PrarentView/ParentView";

function AdminScreen() {
  const navigate = useNavigate();
  const dispatch:any = useDispatch();
  const { allTempleList } = useSelector((state: any) => state.temple);
  const { allNormalUsers } = useSelector((state: any) => state.user);
  const { userOrder } = useSelector((state: any) => state.order);
  const [flatOrderList, setFlatOrderList] = useState<any>([]);
  const [exportOrderList, setExportOrderList] = useState<any>([]);
  const [exportUserList, setExportUserList] = useState<any>([]);


  


 useEffect(() => {
  let exportUserList: any = [];
  if (allNormalUsers && allNormalUsers.length > 0) {
    allNormalUsers.map((user: any, index: number) => {
      let itemOfFlat: any = {};
      itemOfFlat.id = index;
      itemOfFlat.docId = user.id;
      itemOfFlat.userType = user.userType
      itemOfFlat.name = user.personalInfo.firstName +" "+user.personalInfo.lastName;
      itemOfFlat.gender = user?.personalInfo?.gender;
      itemOfFlat.dateOfBirth = user?.personalInfo?.dateOfBirth;
      itemOfFlat.phoneNumber = user?.phoneNumber
      itemOfFlat.email = user?.personalInfo?.email
      itemOfFlat.address = user?.personalInfo?.address?.length > 0  ? user?.personalInfo?.address : " "
      itemOfFlat.userCreatedAt = user.userCreatedAt
      exportUserList.push(itemOfFlat)
    })
     setExportUserList(exportUserList)
  }





 }, [allNormalUsers])


  useEffect(() => {
    if (userOrder && userOrder.length > 0) {

      let flatList: any = [];
      let exportList: any = [];
      userOrder.map((userOdItem: any, index: number) => {
        let itemOfFlat: any = {};
        itemOfFlat.id = index;
        itemOfFlat.docId = userOdItem.id;
        itemOfFlat.templeName = userOdItem?.templeDetails?.name;
        itemOfFlat.orderId = userOdItem?.orderId;
        // itemOfFlat.orderStatus =
        //   userOdItem?.orderStatus[
        //     userOdItem?.orderStatus.length - 1
        //   ]?.orderStatus;
        itemOfFlat.awbNumber =
          userOdItem?.orderStatus[
            userOdItem?.orderStatus.length - 1
          ]?.awbNumber;
        itemOfFlat.pujaDate = getDateFormatedForBookingDate(
          userOdItem?.pujaDate
        );

        itemOfFlat.orderUserName = userOdItem?.orderUserDetails?.orderUserName;
        itemOfFlat.phoneNumber = userOdItem?.orderUserDetails?.phoneNumber;

        itemOfFlat.pujaName = userOdItem?.pujaName;
        itemOfFlat.orderBookingDate = getDateFormatedOrderTime(
          userOdItem?.orderBookingDate
        );
        let groupDetails = "";
        userOdItem?.orderUserDetails?.groupDetails?.map(
          (member: any, index: number) => {
            if (index !== 0) {
              groupDetails = `,` + groupDetails;
            }
            groupDetails =
              groupDetails +
              `( Name: ${member.fullName}, Gotram : ${member?.gotram ? member?.gotram : "Kashyap"
              }, DOB: ${member.dateOfBirth}, Gender: ${member.gender}, Rashi: ${member.rashi
              },)`;
          }
        );
        itemOfFlat.groupDetails = groupDetails;
        //payment deatils
        itemOfFlat.paymentStatus =
          userOdItem?.orderData?.payment?.paymentStatus?.toUpperCase();
        //itemOfFlat.transactionNumber = userOdItem?.orderData?.payment?.transactionId;
        itemOfFlat.paymentDate = getDateFormatedOrderTime(
          userOdItem?.orderData?.payment?.paymentDateTime
        );
        // const {
        //   basic,
        //   adminCommission,
        //   goodies,
        //   gst,
        //   packaging,
        //   platformFee,
        //   delivery,
        // } = userOdItem?.orderData?.priceBreakup;

        const {
          basic = 0,
          adminCommission = 0,
          goodies = 0,
          gst = 0,
          packaging = 0,
          platformFee = 0,
          delivery = 0,
        } = userOdItem?.orderData?.priceBreakup || {};

        const { orderValue } = userOdItem?.orderData?.payment;
        itemOfFlat.totalAmount = (
          Number(basic) +
          Number(adminCommission) +
          Number(goodies) +
          Number(gst) +
          Number(packaging) +
          Number(platformFee) +
          Number(delivery)
        ).toFixed(2);

        const {
          area,
          city,
          latitude,
          longitude,
          formatted_address,
          savedAs,
          state,
          street,
          zip,
          phoneNumber,
        } = userOdItem?.deliveryAddress;

        itemOfFlat.FullDeliveryAddress = `${itemOfFlat.orderUserName}, Phone Number: ${phoneNumber}, Street: ${street}, Area: ${area}, City: ${city}, State: ${state}, Zip: ${zip}`;
        itemOfFlat.zip = zip;
        itemOfFlat.state = state;
        //itemOfFlat.formattedAddress = formatted_address;
        itemOfFlat.coordinateAddress = `Latitude=${latitude}, Longitude=${longitude}`;

        // itemOfFlat.pujaDateTime ="";
        flatList.push(itemOfFlat);
      });

      setFlatOrderList(flatList);

      userOrder.map((userOdItem: any, index: number) => {
        let itemOfFlat: any = {};
        itemOfFlat.id = index;
        itemOfFlat.templeName = userOdItem?.templeDetails?.name;
        itemOfFlat.orderId = userOdItem?.orderId;
        itemOfFlat.orderStatus =
          userOdItem?.orderStatus[
            userOdItem?.orderStatus.length - 1
          ]?.orderStatus;
        itemOfFlat.pujaDate = getDateFormatedForBookingDate(
          userOdItem?.pujaDate
        );
        itemOfFlat.awbNumber =
          userOdItem?.orderStatus[
            userOdItem?.orderStatus.length - 1
          ]?.awbNumber;
        itemOfFlat.orderUserName = userOdItem?.orderUserDetails?.orderUserName;
        itemOfFlat.phoneNumber = userOdItem?.orderUserDetails?.phoneNumber;

        itemOfFlat.pujaName = userOdItem?.pujaName;
        itemOfFlat.orderBookingDate = getDateFormatedOrderTime(
          userOdItem?.orderBookingDate
        );

        let groupDetails = "";
        userOdItem?.orderUserDetails?.groupDetails?.map(
          (member: any, index: number) => {
            if (index !== 0) {
              groupDetails = `,` + groupDetails;
            }
            groupDetails =
              groupDetails +
              `( Name: ${member.fullName}, Gotram : ${member?.gotram ? member?.gotram : "Kashyap"
              }, DOB: ${member.dateOfBirth}, Gender: ${member.gender}, Rashi: ${member.rashi
              },)`;
          }
        );
        itemOfFlat.groupDetails = groupDetails;

        itemOfFlat.paymentDate = getDateFormatedOrderTime(
          userOdItem?.orderData?.payment?.paymentDateTime
        );
        const {
          basic = 0,
          adminCommission = 0,
          goodies = 0,
          gst = 0,
          packaging = 0,
          platformFee = 0,
          delivery = 0,
      } = userOdItem?.orderData?.priceBreakup || {};
        itemOfFlat.basicPrice = basic;
        itemOfFlat.adminCommissionPrice = adminCommission;
        itemOfFlat.goodiesPrice = goodies;
      
        itemOfFlat.packagingPrice = packaging;
        itemOfFlat.platformFeePrice = platformFee;
        itemOfFlat.deliveryPrice = delivery;
        let tamount = (
          Number(basic) +
          Number(adminCommission) +
          Number(goodies) +
          
          Number(packaging) +
          Number(platformFee) +
          Number(delivery)
        )
        let _gst:Number = (18/100)*tamount;
        itemOfFlat.totalAmount = (
         tamount
        ).toFixed(2);
        itemOfFlat.gstPrice = _gst?.toFixed(2);
        let totalAmountForTemple = Number(basic) + Number(adminCommission);
        itemOfFlat.totalAmountForTemple = totalAmountForTemple.toFixed(2);
        let tds = basic * 0.01;
        itemOfFlat.tdsDetuction = tds.toFixed(2);
        let amountToPay = totalAmountForTemple - tds;
        itemOfFlat.amountToPay = amountToPay.toFixed(2);

        const {
          paymentStatus,
          transactionId,
          paymentDateTime,
          paymentMode,
          paymentOrderId,
          orderValue,
        } = userOdItem?.orderData?.payment;
        itemOfFlat.orderValue = orderValue;
        itemOfFlat.paymentStatus = paymentStatus;
        itemOfFlat.transactionNumber = transactionId;
        //itemOfFlat.paymentDateTime = getDateFormatedOrderTime(paymentDateTime);
        itemOfFlat.awbNumber =
          userOdItem?.orderStatus[
            userOdItem?.orderStatus.length - 1
          ]?.awbNumber;
        itemOfFlat.paymentMode = paymentMode;
        itemOfFlat.paymentOrderId = paymentOrderId;
        itemOfFlat.platformFeePrice = platformFee;
        itemOfFlat.couponCode =
          userOdItem?.orderData?.payment?.couponCode?.couponCode;

        //itemOfFlat.deliveryAddress = flattenJSON(userOdItem?.deliveryAddress);
        const {
          area,
          city,
          latitude,
          longitude,
          formatted_address,
          savedAs,
          state,
          street,
          zip,
          phoneNumber,
        } = userOdItem?.deliveryAddress;
        itemOfFlat.FullDeliveryAddress = `${itemOfFlat.orderUserName}, Phone Number: ${phoneNumber}, Street: ${street}, Area: ${area}, City: ${city}, State: ${state}, Zip: ${zip}`;
        itemOfFlat.zip = zip;
        itemOfFlat.state = state;
        //itemOfFlat.formattedAddress = formatted_address;
        itemOfFlat.coordinateAddress = `Latitude=${latitude}, Longitude=${longitude}`;

        // itemOfFlat.pujaDateTime ="";
        exportList.push(itemOfFlat);
      });
      setExportOrderList(exportList);
    }
  }, [userOrder]);

  useEffect(() => {


    // dispatch(getAllTempleList("location"))
    //   .then(() => { })
    //   .catch((e: any) => {
    //     //console.log(e);
    //   });

    dispatch(getAllOrder(100, 100))
      .then(() => { })
      .catch((e: any) => {
        //console.log(e);
      });

    dispatch(getAllUsers())
      .then(() => { })
      .catch((e: any) => {
        //console.log(e);
      });


  }, [dispatch]);

  const convertArrayOfObjectsToCSV = (data: any[]): string => {
    if (data.length === 0) return "";

    const headers = Object.keys(data[0]);
    const csv = [
      headers?.map((item) => splitCamelCase(item))?.join(","), // Add headers as the first line
      ...data.map((item) =>
        headers.map((fieldName) => JSON.stringify(item[fieldName])).join(",")
      ),
    ].join("\n");

    return "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
  };

  function convertAndDownload(jsonData: any) {

    const csvData = jsonToCSV(jsonData);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = 'AllUserList.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  function jsonToCSV(jsonData: any) {
    const headers = Object.keys(jsonData[0]);
    const csv = jsonData.map((row: any) =>
     
      headers.map(fieldName =>
        JSON.stringify(row[fieldName])
      ).join(',')

    );
    return [headers.join(','), ...csv].join('\n');
  }

  const handleDownloadExcel = (type: string) => {
   
    let rawData: any = [];
    let fileName: string = "";
    if (type === 'users') {
      convertAndDownload(exportUserList)
      return;
    } else if (type === 'order') {
      fileName = "Orders";
      rawData = flatOrderList
    }
    else if (type === 'settelementorder') {
      fileName = "Settlements";
      rawData = exportOrderList
    }

    const csvData = convertArrayOfObjectsToCSV(rawData);
    const link = document.createElement("a");
    link.setAttribute("href", csvData);
    link.setAttribute("download", fileName + ".csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
      <AdminView
        allTempleList={allTempleList.organisation}
        allOrder={flatOrderList}
        settlementsOrder={exportOrderList}
        handleDownloadExcel={handleDownloadExcel}
      />
  );
}

export default AdminScreen;

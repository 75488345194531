import { Outlet, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { Link, useLocation } from "react-router-dom";
import {
  Box,
  Grid,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
} from "@mui/material";
import CartNoItem from "../../components/card/Cart/NoItemInCart";
import "./CheckoutV2View.scss";
import PujaStepsView from "../PujaSteps/PujaStepsView";
import { useDispatch, useSelector } from "react-redux";

function CheckoutV2View(props: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = (path: string) => {
    console.log("path", location.pathname);
    return location.pathname === path ? "active" : "inactive";
  };
  // console.log("Location---checkout view", location.pathname);
  const { cartInfo, selectedAddressInCart } = useSelector(
    (state: any) => state.cart
  );

  const steps = [
    "Puja/Chadhava Selection",
    "Add Devotee Details",
    "Review Details & Checkout",
  ];

  return (
    <Box
      className="pageContainer PujaDetailsView"
      style={{ minHeight: "50em" }}
    >
      <Box className="PujaDetailsViewBlock">
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            className="d-none d-md-block"
          ></Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box className="addPujaTab">
              {cartInfo && cartInfo.length > 0 && (
                <Box
                  sx={{
                    width: "100%",
                    borderBottom: "4px solid #fff",
                    paddingBottom: "1rem",
                    marginBottom: "1rem",
                    marginTop: "0.2rem",
                  }}
                >
                  <Stepper
                    activeStep={props.getStepNumber()}
                    alternativeLabel
                    connector={
                      <StepConnector
                        sx={{
                          "& .MuiStepConnector-line": {
                            borderColor: "#d56939",
                            borderTopWidth: "2px",
                          },
                        }}
                      />
                    }
                  >
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              )}
              {/* <PujaStepsView/> */}
              <Box className="tabsContents">
                <Outlet />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            className="d-none d-md-block"
          ></Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default CheckoutV2View;

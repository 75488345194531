import {showLoader, hideLoader} from './../slices/loaderSlice';
// import AsyncStorage from '@react-native-async-storage/async-storage';



export const setGlobalLoader =
  (loadingState:boolean) => async (dispatch: any) => {
    try {
        if(loadingState){
            dispatch(showLoader())
        }
        else{
            dispatch(hideLoader())
        }
      return Promise.resolve(0);
    } catch (error: any) {
      return Promise.reject('');
    }
  };

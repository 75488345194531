import kaliPuja from "../../assets/Kali.jpg";
import ganpati from "../../assets/ganpati-temple.jpg";
import jagannath from "../../assets/jagannath-temple.jpg";
import ganpatiprofile from "../../assets/ganpati-profile.jpg";
import redlike from "../../assets/redlike.png";
import share from "../../assets/share.png";
import postprofile from "../../assets/temple-post-profile.jpg";
import media from "../../assets/media.png";
import date from "../../assets/date.png";
import writepost from "../../assets/writepost.png";
import verifytick from "../../assets/verify-tick.png";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import LeftAddressCard from "../../components/card/LeftBlock/LeftAddressCard";
import RightBlockCard from "../../components/card/RightBlock/RightBlock";
import TempleDetailsCard from "../../components/card/Temple/TempleDetailsCard";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import BlogPostCard from "./BlogComponent/BlogPostCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  BLOG_CARD_RESPONSIVE,
  CAROUSEL_REMOVE_ARRAOW_ON_DEVICE,
} from "../../utils/Constants";
import "./PostsViews.scss";
import { useDispatch } from "react-redux";

import { useEffect, useState } from "react";
import { getAllBlogBYType } from "../../store/actions/BlogAction";
import { formatTimestamp } from "../../utils/HelperFunctions";

const PostsViews: React.FC<any> = ({ allPublishedPosts,parentViewCheck }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [posts,setPosts] = useState([])
  
  const handleReadMore = (id:any) => {
    const url = parentViewCheck ? `/posts/content/${id}`: `/nativeViews/posts/content/${id}`;
    navigate(url);
  };

  const handleViewAll = (type:String) => {
    console.log('---View All Clicked----')
    
    const blogCategoryUrl = parentViewCheck ? `/posts/category/${type}`: `/nativeViews/posts/category/${type}`;
    navigate(blogCategoryUrl);
  };
  // console.log(`PostsViews`, allPublishedPosts);



  return (
    <Box className={`${parentViewCheck ? 'pageContainer' : 'pageContainerBlogNative'} blogCardPage`}>
      <Box className="blogArea">
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            md={1}
            lg={1}
            xl={1}
            className="pagehdLeftBlock"
          ></Grid>
          <Grid item xs={12} md={10} lg={10} xl={10}>
            {Object.keys(allPublishedPosts).length > 0 &&
              Object.keys(allPublishedPosts).map((type: any, index: number) => (
                <Box
                  className="blogCenterBlock"
                  key={`blogCenterBlock${index}`}
                >
                  <Stack
                    className="blogCardTopContent"
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Typography className="blogTitle" variant="h6">
                      {type}
                    </Typography>
                    <Button
                      onClick={()=>handleViewAll(type)}
                      variant="text"
                      endIcon={<ArrowCircleRightRoundedIcon />}
                    >
                      View All
                    </Button>
                  </Stack>
                  <Carousel
                    responsive={BLOG_CARD_RESPONSIVE}
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    keyBoardControl={true}
                    removeArrowOnDeviceType={CAROUSEL_REMOVE_ARRAOW_ON_DEVICE}
                    className="blogCardCarousel"
                  >
                    {Array.isArray(allPublishedPosts[type]) &&
                    allPublishedPosts[type].length > 0 ? (
                      allPublishedPosts[type].map((item: any, i: number) => (
                        <BlogPostCard
                          key={`blogPostCard${i}`} 
                          image={item?.coverImageUrl}
                          title={item?.title} 
                          description={
                            item?.shortDescription
                          } 
                          author={
                            item?.author
                          }
                          updatedAt= {formatTimestamp(item?.updatedAt?.seconds,'MMMM DD,YYYY')}
                          blogId={item?.id}
                          onReadMore={handleReadMore}
                        />
                      ))
                    ) : (
                      <Typography>No posts available.</Typography> 
                    )}
                  </Carousel>
                </Box>
              ))}

            
          </Grid>
          <Grid item xs={12} md={1} lg={1} xl={1} className="pagehdRightBlock">
            {/* <RightBlockCard /> */}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PostsViews;

import {
  Box,
  Grid,
  Pagination,
  PaginationItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import BlogPostCard from "./BlogComponent/BlogPostCard";
import "./BlogCategoryView.scss";
import { formatTimestamp } from "../../utils/HelperFunctions";
import { theme } from "../../theme/MUITheme";
import { useState } from "react";

const ITEMS_PER_PAGE = 6;

const BlogCategoryView: React.FC<any> = ({
  allPostOfType,
  parentViewCheck,
  backToBlog,
}) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(allPostOfType.length / ITEMS_PER_PAGE);
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;

  const handlePageChange = (event: any, page: any) => {
    setCurrentPage(page);
  };

  const handleReadMore = (id: any) => {
    const url = parentViewCheck
      ? `/posts/content/${id}`
      : `/nativeViews/posts/content/${id}`;
    navigate(url);
  };

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { type } = useParams();

  const filteredPostsofType = allPostOfType
    .filter((item: any) => item.isPublished === true)
    .slice(indexOfFirstItem, indexOfLastItem);

  //  const currentItems = filteredPostsofType .slice(indexOfFirstItem, indexOfLastItem);

  const handleBackToBlog = () => {
    backToBlog();
  };

  return (
    <Box
      className={`${
        parentViewCheck ? "pageContainer" : "pageContainerBlogCategoryNative"
      } blogViewAllPage`}
    >
      <Box className="blogViewAllBlock">
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            md={1}
            lg={1}
            xl={1}
            className="pagehdLeftBlock"
          ></Grid>
          <Grid item xs={12} md={10} lg={10} xl={10}>
            {/* --- Click View All Section --- */}
            <Box className="viewAllContentBlock">
              <Box
                className="backToBlockBtn"
                onClick={(e) => {
                  e.preventDefault();
                  handleBackToBlog();
                }}
              >
                Back to Blog
              </Box>
              <Box className="viewAllTopTitle">
                <Typography>Category {type}</Typography>
              </Box>
              <Grid container direction="row">
                {filteredPostsofType?.map((post: any, i = 0) => {
                  return (
                    <Grid item xs={12} md={4} lg={4}>
                      <BlogPostCard
                        key={`blogPostCard${i}`}
                        image={post?.coverImageUrl}
                        title={post?.title}
                        description={post?.shortDescription}
                        author={post?.author}
                        updatedAt={formatTimestamp(
                          post?.updatedAt?.seconds,
                          "MMMM DD,YYYY"
                        )}
                        blogId={post?.id}
                        onReadMore={handleReadMore}
                      />
                    </Grid>
                  );
                })}
              </Grid>
              <Box className="bottomPagination">
                <Pagination
                  className="paginationArea"
                  siblingCount={isMobile ? 0 : 1}
                  boundaryCount={isMobile ? 0 : 1}
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      className={
                        item.type === "previous"
                          ? "prevButton"
                          : item.type === "next"
                          ? "nextButton"
                          : ""
                      }
                      components={{
                        previous: () => <span>Previous</span>,
                        next: () => <span>Next</span>,
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={1} lg={1} xl={1} className="pagehdRightBlock">
            {/* <RightBlockCard /> */}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default BlogCategoryView;

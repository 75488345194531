import React, { useEffect, useState } from 'react';
import { Select, MenuItem, Box } from '@mui/material';
import { capitalizeWords } from '../../../utils/HelperFunctions';
import './styles.scss';

interface FilterDropdownProps {
  analyticslist: string[];
  setKeyFilter: (value: string) => void;
  reset: boolean;
}

const FilterDropdown: React.FC<FilterDropdownProps> = ({ analyticslist, setKeyFilter, reset }) => {
  const [selectedOption, setSelectedOption] = useState<string>('');

  const handleChange = (event: any) => {
    const selectedValue = event.target.value as string;
    setSelectedOption(selectedValue);
    setKeyFilter(selectedValue);
  };


  useEffect(() => {

    if (reset) {
      setSelectedOption("");
    }

  }, [reset])

  return (
    <Box className="userAnalyticsBlock">
      <Select
        value={selectedOption}
        onChange={handleChange}
        displayEmpty
        fullWidth
        style={{ maxWidth: "98%" }}
        className='filterSelect'
      >
        <MenuItem value="" >
          Filter By Event Key
        </MenuItem>

        {analyticslist.map((option) => (
          <MenuItem key={option} value={option}>
            {capitalizeWords(option)}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default FilterDropdown;

import footerLogo from "../../assets/footerlogo.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import HomeIcon from "@mui/icons-material/Home";
import SearchIcon from "@mui/icons-material/Search";
import InstagramIcon from "@mui/icons-material/Instagram";
import VideocamIcon from "@mui/icons-material/Videocam";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LiveCamImg from "../../assets/live-darshan.png";
import BlogIconImg from "../../assets/blog-icon.png";
import "./Footer.scss";
import { Link, NavLink } from "react-router-dom";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import GavelIcon from "@mui/icons-material/Gavel";
import PaymentsIcon from "@mui/icons-material/Payments";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import PolicyIcon from "@mui/icons-material/Policy";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";

function Footer(props: any) {
  const { cartInfo } = useSelector((state: any) => state.cart);

  const currentYear = new Date().getFullYear();

  return (
    <Box className="footerArea">
      <Box className="lgFooter" sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} className="lgFtrmainGrid">
          <Grid item xs={0} sm={0} md={3} className="lgLtGrid"></Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container spacing={2} className="lgMainGrid">
              <Grid item xs={12} sm={4} md={4}>
                <Box className="wiget fsec1">
                  <img src={footerLogo} alt="" />
                  {/* <p>It will take you to a new spiritual experience.</p>
                  <p>Elevate your spiritual journey with Temple Dekho.</p> */}

                  <List className="companyInfoList">
                    <ListItem className="infoListItem">
                      <ListItemAvatar className="iconSec">
                        <i className="bi bi-house me-2"></i>
                      </ListItemAvatar>
                      <ListItemText
                        className="textSec"
                        primary="5th Floor Continental Chambers 15A, Hemanta Basu Sarani Kolkata - 700001"
                      />
                    </ListItem>

                    <ListItem className="infoListItem">
                      <ListItemAvatar className="iconSec">
                        <i className="bi bi-phone me-2"></i>
                      </ListItemAvatar>
                      <ListItemText
                        className="textSec"
                        primary="+91 90733 00055"
                      />
                    </ListItem>

                    <ListItem className="infoListItem">
                      <ListItemAvatar className="iconSec">
                        <i className="bi bi-envelope me-2"></i>
                      </ListItemAvatar>
                      <ListItemText
                        className="textSec"
                        primary="support@templedekho.com"
                      />
                    </ListItem>
                  </List>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={2}
                className="col-sm-5 col-6 lgCentGrid"
              >
                <Box className="wiget fsec2">
                  <h6>Links</h6>
                  <Box className="menuFooter">
                    <Link to="/">Home</Link>
                    <Link to="/temples">Temples</Link>
                    <Link to="/pujas">Pujas</Link>
                    <Link to="/livedarshan">Live Darshan</Link>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Box className="wiget fsec3">
                  <h6>About</h6>
                  <Box className="menuFooter">
                    <Box className="bottomMenuList2">
                      <ul>
                        <li>
                          <a href="/about">About Us</a>
                        </li>
                        <li>
                          <a href="/contactus">Contact Us</a>
                        </li>
                      </ul>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={0} sm={0} md={4} className="lgRtGrid">
                <Box className="wiget fsec3">
                  <h6>Information</h6>
                  <div className="bottomMenuList2">
                    <ul>
                      {/* <li>
              <a href="#">
                <InsertCommentIcon />
                Feedback
              </a>
            </li> */}
                      <li>
                        <a href="/refundscancellations">
                          {/* <PaymentsIcon /> */}
                          Refunds and Cancellations
                        </a>
                      </li>

                      <li>
                        <a href="/communityguidelines">
                          {/* <LocalLibraryIcon /> */}
                          Content & Community Guidlines
                        </a>
                      </li>

                      <li>
                        <a href="/privacypolicy">
                          {/* <PolicyIcon /> */}
                          Privacy Policy
                        </a>
                      </li>
                      <li>
                        <a href="/termsandconditions">
                          {/* <GavelIcon /> */}
                          Terms and Conditions
                        </a>
                      </li>

                      {/* <li>
              <a href="#">
                <SettingsIcon />
                Settings
              </a>
            </li> */}
                    </ul>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={0} sm={0} md={3} className="lgLtGrid"></Grid>
        </Grid>
        <Typography className="copyright-text">
          &#169; Copyright {currentYear},{" "}
          <a href="/privacypolicy">Temple Dekho</a> All Rights Reserved.
        </Typography>
      </Box>
      <Box className="smFooter">
        <Grid container spacing={3} className="footerMenu">
          <Grid item xs={2} className="menuItems">
            <NavLink to="/">
              <HomeIcon />
              <span>Home</span>
            </NavLink>
          </Grid>
          <Grid item xs={3} className="menuItems">
            <NavLink to="/search">
              <SearchIcon />
              <span>Search</span>
            </NavLink>
          </Grid>
          <Grid item xs={2} className="menuItems">
            <NavLink to="/posts">
              <img src={BlogIconImg} className="blogIcon"></img>
              <span>Blog</span>
            </NavLink>
          </Grid>
          <Grid item xs={3} className="menuItems">
            <NavLink to="/livedarshan">
              <img src={LiveCamImg}></img>
              <span>Live Darshan</span>
            </NavLink>
          </Grid>
          <Grid item xs={2} className="menuItems">
            <Box className="cartBlock">
              <NavLink to="/checkoutV2/reviewCart">
                <Box className="smCartIcon">
                  <ShoppingCartIcon />
                  {cartInfo && cartInfo.length > 0 ? (
                    <span className="translate-middle badge rounded-pill smBadge">
                      {cartInfo.length}
                    </span>
                  ) : null}
                </Box>
                <span>Cart</span>
              </NavLink>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Footer;

import kaliPuja from "../../assets/Kali.jpg";
import ganpati from "../../assets/ganpati-temple.jpg";
import jagannath from "../../assets/jagannath-temple.jpg";
import ganpatiprofile from "../../assets/ganpati-profile.jpg";
import redlike from "../../assets/redlike.png";
import share from "../../assets/share.png";
import postprofile from "../../assets/temple-post-profile.jpg";
import media from "../../assets/media.png";
import date from "../../assets/date.png";
import writepost from "../../assets/writepost.png";
import verifytick from "../../assets/verify-tick.png";
import { Outlet, useNavigate } from "react-router-dom";
// import "./TempleView.scss";
import Carousel from "react-multi-carousel";
import LeftAddressCard from "../../components/card/LeftBlock/LeftAddressCard";
import RightBlockCard from "../../components/card/RightBlock/RightBlock";
import TempleDetailsCard from "../../components/card/Temple/TempleDetailsCard";
import { Box, Grid } from "@mui/material";

function ChadhavaView(props: any) {
  return (
    <Box className="pageContainer templePage">
      <Box className="templeBlock">
        <Grid container spacing={1}>
          <Grid item xs={12} md={2} lg={2} xl={2} className="pagehdLeftBlock">
            {/* <LeftAddressCard
              header={props.TDTexts.CURRENTADDRESS}
              address={
                props.userAddressList && props.userAddressList.length > 0
                  ? props.userAddressList[0].formatted_address
                  : ""
              }
              footerButton="Edit or add an address"
            /> */}
          </Grid>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <Box className="templeCenterBlock">
              {props.templeList &&
                props.templeList.map((item: any, id: any) => {
                  return (
                    <TempleDetailsCard
                      id={id}
                      kwy={`templeCenterBlock${id}`}
                      templeData={item}
                      templePictures={
                        item.personalInfo &&
                          item.personalInfo.media &&
                          item.personalInfo.media.pictures
                          ? item.personalInfo.media.pictures
                          : []
                      }
                      templeImage={item.personalInfo &&
                        item.personalInfo.media &&
                        item.personalInfo.media.pictures && item.personalInfo.media.pictures.length > 0
                        ? item.personalInfo.media.pictures[0].pictureUrl
                        : ganpati}
                      verificationStatus={item.verifications.status}
                      templeName={item.personalInfo.name}
                      descriptions={item.personalInfo.descriptions}
                      // descriptions='The Jagannath Temple is an important Hindu temple dedicated to Jagannath, a form of Vishnu–one of the trinity of supreme'
                      selectTemple={props.selectTemple}
                      googleData={item.googleData}
                    />
                  );
                })}
            </Box>
          </Grid>
          <Grid item xs={12} md={2} lg={2} xl={2} className="pagehdRightBlock">
            {/* <RightBlockCard /> */}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default ChadhavaView;
